import React from 'react';

import { useMCActivityReportTemplateById } from 'plantiga-component/Activity/Report/useMCActivityReportTemplate';

import Crumb from './Crumb';

type Props = { templateId: string } & Omit<React.ComponentProps<typeof Crumb>, 'text'>;

export default function ReportTemplateCrumb({ templateId, ...props }: Props) {
  const template = useMCActivityReportTemplateById(templateId);
  return <Crumb {...props} text={template?.title ?? '...'} />;
}
