import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  Archive as ArchiveIcon,
  CallMerge as AddInsoleIcon,
  MoreVert as MoreVertIcon,
  Unarchive as UnarchiveIcon,
} from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Menu, MenuItem } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';
import ConfirmDialog from 'plantiga-common/ConfirmDialog';
import { PairAthleteToPodsDialog } from 'plantiga-component/Devices/PairAthleteToPodsDialog';
import { useEditAthlete } from 'plantiga-firebase/Athletes/actions';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

const useStyles = makeStyles()((theme) => ({
  box: {
    pointerEvents: 'all',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  readonly athleteId: string;
};

export function MoreMenuSkeleton() {
  return (
    <IconButton aria-label="more" size="large">
      <MoreVertIcon />
    </IconButton>
  );
}

function MoreMenu({ athleteId }: Props) {
  const { classes } = useStyles();
  const athlete = useAthlete(athleteId);
  const [openPair, setOpenPair] = React.useState(false);
  const [openArchive, setOpenArchive] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const { editAthlete, loading } = useEditAthlete();

  const handleClick = (e: any) => {
    const { currentTarget } = e;
    e.stopPropagation();
    setAnchorEl(currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handlePair = () => {
    setOpenPair(true);
    handleClose();
  };

  const handleArchive = async () => {
    if (athlete) await editAthlete(athleteId, { archived: !athlete.archived });
    setOpenArchive(false);
  };

  return (
    <Box className={classes.box} onClick={(e: Event) => e.stopPropagation()}>
      <IconButton aria-label="more" onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`${athleteId}-more-menu`}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handlePair} className={classes.menuItem}>
          Pair Pods
          <AddInsoleIcon color="action" className={classes.icon} />
        </MenuItem>
        <MenuItem onClick={() => setOpenArchive(true)} className={classes.menuItem}>
          {athlete && (
            <>
              {athlete.archived ? 'Unarchive' : 'Archive'}
              {athlete.archived ? (
                <UnarchiveIcon color="action" className={classes.icon} />
              ) : (
                <ArchiveIcon color="action" className={classes.icon} />
              )}
            </>
          )}
        </MenuItem>
        {loading && <LinearProgress />}
      </Menu>
      {openPair ? (
        <PairAthleteToPodsDialog
          athleteId={athleteId}
          open={openPair}
          onClose={() => setOpenPair(false)}
        />
      ) : null}
      {openArchive ? (
        <ConfirmDialog
          open={openArchive}
          message="You can always unarchive this person at any time."
          onClose={() => setOpenArchive(false)}
          onConfirm={handleArchive}
        />
      ) : null}
    </Box>
  );
}

export default function MoreMenuGuard(props: Props) {
  const canUpdatePerson = usePrivilege('person:update');
  if (!canUpdatePerson) return null;
  return <MoreMenu {...props} />;
}
