import React from 'react';

import { Button } from '@mui/material';

import { useNavigateURL } from 'plantiga-common/useBuildURL';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';

import DeleteActivityDialog from './DeleteActivityDialog';

type Props = {
  readonly activity: Activity;
  readonly activityId: string;
};

export function DeleteActivity({ activity, activityId }: Props) {
  const athleteId = activity.athlete_id;
  const navigateURL = useNavigateURL();
  const [requestedDelete, setRequestedDelete] = React.useState(false);

  return (
    <div>
      <Button
        disabled={requestedDelete}
        color="error"
        variant="contained"
        onClick={() => setRequestedDelete(true)}
      >
        Delete
      </Button>
      <DeleteActivityDialog
        activity={activity}
        activityId={activityId}
        open={requestedDelete}
        onCancel={() => setRequestedDelete(false)}
        onDelete={() => navigateURL('athlete', { athleteId })}
      />
    </div>
  );
}
