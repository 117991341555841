import React from 'react';

import { noop } from 'lodash-es';
import { useLocalStorage } from 'usehooks-ts';

type ContextType = [boolean, () => void];

export const Context: React.Context<ContextType> = React.createContext<ContextType>([false, noop]);

type Props = {
  readonly children: any;
};

const FULL_WIDTH_LOCAL_KEY = 'plantiga_page_full_width';

function triggerWindowResize() {
  window.dispatchEvent(new Event('resize'));
}

export default function FullWidthPageProvider({ children }: Props) {
  const [fullWidth, setFullWidth] = useLocalStorage(FULL_WIDTH_LOCAL_KEY, false);
  const toggle = React.useCallback(() => {
    setFullWidth((p) => !p);
    triggerWindowResize();
  }, [setFullWidth]);
  const value = React.useMemo(() => [fullWidth, toggle] as ContextType, [fullWidth, toggle]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
