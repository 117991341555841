import React from 'react';

import { ChiralityAvatar } from 'plantiga-component/Devices/Avatar';

import type { FieldDefinition, UnitType } from './typedefs';

const CHIRALITY = {
  left: 'left',
  right: 'right',
  leg: 'leg',
};

const ABBREVIATIONS = {
  maximum: 'Max',
  minimum: 'Min',
  average: 'Avg',
  // distance: 'Dis',
  leg: '',
  height: 'Hgt.',
  left: (<ChiralityAvatar location="L" />) as React.ReactNode,
  right: (<ChiralityAvatar location="R" />) as React.ReactNode,
  asymmetry: 'Asym.',
  acceleration: 'Accel.',
  vertical: 'Vert.',
  horizontal: 'Horz.',
  'reactive strength index': 'RSI',
};

export function abbreviateFieldName(text: string) {
  return Object.entries(ABBREVIATIONS).reduce(
    (nextTexts, [word, replacement]) =>
      nextTexts.flatMap((t) => {
        if (typeof t !== 'string') {
          return [t];
        }

        const re = new RegExp(word, 'gi');
        const result = [];
        let lastIndex = 0;
        let match;

        // eslint-disable-next-line no-cond-assign
        while ((match = re.exec(t)) != null) {
          result.push(t.slice(lastIndex, match.index));
          result.push(replacement);
          lastIndex = match.index + match[0].length;
        }

        result.push(t.slice(lastIndex));

        return result;
      }),

    [text] as React.ReactNode[],
  );
}

export function omitChirality(nodes: React.ReactNode[]) {
  return Object.entries(CHIRALITY).reduce(
    (nextTexts, [word]) =>
      nextTexts.flatMap((t) => {
        if (React.isValidElement<typeof ChiralityAvatar>(t)) {
          return [];
        }
        if (typeof t !== 'string') {
          return [t];
        }

        const re = new RegExp(word, 'gi');
        const result = [];
        let lastIndex = 0;
        let match;

        // eslint-disable-next-line no-cond-assign
        while ((match = re.exec(t)) != null) {
          result.push(t.slice(lastIndex, match.index));
          lastIndex = match.index + match[0].length;
        }

        result.push(t.slice(lastIndex));

        return result;
      }),
    nodes,
  );
}

/**
 * Get the correct name for the field depending on the `unitType` specified.
 */
export default function formatFieldName(
  field: Pick<FieldDefinition, 'name' | 'alt_name' | 'displayUnit'>,
  unitType: UnitType,
  options: {
    abbreviate?: boolean;
    omitChirality?: boolean;
  } = {},
): NonNullable<React.ReactNode> {
  const unit = typeof field?.displayUnit === 'object' ? field.displayUnit[unitType] : null;
  const fieldName = unit ? field?.alt_name?.[unit] ?? field.name : field.name;
  const name = options.abbreviate ? abbreviateFieldName(fieldName) : [fieldName];
  return options.omitChirality ? omitChirality(name) : name;
}
