import { serverTimestamp } from 'firebase/firestore';
import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Collapse, Typography } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';
import type { Stopwatch } from 'plantiga-firebase/Stopwatch/typedefs';
import { useUpdateStopwatch } from 'plantiga-firebase/Stopwatch/useStopwatch';
import { MAX_STOPWATCH_TIME } from 'plantiga-util/constants';

import { Arc5Pod } from './Arc5Pod';
import { CALIBRATION_DURATION, START_SPLASH_DURATION } from './util';

const useStyles = makeStyles()((theme) => ({
  button: {
    minWidth: 96,
    borderRadius: 20,
    // ensure the splash button covers the running button
    zIndex: 1,
  },
  pageGutterBottomContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'initial',
    },
  },
  fullScreen: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: theme.zIndex.appBar,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.plantiga.charcoal,
    color: theme.palette.primary.contrastText,
  },
  timer: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  calibration: {
    display: 'grid',
    position: 'absolute',
    gap: theme.spacing(2),
    maxWidth: 320,
  },
  pods: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: 16,
  },
}));

type Props = {
  readonly formattedTime: any;
  readonly elapsedTime: number;
  readonly swid: string;
  readonly stopwatch: Stopwatch;
  onHide: () => void;
  onShow: () => void;
};

export default function CountDownSplash({
  formattedTime,
  elapsedTime,
  swid,
  stopwatch,
  onHide,
  onShow,
}: Props) {
  const { classes } = useStyles();
  const [loading, update] = useUpdateStopwatch(swid);

  const start = stopwatch?.start ?? null;

  const handleStopActivityNow = React.useCallback(async () => {
    const endTime = new Date(Math.min(Date.now(), start.toMillis() + MAX_STOPWATCH_TIME));
    await update({
      end: endTime,
      server_end: serverTimestamp(),
      run_state: 'stopped',
    });
  }, [start, update]);

  // hide the splash after 5 seconds
  const starting = elapsedTime < START_SPLASH_DURATION;
  const progress = () => {
    if (starting) {
      return (100 * elapsedTime) / CALIBRATION_DURATION;
    }
    return 100;
  };
  const calibrating = progress() >= 0 && progress() < 100;

  React.useEffect(() => {
    if (!starting) {
      onHide();
    } else {
      onShow();
    }
  }, [starting, onHide, onShow]);

  if (!starting) return null;
  return (
    <div className={classes.fullScreen}>
      <div className={classes.timer}>{formattedTime}</div>
      <div className={classes.calibration}>
        <Collapse in={calibrating && starting}>
          <div>
            <Typography variant="h5" align="center">
              Calibrating, don&apos;t move
            </Typography>
            <div className={classes.pods}>
              <Arc5Pod location="left" progress={progress()} jitter={5} />
              <Arc5Pod location="right" progress={progress()} jitter={5} />
            </div>
          </div>
        </Collapse>
        <Collapse in={starting && !calibrating}>
          <div>
            <Typography variant="h5" align="center">
              Calibration complete
            </Typography>
            <Typography variant="h5" align="center">
              You can begin your activity
            </Typography>
          </div>
        </Collapse>
      </div>
      <PageGutterPortal gutter="bottom">
        <div className={classes.pageGutterBottomContainer}>
          <ButtonWithLoading
            id="stopwatch-stop-button"
            className={classes.button}
            onClick={handleStopActivityNow}
            loading={loading}
            disabled={false}
            color="secondary"
            variant="contained"
          >
            Stop
          </ButtonWithLoading>
        </div>
      </PageGutterPortal>
    </div>
  );
}
