import React from 'react';

import { sortBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Checkbox,
  Typography,
  TextField,
  Autocomplete,
  type AutocompleteInputChangeReason,
} from '@mui/material';

import { splitDefaultAthletesFromAthletes } from 'plantiga-common/DefaultAthletes';
import VirtualList from 'plantiga-common/VirtualList';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import type { Athlete } from 'plantiga-firebase/schema';
import useTeam from 'plantiga-firebase/Team/useTeam';
import { useLocalLRU } from 'plantiga-util/useLocalLRU';

import AthleteAvatar from '../Athletes/Avatar';

import useBreakpoint from './useBreakpoint';

const useStyles = makeStyles()((theme) => ({
  listbox: {
    padding: 0,
    maxHeight: 240,
  },
  endAdornment: {
    // an unfortunate value needed to align the icon with our other select components
    marginRight: 10,
  },
  typography: {
    marginLeft: theme.spacing(2),
  },
}));

const SELECT_ALL_ID = 'select-all';

type MultiSelectProps = {
  multiple: true;
  selectAll?: true;
  athleteIds: string[];
  onChange: (athleteIds: string[]) => void;
};

type SingleSelectProps = {
  multiple?: false; // allow the value to be omitted, but still destructured
  selectAll?: undefined;
  athleteIds?: string[]; // allow the value to be omitted, but still destructured
  onChange: (athleteId: string) => void;
};

type Props = (MultiSelectProps | SingleSelectProps) & {
  size?: 'small' | 'medium';
  open?: boolean; // force the open state of the autocomplete from the parent
  disableSecondaryTeamIndividuals?: boolean;
} & Partial<Omit<React.ComponentProps<typeof Autocomplete>, 'onChange' | 'multiple'>>;

function useTeamAthletes() {
  const { teamId } = useTeam();
  const athletes = useAthletes();
  return React.useMemo(() => {
    const [, teamAthletes] = splitDefaultAthletesFromAthletes(teamId, athletes);
    return sortBy(teamAthletes, ({ name }) => name) as Athlete[];
  }, [athletes, teamId]);
}

export default function AutocompletePeople({
  athleteIds = [],
  multiple,
  selectAll = undefined,
  onChange,
  size = 'small',
  open: _open = false,
  disableSecondaryTeamIndividuals = false,
}: Props) {
  const { classes } = useStyles();
  const unFilteredAthletes = useTeamAthletes();
  const isMobile = useBreakpoint();
  const inputRef = React.useRef<HTMLInputElement | undefined>();
  const initialized = React.useRef(false);
  const [open, setOpen] = React.useState(_open);
  const [search, setSearch] = React.useState('');
  const selectedAthleteIds = React.useMemo(() => new Set(athleteIds), [athleteIds]);
  const athletes = React.useMemo(() => {
    if (!disableSecondaryTeamIndividuals) return unFilteredAthletes;
    return unFilteredAthletes.filter((v) => !v.secondary_team);
  }, [unFilteredAthletes, disableSecondaryTeamIndividuals]);
  const [lru, updateLru] = useLocalLRU<string>('autocomplete-people-lru');

  const allSelected = selectedAthleteIds.size === athletes.length;

  // open the autocomplete from the parent
  React.useEffect(() => {
    if (_open) inputRef.current?.focus();
    setOpen((p) => _open || p);
  }, [_open]);

  // auto select when only one person on the team
  React.useEffect(() => {
    if (
      initialized.current === false &&
      open &&
      athletes.length === 1 &&
      athletes[0]?.id !== undefined
    ) {
      if (multiple) {
        onChange([athletes[0].id]);
      } else {
        onChange(athletes[0].id);
      }
      initialized.current = true;
      setOpen(false);
    }
  }, [open, athletes, onChange, multiple]);

  const handleChange = React.useCallback(
    (_: React.SyntheticEvent, v: { id: string } | { id: string }[] | null, r: string) => {
      // reset the search
      if (['clear', 'selectOption'].includes(r)) setSearch('');

      if (!['clear', 'selectOption', 'removeOption'].includes(r)) return;
      if (v == null) return;

      if (multiple === true && Array.isArray(v) && v.find((o) => o.id === SELECT_ALL_ID)) {
        // handle select-all
        onChange(allSelected ? [] : athletes.map(({ id }) => id));
      } else if (multiple === true && Array.isArray(v)) {
        // handle select multiple
        const ids = v.map(({ id }) => id);
        onChange(ids);
        // most recent selections will be the last elements
        updateLru(ids.toReversed());
      } else if (!multiple && 'id' in v) {
        // handle select single
        onChange(v.id);
        updateLru([v.id]);
      }
    },
    [onChange, multiple, athletes, allSelected, updateLru],
  );

  const handleInputChange = React.useCallback(
    (_: React.SyntheticEvent, v: string, r: AutocompleteInputChangeReason) => {
      if (r === 'input') {
        setSearch(v);
      }
    },
    [],
  );

  const selectedAthletes = React.useMemo(
    () => athletes.filter((v) => selectedAthleteIds.has(v.id)),
    [athletes, selectedAthleteIds],
  );

  const handleClose = (_: unknown, reason: string) => {
    if (multiple && reason === 'selectOption') return;
    if (multiple && reason === 'removeOption') return;
    setOpen(false);
  };

  const options = React.useMemo(() => {
    const sorted = sortBy(athletes, [
      (o) => {
        const i = lru.indexOf(o.id);
        return i < 0 ? Infinity : i;
      },
      'name',
    ]);
    if (!selectAll) return sorted;
    return [{ id: SELECT_ALL_ID, name: 'Select All' } as const, ...sorted];
  }, [athletes, lru, selectAll]);

  return (
    <Autocomplete
      openOnFocus
      multiple={multiple}
      disableClearable={!multiple}
      ChipProps={{ size, variant: 'outlined' }}
      limitTags={isMobile ? 1 : 3}
      inputValue={search}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onChange={handleChange}
      options={options}
      value={multiple ? selectedAthletes : selectedAthletes?.[0]}
      classes={{ listbox: classes.listbox, endAdornment: classes.endAdornment }}
      ListboxComponent={VirtualList}
      ListboxProps={{ itemSize: 48, overscanCount: 3 } as any}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {multiple ? (
            <Checkbox
              size="small"
              checked={selected || (option.id === SELECT_ALL_ID && allSelected)}
            />
          ) : null}
          {option.id !== SELECT_ALL_ID && <AthleteAvatar chip athlete={option} />}
          <Typography noWrap variant="subtitle2" className={classes.typography}>
            {option.name}
          </Typography>
        </li>
      )}
      popupIcon={<ExpandMoreIcon />}
      renderInput={(params) => (
        <div>
          <TextField
            {...params}
            inputRef={inputRef}
            size={size}
            placeholder="Search"
            variant="outlined"
            label="Select People"
          />
        </div>
      )}
    />
  );
}
