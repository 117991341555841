import { formatISO, startOfDay } from 'date-fns';
import { groupBy, sumBy, maxBy, minBy, meanBy } from 'lodash-es';

import type { flattenMetricValues } from 'plantiga-component/Metric/useMetricsTrends';

const DEFAULT_DATE_FORMATTER = (date: Date) => formatISO(date, { representation: 'date' });

const getAggregator = (aggregation: string | undefined) => {
  switch (aggregation) {
    case 'max':
      return (v: any[], k: string) => maxBy(v, k)?.[k] ?? 0;
    case 'min':
      return (v: any[], k: string) => minBy(v, k)?.[k] ?? 0;
    case 'sum':
      return sumBy;
    case 'mean':
      return meanBy;
    default:
      return meanBy;
  }
};

export function aggregateByDate(
  data: ReturnType<typeof flattenMetricValues>,
  aggregation: string | undefined,
  dateGroupFormatter: (date: Date) => string = DEFAULT_DATE_FORMATTER,
) {
  const groupedByDate = groupBy(data, ({ date }) => {
    const d = new Date(date);
    return dateGroupFormatter(d);
  });

  const aggregator = getAggregator(aggregation);
  return Object.values(groupedByDate).map((values) => ({
    date: startOfDay(new Date(values[0].date)),
    value: aggregator(values, 'value'),
    values,
  }));
}
