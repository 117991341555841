import type { Device } from '../components/Firestore/Devices/typedefs';

const STATUS_TIMEOUT_MILLIS = 60 * 60 * 1000;

const ERASE_STATUS_TIMEOUT_MILLIS = 20 * 60 * 1000;

/**
@param device: the device object from firestore

If the device was cleanly undocked, then return its status as-is

The function will return unknown if:
 * device is using an old uploader app (released before Feb 26, 2019)
 * device is docked and has a stale status (last_status field)

*/
export default (device: Device): string => {
  const { status, broken } = device;
  if (broken === true) {
    return 'broken';
  }
  // If the property is missing, don't know what the status
  if (status == null) {
    return 'unknown';
  }
  if (typeof status !== 'string') {
    return 'unknown';
  }

  // Earlier versions of UploadApp did not set the status timestamp
  const lastStatus = device.last_status;
  if (!lastStatus) {
    return 'unknown';
  }

  if (device.docked === false) {
    return 'undocked';
  }

  // Give erase longer timeout to report back
  const elapsedTime = Date.now() - lastStatus.toMillis();
  if (status === 'erasing' && elapsedTime < ERASE_STATUS_TIMEOUT_MILLIS) {
    return status;
  }

  // If it hasn't reported status (without undocking), the app could have crashed
  if (elapsedTime >= STATUS_TIMEOUT_MILLIS) {
    return 'unknown';
  }

  if (status === 'charging') {
    return 'data uploaded';
  }

  return status;
};
