import day from './[day]/_urls';

export const root = '/org/:teamId/people/:athleteId/daily';

const deprecatedUrls = {
  athleteDashboard: '/org/:teamId/people/:athleteId/dashboard',
  athleteFeed: '/org/:teamId/people/:athleteId/feed',
  athleteFeedDay: '/org/:teamId/people/:athleteId/feed/:day',
  athleteFeedActivityDay: '/org/:teamId/people/:athleteId/feed/:day/activityType/:type',
} as const;

export default {
  athleteDaily: `${root}/:day?`,

  ...deprecatedUrls,
  ...day,
} as const;
