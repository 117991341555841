import React from 'react';

import { Event } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import Popover from 'plantiga-common/Popover';
import CalendarSkeleton from 'plantiga-common/SlidingCalendar/Skeleton';
import { useNavigateURL } from 'plantiga-common/useBuildURL';

const SlidingCalendar = React.lazy(() => import('plantiga-common/SlidingCalendar'));

type Props = { athleteId?: string };

export default function DailySecondaryAction({ athleteId }: Props) {
  const navigateURL = useNavigateURL();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleChange = React.useCallback(
    (activityId: string) => {
      navigateURL('athleteActivities', { athleteId, activityId });
      setAnchorEl(null);
    },
    [navigateURL, athleteId],
  );

  return (
    <>
      <Tooltip title="Search activities">
        <IconButton
          id="nav-search-activities-button"
          aria-label="search activities"
          size="small"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        >
          <Event fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <React.Suspense fallback={<CalendarSkeleton />}>
          <SlidingCalendar athleteId={athleteId!} onChange={handleChange} />
        </React.Suspense>
      </Popover>
    </>
  );
}
