import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Badge,
  Icon,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from 'plantiga-util/MUI/makeStyles';

import SmallCircleIcon from '../../../icons/SmallCircleIcon';

const ICON_SIZE = 16;
const ICON_GAP = 4;
const useStyles = makeStyles()((theme) => ({
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      // provide a bigger target for thumbs on mobile
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  listItemTextSelected: {
    fontWeight: 'bold',
  },
  listItemIcon: {
    fontSize: ICON_SIZE,
    minWidth: 0,
    // give space for the dropdown icon
    width: ICON_SIZE * 2 + ICON_GAP,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    '& .MuiBadge-root': {
      verticalAlign: 'unset',
    },
    '& .MuiBadge-badge:not(.MuiBadge-invisible)': {
      transform: 'scale(0.75) translate(50%, -50%)',
    },
  },
  icon: {
    overflow: 'visible',
    color: theme.palette.plantiga.green,
    marginTop: -2, // adjust the icon for visual alignment
    '& svg': {
      fontSize: 'inherit',
    },
  },
  badge: {
    fontSize: '.5rem',
  },
}));

type Props = {
  id: string;
  text: string;
  subheader?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  dropDownIcon?: React.ReactElement<any> | null;
  icon?: React.ReactElement<any> | null;
  secondaryAction?: React.ReactNode;
  selected?: boolean;
  depth?: number;
  href?: string;
  to?: string;
  badgeContent?: React.ReactNode;
};

export default function DrawerItem({
  id,
  text,
  subheader,
  onClick,
  className,
  disabled = false,
  dropDownIcon = null,
  icon = null,
  secondaryAction = null,
  selected = false,
  depth = 0,
  badgeContent = undefined,
  href,
  to,
}: Props) {
  const { classes, cx } = useStyles();
  const theme = useTheme();

  let component;
  if (to) {
    component = RouterLink;
  } else if (href) {
    component = 'a';
  }

  return (
    <ListItem
      button
      disabled={disabled}
      id={id}
      className={cx(classes.listItem, className)}
      style={{ paddingLeft: parseInt(theme.spacing(depth * 3), 10) + ICON_GAP }}
      onClick={onClick}
      selected={selected}
      to={to}
      href={href}
      component={component}
    >
      <ListItemIcon className={classes.listItemIcon}>
        {dropDownIcon ?? <span />}
        <Icon fontSize="inherit" className={classes.icon}>
          {icon ?? <SmallCircleIcon />}
        </Icon>
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{ className: cx({ [classes.listItemTextSelected]: selected }) }}
        secondary={subheader}
      >
        <Badge badgeContent={badgeContent} classes={{ badge: classes.badge }}>
          {text}
        </Badge>
      </ListItemText>
      <ListItemSecondaryAction>{secondaryAction}</ListItemSecondaryAction>
    </ListItem>
  );
}
