import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Block, Check, CloudUpload, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import { useUpload } from '../../Firestore/Storage/useFiles';

type Props = {
  readonly pathPrefix: string;
  readonly file: any;
  readonly onComplete?: () => void;
  readonly onCancel: (arg1: any) => void;
};

const useStyles = makeStyles()({
  progress: {
    position: 'absolute',
  },
});

export default function FileUploadItem({ pathPrefix, file, onComplete, onCancel }: Props) {
  const { classes } = useStyles();
  const { progress, cancel, completed } = useUpload(pathPrefix, file, { onComplete });

  return (
    <ListItem key={file.name}>
      <ListItemAvatar>
        <Avatar>
          <CircularProgress
            size={40}
            value={progress}
            variant={progress > 1 ? 'determinate' : 'indeterminate'}
            className={classes.progress}
          />
          {completed ? <Check /> : <CloudUpload />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={file.name} />
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="Delete"
          onClick={() => {
            cancel();
            onCancel(file);
          }}
          size="large"
        >
          {completed ? <DeleteIcon /> : <Block />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
