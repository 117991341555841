import { DocumentReference } from 'firebase/firestore';
import React from 'react';

import { useField } from 'plantiga-common/Fields/useFields';
import useFeatureFlag from 'plantiga-firebase/FeatureFlags/useFeatureFlag';
import { useUnitTranslator } from 'plantiga-firebase/Team/universalUnitTranslator';
import { getSummaryValue } from 'plantiga-util/summaries';

import { doc } from '..';
import type { MetaSummary } from '../schema';
import useFirestore from '../useFirestore';
import { useSubscribeDocument } from '../useSubscribe';

import type { Summary } from './typedefs';

export function useSummary<T extends MetaSummary>(
  athleteId: string,
  activityId: string | null | undefined,
  summaryType: string,
): {
  loading: boolean;
  summary: T | null | undefined;
} {
  const db = useFirestore();

  const ref = React.useMemo(() => {
    if (activityId == null) {
      return null;
    }
    // TODO: this does not work for mixed activity metrics
    const docId = `${activityId}_${summaryType}`;
    return doc(db, 'athletes', athleteId, 'summaries', docId) as DocumentReference<T>;
  }, [db, athleteId, activityId, summaryType]);

  const [summary, loading] = useSubscribeDocument(ref);

  return { summary, loading };
}

export type SummarySnaps = Array<
  {
    id: string;
  } & Summary
>;

const formatOptions = { nDigits: 3 };

export function useActivityMetric(
  athleteId: string,
  activityId: string | null | undefined,
  fieldId: string,
): {
  storedValue: number | null | undefined;
  metricValue: string;
  reason: string | null | undefined;
  reasonType: 'info' | 'warning';
  loading: boolean;
} {
  const fieldInfo = useField(fieldId);
  const wtfFF = useFeatureFlag('wtf');
  const { translate } = useUnitTranslator(
    fieldInfo.storedUnit,
    fieldInfo.displayUnit,
    formatOptions,
  );
  const { loading: loadingSummary, summary } = useSummary(
    athleteId,
    activityId,
    fieldInfo.summary_type,
  );
  const { loading: loadingWtfSummary, summary: wtfSummary } = useSummary(
    athleteId,
    activityId,
    'wtf_summary',
  );
  const [storedValue, reason, reasonType] = React.useMemo(
    () => getSummaryValue(summary, fieldInfo, wtfFF ? wtfSummary : null),
    [fieldInfo, summary, wtfFF, wtfSummary],
  );

  const metricValue = translate(storedValue) as string;

  const loading = loadingSummary || loadingWtfSummary;
  return React.useMemo(
    () => ({ storedValue, metricValue, reason, reasonType, loading }),
    [loading, reason, reasonType, storedValue, metricValue],
  );
}
