import React from 'react';

import { PersonAdd as PersonAddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';

import AddPersonDialog from './AddPersonDialog';

export default function CreatePersonButton() {
  const [open, setOpen] = React.useState(false);
  const canCreatePerson = usePrivilege('person:create');
  if (!canCreatePerson) return null;
  return (
    <>
      <Button
        variant="outlined"
        size="large"
        onClick={() => setOpen(true)}
        endIcon={<PersonAddIcon />}
      >
        Add Person
      </Button>
      <AddPersonDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
}
