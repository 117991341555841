import React from 'react';

import { Edit as EditIcon } from '@mui/icons-material';
import { Button, DialogContent, IconButton } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';
import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';
import useUpdatEvent from 'plantiga-firebase/Events/useUpdateEvent';

import EditEvent from './EditEventForm';

type Props = {
  readonly athleteId: string;
  readonly eventId: string;
  readonly event: PlantigaEvent;
};

export default function EditEventIcon({ athleteId, eventId, event: initialEvent }: Props) {
  const canUpdateEvent = usePrivilege('event:update');
  const { update: updateEvent, loading } = useUpdatEvent();
  const [open, setOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const [event, setEvent] = React.useState(initialEvent);

  const handleChange = React.useCallback((updatedEvent: PlantigaEvent, isValid: boolean) => {
    setDisabled(!isValid);
    setEvent(updatedEvent);
  }, []);

  const handleUpdate = React.useCallback(async () => {
    await updateEvent(eventId, event);
    setOpen(false);
  }, [updateEvent, event, eventId]);

  const actionButtons = React.useMemo(
    () => [
      <Button key="dialog-button-1" onClick={() => setOpen(false)}>
        Cancel
      </Button>,
      <ButtonWithLoading
        key="dialog-button-2"
        color="primary"
        loading={loading}
        disabled={disabled}
        onClick={handleUpdate}
      >
        Save
      </ButtonWithLoading>,
    ],
    [handleUpdate, loading, disabled],
  );

  return (
    <React.Fragment key={open.toString()}>
      <IconButton onClick={() => setOpen(true)} disabled={!canUpdateEvent} size="large">
        <EditIcon />
      </IconButton>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        title="Edit Event"
        onClose={() => setOpen(false)}
        actionButtons={actionButtons}
      >
        <DialogContent>
          <EditEvent athleteId={athleteId} event={initialEvent} onFormChanged={handleChange} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
