import React from 'react';

import { useQuery, useLocation } from 'plantiga-common/react-router-hooks';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import RedirectToTeam from 'plantiga-component/Welcome/RedirectToTeam';

export default function WelcomeView() {
  const { next } = useQuery();
  const { navigate } = useLocation();
  const navigateURL = useNavigateURL();

  const redirectToLanding = (teamId: any) => {
    if (next) {
      navigate(next);
    } else {
      navigateURL('teamLanding', { teamId });
    }
    localStorage.setItem('currentTeamId', teamId);
    return null;
  };

  return <RedirectToTeam redirect={redirectToLanding} />;
}
