import React from 'react';

import { SvgIcon } from '@mui/material';

function RunningBackIcon({ color }: { color?: React.ComponentProps<typeof SvgIcon>['color'] }) {
  return (
    <SvgIcon color={color} viewBox="150 25 550 550">
      <path d="M473.05,210.12c-10.25,11.13-16.51,23.34-16.72,39.26-.1,7.33-5.08,15.83-10.28,21.61-11.13,12.39-8.94,29,7.2,34.4,27,9,42.44,25.8,51.48,53.77,11.19,34.65,23.39,68.63,26.76,105.17.71,7.7,3.6,15.17,4.65,22.86,1.81,13.35,8.47,21.31,22.18,23.52,9.53,1.54,8.46,8.44,5.41,14.72-4.69,9.69-13.09,14.18-23.66,13.21-10.81-1-22.63-.7-25.57-15.56a296.71,296.71,0,0,0-8.57-34c-3.21-9.87-8.18-19.15-11.92-28.86-4.35-11.31-11.62-22.85-11.52-34.23.18-19.32-9.06-29.89-24.31-37.69-13.4-6.86-27-13.26-42.83-21-6.39,28.05-13.13,56.11-19.09,84.34-3.89,18.45-32.45,26.43-48.18,11.73-8.09-7.57-13.26-18.39-19.2-28.07-3-4.95-5.83-10.45-7-16.06s0-11.51.13-17.28l4.57-.25,6.72,11.56c-11.45-33.3-5.54-66,3.8-98.85l40,17.49c.35-.88.71-1.75,1.06-2.63-8.05-3.93-16-8.14-24.16-11.73-37.61-16.49-39.39-48.63-35.82-82.72.51-4.92,5.26-9.26,7.36-14.17,2.4-5.62,7.09-12.61,5.52-17.21-11.76-34.36-5.21-49.13,28.87-61l11.27-3.89c-3.46-15.77-8.29-32.31,5.17-45.29,6.31-6.09,14.54-12.24,22.8-13.91,28.26-5.71,46.28,9.55,53.63,43.72,3.28.5,6.81,1.12,10.36,1.55,23.12,2.79,37.32,21.06,31.34,43.53-2.58,9.71.79,11.36,7.76,14.86a122.35,122.35,0,0,1,25.06,16.6c7.19,6.2,12.83,14.19,21.75,24.34-6.43,12-14.32,24.84-20.28,38.5a99.22,99.22,0,0,0-8,30.33c-1.26,14.05-11.38,18.87-21.55,21.89-5.93,1.77-15.25-.22-13.69-11.6.46-3.38-1.34-7.19-.49-10.37,2.09-7.75.48-16.76,11.15-21.44,5-2.19,8.46-10.78,10.08-17.13,2-7.82,1.34-16.32,1.76-23.3Z" />
    </SvgIcon>
  );
}

RunningBackIcon.defaultProps = {
  color: undefined,
};

export default RunningBackIcon;
