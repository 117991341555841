import athleteId from './[athleteId]/_urls';

export const root = '/org/:teamId/people/';

export default {
  athletes: root,
  athleteRedirectByName: '/org/:teamId/people/name/:name',

  ...athleteId,
} as const;
