import React from 'react';

import { orderBy } from 'lodash-es';

import useBreakpoint from './useBreakpoint';

export type Stackable = {
  readonly priority: number;
  readonly element: React.ReactNode;
};

type ObjectStack = Record<symbol, Stackable>;

type StackContextType = {
  readonly smallScreen: boolean;
  readonly stack: ObjectStack;
  readonly setStack: (arg1: ObjectStack | ((arg1: ObjectStack) => ObjectStack)) => void;
};

const StackContext = React.createContext<StackContextType>({
  smallScreen: false,
  stack: {},
  setStack: () => {},
});

type Props = {
  readonly children: any;
};

export function useStack(): any {
  const { stack } = React.useContext(StackContext);
  return React.useMemo(() => {
    const stackValues: Array<Stackable> = Object.getOwnPropertySymbols(stack).map((k) => stack[k]);
    return orderBy(stackValues, ['priority'], ['desc']).map((sv) => sv.element);
  }, [stack]);
}

export function usePushStack(stackable: Stackable): boolean {
  const symbolRef = React.useRef(Symbol('stack placeholder'));
  const { smallScreen, setStack } = React.useContext(StackContext);

  // Change
  React.useEffect(() => {
    setStack((prevStack: any) => {
      const newStack = { ...prevStack } as const;
      newStack[symbolRef.current] = stackable;
      return newStack;
    });
  }, [stackable, setStack]);

  // only return callback on unmount
  React.useEffect(
    () =>
      function unmount() {
        setStack((prevStack) => {
          const next: any = { ...prevStack };
          delete next[symbolRef.current];
          return next;
        });
      },
    [setStack],
  );
  return smallScreen;
}

export default function StackProvider({ children }: Props) {
  const [stack, setStack] = React.useState({});
  const smallScreen = useBreakpoint();
  const context = React.useMemo(
    () => ({ stack, setStack, smallScreen }),
    [stack, setStack, smallScreen],
  );

  return <StackContext.Provider value={context}>{children}</StackContext.Provider>;
}
