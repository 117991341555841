import React from 'react';

import { extent, type ScaleLinear } from 'd3';
import { compact } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Slider, Tooltip, Typography } from '@mui/material';

import { useFields } from 'plantiga-common/Fields';

import { useQuestionMeta } from './useQuestionMeta';

const cssLinearGradientFromColorScale = (colorScale: ScaleLinear<string, string> | undefined) => {
  if (colorScale == null) return undefined;

  const [min, max] = extent(colorScale.domain()) as [number, number];
  const percent = (v: number) => ((v - min) / (max - min)) * 100;

  const steps = colorScale
    .range()
    .map((color, i) => `${color} ${percent(colorScale.domain()[i])}%`);
  return `linear-gradient(to right, ${steps.join(', ')})`;
};

const BAR_HEIGHT = 8;
const THUMB_HEIGHT = 32;

const useStyles = makeStyles<void, 'immutable' | 'markLabel'>()((theme, _params, classes) => ({
  container: {
    width: '100%',
    [`&.${classes.immutable}`]: {
      pointerEvents: 'none',
    },
  },
  root: {
    // select the first child with class markLabel
    [`& .${classes.markLabel}:not(.${classes.markLabel} ~ .${classes.markLabel})`]: {
      transform: 'translateX(0)',
    },
  },
  track: {
    height: BAR_HEIGHT,
    borderRadius: BAR_HEIGHT / 2,
    background: 'transparent',
    border: 'none',
  },
  rail: {
    height: BAR_HEIGHT,
    borderRadius: BAR_HEIGHT / 2,
  },
  thumb: {
    height: THUMB_HEIGHT,
    width: THUMB_HEIGHT,
    border: '2px solid white',
    '&::before': {
      boxShadow: 'none',
    },
    '&::after': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.primary,
      fontSize: THUMB_HEIGHT / 2,
    },
  },
  mark: {
    opacity: 0,
  },
  markLabel: {
    fontSize: '0.75rem',
    transform: 'translateX(-100%)',
    marginTop: 4,
    color: theme.palette.text.secondary,
  },
  immutable: {},
}));

function ValueLabelComponent({
  children,
  open,
  value,
  valueLabelDisplay,
}: React.PropsWithChildren<{ open: boolean; value: string; valueLabelDisplay: string }>) {
  if (valueLabelDisplay === 'off') return <>{children}</>;
  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children as React.ReactElement<any, any>}
    </Tooltip>
  );
}

type Props = {
  fieldId: string;
  value: number | null;
  hideMarks?: boolean;
  immutable?: boolean;
  onChange?: (v: number) => void;
};

export function QuestionSlider({
  fieldId,
  value,
  hideMarks = false,
  immutable = false,
  onChange,
}: Props) {
  const { getField } = useFields();
  const qMeta = useQuestionMeta(fieldId);

  const markDomain = getField(fieldId).valid_range ?? [1, 10];
  const marks = compact(
    qMeta?.labels
      ? [
          { value: markDomain[0], label: qMeta.labels.at(0) },
          { value: markDomain[1], label: qMeta.labels.at(-1) },
        ]
      : [],
  );

  const { classes, cx, css, theme } = useStyles();
  const qualityColor = value != null ? qMeta?.colorScale?.(value) : theme.palette.location.none;
  return (
    <div className={cx(classes.container, { [classes.immutable]: immutable })}>
      <Typography variant="body2" id={`slide-${fieldId}`}>
        {getField(fieldId).name}
      </Typography>
      <Slider
        marks={hideMarks ? undefined : marks}
        classes={{
          root: classes.root,
          track: classes.track,
          rail: cx(
            classes.rail,
            css({
              background: cssLinearGradientFromColorScale(qMeta?.colorScale),
              opacity: value == null ? 0.25 : 1,
            }),
          ),
          thumb: cx(
            classes.thumb,
            css({
              background: qualityColor,
              pointerEvents: value == null ? 'none' : undefined,
              '::after': { content: `'${value ?? '-'}'` },
            }),
          ),
          mark: classes.mark,
          markLabel: classes.markLabel,
        }}
        aria-labelledby={`slide-${fieldId}`}
        step={1}
        min={markDomain[0]}
        max={markDomain[1]}
        value={value ?? 5.5}
        valueLabelFormat={(v: number) => qMeta?.labels[v - 1] ?? v}
        valueLabelDisplay={value ? 'auto' : 'off'}
        components={{
          ValueLabel: ValueLabelComponent,
        }}
        onChange={(_: unknown, v: number) => onChange?.(v)}
      />
    </div>
  );
}
