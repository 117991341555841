import React from 'react';

import { DialogContent, TextField, Typography } from '@mui/material';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';

import { textFieldProps } from './util';

type Props = {
  title: string;
  isMobile: boolean;
  name: string;
  setName: any;
};

export default function NameForm({ title, isMobile, name, setName }: Props) {
  return (
    <ConditionalWrapper condition={!isMobile} wrapperComponent={DialogContent}>
      {!isMobile && (
        <Typography gutterBottom variant="h5" align="center">
          {title}
        </Typography>
      )}
      <form noValidate>
        <TextField
          autoFocus
          id="full-name"
          label="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          {...textFieldProps}
        />
      </form>
    </ConditionalWrapper>
  );
}
