import { type StorageReference } from 'firebase/storage';
import React from 'react';

import ListFiles from 'plantiga-common/Storage/ListFiles';

import AsymmetryMapListItem from './AsymmetryMapListItem';
import CSVListItem from './CSVListItem';

type Props = {
  readonly teamId: string;
  readonly athleteId: string;
  readonly activityId: string;
};

export default function ListActivityFiles({ teamId, activityId, athleteId }: Props) {
  const pathPrefix = `teams/${teamId}/activities/${activityId}/extra`;

  const extraItems = React.useCallback(
    (files: StorageReference[], refresh: () => void) => {
      const items = [
        <CSVListItem
          key="CSVListItem"
          files={files}
          refreshFiles={refresh}
          athleteId={athleteId}
          activityId={activityId}
        />,
      ];

      items.push(
        <AsymmetryMapListItem
          key="AsymmetryMapListItem"
          files={files}
          refreshFiles={refresh}
          activityId={activityId}
        />,
      );

      return items;
    },
    [activityId, athleteId],
  );

  return <ListFiles pathPrefix={pathPrefix} extraItems={extraItems} />;
}
