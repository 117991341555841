import React from 'react';

import { Search as SearchIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import AutocompletePeople from 'plantiga-common/AutocompletePeople';
import Popover from 'plantiga-common/Popover';
import { useNavigateURL } from 'plantiga-common/useBuildURL';

import useSecondaryActionStyles from './useSecondaryActionStyles';

export default function PeopleSecondaryAction() {
  const { classes, cx } = useSecondaryActionStyles();
  const navigateURL = useNavigateURL();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleChange = React.useCallback(
    (athleteId: string) => {
      navigateURL('athlete', { athleteId });
      setAnchorEl(null);
    },
    [navigateURL],
  );

  return (
    <>
      <Tooltip title="Search people">
        <IconButton
          id="nav-search-people-button"
          aria-label="search people"
          size="small"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        >
          <SearchIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        transformOrigin={{ horizontal: 'left', vertical: 'center' }}
        classes={{ paper: cx(classes.popoverPaper, classes.peopleAutocompletePaper) }}
        TransitionProps={{ onEntered: () => setOpen(true), onExited: () => setOpen(false) }}
      >
        <AutocompletePeople open={open} onChange={handleChange} />
      </Popover>
    </>
  );
}
