import React from 'react';

import { size, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Button, ListItem, ListItemText, Typography } from '@mui/material';

import { useParams } from 'plantiga-common/react-router-hooks';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import { useMCMultiActivityReportTemplates } from 'plantiga-component/Activity/Report/useMCActivityReportTemplate';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

function ListItemComponent({ children }: { children: React.ReactNode }) {
  const { classes } = useStyles();
  return (
    <ListItem>
      <ListItemText>
        <div className={classes.container}>
          <Typography variant="subtitle1" component="span" color="textSecondary">
            Reports:
          </Typography>
          {children}
        </div>
      </ListItemText>
    </ListItem>
  );
}

function DivComponent({ children }: { children: React.ReactNode }) {
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="subtitle2" color="textSecondary">
        Also available as:
      </Typography>
      <div className={classes.container}>{children}</div>
    </>
  );
}

type Props = {
  readonly activityTypes: Set<string>;
  readonly dateString: string;
  readonly variant?: 'listItem';
};

export default function MultiActivityReports({ activityTypes, dateString, variant }: Props) {
  const { athleteId } = useParams();
  const navigateURL = useNavigateURL();
  const templates = useMCMultiActivityReportTemplates(activityTypes);

  const handleClick = React.useCallback(
    (templateId: string) => () => {
      navigateURL('athleteMultiActivityReport', { athleteId, day: dateString, templateId });
    },
    [athleteId, navigateURL, dateString],
  );

  const Component = variant === 'listItem' ? ListItemComponent : DivComponent;

  if (size(templates) === 0) return null;
  return (
    <Component>
      {toPairs(templates).map(([k, { title }]: [any, any]) => (
        <Button
          key={title}
          style={{ zIndex: 10 }}
          size="small"
          color="primary"
          variant="outlined"
          onClick={handleClick(k)}
        >
          {title}
        </Button>
      ))}
    </Component>
  );
}
