import React from 'react';

import { omit } from 'lodash-es';

import {
  DirectionsRun as DirectionsRunIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  RadioButtonChecked as RecordIcon,
  Settings as SettingsIcon,
  SupervisorAccount as SupervisorAccountIcon,
  Timeline as TimelineIcon,
  Today as TodayIcon,
  Tune as TuneIcon,
  ViewStream as ViewStreamIcon,
  GetApp as ExportIcon,
  CloudUpload as DockIcon,
  CropPortrait as PodIcon,
  Devices as DevicesIcon,
} from '@mui/icons-material';

import { useHasFeature, useHasPrivilege } from 'plantiga-auth/usePrivilege';
import { useLocation } from 'plantiga-common/react-router-hooks';
import { useIsUserAdmin } from 'plantiga-firebase/Auth/useUser';
import { useFeatureFlags } from 'plantiga-firebase/FeatureFlags/useFeatureFlag';

import {
  getNestedPaths,
  collectParams,
  disableItems,
  badgeItems,
  isMissingParams,
} from './navItemUtils';
import {
  PeopleSecondaryAction,
  RecordSecondaryAction,
  PodsSecondaryAction,
  DailySecondaryAction,
} from './SecondaryAction';
import { ActivitySubheader, PersonSubheader } from './Subheader';
import type { NavItemDefn } from './typedefs';

const NAV_ITEMS: NavItemDefn[] = [
  {
    path: 'athletes',
    text: 'People',
    icon: <GroupIcon />,
    renderSubheader: PersonSubheader,
    renderSecondaryAction: PeopleSecondaryAction,
    items: [
      {
        path: 'athleteLoadOverview',
        icon: <TodayIcon />,
        text: 'Athlete Dashboard',
      },
      {
        path: 'athleteDaily',
        altPaths: ['athleteDailyActivityDay'],
        icon: <ViewStreamIcon />,
        text: 'Activity Feed',
        renderSubheader: ActivitySubheader,
        renderSecondaryAction: DailySecondaryAction,
        items: [
          { path: 'activityExplore', text: 'Explore' },
          { path: 'activityInfo', text: 'Info' },
          { path: 'activityLabels', text: 'Labels' },
          { path: 'activityFiles', text: 'Files' },
          { path: 'activityReport', text: 'Report' },
          { path: 'activityStatsForNerds', text: 'Stats For Nerds' },
        ],
      },
      { path: 'athleteTimeline', text: 'Trends', icon: <TimelineIcon /> },
      {
        path: 'athleteProfile',
        text: 'Profile',
        icon: <PersonIcon />,
        initOpen: false,
        items: [
          { path: 'athleteProfileInfo', text: 'Info' },
          { path: 'athleteProfileLabels', text: 'Labels' },
          { path: 'athleteProfileEvents', text: 'Events' },
          { path: 'athleteProfileFiles', text: 'Files' },
        ],
      },
    ],
  },
  {
    path: 'record',
    text: 'Record',
    icon: <RecordIcon />,
    renderSecondaryAction: RecordSecondaryAction,
  },
  {
    path: 'teamDocks',
    text: 'Devices',
    icon: <DevicesIcon />,
    renderSecondaryAction: PodsSecondaryAction,
    items: [
      { path: 'teamDocks', text: 'Docks', icon: <DockIcon /> },
      { path: 'teamPods', text: 'Pods', icon: <PodIcon /> },
    ],
  },
  { path: 'teamFeed', text: 'Feed', icon: <ViewStreamIcon /> },
  { path: 'teamExport', text: 'Export', icon: <ExportIcon /> },
  {
    path: 'teamProfile',
    text: 'Settings',
    icon: <SettingsIcon />,
    initOpen: false,
    items: [
      { path: 'teamPreferences', text: 'Preferences', icon: <TuneIcon /> },
      { path: 'teamUsers', text: 'Users', icon: <SupervisorAccountIcon /> },
      { path: 'teamActivityTypes', text: 'Activity Types', icon: <DirectionsRunIcon /> },
      { path: 'teamTimeline', text: 'Timeline', icon: <TimelineIcon /> },
    ],
  },
];

function getStaleParams(
  prev: { [paramKey: string]: string },
  next: { [paramKey: string]: string },
) {
  const staleParamKeys = [];
  // if the athlete changes the previous activityId is stale
  if ('athleteId' in prev && 'athleteId' in next && prev.athleteId !== next.athleteId) {
    staleParamKeys.push('activityId');
  }
  return staleParamKeys;
}

export default function useNavItems() {
  const { location } = useLocation();
  const [params, setParams] = React.useState<{ [param: string]: string }>({});
  const hasPrivilege = useHasPrivilege();
  const hasFeature = useHasFeature();
  const featureFlags = useFeatureFlags();
  const isAdmin = useIsUserAdmin();

  // collect all possible url params for the current item and any nested items
  React.useEffect(() => {
    const paths = getNestedPaths(NAV_ITEMS);
    const n = collectParams(location.pathname, paths, { omitUndefined: true });
    setParams((p) => {
      const staleParamKeys = getStaleParams(p, n);
      return omit({ ...p, ...n }, staleParamKeys);
    });
  }, [location.pathname]);

  const hasFeatureFlag = React.useCallback(
    (item: NavItemDefn) => {
      if (item.featureFlag == null) return true;
      if (item.featureFlag.startsWith('!')) return !featureFlags[item.featureFlag.slice(1)];
      return featureFlags[item.featureFlag];
    },
    [featureFlags],
  );

  const items = React.useMemo(() => {
    // add an "admin" badge to nav items not accessible to the organization
    const itemsWithBadges = badgeItems(NAV_ITEMS, (i) =>
      isAdmin && !(hasPrivilege('navigate', i.path, null) && hasFeatureFlag(i))
        ? 'Admin'
        : undefined,
    );

    return disableItems(
      itemsWithBadges,
      // disable items that are missing required url parameters
      (i) => isMissingParams(i, params) || !hasFeature('navigate', i.path, null),
      // admins can access any page for any organization
      (i) => isAdmin || (hasPrivilege('navigate', i.path, null) && hasFeatureFlag(i)),
    );
  }, [params, isAdmin, hasFeature, hasPrivilege, hasFeatureFlag]);

  return React.useMemo(() => ({ items, params }), [items, params]);
}
