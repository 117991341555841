import { getFunctions } from 'firebase/functions';

import { getToken } from './useToken';

type Options = {
  readonly urlOverride?: string | null | undefined;
  readonly contentType?: 'application/json' | null;
};

export function httpsCallablePost(name: string, ops?: Options) {
  const { urlOverride, contentType } = ops ?? {
    urlOverride: null,
    contentType: 'application/json',
  };
  return async (inputData: any) => {
    const functions = getFunctions();
    // @ts-expect-error
    // eslint-disable-next-line no-underscore-dangle
    const url = `${urlOverride || functions._url(name)}`;
    const token = await getToken();

    const options = {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        ...(contentType && { 'Content-Type': contentType }),
        Authorization: `Bearer ${token}`,
      },
      body: contentType ? JSON.stringify(inputData) : inputData,
      redirect: 'error', // manual, *follow, error
    } as const;

    const res = await fetch(url, options);
    if (res.status < 200 || res.status >= 400) {
      return { data: null };
    }

    return res.json();
  };
}
