import https from 'https';

/** Maps an `activityType` to it's corresponding url subdirectory on plantiga.com */
export const HELP_PAGE_ACTIVITY_TYPES_TO_SUBDIRECTORY: { [activityType: string]: string } = {
  walk: 'walk',
  run: 'run',
  consecutive_cmj: 'ccmj',
  Cyclic_jump: 'cyclic-jump',
  single_leg_jump_distance: 'slj-d',
  height_single_leg_jump: 'slj-h',
  single_leg_jump_rsi: 'slj-rsi',
  open: 'mixed',
  cycling: 'cycling',
};

// load all urls from https://plantiga.com/sitemap.xml
// it can take upto 1 hour for the sitemap to update
// https://support.squarespace.com/hc/en-us/articles/206543547-Your-site-map
const OPTIONS = {
  hostname: 'plantiga.com',
  path: '/sitemap.xml',
  headers: { 'User-Agent': 'javascript' },
};

export function fetchPlantigaSiteMapUrls() {
  return new Promise<Set<string>>((resolve, reject) => {
    const siteUrls = new Set<string>();
    https.get(OPTIONS, (res) => {
      if (res.statusCode !== 200) reject(new Error('Could not load sitemap'));
      let body = '';
      res.on('data', (chunk) => {
        body += chunk;
      });
      res.on('end', () => {
        const parser = new DOMParser();
        const siteMapXMLDoc = parser.parseFromString(body, 'text/xml');
        const urls = siteMapXMLDoc.getElementsByTagName('url');

        for (let i = 0; i < urls.length; i += 1) {
          const urlElement = urls[i];
          const loc = urlElement.getElementsByTagName('loc')[0].textContent;
          if (loc) siteUrls.add(loc);
        }
        resolve(siteUrls);
      });
    });
  });
}
