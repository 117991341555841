/* eslint-disable tss-unused-classes/unused-classes */
import { makeStyles } from 'tss-react/mui';

const useSecondaryActionStyles = makeStyles()(() => ({
  badge: {
    transform: 'scale(0.75) translate(50%, -50%)',
  },
  popoverPaper: {
    maxHeight: 480,
    maxWidth: 480,
    minWidth: 240,
    overflow: 'scroll',
  },
  peopleAutocompletePaper: {
    overflow: 'visible',
  },
}));

export default useSecondaryActionStyles;
