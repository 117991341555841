import React from 'react';

import ErrorPage from './ErrorPage';
import FirebaseUnavailableCard from './FirebaseUnavailableCard';

export default function ErrorView() {
  return (
    <ErrorPage>
      <FirebaseUnavailableCard />
    </ErrorPage>
  );
}
