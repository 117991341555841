import React from 'react';

import { useLocation } from 'plantiga-common/react-router-hooks';

import urls from '../../util/urls';

import RedirectToTeam from './RedirectToTeam';

export default function CurrentOrgRedirect() {
  const { location, navigate } = useLocation();

  const redirectToCurrentTeam = (teamId: any) => {
    const path = location.pathname.replace(urls.currentTeam, `/org/${teamId}`);
    navigate(`${path}${location.search}`);
    localStorage.setItem('currentTeamId', teamId);
    return null;
  };

  return <RedirectToTeam redirect={redirectToCurrentTeam} />;
}
