import React, { useCallback, useMemo, useEffect, useState } from 'react';

import { toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CheckCircleOutline } from '@mui/icons-material';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';

import Dialog from 'plantiga-common/Dialog';
import useBreakpoint from 'plantiga-common/useBreakpoint';

import { useMark } from '../../Firestore/Marks/useMarks';
import type { Stopwatch } from '../../Firestore/Stopwatch/typedefs';

const useStyles = makeStyles()((theme) => ({
  mark: {
    margin: theme.spacing(2),
    marginBottom: 0,
  },
}));

type Props = {
  readonly onClose: any;
  readonly markTime: Date | null | undefined;
  readonly swid: string;
  readonly stopwatch: Stopwatch;
};

export default function MarkDialog({ swid, markTime, stopwatch, onClose }: Props) {
  const { classes } = useStyles();
  const { addMark, deleteMark, finalizeMark } = useMark();
  const [selectedLabels, setSelectedLabels] = useState<{
    [key: string]: any;
  }>({});
  const [markIds, setMarkIds] = useState<string[]>([]);
  const [notes, setNotes] = useState('');
  const fullScreen = useBreakpoint();
  const { mark_labels: availableLabels } = stopwatch;

  // Creates marks and stores ids if marks are cleared.
  useEffect(() => {
    if (markTime != null && markIds.length === 0) {
      addMark({
        swid,
        dt: markTime,
        labels: selectedLabels,
        notes,
      }).then((result) => setMarkIds(result));
    }
  }, [addMark, markIds.length, markTime, notes, selectedLabels, swid]);

  const doClose = useCallback(() => {
    onClose();
    setSelectedLabels({});
    setNotes('');
    setMarkIds([]);
  }, [onClose]);

  const doInputLabel = useCallback(
    (ev: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setNotes(ev.currentTarget.value);
    },
    [],
  );

  const cancelMark = useCallback(() => {
    deleteMark(markIds).then(() => doClose());
  }, [deleteMark, doClose, markIds]);

  const doMark = useCallback(() => {
    finalizeMark({ markIds, labels: selectedLabels, notes }).then(() => doClose());
  }, [finalizeMark, markIds, selectedLabels, notes, doClose]);

  const doSelect = useCallback(
    (key: string, value: string) => () => {
      if (Object.keys(availableLabels).length === 1) {
        const markLabels = { [key]: value } as const;
        finalizeMark({ markIds, labels: markLabels, notes }).then(() => doClose());
      } else {
        setSelectedLabels({ ...selectedLabels, [key]: value });
      }
    },
    [availableLabels, finalizeMark, markIds, notes, doClose, selectedLabels],
  );

  const actionButtons = useMemo(
    () => [
      <Button key="dialog-button-1" onClick={cancelMark}>
        Cancel
      </Button>,
      <Button key="dialog-button-2" onClick={doMark} color="primary" autoFocus id="mark-ok-button">
        OK
      </Button>,
    ],
    [cancelMark, doMark],
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      title="Set Mark"
      open={Boolean(markTime)}
      onClose={cancelMark}
      actionButtons={actionButtons}
      aria-labelledby="simple-dialog-title"
    >
      <Typography variant="body1" className={classes.mark}>
        {markTime && markTime.toLocaleString()}
      </Typography>
      {/* This List is not inside DialogContent to avoid the extra padding. */}
      <List>
        {toPairs(availableLabels).map(([key, choices]) => (
          <React.Fragment key={key}>
            <ListSubheader component="div">{`${key}:`}</ListSubheader>
            {choices.map((value) => (
              <ListItem
                key={value}
                button
                selected={selectedLabels[key] === value}
                onClick={doSelect(key, value)}
              >
                {selectedLabels[key] === value ? (
                  <ListItemIcon>
                    <CheckCircleOutline />
                  </ListItemIcon>
                ) : null}
                <ListItemText inset primary={value} />
              </ListItem>
            ))}
          </React.Fragment>
        ))}
        <Divider />
        <ListItem>
          <TextField id="notes" label="Notes" onChange={doInputLabel} fullWidth />
        </ListItem>
      </List>
    </Dialog>
  );
}
