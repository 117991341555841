import { validate } from 'jsonschema';
import { keys, merge, omit, pickBy } from 'lodash-es';

export type RequiredLabelVariant = 'load_carriage' | 'none';

export type CustomLabelVariant = 'activity' | 'admin' | 'stopwatch' | 'none';

type LabelProperty = {
  type: 'string';
  pattern?: string | RegExp;
  description?: string;
  auto_complete?: boolean;
  admin?: boolean;
  suggestions?: string[];
};

type LabelSchema = {
  id: string;
  type: 'object';
  properties: Record<string, LabelProperty>;
};

const NO_SUGGESTIONS: string[] = [];

const requiredLabels: Partial<Record<RequiredLabelVariant, Array<string>>> = {
  load_carriage: ['weight'],
  none: [],
};

const activityLabelSchema: LabelSchema = {
  id: '/ActivityEditLabelSchema',
  type: 'object',
  properties: {
    surface_type: {
      type: 'string',
    },
    'offset-left': {
      type: 'string',
      pattern: '^[+-]?\\d+(\\.\\d+)?$',
      description: 'Must be a real number',
      auto_complete: true,
    },
    'offset-right': {
      type: 'string',
      pattern: '^[+-]?\\d+(\\.\\d+)?$',
      description: 'Must be a real number',
      auto_complete: true,
    },
    'summary-start': {
      type: 'string',
      description: 'Time in the format HH:MM:SS.MM',
      auto_complete: true,
    },
    'summary-end': {
      type: 'string',
      description: 'Time in the format HH:MM:SS.MM',
      auto_complete: true,
    },
    plantiga_pod_orientation_error: {
      type: 'string',
      auto_complete: true,
      admin: true,
    },
    'rotate-left': {
      type: 'string',
      description: 'The way to rotate the pod to correct the orientation',
      auto_complete: true,
      pattern: /^flip-z|flip-x|flip-y|flip-\?|none$/,
      suggestions: ['flip-z', 'flip-x', 'flip-y', 'none'],
    },
    'rotate-right': {
      type: 'string',
      description: 'The way to rotate the pod to correct the orientation',
      auto_complete: true,
      pattern: /^flip-z|flip-x|flip-y|flip-\?|none$/,
      suggestions: ['flip-z', 'flip-x', 'flip-y', 'none'],
    },
    'imu-minimum-frequency': {
      type: 'string',
      pattern: '^[0-9]+$',
      description: 'Must be a positive integer between 0-500',
      auto_complete: true,
      suggestions: ['380', '200'],
      admin: true,
    },
  },
};

const adminLabelSchema: LabelSchema = {
  id: '/AdminLabelSchema',
  type: 'object',
  properties: {
    plantiga_ignored_by: {
      type: 'string',
      auto_complete: true,
    },
    plantiga_validated_by: {
      type: 'string',
      auto_complete: true,
    },
    plantiga_validated_on: {
      type: 'string',
      pattern: '^[0-9][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]$',
      description: 'Must be a ISO date string YYYY-MM-DD',
      auto_complete: true,
    },
  },
};

const stopwatchLabelSchema: LabelSchema = {
  id: '/StopwatchLabelSchema',
  type: 'object',
  properties: {
    weight: {
      type: 'string',
      pattern: '^[0-9]+\\s*(kg|lb)',
      description: 'Carried weight in lb or kg',
    },
  },
};

const labelSchemas: Partial<Record<CustomLabelVariant, any>> = {
  activity: activityLabelSchema,
  admin: adminLabelSchema,
  stopwatch: stopwatchLabelSchema,
};

function getLabelSchema(variant: CustomLabelVariant) {
  return labelSchemas?.[variant] ?? {};
}

export function getRequiredLabels(variant: RequiredLabelVariant): Array<string> {
  return requiredLabels?.[variant] ?? [];
}

export function getLabelSuggestions(
  variant: CustomLabelVariant,
  options: {
    requiredLabelVariant?: RequiredLabelVariant | undefined;
    isAdmin?: boolean;
  } = {},
) {
  const { requiredLabelVariant, isAdmin } = options;
  const adminSchema = isAdmin === true ? getLabelSchema('admin') : {};
  const adminSchemaProperties = adminSchema?.properties ?? {};
  const schema = getLabelSchema(variant);
  const schemaProperties = pickBy(schema?.properties ?? {}, (v) =>
    v.admin ? v.admin === isAdmin : true,
  );
  const required = getRequiredLabels(requiredLabelVariant || 'none');
  const labelSchemaProperties = {
    ...adminSchemaProperties,
    ...omit(schemaProperties, required),
  } as const;
  return keys(pickBy(labelSchemaProperties, (v) => v.auto_complete));
}

export function getLabelValueSuggestions(label: string) {
  const labels = Object.values(labelSchemas).reduce((p, v) => ({ ...p, ...v.properties }), {});
  return labels?.[label]?.suggestions ?? NO_SUGGESTIONS;
}

export function validateLabels(
  labels: {
    [key: string]: string;
  },
  options: {
    variant?: CustomLabelVariant;
    requiredLabelVariant?: RequiredLabelVariant;
    isAdmin?: boolean;
  } = {},
) {
  const { isAdmin, variant, requiredLabelVariant } = options;
  const adminSchema = isAdmin ? getLabelSchema('admin') : {};
  const schema = getLabelSchema(variant || 'none');
  const required = getRequiredLabels(requiredLabelVariant || 'none');
  const mergedSchema = merge({}, adminSchema, schema);
  return validate(labels, { ...mergedSchema, required });
}

export function isReserved(labelKey: string, options?: { isAdmin?: boolean }) {
  if (options?.isAdmin === true) return false;
  if (labelKey === 'source_activity') return true;
  if (labelKey === 'split') return true;
  if (labelKey.startsWith('plantiga_')) return true;
  return false;
}
