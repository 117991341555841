import React from 'react';

import Wait from 'plantiga-common/Wait';
import NoOfflineSupport from 'plantiga-component/Error/NotConnected';
import { useActivityTypesLoading } from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import { useAthletesLoading } from 'plantiga-firebase/Athletes/useAthletes';
import { useConnected } from 'plantiga-firebase/Connected/useConnected';
import { useDevicesLoading } from 'plantiga-firebase/Devices/useDevices';
import { useActiveStopwatches } from 'plantiga-firebase/Stopwatch/useStopwatch';

type Props = {
  children: React.ReactNode;
};

export default function StopwatchLayout({ children }: Props) {
  const { connected } = useConnected();
  const { loading: athletesLoading, error: athletesError } = useAthletesLoading();
  const { loading: devicesLoading, error: deviceError } = useDevicesLoading();
  const { loading: stopwatchesLoading } = useActiveStopwatches();
  const { initialized: activityTypesInitialized, error: activityTypesError } =
    useActivityTypesLoading();

  if (!connected) {
    return <NoOfflineSupport message="We do not support recording activities while offline." />;
  }
  return (
    <Wait
      waitingOn={
        athletesLoading || !activityTypesInitialized || devicesLoading || stopwatchesLoading
      }
      error={activityTypesError || deviceError || athletesError}
      info="Athletes, Activity Types and Devices"
    >
      {children}
    </Wait>
  );
}
