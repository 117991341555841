export const root = '/org/:teamId/record/:activityTypeId?';

export const rootDeprecated = '/org/:teamId/stopwatch/:activityTypeId?';
const deprecatedUrls = {
  stopwatch: rootDeprecated,
  stopwatchRunning: '/org/:teamId/stopwatch/:activityTypeId/running/:swid',
  stopwatchFinalize: '/org/:teamId/stopwatch/:activityTypeId/finalized/:swid',
} as const;

export default {
  record: root,
  recordActive: '/org/:teamId/record/:activityTypeId/recording/:swid',
  recordFinalize: '/org/:teamId/record/:activityTypeId/finalize/:swid',
  ...deprecatedUrls,
} as const;
