import { runTransaction, Transaction } from 'firebase/firestore';
import React from 'react';

import { get } from 'lodash-es';
import { useIsMounted, useLocalStorage } from 'usehooks-ts';

import { doc } from '..';
import { useToast } from '../../Toast/UseToast';
import useUser from '../Auth/useUser';
import useFirestore from '../useFirestore';

import useCoach from './useCoach';

export default function useDismissAlert() {
  const currentUser = useUser();
  const db = useFirestore();
  const postToast = useToast();
  const [loading, setLoading] = React.useState(false);
  const mounted = useIsMounted();
  const { coach } = useCoach();
  const [localDismissed, setLocalDismissed] = useLocalStorage('plantiga_dismissed_alerts', {});

  const coachRef = React.useMemo(
    () => (currentUser?.email ? doc(db, 'coaches', currentUser.email) : null),
    [db, currentUser],
  );

  const dismissOnLocal = React.useCallback(
    (alert: string) => {
      setLocalDismissed((prev) => ({ ...prev, [alert]: true }));
    },
    [setLocalDismissed],
  );

  const dismissOnCoach = React.useCallback(
    async (alert: string) => {
      const dismissAlertTransaction = async (transaction: Transaction) => {
        if (coachRef == null) return;

        const coachDoc = await transaction.get(coachRef);
        const prevDismissed = coachDoc.exists() ? get(coachDoc.data(), 'dismissed', {}) : {};
        await transaction.update(coachRef, {
          dismissed: { ...prevDismissed, [alert]: true },
        });
      };
      try {
        await runTransaction(db, dismissAlertTransaction);
      } catch (err: any) {
        console.error(err);
        postToast({ message: 'Could not dismiss', variant: 'error' });
      }
    },
    [db, coachRef, postToast],
  );

  const dismissAlert = React.useCallback(
    async (alert: string) => {
      setLoading(true);
      if (coach?.demo) {
        await dismissOnLocal(alert);
      } else {
        await dismissOnCoach(alert);
      }
      if (!mounted()) return;
      setLoading(false);
    },
    [coach?.demo, mounted, dismissOnLocal, dismissOnCoach],
  );

  const dismissedAlerts: { [alert: string]: boolean } = React.useMemo(() => {
    if (coach?.demo) return localDismissed;
    return coach?.dismissed ?? {};
  }, [coach, localDismissed]);

  return { dismissedAlerts, dismissAlert, loading };
}
