import useSWR from 'swr';

import useActivitySummariesClient from 'plantiga-common/useActivitySummariesClient';

export default function useActivityMetrics(
  athleteId: string | null,
  activityId: string | null,
  activityType: string | null,
  fieldIds: string[],
) {
  const client = useActivitySummariesClient();
  const fetcher = async ([func, userId, actId, actType, fIds]: [
    typeof client.getActivityMetrics,
    string,
    string,
    string,
    string[],
  ]) =>
    func({
      userId,
      activityId: actId,
      query: { activityType: actType, fieldKeys: fIds },
    });
  const shouldFetch = athleteId && activityId && activityType && fieldIds.length > 0;
  return useSWR(
    shouldFetch ? [client.getActivityMetrics, athleteId, activityId, activityType, fieldIds] : null,
    fetcher,
    {
      keepPreviousData: true,
    },
  );
}
