import React from 'react';

import { useIsMounted } from 'usehooks-ts';

const emptyArgs = (args: Array<any>) =>
  args.length === 0 || (args.length === 1 && Array.isArray(args[0]) && args[0].length === 0);

/**
 * Invoke an async function with automatic error and loader handling.
 * Inspired by `use-async-resource` but can be used without `React.Suspense`.
 *
 * #### Lazy initialization
 * When args are present, the data is eagerly initialized.
 * You can lazily initialize the data by omitting args and using the `getData` method.
 * If `doAsync` does not take any arguments, pass an empty array.
 *
 *
 * @param {*} doAsync - an asynchronous callback function
 * @param {...*} args - callback arguments
 *
 * @example
 * function asyncNow(arg) {
 *   return new Promise(res => (res(`${arg} ${new Date().toISOString()}`));
 * }
 *
 * function MyComponent() {
 *   const rnd = useAsync(asyncNow, "today is");
 *   if (rnd.error) return <ErrorView error={rnd.error} />
 *   if (rnd.loading) return 'loading...';
 *   return (
 *     <div>
 *       <span>{rnd.data}</span> // "today is 2020-01-01T00:00:00.000Z"
 *       <button onClick={() => getData()}>Update</button>
 *     </div>
 *    )
 * }
 *
 * @return {Object} r - The returned object
 * @return {any} r.data - The resolved value from `doAsync`.
 * @return {Function} r.getData - A refresh handler. The `args` are forwarded to `doAsync`, or the initial args are used.
 * @return {boolean} r.loading - True if `doAsync` is pending
 * @return {boolean} r.initialized - True if `doAsync` has resolved at least once.
 * @return {Error} r.error - The error associated with the last call to `doAsync`.
 *
 *
 */
export default function useAsync<DoAsyncFunc extends (...a: any[]) => Promise<any>>(
  doAsync: DoAsyncFunc,
  ...args: Parameters<DoAsyncFunc> | [[]] | []
) {
  const isMounted = useIsMounted();
  const savedArgs = React.useRef(emptyArgs(args) ? [] : args);
  const [data, setData] = React.useState<Awaited<ReturnType<DoAsyncFunc>> | undefined>(undefined);
  const [asyncState, setAsyncState] = React.useState({
    error: undefined as undefined | Error,
    loading: args.length > 0,
    initialized: false,
  });

  React.useEffect(() => {
    savedArgs.current = args;
  }, [args]);

  const getData = React.useCallback(
    async (...newArgs: Parameters<DoAsyncFunc> | []) => {
      let result: Awaited<ReturnType<typeof doAsync>> | undefined;
      setAsyncState((prev) => ({ ...prev, loading: true }));
      try {
        result =
          newArgs.length > 0 ? await doAsync(...newArgs) : await doAsync(...savedArgs.current);

        if (!isMounted()) return undefined;
        setData(result);
      } catch (error: any) {
        console.error(error);
        if (!isMounted()) return undefined;

        setAsyncState((prev) => ({ ...prev, loading: false, error }));
        return result;
      }
      if (!isMounted()) return undefined;

      setAsyncState({ loading: false, error: undefined, initialized: true });
      return result;
    },
    [doAsync, isMounted],
  );

  const eagerLoad = args.length > 0;

  React.useEffect(() => {
    if (eagerLoad) getData();
  }, [eagerLoad, getData]);

  return React.useMemo(() => ({ data, getData, ...asyncState }), [asyncState, data, getData]);
}
