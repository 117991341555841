import activity from './daily/[day]/activity/_urls';
import daily from './daily/_urls';
import profile from './profile/_urls';

export const root = '/org/:teamId/people/:athleteId';

export default {
  athlete: root,
  athletePage: '/org/:teamId/people/:athleteId/:page',
  athleteLoadOverview: '/org/:teamId/people/:athleteId/load-overview',
  athleteTimeline: '/org/:teamId/people/:athleteId/trends',

  ...daily,
  ...activity,
  ...profile,
} as const;
