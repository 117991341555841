import React from 'react';

import type { ScaleLinear } from 'd3';

import { Line } from '@visx/shape';
import { Text } from '@visx/text';

const labelAngle = (radians: number) => {
  const inner = (degrees: number) => {
    const a = (degrees + 90) % 360;
    // flip upside down labels
    if (a > 90 && a < 270) return (a + 180) % 360;
    return a;
  };
  return inner((radians * 180) / Math.PI);
};

type Props = {
  scale: ScaleLinear<number, number>;
  axisIndex: number;
  axesCount: number;
  label?: string;
  ticks?: number;
  hideTickLabels?: boolean;
  stroke?: string;
  labelOffset?: number;
  rotateRads?: number;
};

export function RadarAxis({
  scale,
  axisIndex,
  axesCount,
  label = undefined,
  ticks = undefined,
  hideTickLabels = false,
  stroke = 'black',
  labelOffset = 16,
  rotateRads = 0,
}: Props) {
  const radius = scale.range()[1] - scale.range()[0];
  const angle = -((axisIndex + 1) * Math.PI * 2) / axesCount + Math.PI / 2 + rotateRads;
  const x = Math.cos(angle) * radius;
  const y = Math.sin(angle) * radius;
  return (
    <React.Fragment key={Math.random()}>
      <Text
        x={x * (((x ** 2 + y ** 2) ** 0.5 + labelOffset) / (x ** 2 + y ** 2) ** 0.5)}
        y={y * (((x ** 2 + y ** 2) ** 0.5 + labelOffset) / (x ** 2 + y ** 2) ** 0.5)}
        angle={labelAngle(angle)}
        verticalAnchor="middle"
        textAnchor="middle"
        width={(Math.PI * radius * 2) / (axesCount || 1)}
      >
        {label}
      </Text>
      <Line from={{ x: 0, y: 0 }} to={{ x, y }} stroke={stroke} />
      {ticks &&
        [...new Array(ticks)].map((_, i, a) => {
          const tick = i + 1;
          const tickSize = 10;
          // radius of the tick
          const r = (radius / a.length) * tick;
          // circumference of circle with tick radius
          const c = Math.PI * 2 * r;
          // the angle swept by a slice from the axis forming a right triangle with height "tickSize"
          const dTheta = Math.PI / (c / tickSize);
          return (
            <React.Fragment key={`tick-${tick}`}>
              {!hideTickLabels && (
                <Text
                  x={Math.cos(angle + 4 * dTheta) * r}
                  y={Math.sin(angle + 4 * dTheta) * r}
                  verticalAnchor="middle"
                  textAnchor="middle"
                  fontSize={8}
                >
                  {`${(i + 1) * 50}%`}
                </Text>
              )}
              <Line
                from={{ x: Math.cos(angle + dTheta) * r, y: Math.sin(angle + dTheta) * r }}
                to={{ x: Math.cos(angle - dTheta) * r, y: Math.sin(angle - dTheta) * r }}
                stroke={stroke}
              />
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
}
