import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import FeedRedirect from 'plantiga-component/Athletes/Daily/Redirect';
import NotFound from 'plantiga-component/Error/NotFound';

import AthleteLayout from './_root';
import urls, { root } from './_urls';
import activityId from './daily/[day]/activity/[activityId]/_routes';
import { rootDeprecated as activityRootDeprecated } from './daily/[day]/activity/[activityId]/_urls';
import { root as dailyRoot } from './daily/_urls';
import profile from './profile/_routes';
import { root as profileRoot } from './profile/_urls';

const trends = React.lazy(() => import('./trends'));

const daily = React.lazy(() => import('./daily/_routes'));

const loadOverview = React.lazy(() => import('plantiga-component/Athletes/LoadOverview'));

export default function AthleteRoutes() {
  return (
    <AthleteLayout>
      <Switch>
        <Redirect exact from={root} to={urls.athleteLoadOverview} />
        <Route path={dailyRoot} component={daily} />
        <Route path={profileRoot} component={profile} />
        <Route path={urls.athleteLoadOverview} component={loadOverview} />
        <Route path={urls.athleteTimeline} component={trends} />

        {/* handle old activity page urls that did not include the activity date */}
        <Route path={activityRootDeprecated} component={activityId} />
        <Route exact path={urls.athleteDashboard} component={FeedRedirect} />
        <Route exact path={urls.athleteFeed} component={FeedRedirect} />
        <Route exact path={urls.athleteFeedDay} component={FeedRedirect} />
        <Redirect exact from={urls.athleteFeedActivityDay} to={urls.athleteDailyActivityDay} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </AthleteLayout>
  );
}
