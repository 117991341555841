import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from 'plantiga-component/Error/NotFound';
import Loading from 'plantiga-component/Loading';

import urls from './_urls';
import measures from './measures';
import timeseries from './timeseries';
import typography from './typography';

export default function UtilRoutes() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route path={urls.measures} exact component={measures} />
        <Route path={urls.timeseries} exact component={timeseries} />
        <Route path={urls.typography} exact component={typography} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </React.Suspense>
  );
}
