import React, { useMemo, useState, useCallback } from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, DialogContent, Grid, Typography } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import { deleteDevice } from 'plantiga-firebase/Devices/actions';
import useTeam from 'plantiga-firebase/Team/useTeam';

import { deviceNiceId } from '../../util/deviceNiceId';
import getDeviceStatus from '../../util/getDeviceStatus';
import { useAthlete } from '../Firestore/Athletes/useAthletes';
import type { Device } from '../Firestore/Devices/typedefs';
import { useToast } from '../Toast/UseToast';

type Props = {
  open: boolean;
  onClose: () => void;
  deviceId: string;
  device: Device;
};

const useStyles = makeStyles()((theme) => ({
  text: {
    marginTop: theme.spacing(2),
  },
}));

export default function RemoveDeviceDialog({ open, onClose, deviceId, device }: Props) {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(false);
  const { teamId } = useTeam();
  const postToast = useToast();
  const formatDeviceId = useMemo(() => deviceNiceId(deviceId), [deviceId]);
  const { athlete_id: athleteId } = device;
  const docked = getDeviceStatus(device) !== 'undocked' && getDeviceStatus(device) !== 'unknown';
  const athlete = useAthlete(athleteId || '');

  const handleDeleteDevice = useCallback(async () => {
    try {
      setLoading(true);
      await deleteDevice({ teamId, deviceId });
      postToast({
        message: `Successfully removed pod ${formatDeviceId}`,
        variant: 'success',
      });
      setLoading(false);
    } catch (error: any) {
      postToast({
        message: `Could not removed pod ${formatDeviceId}`,
        variant: 'error',
      });
    }
  }, [deviceId, teamId, formatDeviceId, postToast]);

  const actionButtons = useMemo(
    () => [
      <Button key="dialog-button-1" onClick={onClose}>
        cancel
      </Button>,
      <ButtonWithLoading
        key="dialog-button-2"
        color="secondary"
        variant="contained"
        loading={loading}
        // let users remove the pod, even if docked,
        // as the pod status may be stale if a dock disconnects
        onClick={handleDeleteDevice}
      >
        remove
      </ButtonWithLoading>,
    ],
    [handleDeleteDevice, loading, onClose],
  );

  const message = useMemo(() => {
    if (docked) return `Please undock ${formatDeviceId} before removing.`;
    if (athlete)
      return `The Pod ${formatDeviceId} is paired to ${athlete.name}. It will be unpaired if you remove it.`;
    return `Are you sure you want to remove Pod ${formatDeviceId}?`;
  }, [athlete, docked, formatDeviceId]);

  return (
    <Dialog
      title={docked ? 'Pod is docked' : 'Are you sure?'}
      open={open}
      actionButtons={actionButtons}
      color="secondary"
      onClose={onClose}
    >
      <DialogContent>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Typography variant="body1" className={classes.text}>
              {message}
            </Typography>
            <Typography variant="body1" className={classes.text}>
              This Pod will appear again if docked.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
