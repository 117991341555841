import React from 'react';
import ReactDOM from 'react-dom';

import useTeamAppBar from './useTeamAppBar';

type Props = {
  children: React.ReactNode;
};

/**
 * PageGutterPortal will render its children in the left or right gutter
 * of its closest parent ContentContainer.
 */
export default function TeamAppBarPortal({ children }: Props) {
  const node = useTeamAppBar();

  if (node == null) return null;
  // wrap the portal in a React.Fragment so the returned type is JSX.
  return <>{ReactDOM.createPortal(children, node)}</>;
}
