import React from 'react';

import makeStyles from '../../util/MUI/makeStyles';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  readonly children: React.ReactNode;
};

export default function ErrorPage({ children }: Props) {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
}
