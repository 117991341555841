import React from 'react';

import { find, fromPairs, groupBy, isEqual, map, pickBy, size, some } from 'lodash-es';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import type { CustomLabelVariant, RequiredLabelVariant } from 'plantiga-common/Labels/schema';
import {
  getLabelSuggestions,
  getRequiredLabels,
  validateLabels,
  isReserved,
} from 'plantiga-common/Labels/schema';
import { useIsUserAdmin } from 'plantiga-firebase/Auth/useUser';
import makeFirestoreSafe from 'plantiga-util/makeFirestoreSafe';
import makeStyles from 'plantiga-util/MUI/makeStyles';

import CustomLabelField from './CustomLabelField';
import {
  getLabelKeyError,
  initializeStandardLabels,
  initializeCustomLabels,
  NEW_LABEL,
  KEY_ERRORS,
} from './util';
import type { CustomLabelsType } from './util';

const useStyles = makeStyles()((theme) => ({
  addLabelContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  labelContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  labels: {
    [key: string]: string;
  };
  onLabelsChanged?: (arg1: any, arg2: boolean) => any;
  disabled?: boolean;
  loading?: boolean;
  onSave?: any;
  variant?: CustomLabelVariant;
  requiredLabelVariant?: RequiredLabelVariant;
};

export default function CustomLabels({
  labels,
  disabled = false,
  loading = false,
  onSave = undefined,
  onLabelsChanged,
  variant = 'none',
  requiredLabelVariant = 'none',
}: Props) {
  const { classes } = useStyles();
  const isAdmin = useIsUserAdmin();

  const requiredLabels = getRequiredLabels(requiredLabelVariant);

  const keySuggestions = React.useMemo(
    () => getLabelSuggestions(variant, { requiredLabelVariant, isAdmin }),
    [requiredLabelVariant, variant, isAdmin],
  );

  const emptyInputs = React.useMemo(
    () => [
      ...requiredLabels.map((key) => ({
        key,
        keyError: '',
        value: '',
      })),
      NEW_LABEL,
    ],
    [requiredLabels],
  );

  const [labelErrors, setLabelErrors] = React.useState<{ [key: string]: any[] }>({});
  const [badLabel, setBadLabel] = React.useState(false);
  const standardLabels = React.useRef(initializeStandardLabels(labels));
  const initialLabels = React.useRef({
    ...fromPairs(map(requiredLabels, (k) => [k, ''])),
    ...(labels as {
      [key: string]: string;
    }),
  });
  const initialCustomLabels = React.useRef(initializeCustomLabels(labels, requiredLabels));

  const [customLabels, setCustomLabels] = React.useState<CustomLabelsType>(
    initializeCustomLabels(labels, requiredLabels),
  );

  const formatLabels = React.useCallback(() => {
    const custom = fromPairs(
      customLabels
        .filter((label) => !isEqual(label, NEW_LABEL))
        .map((label) => [label.key, label.value]),
    );
    return { ...standardLabels.current, ...custom };
  }, [customLabels]);

  const haveEmptyLabel = React.useMemo(
    () => find(customLabels, ({ key, value }) => !key || !value) != null,
    [customLabels],
  );

  const noChanges = isEqual(formatLabels(), initialLabels.current);

  React.useEffect(() => {
    const formattedLabels = formatLabels();
    const v = validateLabels(formattedLabels, {
      variant,
      requiredLabelVariant,
      isAdmin,
    });
    const errors = groupBy(v.errors, 'path.0');
    const mostErrors = pickBy(errors, size);

    const foundBadLabel =
      some(
        customLabels,
        ({ key, value, keyError }) => keyError || (key && !value) || (!key && value),
      ) || size(mostErrors) > 0;

    setLabelErrors(mostErrors);
    setBadLabel(foundBadLabel);
  }, [
    customLabels,
    isAdmin,
    formatLabels,
    noChanges,
    onLabelsChanged,
    requiredLabelVariant,
    variant,
  ]);

  const handleKeyChange = (index: number) => (value: string) => {
    const error = getLabelKeyError(value, {
      otherKeys: customLabels.map((v) => v.key),
      isAdmin,
    });
    const firestoreSafeKey = makeFirestoreSafe(value);
    setCustomLabels((prev) => {
      const newCustomLabels = [...prev];
      newCustomLabels[index] = {
        ...prev[index],
        keyError: error,
        key: firestoreSafeKey,
      };
      return newCustomLabels;
    });
  };

  const handleValueChange = (index: number) => (value: string) => {
    setCustomLabels((prev) => {
      const newCustomLabels = [...prev];
      newCustomLabels[index] = { ...prev[index], value };
      return newCustomLabels;
    });
  };

  const handleAddLabel = () => {
    setCustomLabels((prev) => prev.concat(NEW_LABEL));
  };

  const handleRemoveLabel = (index: number) => () => {
    if (index === emptyInputs.length - 1 && customLabels.length === emptyInputs.length) {
      setCustomLabels((prev) => [...prev.slice(0, index), NEW_LABEL]);
      return;
    }
    if (!requiredLabels.includes(customLabels[index].key)) {
      setCustomLabels((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    }
  };

  const handleReset = React.useCallback(() => {
    setCustomLabels(initialCustomLabels.current);
  }, []);

  const handleSave = React.useCallback(() => {
    const newLabels = formatLabels();
    onSave(newLabels);
    initialLabels.current = newLabels;
    initialCustomLabels.current = initializeCustomLabels(newLabels, requiredLabels);
  }, [formatLabels, onSave, requiredLabels]);

  React.useEffect(() => {
    if (noChanges || onLabelsChanged == null) return;
    onLabelsChanged(formatLabels(), badLabel);
  }, [badLabel, noChanges, formatLabels, onLabelsChanged]);

  return (
    <div className={classes.labelContainer}>
      {customLabels.map(({ key, value, keyError }, index) => (
        <CustomLabelField
          // In order for the field to maintain its identity while we are changing the label key,
          // we must use a different react key than the label itself. Otherwise we keep getting
          // thrown out of the input. The best candidate I can think of is the index.
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          suggestions={keySuggestions}
          reserved={!keyError && isReserved(key, { isAdmin })}
          required={requiredLabels.includes(key)}
          isNew={initialLabels.current[key] == null || keyError === KEY_ERRORS.duplicate}
          onRemoveLabel={handleRemoveLabel(index)}
          onKeyChange={handleKeyChange(index)}
          onValueChange={handleValueChange(index)}
          labelKey={key.toString()}
          labelValue={value.toString()}
          keyError={keyError}
          labelError={labelErrors[key]}
        />
      ))}
      {!disabled && (
        <div className={classes.addLabelContainer}>
          <Button
            id="addLabelButton"
            onClick={handleAddLabel}
            startIcon={<AddIcon />}
            disabled={haveEmptyLabel}
          >
            Add Label
          </Button>
        </div>
      )}
      {!disabled && onSave != null && loading != null && (
        <div className={classes.buttonContainer}>
          <Button disabled={loading || noChanges} onClick={handleReset}>
            Cancel
          </Button>
          <ButtonWithLoading
            loading={loading}
            className={classes.button}
            color="primary"
            variant="contained"
            disabled={loading || noChanges || badLabel}
            onClick={handleSave}
          >
            Save
          </ButtonWithLoading>
        </div>
      )}
    </div>
  );
}
