import { memoize } from 'lodash-es';

import ApiClient from './ApiClient';

const getClient = memoize((apiTarget: string) => new ApiClient(apiTarget));

// apiTarget is useful for testing against local servers
export default function useApi(apiTarget?: string): ApiClient {
  const { apiUrl } = window.plantiga;
  return getClient(apiTarget ?? apiUrl);
}
