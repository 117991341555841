import React from 'react';

import type { TimeseriesDefn } from '@plantiga/activity-summaries';

import timeseries from 'plantiga-util/timeSeries';

type TimeseriesContextType = {
  timeseries: { [id: string]: TimeseriesDefn };
  loading: boolean;
  error: Error | undefined;
};

const Context: React.Context<TimeseriesContextType> = React.createContext<TimeseriesContextType>({
  timeseries,
  loading: true,
  error: undefined,
});

export default Context;
