import React from 'react';

import Wait from 'plantiga-common/Wait';
import { useActivityTypesLoading } from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import { useAthletesLoading } from 'plantiga-firebase/Athletes/useAthletes';

type Props = {
  children: React.ReactNode;
};

export default function AthletesLayout({ children }: Props) {
  const { loading: athletesLoading, error: athletesError } = useAthletesLoading();
  const { initialized: activityTypesInitialized, error: activityTypesError } =
    useActivityTypesLoading();
  return (
    <Wait
      waitingOn={athletesLoading || !activityTypesInitialized}
      error={athletesError || activityTypesError}
      info="Athletes and Activity Types"
    >
      {children}
    </Wait>
  );
}
