import { serverTimestamp } from 'firebase/firestore';
import React, { useEffect, useState, useCallback, useRef } from 'react';

import { formatDuration } from 'date-fns';
import { get } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';

import { MAX_STOPWATCH_TIME } from '../../../util/constants';
import type { Athletes } from '../../Firestore/Athletes/typedefs';
import type { Timestamp } from '../../Firestore/schema';
import type { Stopwatch } from '../../Firestore/Stopwatch/typedefs';
import { useUpdateStopwatch } from '../../Firestore/Stopwatch/useStopwatch';
import useInterval from '../../Firestore/Timer/useInterval';
import AthleteList from '../AthleteList';
import MarkButton from '../Marks/MarkButton';
import format from '../util';

import CountDownSplash from './CountDownSplash';

const useStyles = makeStyles()((theme) => ({
  button: {
    minWidth: 96,
    borderRadius: 20,
  },
  pageGutterBottomContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'initial',
    },
  },
  pageGutterBottomRight: {
    position: 'absolute',
    right: theme.spacing(1),
  },
  root: {
    textAlign: 'center',
    fontFamily: 'monospace',
    padding: theme.spacing(1),
    fontSize: '2.5rem',
  },
}));

type Props = {
  readonly athletes: Athletes;
  readonly swid: string;
  readonly stopwatch: Stopwatch;
};

const getTimerTime = (
  currentState: string | null,
  elapsedTime: number,
  start: Timestamp,
  end: Timestamp,
) => {
  switch (currentState) {
    case 'running':
      return elapsedTime;
    case 'stopped':
      return end.toMillis() - start.toMillis();
    default:
      return 0;
  }
};

export default function Running({ athletes, swid, stopwatch }: Props) {
  const { start } = stopwatch;
  const end = get(stopwatch, 'end', null);
  const currentState = get(stopwatch, 'run_state', null);

  const [elapsedTime, setElapsedTime] = useState(start ? Date.now() - start.toMillis() : 0);
  const [loading, update] = useUpdateStopwatch(swid);

  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const { classes } = useStyles();

  const isMounted = React.useRef(true);
  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  // set the elapsed time
  useInterval(() => {
    if (currentState === 'running') {
      const timeDelta = start ? Math.min(Date.now() - start.toMillis(), MAX_STOPWATCH_TIME) : 0;
      if (isMounted.current) {
        setElapsedTime(timeDelta);
      }
    }
  }, 250);

  // clean up timer on component unMount
  useEffect(
    () => () => {
      if (timeoutId.current) {
        update({
          run_state: 'stopped',
          end: new Date(),
          server_end: serverTimestamp(),
        });
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    },
    [update],
  );

  const handleStopActivity = useCallback(async () => {
    const endTime = new Date(Math.min(Date.now(), start.toMillis() + MAX_STOPWATCH_TIME));
    await update({
      run_state: 'stopped',
      end: endTime,
      server_end: serverTimestamp(),
    });
  }, [start, update]);

  // @ts-expect-error - TS2345 - Argument of type 'Timestamp | null' is not assignable to parameter of type 'Timestamp'.
  const time = getTimerTime(currentState, elapsedTime, start, end);
  const [splashVisible, setSplashVisible] = React.useState(true);

  return (
    <>
      <Typography component="h4" variant="h4" gutterBottom>
        {stopwatch.name}
      </Typography>
      <div className={classes.root}>{format(time, true, false)}</div>
      {elapsedTime === MAX_STOPWATCH_TIME ? (
        <Alert severity="warning">
          You have reached the maximum recording time of{' '}
          {formatDuration({ seconds: MAX_STOPWATCH_TIME / 1000 })} for this activity. You may
          continue recording data, but it will not appear as part of this session.
        </Alert>
      ) : null}
      <CountDownSplash
        formattedTime={format(time, true, false)}
        elapsedTime={elapsedTime}
        swid={swid}
        stopwatch={stopwatch}
        onHide={() => setSplashVisible(false)}
        onShow={() => setSplashVisible(true)}
      />
      <AthleteList athletes={athletes} />

      {!splashVisible && (
        <PageGutterPortal gutter="bottom">
          <div className={classes.pageGutterBottomContainer}>
            <ButtonWithLoading
              id="stopwatch-stop-button"
              className={classes.button}
              variant="contained"
              disabled={false}
              loading={loading}
              onClick={handleStopActivity}
              color="secondary"
            >
              Done
            </ButtonWithLoading>
            <div className={classes.pageGutterBottomRight}>
              <MarkButton swid={swid} stopwatch={stopwatch} />
            </div>
          </div>
        </PageGutterPortal>
      )}
    </>
  );
}
