import Fuse from 'fuse.js';
import { map, padStart } from 'lodash-es';

import type { Athletes } from 'plantiga-firebase/Athletes/typedefs';
import type { Device } from 'plantiga-firebase/Devices/typedefs';

import { deviceNiceId } from './deviceNiceId';

const FAR_FUTURE = new Date(20000000000000);
// 8 hour battery life or 12.5%/hour
const POD_DRAIN_PERCENT_PER_MILLIS = 12.5 / (60 * 60 * 1000);

export type SearchableDevice = Device & {
  shortId: string;
  athleteName: string | undefined;
};

const DEVICE_SORT_OPTIONS: Fuse.IFuseOptions<SearchableDevice> = {
  keys: ['name', 'shortId', 'athlete_id', 'athleteName', 'id'],
  threshold: 0.2,
};

function makeDeviceSearchable(device: Device, athletes: Athletes): SearchableDevice {
  return {
    ...device,
    shortId: deviceNiceId(device.id),
    athleteName: device.athlete_id ? athletes[device.athlete_id]?.name : undefined,
  };
}

export function makeDevicesSearchable(devices: Device[], athletes: Athletes): SearchableDevice[] {
  return devices.map((d) => makeDeviceSearchable(d, athletes));
}

export function makeDeviceSortKey(d: Device): string {
  const lastDocked = d.last_status ? d.last_status.toMillis() : 0;

  // We need ascending sort, so invert the timestamps
  // Truncate the times to a minute window, so that pairs that were docked together
  // show consistently L/R order.
  const staleness = (+FAR_FUTURE - lastDocked) / 60000;
  const reltime = staleness.toFixed(0);
  return `${padStart(reltime, 12, '0')}-${d.athlete_id || 'Z'}-${d.location || 'X'}`;
}

export function searchDevices(
  deviceList: SearchableDevice[],
  searchTerm: string,
): SearchableDevice[] {
  if (searchTerm.length > 0) {
    const fuseSearch = new Fuse(deviceList, DEVICE_SORT_OPTIONS);
    return map(fuseSearch.search(searchTerm), 'item');
  }
  return deviceList;
}

/** Converts the battery percentage reported from the Pod into a User facing value.
 *
 * The pod's reported battery percentage does not directly align with the user experience.
 * e.g. the pod shuts down when `pod.battery_percentage` < 5%
 */
export const podBattery = (pod: Device) => {
  if (pod.battery_percentage == null) {
    return 0;
  }
  // constrain the battery percentage between 5% and 100%
  const clamped = Math.max(5, Math.min(100, pod.battery_percentage));
  return ((clamped - 5) / 95) * 100;
};

/** predict the remaining run-time of the pod in milliseconds */
export const predictPodRunTime = (pod: Device) => {
  if (pod.last_status == null) {
    return 0;
  }
  const timeSinceLastStatus = +new Date() - +pod.last_status.toDate();
  const batteryAtLastStatus = podBattery(pod);
  const batteryUsed = timeSinceLastStatus * POD_DRAIN_PERCENT_PER_MILLIS;
  return Math.max((batteryAtLastStatus - batteryUsed) / POD_DRAIN_PERCENT_PER_MILLIS, 0);
};
