import React from 'react';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useParams } from 'plantiga-common/react-router-hooks';
import useTeam from 'plantiga-firebase/Team/useTeam';

import ListAthleteFiles from './ListAthleteFiles';

export default function FilesView() {
  const { teamId } = useTeam();
  const { athleteId } = useParams();

  return (
    <ContentContainer id="aui_profile_files">
      <ListAthleteFiles teamId={teamId} athleteId={athleteId} />
    </ContentContainer>
  );
}
