export const rootDeprecated = '/org/:teamId/people/:athleteId/activity/:activityId';
export const root = '/org/:teamId/people/:athleteId/daily/:day/activity/:activityId';

const deprecatedUrls = {
  athleteActivityRaw: '/org/:teamId/people/:athleteId/activity/:activityId/raw',
  athleteActivityInfo: '/org/:teamId/people/:athleteId/activity/:activityId/info',
  athleteActivityLabels: '/org/:teamId/people/:athleteId/activity/:activityId/labels',
  athleteActivityFiles: '/org/:teamId/people/:athleteId/activity/:activityId/files',
  athleteActivityExplore: '/org/:teamId/people/:athleteId/activity/:activityId/explore',
  athleteActivityReport: '/org/:teamId/people/:athleteId/activity/:activityId/report',
  athleteActivitySummaries: '/org/:teamId/people/:athleteId/activity/:activityId/summaries',
  athleteActivities: '/org/:teamId/people/:athleteId/activity/:activityId?/:page?',
  activitySummaries: `${root}/summaries`,
} as const;

export default {
  activity: `${root}/:page?`,
  activityRaw: `${root}/raw`,
  activityExplore: `${root}/explore`,
  activityInfo: `${root}/info`,
  activityLabels: `${root}/labels`,
  activityFiles: `${root}/files`,
  activityReport: `${root}/report`,
  activityStatsForNerds: `${root}/stats-for-nerds`,

  ...deprecatedUrls,
} as const;
