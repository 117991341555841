import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useLocation } from 'plantiga-common/react-router-hooks';

import finalized from './[activityTypeId]_finalized_[swid]';
import running from './[activityTypeId]_running_[swid]';
import StopwatchLayout from './_root';
import urls, { root } from './_urls';

import index from './index';

export default function StopwatchRoutes() {
  const { location } = useLocation();
  return (
    <StopwatchLayout>
      <Switch>
        <Redirect
          exact
          from={urls.stopwatch}
          to={{ pathname: urls.record, search: location.search }}
        />
        <Redirect
          from={urls.stopwatchRunning}
          to={{ pathname: urls.recordActive, search: location.search }}
        />
        <Redirect
          from={urls.stopwatchFinalize}
          to={{ pathname: urls.recordFinalize, search: location.search }}
        />
        <Route path={root} exact component={index} />
        <Route path={urls.recordActive} component={running} />
        <Route path={urls.recordFinalize} component={finalized} />
      </Switch>
    </StopwatchLayout>
  );
}
