import React from 'react';

import { map, pickBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Button, Collapse, Typography, MenuItem } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import type { StopwatchWarnings as Warnings } from 'plantiga-firebase/Stopwatch/typedefs';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';

import { PairAthleteToPodsDialog } from '../../Devices/PairAthleteToPodsDialog';

import { IGNORABLE_WARNINGS } from './warnings';

type ActionProps = {
  athleteId: string;
  ignored: boolean;
  toggleWarning: (warningKey: keyof Warnings) => void;
};

const useStyles = makeStyles()((theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'start',
    gap: theme.spacing(1),
    marginTop: 8,
    marginBottom: 8,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
    border: `1px solid ${theme.palette.warning.main}`,
    margin: -1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  warningPaper: {
    display: 'grid',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
  },
  ignoreButton: {
    textTransform: 'capitalize',
  },
}));

function ActionButtons({
  warningType,
  onClick,
  toggleWarning,
  ignored,
  callToAction = 'Resolve',
  loading = false,
}: Omit<ActionProps, 'athleteId'> & {
  loading?: boolean;
  warningType: keyof Warnings;
  callToAction?: string;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}) {
  const { classes } = useStyles();

  return (
    <div className={classes.actionButtons}>
      {onClick != null && (
        <ButtonWithLoading
          size="small"
          color="primary"
          variant="contained"
          onClick={onClick}
          loading={loading}
          disabled={loading}
        >
          {callToAction}
        </ButtonWithLoading>
      )}
      {IGNORABLE_WARNINGS[warningType] && (
        <Button
          id={`${warningType}-ignore-warnings-button`}
          size="small"
          variant="outlined"
          onClick={() => toggleWarning(warningType)}
          className={classes.ignoreButton}
        >
          {ignored ? 'un-ignore' : 'ignore'}
        </Button>
      )}
    </div>
  );
}

function BrokenDevicesInfo({ athleteId }: { athleteId: string }) {
  const devices = useDevices(athleteId);
  const brokenDevices = pickBy(devices, (d) => d.broken);
  return (
    <div>
      {map(brokenDevices, (brokenDevice) => (
        <Typography key={brokenDevice.id} gutterBottom>
          {`Pod ${deviceNiceId(
            brokenDevice.id,
          )} has been flagged as broken. Please un-pair this Pod and set it aside`}
        </Typography>
      ))}
    </div>
  );
}

function HaveLowRunTimeDevicesAction(props: Omit<ActionProps, 'athleteId'>) {
  return (
    <ActionButtons
      {...props}
      // eslint-disable-next-line react/destructuring-assignment
      callToAction={`I am${props.ignored ? ' not ' : ' '}using a Carry case`}
      onClick={() => props.toggleWarning('haveLowRunTimeDevices')}
      warningType="haveLowRunTimeDevices"
    />
  );
}

function MissingDevicesAction({ athleteId, ...props }: ActionProps) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <ActionButtons
        {...props}
        callToAction="Pair"
        onClick={() => setOpen(true)}
        warningType="haveMissingDevices"
      />
      <PairAthleteToPodsDialog athleteId={athleteId} open={open} onClose={() => setOpen(false)} />
    </>
  );
}

type WarningsListItemProps = {
  athleteId: string;
  warningType: keyof Warnings;
  ignored: boolean;
  toggleWarning: (warningKey: keyof Warnings) => void;
  removeAthlete: (athleteId: string) => void;
};

export const WarningsListItem = React.memo(
  ({ athleteId, warningType, ignored, toggleWarning, removeAthlete }: WarningsListItemProps) => {
    const { classes } = useStyles();
    const [open, setOpen] = React.useState(true);

    const primary = React.useMemo(() => {
      switch (warningType) {
        case 'haveLowRunTimeDevices':
          return 'Pods off dock for 7+ hours';
        case 'haveBrokenDevices':
          return 'Broken Pods';
        case 'haveMissingDevices':
          return 'Pods need to be paired';
        case 'haveExpiredDevices':
          return 'Pods need to be synced';
        case 'haveActiveRecording':
          return 'Already Recording';
        default:
          return '';
      }
    }, [warningType]);

    const Info = React.useMemo(() => {
      switch (warningType) {
        case 'haveLowRunTimeDevices':
          return <Typography>We recommend docking the Pods to charge them.</Typography>;
        case 'haveBrokenDevices':
          return <BrokenDevicesInfo athleteId={athleteId} />;
        case 'haveMissingDevices':
          return (
            <Typography>
              A left and right Pod must be paired to this person before you start.
            </Typography>
          );
        case 'haveExpiredDevices':
          return <Typography>You must dock your Pods before collecting data.</Typography>;
        case 'haveActiveRecording':
          return <Typography>Only one recording can be active per person.</Typography>;
        default:
          return null;
      }
    }, [athleteId, warningType]);

    const onToggleWarning = (warningKey: keyof Warnings) => {
      setOpen(ignored);
      toggleWarning(warningKey);
    };

    const renderSecondaryAction = () => {
      const commonProps = {
        athleteId,
        ignored,
        toggleWarning: onToggleWarning,
        warningType,
      };
      switch (warningType) {
        case 'haveLowRunTimeDevices':
          return <HaveLowRunTimeDevicesAction {...commonProps} />;
        case 'haveMissingDevices':
          return <MissingDevicesAction {...commonProps} />;
        case 'haveActiveRecording':
          return (
            <ActionButtons
              {...commonProps}
              callToAction="Remove"
              onClick={() => removeAthlete(athleteId)}
            />
          );
        default:
          return null;
      }
    };

    return (
      <>
        <MenuItem
          onClick={() => setOpen((prev) => !prev)}
          className={classes.item}
          disabled={!ignored}
        >
          <Typography variant="body1">
            <b>{primary}</b>
          </Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={open}>
          <div className={classes.warningPaper}>
            {Info}
            {renderSecondaryAction()}
          </div>
        </Collapse>
      </>
    );
  },
);
