import React from 'react';

import ListFiles from 'plantiga-common/Storage/ListFiles';

type Props = {
  readonly teamId: string;
  readonly athleteId: string;
};

export default function ListAthleteFiles({ teamId, athleteId }: Props) {
  const pathPrefix = `teams/${teamId}/athletes/${athleteId}/extra`;
  return <ListFiles pathPrefix={pathPrefix} />;
}
