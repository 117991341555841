import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

import { Button, Divider, Grid, Card } from '@mui/material';

import ContentContainer from 'plantiga-common/ContentContainer';
import Div100vh from 'plantiga-common/Div100vh';
import { useQuery } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';

import HeroGridItem from './HeroGridItem';
import SocialLogin from './SocialLogin';
import UnauthenticatedAppBar from './UnauthenticatedAppBar';
import UserPassForm from './UserPassForm';

const useStyles = makeStyles()((theme) => ({
  flexContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonPanel: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  grid: {
    height: '100%',
  },
  gridItem2: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  divider: {
    margin: theme.spacing(2),
  },
  paper2: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: theme.breakpoints.values.md,
    flexGrow: 1,
    alignSelf: 'stretch',
  },
}));

export default function SignInView() {
  const { classes } = useStyles();
  const buildURL = useBuildURL();
  const { next } = useQuery();
  return (
    <Div100vh>
      <UnauthenticatedAppBar />
      <ContentContainer>
        <div className={classes.flexContainer}>
          <Card variant="outlined" className={classes.paper2} elevation={0}>
            <Grid container className={classes.grid} spacing={0}>
              <HeroGridItem />
              <Grid item xs={12} sm={6} className={classes.gridItem2}>
                <UserPassForm next={next || '/'} />
                <div className={classes.buttonPanel}>
                  <Button size="small" component={Link} to={buildURL('passwordReset')}>
                    Password Reset
                  </Button>
                </div>
                <Divider className={classes.divider} />
                <SocialLogin next={next || '/'} />
              </Grid>
            </Grid>
          </Card>
        </div>
      </ContentContainer>
    </Div100vh>
  );
}
