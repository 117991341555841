import React from 'react';

import { uniqBy } from 'lodash-es';

import type { MetricValueDefn } from '@plantiga/activity-summaries';

import { useFields, type FieldDefinition } from 'plantiga-common/Fields';
import type { ActivityTypeDefns } from 'plantiga-firebase/ActivityTypes/ActivityTypeDefn';
import useActivityTypes from 'plantiga-firebase/ActivityTypes/useActivityTypes';

export const SPLIT_ACTIVITY_TYPES = ['walk', 'run', 'unilateral_jump', 'bilateral_jump'];

type Options = { includeSplitMetrics?: boolean; activityTypes?: string[] };
/**
 * Returns every possible metric definition matching the fieldId.
 *
 * e.g. if a fieldId matches a walk activity, return all activity types based on walks
 * and all the walk split activity types from mixed activities
 */
function expandFieldIdToMetricDefns(
  fieldId: string,
  fieldDefn: FieldDefinition,
  activityTypeDefns: ActivityTypeDefns,
  options?: Options,
): MetricValueDefn[] {
  const opts = {
    // split-activity-type metrics can duplicate with the activity-type metrics for open activities
    // so by default, we won't fetch them
    includeSplitMetrics: !'open'.match(fieldDefn.activity_types),
    ...(options ?? {}),
  };
  const actTypes = Object.values(activityTypeDefns).filter(
    (actType) => actType.base.match(fieldDefn.activity_types) != null,
  );

  const mixedActivityTypeIds = Object.values(activityTypeDefns).filter(
    (actType) => actType.base === 'open',
  );

  // e.g. open->walk, open->run, custom-open->bilateral_jump, etc...
  const splitMetricDefns = opts.includeSplitMetrics
    ? actTypes
        .filter((actType) => SPLIT_ACTIVITY_TYPES.includes(actType.base))
        .flatMap((splitActType) =>
          mixedActivityTypeIds.map((activityType) => ({
            activity_type: activityType.id,
            split_activity_type: splitActType.base,
            field_id: fieldId,
          })),
        )
    : [];

  // e.g. open, walk, custom-walk, run, etc...
  const singleMetricDefns = actTypes.map((actType) => ({
    activity_type: actType.id,
    split_activity_type: null,
    field_id: fieldId,
  }));

  // if the activity_types are explicit, use them to allow fetching activities that are no-longer enabled
  // otherwise, only show enabled activity-types
  return [...singleMetricDefns, ...splitMetricDefns].filter((m) =>
    options?.activityTypes
      ? options?.activityTypes?.includes(m.activity_type)
      : activityTypeDefns[m.activity_type].defn.enabled,
  );
}

export function useMetricDefns(fieldId: string | string[], options?: Options): MetricValueDefn[] {
  const { getField } = useFields();

  const activityTypeDefns = useActivityTypes({ enabled: false });
  return React.useMemo(() => {
    const fids = typeof fieldId === 'string' ? [fieldId] : fieldId;
    const defns = fids
      .filter((fid) => getField(fid).base !== 'not_found')
      .flatMap((fid) => expandFieldIdToMetricDefns(fid, getField(fid), activityTypeDefns, options));
    // remove any duplicate defns which can result from `expandFieldIdToMetricDefns`
    return uniqBy(defns, (v) => `${v.activity_type}.${v.split_activity_type}.${v.field_id}`);
  }, [activityTypeDefns, fieldId, getField, options]);
}
