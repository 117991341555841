import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Link as LinkIcon, Archive as ArchiveIcon } from '@mui/icons-material';
import { Avatar, Badge, colors } from '@mui/material';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';
import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

const useStyles = makeStyles()(() => ({
  root: {
    backgroundColor: colors.blue[100],
    color: colors.blue[600],
  },
  chip: {
    marginLeft: '-1px',
    width: '32px',
    height: '32px',
    fontSize: '1rem',
    marginRight: '-4px',
  },
  interactive: {
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

function LinkedBadge({ children }: React.PropsWithChildren<{}>) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      badgeContent={<LinkIcon />}
    >
      {children}
    </Badge>
  );
}

function ArchiveBadge({ children }: React.PropsWithChildren<{}>) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      badgeContent={<ArchiveIcon />}
    >
      {children}
    </Badge>
  );
}

function AvatarBadge({ athlete, children }: React.PropsWithChildren<{ athlete: Athlete }>) {
  return (
    <ConditionalWrapper wrapperComponent={ArchiveBadge} condition={!!athlete.archived}>
      <ConditionalWrapper wrapperComponent={LinkedBadge} condition={!!athlete.secondary_team}>
        {children}
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}

type Props = {
  readonly athlete: Athlete;
  readonly chip?: boolean;
  readonly onClick?: any;
};

export default function AthleteAvatar({ athlete, chip = false, onClick }: Props) {
  const { classes, cx } = useStyles();

  const [first, last] = athlete.name.split(' ');
  const F = first ? first[0].toUpperCase() : '';
  const L = last ? last[0].toUpperCase() : '';
  const initials = `${F}${L}`;

  return (
    <AvatarBadge athlete={athlete}>
      <Avatar
        className={cx(classes.root, {
          [classes.chip]: chip,
          [classes.interactive]: onClick != null,
        })}
        onClick={onClick}
      >
        {initials}
      </Avatar>
    </AvatarBadge>
  );
}
