import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from 'plantiga-component/Error/NotFound';

import AuthRoutes from './__auth/_routes';
import { root as authRoot } from './__auth/_urls';
import { root } from './_urls';
import AccountRoutes from './account/_routes';
import { root as accountRoot } from './account/_urls';
import ErrorRoutes from './error/_routes';
import { root as errorRoot } from './error/_urls';
import UtilRoutes from './util/_routes';
import { root as utilRoot } from './util/_urls';

export default function Routes() {
  return (
    <Switch>
      <Route path={root} exact component={AuthRoutes} />
      <Route path={authRoot} component={AuthRoutes} />
      <Route path={utilRoot} component={UtilRoutes} />
      <Route path={accountRoot} component={AccountRoutes} />
      <Route path={errorRoot} component={ErrorRoutes} />
      <Route path="*" render={() => <NotFound />} />
    </Switch>
  );
}
