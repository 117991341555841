import React from 'react';

import { toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import type { Athletes } from '../Firestore/Athletes/typedefs';

import AthleteChip from './AthleteChip';

const useStyles = makeStyles()((theme) => ({
  chipList: {
    margin: theme.spacing(1),
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  readonly athletes: Athletes;
};

export default function AthleteChips({ athletes }: Props) {
  const { classes } = useStyles();
  return (
    <div className={classes.chipList}>
      {toPairs(athletes).map(([athleteId, athlete]: [any, any]) => (
        <AthleteChip key={athleteId} athleteId={athleteId} athlete={athlete} />
      ))}
    </div>
  );
}
