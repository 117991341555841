import { initializeApp, type FirebaseApp } from 'firebase/app';
import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  browserSessionPersistence,
  debugErrorMap,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  setPersistence,
  signInWithCustomToken,
  type UserCredential,
} from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import React from 'react';

import makeDebug from 'debug';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';

import type { Theme } from '@mui/material';
import * as Sentry from '@sentry/react';

import { filterSentryErrors } from 'plantiga-util/errors';

import App from './pages/_root';
import Routes from './pages/_routes';

const debug = makeDebug('plantiga:main');

export type Plantiga = {
  firebase: FirebaseApp;
  signInCi: (token: string) => Promise<UserCredential>;
  history: any;
  version: string | null | undefined;
};

type PlantigaConfig = {
  site: string;
  serviceWorker: boolean;
  firebaseConfig: Record<string, string>;
  domain: string;
  apiUrl: string;
  stripeKey: string;
  stripePortalURL: string;
  stripePlans: Record<string, Record<string, string>>;
  theme: Theme;
  sentryDSN: string;
  dynamicLinkBase: string;
  featureFlags: Record<string, boolean>;
};

declare global {
  interface Window {
    plantiga: PlantigaConfig;
    location: Location;
    _hsq: any;
  }
}

async function main(): Promise<Plantiga> {
  const loadTime = performance.now();
  debug(`plantiga.io (loadTime:${loadTime.toFixed(0)}ms)`, process.env.BUILD_VERSION);

  const history = createBrowserHistory();
  if (!import.meta.env.DEV && window.plantiga.sentryDSN != null) {
    debug('Sentry Init');
    Sentry.init({
      dsn: window.plantiga.sentryDSN,
      release: process.env.BUILD_VERSION,
      environment: window.plantiga.site,
      tracesSampleRate: 1.0,
      beforeSend: filterSentryErrors,
      integrations(defaultIntegrations) {
        const integrations = defaultIntegrations.filter(
          (i) =>
            // Disable error reports for window.onerror and window.onunhandledrejection
            // which were resulting in too many false positives.
            // TODO: re-enable in the future.
            i.name !== 'GlobalHandlers' && i.name !== 'Breadcrumbs',
        );

        // Breadcrumbs was auto loading too much data from console. disable for now
        integrations.push(new Sentry.Integrations.Breadcrumbs({ console: false }));
        integrations.push(
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
          }),
        );

        return integrations;
      },
    });
    let displayMode = 'browser tab';
    if (window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }
    // Record time when the initial bundle renders on page
    Sentry.setMeasurement('ui.shell.load', loadTime, 'millisecond');
    Sentry.setTag('ui.displayMode', displayMode);
  }

  const app = initializeApp(window.plantiga.firebaseConfig);
  // This adds 10kB minified/gzipped of string messages for resolving API errors into
  // user-readable messages.
  initializeAuth(app, {
    errorMap: debugErrorMap,
    // See https://github.com/firebase/firebase-js-sdk/blob/cdada6c68f9740d13dd6674bcb658e28e68253b6/packages/auth/src/platform_browser/index.ts#L44-L51
    popupRedirectResolver: browserPopupRedirectResolver,
    persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
  });
  // We have seen a QuotaExceedError on ios Applications which have an upper bound
  // of 50MB for indexedDB storage space.
  // Default firestore cache size is 40MB, which triggered this error on ios
  initializeFirestore(app, {
    cacheSizeBytes: 30 * 1024 * 1024,
  });

  const element = document.getElementById('root');
  if (element == null) {
    throw new Error("Could not get element with id='root'");
  }

  const component = React.createElement<any>(App, { history }, React.createElement(Routes));

  debug('Start Render');

  createRoot(element).render(component);

  return {
    firebase: app,
    async signInCi(token) {
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      return signInWithCustomToken(auth, token);
    },
    history,
    version: process.env.BUILD_VERSION,
  };
}

main().then((app) => {
  Object.assign(window.plantiga, app);
});
