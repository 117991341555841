import React from 'react';
import ReactDOM from 'react-dom';

import usePageGutters from './usePageGutters';

type Props = {
  children: React.ReactNode;
  gutter: 'right' | 'left' | 'top' | 'bottom';
};

/**
 * PageGutterPortal will render its children in the left or right gutter
 * of its closest parent ContentContainer.
 */
export default function PageGutterPortal({ children, gutter }: Props) {
  const { rightGutterRef, leftGutterRef, topRef, bottomRef } = usePageGutters();

  const parentNode = React.useMemo(() => {
    switch (gutter) {
      case 'left':
        return leftGutterRef;
      case 'right':
        return rightGutterRef;
      case 'top':
        return topRef;
      case 'bottom':
        return bottomRef;
      default:
        return null;
    }
  }, [gutter, rightGutterRef, leftGutterRef, topRef, bottomRef]);

  if (parentNode == null) {
    return null;
  }
  // wrap the portal in a React.Fragment so the returned type is JSX.
  return <>{ReactDOM.createPortal(children, parentNode)}</>;
}
