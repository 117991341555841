import React from 'react';

import type { ScaleLinear } from 'd3';
import { maxBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { LegendOrdinal } from '@visx/legend';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';

import { RadarAxis } from './RadarAxis';
import { Tooltip } from './Tooltip';
import type { BaseDatum } from './typedefs';

const useStyles = makeStyles()((theme) => ({
  plotContainer: {
    position: 'relative',
    flex: 1,
  },
  legendContainer: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'end',
  },
}));

// accessors
const value = (d: { value: number }) => d.value;
const label = (d: { label: string }) => d.label;

// the shaded region shape (team or athlete)
function genPolygonPoints<Datum>(
  dataArray: Datum[],
  rotateDegs: number,
  getScale: (d: Datum) => (n: number) => number,
  getValue: (d: Datum) => number,
  getLabel: (d: Datum) => string,
) {
  const step = (Math.PI * 2) / dataArray.length;
  const dataPoints = new Array<{ x: number; y: number; label: string; datum: Datum | null }>(
    dataArray.length,
  ).fill({
    x: 0,
    y: 0,
    label: '',
    datum: null,
  });
  const pointString: string = new Array(dataArray.length + 1).fill('').reduce((res, _, i) => {
    if (i > dataArray.length) return res;
    const datum = dataArray[i - 1];
    const scale = getScale(datum);
    const xVal = scale(getValue(datum)) * Math.sin(i * step - rotateDegs);
    const yVal = scale(getValue(datum)) * Math.cos(i * step - rotateDegs);
    dataPoints[i - 1] = { x: xVal, y: yVal, label: getLabel(datum), datum };
    return `${res}${xVal},${yVal} `;
  });

  return { dataPoints, pointString };
}

// the area of the radar plot belonging to an axis (shaped like a pizza slice about the axis)
function genSectorPolygons<Datum>(athleteData: Datum[], rotateRads: number, radius: number) {
  const n = athleteData.length;
  const step = (Math.PI * 2) / n;
  return athleteData.map((datum, i) => ({
    points: `
      ${Math.sin((i + 1.5) * step - rotateRads) * radius},${
      Math.cos((i + 1.5) * step - rotateRads) * radius
    }
      ${Math.sin((i + 1) * step - rotateRads) * radius},${
      Math.cos((i + 1) * step - rotateRads) * radius
    }
      ${Math.sin((i + 0.5) * step - rotateRads) * radius},${
      Math.cos((i + 0.5) * step - rotateRads) * radius
    }
      0,0
    `,
    datum,
  }));
}

// the linear scale of each axis
function genAxisScales<Datum>(
  athleteData: Datum[],
  teamData: Datum[],
  radius: number,
  getValue: (d: Datum) => number,
  getLabel: (d: Datum) => string,
) {
  return athleteData.reduce((p, d) => {
    // the compare point should be halfway on the radar axis (if possible)
    const teamValue = getValue(teamData.find((v) => getLabel(v) === getLabel(d)) ?? d) * 2;
    const athleteValue = 1.1 * getValue(d);
    const max = maxBy([teamValue, athleteValue], (v) => Math.abs(v)) ?? 0;
    return Object.assign(p, {
      [getLabel(d)]: scaleLinear<number>({
        range: [0, radius],
        domain: [0, max],
      }),
    });
  }, {} as { [label: string]: ScaleLinear<number, number> });
}

const rotateArrayRight = <D extends unknown>(a: D[], n: number) => [
  ...a.slice(n),
  ...a.slice(0, n),
];

const DEFAULT_MARGIN = { top: 50, left: 50, right: 50, bottom: 10 };

export type Props = {
  width: number;
  height: number;
  athleteName: string;
  margin?: { top: number; right: number; bottom: number; left: number };
  athleteData: BaseDatum[];
  icon?: boolean;
  rotateAxes?: number;
  rotateRads?: number;
  teamData: BaseDatum[];
};

export function Plot({
  width,
  height,
  athleteData: _athleteData,
  teamData: _teamData,
  athleteName,
  icon = false,
  margin = DEFAULT_MARGIN,
  // rotate the axes in place
  rotateAxes = 1,
  // rotate the plot
  rotateRads = Math.PI,
}: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const radius = Math.min(xMax, yMax) / 2;

  const athleteData = rotateArrayRight(_athleteData, rotateAxes);
  const teamData = rotateArrayRight(_teamData, rotateAxes);
  const yScales = genAxisScales(athleteData, teamData, radius, value, label);
  const athletePolygonPoints = genPolygonPoints(
    athleteData,
    rotateRads,
    (d) => yScales[d.label],
    value,
    label,
  );
  const teamPolygonPoints = genPolygonPoints(
    teamData,
    rotateRads,
    (d) => yScales[d.label],
    value,
    label,
  );
  const sectorPolygonPoints = genSectorPolygons(athleteData, rotateRads, radius);

  const colorScale = scaleOrdinal({
    domain: ['athlete', 'team'],
    range: [theme.palette.location.achiral, theme.palette.location.none],
  });

  const [hoveredLabel, setHoveredLabel] = React.useState<string | null>(null);

  const legendLabelFormat = (v: string) => {
    if (v === 'athlete') return athleteName;
    return 'Team Average (Last 28 Days)';
  };

  const hovered = (d: BaseDatum | null) => d?.label === hoveredLabel;

  const { containerRef, containerBounds } = useTooltipInPortal();

  const { showTooltip, tooltipOpen, tooltipTop = 0 } = useTooltip();

  // event handlers
  const handlePointerMove = React.useCallback(
    (e: React.MouseEvent) => {
      showTooltip({
        tooltipLeft: e.clientX - containerBounds.left,
        tooltipTop: e.clientY - containerBounds.top,
      });
    },
    [showTooltip, containerBounds],
  );

  return (
    <div className={classes.plotContainer} ref={containerRef}>
      {!icon && tooltipOpen && (
        <Tooltip
          top={tooltipTop}
          width={width}
          height={height}
          colorScale={colorScale}
          athleteName={athleteName}
          athleteDatum={athleteData.find(hovered)}
          teamDatum={teamData.find(hovered)}
        />
      )}
      <svg width={width} height={height}>
        {!icon && (
          <Group className="radar-axis" top={height / 2} left={width / 2}>
            {athleteData.map((d, i) => (
              <RadarAxis
                key={label(d)}
                scale={yScales[label(d)]}
                ticks={4}
                axisIndex={i}
                axesCount={athleteData.length}
                label={label(d)}
                hideTickLabels={!hovered(d)}
                rotateRads={rotateRads}
              />
            ))}
          </Group>
        )}
        {/* athlete and team shaded regions */}
        <Group className="radar-regions" top={height / 2} left={width / 2}>
          <polygon
            points={teamPolygonPoints.pointString}
            stroke={colorScale('team')}
            fill={colorScale('team')}
            fillOpacity={0.5}
          />
          <polygon
            points={athletePolygonPoints.pointString}
            stroke={colorScale('athlete')}
            fill={colorScale('athlete')}
            fillOpacity={0.5}
          />
        </Group>
        {/* data points visible on hover */}
        <Group className="radar-data-points" top={height / 2} left={width / 2}>
          {!icon &&
            teamPolygonPoints.dataPoints.map((p) => (
              <circle
                key={`team-${p.label}`}
                r={4}
                cx={p.x}
                cy={p.y}
                fill={colorScale('team')}
                fillOpacity={hovered(p.datum) ? 1 : 0}
              />
            ))}
          {!icon &&
            athletePolygonPoints.dataPoints.map((p) => (
              <circle
                key={`athlete-${p.label}`}
                r={4}
                cx={p.x}
                cy={p.y}
                fill={colorScale('athlete')}
                fillOpacity={hovered(p.datum) ? 1 : 0}
              />
            ))}
        </Group>
        {/* hit-box to select datums on hover */}
        <Group className="radar-sectors" top={height / 2} left={width / 2}>
          {!icon &&
            sectorPolygonPoints.map((v) => (
              <polygon
                key={v.points}
                points={v.points}
                fillOpacity={0}
                onMouseMove={handlePointerMove}
                onMouseOver={() => setHoveredLabel(v.datum.label)}
                onMouseOut={() => setHoveredLabel(null)}
              />
            ))}
        </Group>
      </svg>
      {!icon && (
        <div className={classes.legendContainer}>
          <LegendOrdinal scale={colorScale} labelFormat={legendLabelFormat} />
        </div>
      )}
    </div>
  );
}
