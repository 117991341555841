import React from 'react';

import { DialogContent, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';
import PickableUnitTextField from 'plantiga-common/PickableUnitTextField';

import type { PersonalInfo } from './typedefs';
import { textFieldProps } from './util';

type Props = {
  title: string;
  isMobile: boolean;
  readonly personalInfo: PersonalInfo;
  setPersonalInfo: any;
};

export default function PersonalInfoForm({
  title,
  isMobile,
  personalInfo,
  setPersonalInfo,
}: Props) {
  const { gender, dateOfBirth, height, weight } = personalInfo;

  const handleChange = (key: string, value: any) => {
    setPersonalInfo({
      ...personalInfo,
      [key]: value,
    });
  };

  return (
    <ConditionalWrapper condition={!isMobile} wrapperComponent={DialogContent}>
      {!isMobile && (
        <Typography gutterBottom variant="h5" align="center">
          {title}
        </Typography>
      )}
      <Typography paragraph variant="body1">
        Providing basic information about this person can help Plantiga create more personalized
        insights.
      </Typography>
      <form noValidate autoComplete="off">
        <TextField
          autoFocus
          select
          id="outlined-gender"
          label="Gender"
          value={gender || ''}
          onChange={(e) => handleChange('gender', e.target.value)}
          {...textFieldProps}
        >
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="other">Other</MenuItem>
          <MenuItem value="undisclosed">Prefer not to say</MenuItem>
        </TextField>
        <br />
        <DatePicker
          disableFuture
          label="Date of Birth"
          value={dateOfBirth}
          onChange={(v) => handleChange('dateOfBirth', v)}
          slotProps={{ textField: { fullWidth: true } }}
        />
        <br />
        <PickableUnitTextField
          label="Height"
          storedUnit="m"
          displayUnit={{
            imperial: 'ft/in',
            metric: 'cm',
            research: 'cm',
          }}
          value={height ?? null}
          onChange={(v) => handleChange('height', v)}
          {...textFieldProps}
        />
        <PickableUnitTextField
          label="Weight"
          storedUnit="kg"
          displayUnit={{
            imperial: 'lbs',
            metric: 'kg',
            research: 'kg',
          }}
          value={weight ?? null}
          onChange={(v) => handleChange('weight', v)}
          {...textFieldProps}
        />
      </form>
    </ConditionalWrapper>
  );
}
