import { matchPath } from 'react-router';

import { getUrls, type UrlParams, type urlKey } from './urls';

export function findPathMatch(
  locationPathname: string,
  paths: (keyof ReturnType<typeof getUrls>)[],
) {
  const urls = getUrls();
  let match = null;
  for (let i = 0; i < paths.length; i += 1) {
    match = matchPath<UrlParams>(locationPathname, { path: urls[paths[i]] }) ?? match;
    if (match?.isExact) break;
  }
  return match;
}

export function findNestedPaths(rootPathname: urlKey) {
  const urls = getUrls();
  return Object.entries(urls)
    .filter(([_, url]) => matchPath(url, { path: urls[rootPathname] }) != null)
    .map(([pathname]) => pathname) as urlKey[];
}
