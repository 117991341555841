import React from 'react';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';
import ConfirmDialog from 'plantiga-common/ConfirmDialog';
import useDeleteEvent from 'plantiga-firebase/Events/useDeleteEvent';
import useTeam from 'plantiga-firebase/Team/useTeam';

type Props = {
  eventId: string;
  onDelete?: any;
};

export default function DeleteEventIcon({ eventId, onDelete }: Props) {
  const { teamId } = useTeam();
  const canDeleteEvent = usePrivilege('event:delete');
  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );
  const { remove } = useDeleteEvent();
  const [open, setOpen] = React.useState(false);

  const handleDelete = React.useCallback(async () => {
    await remove(teamId, eventId);
    if (unMounted.current || onDelete == null) return;
    onDelete();
    setOpen(false);
  }, [teamId, eventId, remove, onDelete]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} disabled={!canDeleteEvent} size="large">
        <DeleteIcon />
      </IconButton>
      <ConfirmDialog
        open={open}
        confirmationText="delete"
        color="secondary"
        message="Once the event has been deleted, it cannot be undone."
        onClose={() => setOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  );
}
