import React from 'react';

import { formatDistanceToNow } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { Timer } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { useNavigateURL } from 'plantiga-common/useBuildURL';

import type { Stopwatch } from '../../Firestore/Stopwatch/typedefs';

type Props = {
  readonly stopwatch: Stopwatch;
  readonly swid: string;
  readonly onClick?: any;
};

const useStyles = makeStyles()((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function ActiveStopwatchListItem({ swid, stopwatch, onClick = null }: Props) {
  const navigateURL = useNavigateURL();
  const { classes } = useStyles();
  const {
    name,
    start,
    created_by: createdBy,
    athlete_ids: athleteIds,
    type_id: activityTypeId,
  } = stopwatch;
  const secondary: Array<
    | React.ReactElement<React.ComponentProps<'br'>>
    | React.ReactElement<React.ComponentProps<'span'>>
  > = [];
  if (start) {
    secondary.push(
      <span key="Started">{`Started: ${formatDistanceToNow(start.toDate())} ago`}</span>,
    );
  }
  if (createdBy) {
    secondary.push(<br key="br1" />);
    secondary.push(<span key="createdBy">{`Created By: ${createdBy}`}</span>);
  }

  const handleNavigate = (e: any) => {
    navigateURL('recordActive', { activityTypeId, swid }, { people: athleteIds.join(',') });
    if (onClick != null) {
      onClick(e);
    }
  };

  return (
    <ListItem key={swid} button className={classes.nested} onClick={handleNavigate}>
      <ListItemIcon>
        <Timer />
      </ListItemIcon>
      <ListItemText primary={name} secondary={secondary} />
    </ListItem>
  );
}
