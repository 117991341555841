import React from 'react';

import { compact, find } from 'lodash-es';

import { Button, DialogContent, DialogActions } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import usePair, { useUnpair } from 'plantiga-firebase/Devices/usePair';

import SelectLeftAndRightDevice from './SelectLeftAndRightDevice';
import type { ChiralDevices } from './SelectLeftAndRightDevice';

const diffDeviceIds = (a: ChiralDevices, b: ChiralDevices) =>
  compact([a.L?.id === b.L?.id ? null : a.L?.id, a.R?.id === b.R?.id ? null : a.R?.id]);

type Props = {
  readonly onClose: () => void;
  readonly open: boolean;
  readonly athleteId: string;
  readonly dialogDetails?: React.ReactNode;
};

export function PairAthleteToPodsDialog({ open, onClose, dialogDetails, athleteId }: Props) {
  const { pair, loading: pairing } = usePair();
  const { unpair, loading: unpairing } = useUnpair();
  const devices = useDevices();
  const athlete = useAthlete(athleteId);
  const paired = React.useMemo(
    () => ({
      L: find(devices, (v) => v.athlete_id === athleteId && v.location === 'L'),
      R: find(devices, (v) => v.athlete_id === athleteId && v.location === 'R'),
    }),
    [athleteId, devices],
  );
  const [toPair, setToPair] = React.useState<ChiralDevices>(paired);

  const handlePair = React.useCallback(async () => {
    const unpairIds = diffDeviceIds(paired, toPair);
    await Promise.all(unpairIds.map((id) => unpair(id)));
    const pairIds = diffDeviceIds(toPair, paired);
    await pair(pairIds, athleteId);
    onClose();
  }, [athleteId, onClose, pair, unpair, paired, toPair]);

  const handleChange = React.useCallback((location: 'L' | 'R', d: Device | undefined) => {
    setToPair((p) => ({ ...p, [location]: d }));
  }, []);

  const devicesChanged = toPair.L?.id !== paired.L?.id || toPair.R?.id !== paired.R?.id;
  const devicesMissing = toPair.L == null || toPair.R == null;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      title={`Pair Pods to ${athlete?.name ?? 'this person'}`}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        {dialogDetails}
        <SelectLeftAndRightDevice devices={toPair} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button key="dialog-button-1" onClick={onClose} disabled={pairing}>
          Cancel
        </Button>
        <ButtonWithLoading
          key="dialog-button-2"
          color="primary"
          variant="outlined"
          disabled={!devicesChanged || devicesMissing}
          loading={pairing || unpairing}
          onClick={handlePair}
        >
          Pair
        </ButtonWithLoading>
      </DialogActions>
    </Dialog>
  );
}
