import React from 'react';

import { map } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Typography, FormControl, FormHelperText, Collapse } from '@mui/material';

import { Pod } from 'plantiga-component/Teams/Devices/Pod';
import type { Device } from 'plantiga-firebase/Devices/typedefs';

import AutocompleteDevice from './AutocompleteDevice';
import useDeviceWarnings from './useDeviceWarnings';

export type ChiralDevices = { L: Device | undefined; R: Device | undefined };

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  pairDeviceSelect: {
    gap: theme.spacing(1),
    minWidth: 240,
    flex: 1,
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function DeviceWarnings({ deviceId }: { deviceId: string }) {
  const warnings = useDeviceWarnings(deviceId);
  return (
    <>
      {map(warnings, (warning, k) => (
        <FormHelperText key={k}>{warning}</FormHelperText>
      ))}
    </>
  );
}

function DeviceSelect({
  location,
  device,
  onChange,
}: {
  location: 'L' | 'R';
  device: Device | undefined;
  onChange: (device: Device | undefined) => void;
}) {
  const { classes } = useStyles();
  return (
    <div className={classes.pairDeviceSelect}>
      <FormControl fullWidth className={classes.flexCenter}>
        <Typography variant="subtitle1">{location === 'L' ? 'Left' : 'Right'}</Typography>
        <AutocompleteDevice location={location} onChange={onChange} deviceId={device?.id} />
        <Collapse in={!!device?.id}>
          {device?.id ? <DeviceWarnings deviceId={device.id} /> : null}
          {device?.id ? <Pod hideChip readonly pod={device} /> : null}
        </Collapse>
      </FormControl>
    </div>
  );
}

type Props = {
  readonly devices: { L: Device | undefined; R: Device | undefined };
  readonly onChange: (location: 'L' | 'R', device: Device | undefined) => void;
};

export default function SelectLeftAndRightDevice({ devices, onChange }: Props) {
  const { classes } = useStyles();

  const handleChange = (l: 'L' | 'R') => (d: Device | undefined) => onChange(l, d);
  return (
    <div className={classes.container}>
      <DeviceSelect device={devices.L} location="L" onChange={handleChange('L')} />
      <DeviceSelect device={devices.R} location="R" onChange={handleChange('R')} />
    </div>
  );
}
