import {
  getStorage,
  listAll,
  ref,
  uploadBytesResumable,
  deleteObject,
  getDownloadURL,
  type StorageReference,
} from 'firebase/storage';
import { useCallback, useEffect, useState, useRef } from 'react';

import makeDebug from 'debug';
import { find } from 'lodash-es';

import useApiClient from 'plantiga-util/apiClient';
import cloudRunFunction from 'plantiga-util/cloudRunFunction';
import saveFile from 'plantiga-util/saveFile';

import { useToast } from '../../Toast/UseToast';

/**
React hook to integrate with storage

@param teamId:
@param directory: path to the directory to list the files
*/
const useFiles = (directory: string) => {
  const isMounted = useRef(true);
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState<StorageReference[]>([]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const refresh = useCallback(async () => {
    setLoading(true);
    const res = await listAll(ref(getStorage(), directory));
    if (!isMounted.current) return;
    setFiles(res.items);
    setLoading(false);
  }, [directory]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const deleteFile = async (file: StorageReference) => {
    const newFiles = files.filter((f) => f !== file);
    setFiles(newFiles);
    await deleteObject(file);
  };

  const downloadFile = async (file: StorageReference) => {
    const uri = await getDownloadURL(file);
    saveFile({ uri, filename: file.name });
  };

  return {
    loading,
    files,
    getDownloadURL,
    deleteFile,
    downloadFile,
    refresh,
  };
};

const debug = makeDebug('firebase');

/**
@param file: file object from input element
@returns {progress: int, cancel}
*/
export const useUpload = (
  pathPrefix: string,
  file: any,
  {
    onComplete,
  }: {
    onComplete?: any;
  } = {
    onComplete: undefined,
  },
): {
  cancel: () => Promise<void>;
  progress: number;
  error: Error | null;
  completed: boolean;
} => {
  const [error, setError] = useState<Error | null>(null);
  const [completed, setCompleted] = useState(false);
  const [progress, setProgress]: [number, any] = useState(0);
  const [uploadTask, setTask]: [any, any] = useState(null);
  const onCompleteRef = useRef(onComplete);
  useEffect(() => {
    onCompleteRef.current = onComplete;
  }, [onComplete]);

  // @ts-expect-error - TS2345 - Argument of type '() => Function' is not assignable to parameter of type 'EffectCallback'.
  useEffect(() => {
    const path = `${pathPrefix}/${file.name}`;
    const fileRef = ref(getStorage(), path);

    const task = uploadBytesResumable(fileRef, file);
    setTask(task);
    const unsubscribe = task.on(
      'state_changed',
      (snapshot) => {
        const prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(prog);
      },
      (err) => {
        setError(err);
      },
      () => {
        setCompleted(true);
        if (onCompleteRef.current) onCompleteRef.current();
      },
    );
    return unsubscribe;
  }, [pathPrefix, file.name, file]);

  const cancel = async () => {
    if (!completed && uploadTask != null) {
      debug(`cancel uploadTask for file ${file.name}`);
      uploadTask.cancel();
    } else {
      const path = `${pathPrefix}/${file.name}`;
      const fileRef = ref(getStorage(), path);
      await deleteObject(fileRef);
    }
  };

  return {
    progress,
    cancel,
    error,
    completed,
  };
};

export const useGenerateCSVFile = (
  teamId: string,
  athleteId: string,
  activityId: string,
  files: Array<any>,
  refreshFiles: any,
): {
  generate: () => Promise<void>;
  status: string;
} => {
  const haveCSV0 = find(files, { name: `${activityId}.csv` }) != null;
  const [status, setStatus] = useState<string>(haveCSV0 ? 'created' : 'missing');
  const postToast = useToast();
  const apiClient = useApiClient();

  useEffect(() => {
    const haveCSV1 = find(files, { name: `${activityId}.csv` }) != null;
    setStatus(haveCSV1 ? 'created' : 'missing');
  }, [teamId, activityId, files]);

  const generate = useCallback(async () => {
    setStatus('loading');
    try {
      await apiClient.generateCsv({ teamId, athleteId, activityId });
      refreshFiles();
    } catch (err: any) {
      console.error(err);
      setStatus('missing');
      postToast({ message: 'Failed to create csv', variant: 'error' });
    }
  }, [apiClient, teamId, athleteId, activityId, refreshFiles, postToast]);

  return { generate, status };
};

export const useGenerateAsymmetryMap = (
  teamId: string,
  activityId: string,
  files: Array<any>,
  refreshFiles: any,
): {
  generate: () => Promise<void>;
  status: string;
} => {
  const haveAsymmetryMap0 = find(files, { name: `${activityId}_movement_map.jpg` }) != null;
  const [status, setStatus] = useState<string>(haveAsymmetryMap0 ? 'created' : 'missing');
  const postToast = useToast();

  useEffect(() => {
    const haveAsymmetryMap1 = find(files, { name: `${activityId}_movement_map.jpg` }) != null;
    setStatus(haveAsymmetryMap1 ? 'created' : 'missing');
  }, [teamId, activityId, files]);

  const generate = useCallback(async () => {
    setStatus('loading');
    const generateAsymmetryMap = cloudRunFunction('customer_reports/generate_asymmetry_map');

    try {
      await generateAsymmetryMap({ team_id: teamId, activity_id: activityId });
      refreshFiles();
    } catch (err: any) {
      console.error(err);
      setStatus('missing');
      postToast({ message: 'Failed to create movement map', variant: 'error' });
    }
  }, [teamId, activityId, refreshFiles, postToast]);

  return { generate, status };
};

export default useFiles;
