import React, { forwardRef, useEffect, useRef, useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { makeStyles } from 'tss-react/mui';

import { Check as CheckIcon } from '@mui/icons-material';
import { Button, Grid, Paper, Typography } from '@mui/material';

import { useStableValue } from 'plantiga-common/hooks';

const useStyles = makeStyles()((theme) => ({
  button: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minWidth: 32,
    color: theme.palette.primary.contrastText,
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    color: theme.palette.primary.contrastText,
    minWidth: 300,
    maxWidth: 400,
  },
  /* eslint-disable tss-unused-classes/unused-classes */
  default: {
    backgroundColor: theme.palette.plantiga.lightCharcoal,
  },
  success: {
    background: theme.palette.success.main,
  },
  warning: {
    background: theme.palette.warning.main,
  },
  error: {
    background: theme.palette.error.main,
  },
  /* eslint-enable tss-unused-classes/unused-classes */
}));

// id generated by snackbar
type Props = {
  readonly snackbarId: string | number | undefined;
  readonly message: React.ReactNode;
  readonly action: any | null | undefined;
  readonly undoAction: any | null | undefined;
  readonly undoText: string | null | undefined;
  readonly variant: 'success' | 'error' | 'warning' | 'default';
  readonly timeout: number;
};

export default forwardRef<typeof Paper, Props>(
  ({ snackbarId, message, action, undoAction, variant, timeout, undoText }: Props, ref) => {
    const { closeSnackbar } = useSnackbar();
    const timeoutRef = useRef();
    const actionStable = useStableValue(action);
    const undoActionStable = useStableValue(undoAction);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (actionStable) {
        const timeRef = setTimeout(() => {
          closeSnackbar(snackbarId);
          actionStable();
        }, timeout * 1000);

        // @ts-expect-error - TS2322 - Type 'Timeout' is not assignable to type 'undefined'.
        timeoutRef.current = timeRef;
        return () => clearTimeout(timeoutRef.current);
      }
    }, [actionStable, closeSnackbar, snackbarId, timeout]);

    const undo = useCallback(() => {
      if (actionStable) {
        clearTimeout(timeoutRef.current);
        if (undoActionStable) undoActionStable();
      }
      closeSnackbar(snackbarId);
    }, [actionStable, undoActionStable, closeSnackbar, snackbarId]);

    const dismiss = useCallback(() => {
      if (actionStable) {
        clearTimeout(timeoutRef.current);
        actionStable();
      }
      closeSnackbar(snackbarId);
    }, [actionStable, closeSnackbar, snackbarId, timeoutRef]);

    const { classes } = useStyles();

    return (
      <Paper elevation={2} classes={{ root: classes[variant] }} ref={ref}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          classes={{ root: classes.container }}
        >
          <Grid item xs={12}>
            <Typography type="body1">{message}</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="flex-end">
            <Grid container justifyContent="flex-end" spacing={1}>
              {action && (
                <Grid item>
                  <Button variant="text" onClick={undo} className={classes.button}>
                    {undoText}
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button onClick={dismiss} className={classes.button}>
                  <CheckIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  },
);
