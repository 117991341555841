import React from 'react';

import { SvgIcon } from '@mui/material';

function TennisServeIcon({ color }: { color?: React.ComponentProps<typeof SvgIcon>['color'] }) {
  return (
    <SvgIcon color={color} viewBox="150 25 550 550">
      <path
        d="M507.8,51c2.9,2.5,5.8,5,8.8,7.5c3-9.4-3.7-21.5,7.9-29.5c5.1,5.6,10.4,10.4,11.4,18.2c0.4,3.3,1.9,6.6,2,9.9
  c0.2,6.5,0.2,13-0.6,19.4c-1.4,10.8-3.6,21.4-5.4,32.1c-2.3,13-6.1,25.2-15.1,35.4c-2.9,3.3-4.6,7.6-6.8,11.5
  c-3,5.3-4.9,11.5-9,15.7c-5.7,5.9-3.8,10.3-1.3,16.8c9.5,23.7,17.3,47.9,18.1,73.8c0.1,2.4,1.2,4.8,1.9,7.1
  c5.5,16.5,11.4,32.9,16.4,49.6c1.5,5.1,0.6,11,1,16.4c0.4,5.5,1.1,10.9,1.7,16.9c-8.2,0.1-7.9,6.6-8.1,13.8
  c-0.6,24.1-5,47.4-16.3,69.2c-2.8,5.4-5.5,9.7-11.1,12c-1.1,0.5-2.1,1.4-2.9,2.3c-8.2,8.3-18.2,13.5-29.2,17.2
  c-10.7,3.6-21.3,7.8-31.9,11.8c0.1,0.5,0.2,1,0.3,1.5c1.3,0.6,2.7,1.3,5.3,2.5c-1.2,0.5-1.9,0.6-2.5,1c-4.4,2.7-4.5,4.8-0.7,7.9
  c6.2,5,12.5,10,18.4,15.3c1.9,1.7,4.2,4.9,3.8,6.9c-0.4,1.8-4.1,3.6-6.6,4c-5,0.9-10.3,1.2-15.4,1c-5.2-0.2-9.4-2.6-12.5-7.1
  c-5.8-8.3-8.8-9.9-18-10.4c2.3,6.4,1,13.5,6.4,18.8c1.1,1.1,1.7,2.7,2.4,4.1c2.4,5.4,0.6,8.4-5.3,8.2c-4.4-0.2-8.9-0.8-13.2-2
  c-7.6-2.1-11.7-7.2-12.6-15.4c-0.4-4-2.4-8-4.5-11.5c-2.5-4.3-3-8.6-0.3-12.6c4.3-6.6,7.7-13.5,16-17.1c4.7-2,7.7-8.6,11.1-13.3
  c5.1-7.1,9.5-14.7,15-21.4c5.9-7.3,13-13.2,23.2-13.9c1.4-0.1,3.7-3.4,3.7-5.2c0.1-4.8,0-9.8-1.3-14.3c-4-13.8-8.6-27.5-13.1-41.9
  c-1.8,1.2-3.3,2.3-5.2,3.5c-2.8-8.2-4.1-16.1,0.3-24.2c1.2-2.1,0.7-5.1,0.9-7.7c0.9-11,1.3-22,2.8-32.9c0.6-4.1,3.2-8.4,6-11.7
  c6-7.3,5.8-19-1.5-24.8c-4.4-3.5-7.4-7.4-7.6-13.4c-0.2-4.9-2.4-8.3-7.9-9c-4-0.5-7.9-2.2-11.7-3.3c-7.2,7-14.1,14.4-21.8,20.9
  c-6.8,5.7-14.3,10.6-21.8,15.5c-7,4.5-14.5,5.1-21.7,0.6c-7-4.3-13.6-9.1-20.6-13.3c-2.5-1.5-5.7-2-8.5-3
  c-8.5-3.2-10.4-6.5-8.4-15.1c1.2-5.1,2.8-10,4.2-15.1c0.2-0.6,0-1.4-0.1-2.1c-1.6-8.9-2.5-18-5-26.7c-2.1-7.5-5.9-14.5-8.8-21.8
  c-8.9-22.4-17.6-44.8-21.8-68.7c-0.9-5.2-1.3-10.8-0.4-15.9c0.5-3.1,3.5-6.7,6.4-8.1c3.8-1.9,6.6,1.7,8.4,4.8
  c6.4,10.8,8.5,23.1,10.3,35.2c3.6,23.8,6,47.8,10.1,71.5c2,11.5,6.4,22.6,9.9,33.8c0.2,0.8,1,1.7,1.7,1.9c5.3,1.7,6.7,5.9,7.4,10.7
  c0.1,1.1,0.9,2.4,1.7,3c11.5,7.7,26.9,11.8,39.2-1c3.9-4.1,8.7-7.3,13.1-11c1.3-1.1,2.2-2.6,3.3-4c5.4-6.8,10.3-14.1,16.3-20.3
  c3-3.1,7.9-4.5,12.2-6.8c-1.1-2.9-3-6.3-3.5-9.8c-0.5-3.4,0.6-7.1,1.1-11.8c-1.7-1.8-4.6-4.6-7.1-7.7c-4.6-5.7-5.9-12.1-2.6-19
  c2.3-4.8,4.3-9.8,7-14.4c2.4-3.9,5.9-6.4,11-6.5c1.6,0,3.2-1.5,4.8-2.4c3.9-2.2,7.9-6.5,11.5-6.2c5.3,0.4,4.8,6.9,6.1,11.2
  c1.2,3.9,3.6,7.3,5.3,11.1c3.3,7.2,9.8,13.6,5.4,22.6c-0.6,1.2-0.1,2.9-0.1,4.4c0.5,0.3,0.9,0.6,1.4,0.8c3.1-3.3,6.6-6.4,9.3-10
  c5.3-7.2,9.7-15.1,19.5-17.2c0.3-0.1,0-1.5,0.4-2c1.2-1.7,2.6-3.4,4-5c5.7-6.6,10-13.6,11-22.6c1-8.7,4.6-16.5,10-23.9
  c3.6-4.9,5.4-11.2,7.2-17.1c0.6-1.8-0.9-4.6-2.1-6.4c-4.6-6.9-9.5-13.5-14.3-20.2C506.4,52.2,507.1,51.6,507.8,51z"
      />
    </SvgIcon>
  );
}

TennisServeIcon.defaultProps = {
  color: undefined,
};

export default TennisServeIcon;
