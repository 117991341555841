import React from 'react';

import { addDays, endOfDay } from 'date-fns';
import { groupBy, difference, orderBy, uniq } from 'lodash-es';

import { useFields } from 'plantiga-common/Fields';
import { aggregateByDate } from 'plantiga-component/Athletes/LoadOverview/aggregateByDate';
import { useMetricDefns } from 'plantiga-component/Metric/useMetricDefns';
import { flattenMetricValues } from 'plantiga-component/Metric/useMetricsTrends';
import { useOrgMetricsTrends } from 'plantiga-component/Metric/useOrgMetricsTrends';
import useActivitiesForDayOf from 'plantiga-firebase/Activity/useActivitiesForDayOf';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import { metricAggregation } from 'plantiga-util/metric';

import { teamDashboardTemplate, templateFieldId } from './constants';

/**
 * `TeamDashDatum` represents the aggregated metric value
 * (aggregation determined by the `MetricDefinition`)
 * for a given `athlete`, `fieldId`, and `date`
 */
export type TeamDashDatum = {
  athleteId: string;
  fieldId: string;
} & ReturnType<typeof aggregateByDate>[number];

const FIELD_IDS = uniq(
  Object.values(teamDashboardTemplate)
    .flatMap((v) => Object.values(v))
    .map((v) => templateFieldId(v)),
);

export function useTeamDashboard(date: Date, showArchived: boolean) {
  const { getField } = useFields();
  const athletes = useAthletes({ withArchived: showArchived });

  // get all organization activities for this date
  const {
    activities,
    loading: initializing,
    error: activitiesError,
  } = useActivitiesForDayOf(null, date);
  const activitiesByAthleteId = React.useMemo(
    () => groupBy(activities, 'athlete_id'),
    [activities],
  );

  // sort athletes by whether they recorded an activity, then alphabetically
  const athleteIds = React.useMemo(
    () =>
      orderBy(Object.keys(athletes), [
        (id) => !(id in activitiesByAthleteId),
        (id) => athletes[id].name,
      ]),
    [athletes, activitiesByAthleteId],
  );

  // limit metrics to activity-types recorded on this date
  const metricDefnsOpts = React.useMemo(
    () => ({
      activityTypes: uniq(Object.values(activities).map((v) => v.labels.type_id ?? v.labels.type)),
    }),
    [activities],
  );
  const metricDefns = useMetricDefns(FIELD_IDS, metricDefnsOpts);

  // fetch data for athletes with activities on this date
  const athleteIdsWithoutData = React.useMemo(
    () => new Set(difference(Object.keys(athletes), Object.keys(activitiesByAthleteId))),
    [athletes, activitiesByAthleteId],
  );
  const orgMetricOpts = React.useMemo(
    () => ({
      before: endOfDay(date),
      after: addDays(date, -28),
      skipFetch: (athleteId: string) => athleteIdsWithoutData.has(athleteId),
      sourceActivityOnly: true,
    }),
    [date, athleteIdsWithoutData],
  );
  const {
    data,
    isLoading,
    error: metricsError,
  } = useOrgMetricsTrends(athleteIds, metricDefns, orgMetricOpts);

  // flatten and aggregate data by athleteId, fieldId, and date
  const datums: TeamDashDatum[] | undefined = React.useMemo(
    () =>
      data?.flatMap((athleteData) =>
        Object.entries(groupBy(athleteData.data, 'field_id')).flatMap(([fieldId, mvs]) =>
          aggregateByDate(flattenMetricValues(mvs), metricAggregation(fieldId, getField)).map(
            (o) => ({ ...o, athleteId: athleteData.athleteId, fieldId }),
          ),
        ),
      ),
    [data, getField],
  );

  return {
    data,
    datums,
    isLoading,
    initializing,
    error: metricsError || activitiesError,
  };
}
