import React from 'react';

import { Button, DialogContent } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';
import useCreateEvent from 'plantiga-firebase/Events/useCreateEvent';

import EditEventForm from './EditEventForm';

type Props = {
  athleteId: string;
  open: boolean;
  onClose: any;
};

export default function NewEventDialog({ athleteId, open, onClose }: Props) {
  const { create: createEvent, loading } = useCreateEvent();
  const [event, setEvent] = React.useState<any>();
  const [disabled, setDisabled] = React.useState<any>();

  const handleChange = React.useCallback((updatedEvent: PlantigaEvent, isValid: boolean) => {
    setDisabled(!isValid);
    setEvent(updatedEvent);
  }, []);

  const handleCreate = React.useCallback(async () => {
    if (event == null) return;
    const newEventId = await createEvent(event);
    onClose(newEventId, event.date);
  }, [createEvent, onClose, event]);

  const actionButtons = React.useMemo(
    () => [
      <Button key="dialog-button-1" onClick={onClose}>
        Cancel
      </Button>,
      <ButtonWithLoading
        key="dialog-button-2"
        color="primary"
        loading={loading}
        disabled={disabled}
        onClick={handleCreate}
      >
        Create
      </ButtonWithLoading>,
    ],
    [handleCreate, onClose, loading, disabled],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      title="New Event"
      onClose={onClose}
      actionButtons={actionButtons}
    >
      <DialogContent>
        <EditEventForm athleteId={athleteId} onFormChanged={handleChange} />
      </DialogContent>
    </Dialog>
  );
}
