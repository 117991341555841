import React from 'react';

import { uniq, compact } from 'lodash-es';

import type { MetricValueDefn } from '@plantiga/activity-summaries';

import { useFields } from 'plantiga-common/Fields';
import PlantigaInfoIcon from 'plantiga-component/Common/PlantigaInfo/PlantigaInfo';

import { hasHelpPage, buildHelpPageUrl } from '../../util/helpPage';

type Props = {
  metrics: MetricValueDefn[] | undefined;
} & Omit<React.ComponentProps<typeof PlantigaInfoIcon>, 'helpPageUrls'>;

export default function MetricInfo({ metrics, ...rest }: Props) {
  const { getField } = useFields();

  const helpPageUrls = React.useMemo(() => {
    if (metrics == null) return [];
    const urls = metrics.map(
      ({
        activity_type: activityType,
        split_activity_type: splitActivityType,
        field_id: fieldId,
      }) => {
        const field = getField(fieldId);
        if (field.help_url_slug == null) return null;
        if (!hasHelpPage(splitActivityType ?? activityType, field)) return null;
        return buildHelpPageUrl(splitActivityType ?? activityType, field.help_url_slug);
      },
    );
    return uniq(compact(urls));
  }, [getField, metrics]);

  if (helpPageUrls.length === 0) return null;
  return <PlantigaInfoIcon helpPageUrls={helpPageUrls} {...rest} />;
}
