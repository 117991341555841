import React from 'react';
import { Redirect } from 'react-router-dom';

import { pick } from 'lodash-es';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useQuery, useParams } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';
import Wait from 'plantiga-common/Wait';
import Running from 'plantiga-component/Stopwatch/Running/Running';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import type { Stopwatch } from 'plantiga-firebase/Stopwatch/typedefs';
import useStopwatch from 'plantiga-firebase/Stopwatch/useStopwatch';

type Props = {
  stopwatch: Stopwatch;
  swid: string;
  activityTypeId: string;
};

function RunningStopwatchController({ stopwatch, swid, activityTypeId }: Props) {
  const queryParams = useQuery();
  const buildURL = useBuildURL();
  const athletes = useAthletes({ withArchived: true });
  const stopwatchAthletes = React.useMemo(
    () => pick(athletes, stopwatch.athlete_ids),
    [athletes, stopwatch],
  );

  if (stopwatch.run_state === 'stopped') {
    const to = buildURL('stopwatchFinalize', { activityTypeId, swid }, queryParams);
    return <Redirect to={to} />;
  }
  if (stopwatch.run_state !== 'running') {
    const to = buildURL('stopwatch', {}, queryParams);
    return <Redirect to={to} />;
  }

  return (
    <ContentContainer>
      <Running stopwatch={stopwatch} swid={swid} athletes={stopwatchAthletes} />
    </ContentContainer>
  );
}

export default function RunningView() {
  const { activityTypeId, swid } = useParams();
  const { stopwatch, loading, error } = useStopwatch(swid);

  return (
    <Wait waitingOn={loading || stopwatch == null || swid == null} error={error} info="RunningView">
      {/* TODO fix typescript errors for Wait's children */}
      {stopwatch && (
        <RunningStopwatchController
          stopwatch={stopwatch}
          swid={swid}
          activityTypeId={activityTypeId}
        />
      )}
    </Wait>
  );
}
