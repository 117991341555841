import React from 'react';

import useCoach from 'plantiga-firebase/Coach/useCoach';
import FeatureFlagsContext, {
  type FeatureFlags,
} from 'plantiga-firebase/FeatureFlags/FeatureFlagsContext';
import type { Coach as User } from 'plantiga-firebase/schema';
// eslint-disable-next-line no-restricted-imports
import { useTeamFeatures, type Features } from 'plantiga-firebase/Team/useTeamFeature';
import type { urlKey } from 'plantiga-util/urls';

import { useTierViews } from './useTierViews';

export type Claim = (p: Principal) => boolean;

export class Principal {
  user: User;

  demo: boolean;

  features: Features;

  flags: FeatureFlags;

  views: Set<urlKey>;

  constructor(
    user: User,
    demo: boolean,
    features: Features,
    flags: FeatureFlags,
    views: Set<urlKey>,
  ) {
    this.user = user;
    this.demo = demo;
    this.features = features;
    this.flags = flags;
    this.views = views;
  }

  hasClaim(c: Claim) {
    return c(this);
  }
}

export function usePrincipal(): Principal | null {
  const { coach } = useCoach();
  const { featureFlags } = React.useContext(FeatureFlagsContext);
  const features = useTeamFeatures();
  const { include: views } = useTierViews();
  const isDemo = coach?.demo ?? false;
  return React.useMemo(
    () => (coach ? new Principal(coach, isDemo, features, featureFlags, views) : null),
    [coach, isDemo, features, featureFlags, views],
  );
}
