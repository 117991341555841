import React from 'react';
import { useDropzone } from 'react-dropzone';

import { fromPairs, omit, sortBy, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CloudDownload, CloudUpload, Delete as DeleteIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';

import useFiles from '../../Firestore/Storage/useFiles';

import FileUploadItem from './FileUploadItem';

type Props = {
  readonly pathPrefix: string;
  readonly extraItems?: any;
};

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  dropzoneHidden: {
    display: 'none',
  },
  dropzone: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.grey[300],
    zIndex: 2,
    cursor: 'pointer',
    border: '4px',
    borderStyle: 'dashed',
    borderColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ListFiles({ pathPrefix, extraItems }: Props) {
  const [filesToUpload, setFilesToUpload] = React.useState<{
    [key: string]: File;
  }>({});

  const onDrop = (acceptedFiles: Array<File>) => {
    setFilesToUpload((currentFiles) => ({
      ...currentFiles,
      ...fromPairs(acceptedFiles.map((f) => [f.name, f])),
    }));
  };

  const removeFromUploads = (file: any) => {
    setFilesToUpload(omit(filesToUpload, [file.name]));
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noKeyboard: true,
  });

  const { classes, cx } = useStyles();
  const { files, deleteFile, downloadFile, refresh } = useFiles(pathPrefix);

  return (
    <div key="FileListCard" className={classes.root}>
      <div {...getRootProps()}>
        <div
          className={cx({
            [classes.dropzone]: isDragActive,
            [classes.dropzoneHidden]: !isDragActive,
          })}
        >
          <Typography>Drop files to upload</Typography>
        </div>
        <input
          id="upload-activity-file"
          {...getInputProps()}
          // @ts-expect-error - TS2769 - No overload matches this call.
          onChange={(e) => onDrop(Array.from(e.target.files))}
        />
        <Typography variant="h5">Extra Files</Typography>
        <List onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation()}>
          {extraItems ? extraItems(files, refresh) : null}
          {files.map((file) => (
            <ListItem key={file.name} button onClick={() => downloadFile(file)}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <CloudDownload />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="Delete"
                  onClick={() => deleteFile(file)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {sortBy(toPairs(filesToUpload)).map(([, file]: [any, any]) => (
            <FileUploadItem
              key={file.name}
              file={file}
              pathPrefix={pathPrefix}
              onCancel={() => removeFromUploads(file)}
            />
          ))}
        </List>
      </div>
      <div>
        <Button variant="outlined" color="secondary" className={classes.button} onClick={open}>
          Upload
          <CloudUpload className={classes.icon} />
        </Button>
      </div>
    </div>
  );
}
