import React from 'react';

import { pickBy } from 'lodash-es';
import useSWRInfinite from 'swr/infinite';

import type { MetricValues, MetricValueDefn } from '@plantiga/activity-summaries';

import useActivitySummariesClient from 'plantiga-common/useActivitySummariesClient';
import useTeam from 'plantiga-firebase/Team/useTeam';

type FetcherArgs = [
  userId: string,
  orgId: string,
  metricDefinitions: MetricValueDefn[],
  opts: Options | undefined,
];

type Options = {
  skipFetch?: (athleteId: string) => boolean;
} & Omit<
  Parameters<ReturnType<typeof useActivitySummariesClient>['getUserMetrics']>[0],
  'userId' | 'orgId' | 'metricDefinitions'
>;

export function useOrgMetricsTrends(
  athleteIds: string[],
  metrics: MetricValueDefn[],
  options?: Options,
) {
  const { teamId } = useTeam();

  const client = useActivitySummariesClient();

  const getArgs = React.useCallback(
    (index: number): FetcherArgs => [athleteIds[index], teamId, metrics, options],
    [athleteIds, teamId, metrics, options],
  );

  const fetcher = React.useCallback(
    async ([userId, orgId, metricDefinitions, opts]: FetcherArgs): Promise<{
      athleteId: string;
      data: null | undefined | MetricValues[];
    }> => {
      if (opts?.skipFetch?.(userId)) {
        return { athleteId: userId, data: null };
      }

      const { data } = await client.getUserMetrics({
        userId,
        orgId,
        metricDefinitions,
        ...pickBy(opts, (v) => v !== undefined),
      });
      return { athleteId: userId, data };
    },
    [client],
  );

  return useSWRInfinite(getArgs, fetcher, {
    parallel: true,
    initialSize: athleteIds.length,
    fallbackData: athleteIds.map((athleteId) => ({
      athleteId,
      data: options?.skipFetch?.(athleteId) ? null : undefined,
    })),
  });
}
