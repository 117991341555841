import React from 'react';

import LZString from 'lz-string';

type Item = {
  compressed: boolean;
  data: string;
};

export function useLocalCache<D>(cacheKey: string, compress?: boolean) {
  const dataToPersist = React.useRef<D | undefined>();

  React.useEffect(() => {
    window.addEventListener('beforeunload', () => {
      const data = compress
        ? LZString.compressToUTF16(JSON.stringify(dataToPersist.current))
        : JSON.stringify(dataToPersist.current);
      const item: Item = { compressed: compress ?? false, data };
      localStorage.setItem(cacheKey, JSON.stringify(item));
    });
  }, [cacheKey, compress]);

  const update = React.useCallback((v: D | undefined) => {
    dataToPersist.current = v;
  }, []);

  const cacheValue = React.useMemo(() => {
    const item = localStorage.getItem(cacheKey);
    if (item == null) return undefined;

    try {
      const { compressed, data }: Item = JSON.parse(item);
      const decompressed = compressed ? LZString.decompressFromUTF16(data) : data;
      return JSON.parse(decompressed) as D;
    } catch (err: unknown) {
      // cached data was not stored correctly so remove it
      localStorage.removeItem(cacheKey);
    }
    return undefined;
  }, [cacheKey]);

  return [cacheValue, update] as const;
}
