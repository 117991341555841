import React from 'react';

import { TextField, Typography } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';

import { useSignIn } from '../Firestore/Auth/signIn';

type Props = {
  readonly next: string;
};

export default function UserPassForm({ next }: Props) {
  const [{ email, password }, setEmailPassword] = React.useState({
    email: '',
    password: '',
  });
  const { signIn, emailError, passwordError, loading } = useSignIn();

  const handleEnter = (e: any) => {
    if (e.key === 'Enter') {
      signIn(email, password, next);
    }
  };

  return (
    <div>
      <Typography variant="h4" align="center" id="signin-button">
        Sign In
      </Typography>
      <TextField
        error={emailError !== ''}
        helperText={emailError}
        fullWidth
        margin="normal"
        variant="outlined"
        value={email}
        placeholder="email"
        id="signinEmail"
        name="email"
        type="email"
        label="Email"
        onChange={(e) => setEmailPassword({ email: e.target.value, password })}
        onKeyPress={handleEnter}
      />
      <br />
      <TextField
        fullWidth
        error={passwordError !== ''}
        helperText={passwordError}
        margin="normal"
        variant="outlined"
        value={password}
        placeholder="password"
        id="signinPassword"
        label="Password"
        name="password"
        type="password"
        onChange={(e) => setEmailPassword({ email, password: e.target.value })}
        onKeyPress={handleEnter}
      />
      <br />
      <ButtonWithLoading
        id="submitEmailPass"
        fullWidth
        color="primary"
        disabled={loading}
        loading={loading}
        variant="contained"
        onClick={() => signIn(email, password, next)}
      >
        Sign In
      </ButtonWithLoading>
    </div>
  );
}
