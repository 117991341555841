import React from 'react';

const DEFAULT_OPTIONS = {
  stop: false,
  leading: false,
} as const;

/**
 * Calls the provided function at a regular interval.
 *
 * @param {*} callback the function to invoke once per interval
 * @param {*} delay the interval time in milliseconds
 * @param {*} options interval configuration options
 * @param {*} options.stop clears the interval when set true
 * @param {*} options.leading invoke the callback immediately
 */
export default function useInterval(
  callback: any,
  delay: null | number,
  options: {
    readonly stop?: boolean;
    readonly leading?: boolean;
  } = DEFAULT_OPTIONS,
) {
  // eslint-disable-next-line no-underscore-dangle
  const _options = { ...DEFAULT_OPTIONS, ...options } as const;
  const savedCallback = React.useRef(callback);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
    if (_options.leading === true && delay !== null) {
      callback();
    }
  }, [callback, delay, _options.leading]);

  // Set up the interval.
  React.useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    function tick() {
      savedCallback.current();
    }
    if (_options.stop === false && delay !== null) {
      interval = setInterval(tick, delay);
    }
    return () => {
      if (interval == null) return;
      clearInterval(interval);
    };
  }, [delay, _options.stop]);
}
