import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';

import { MoreVert } from '@mui/icons-material';
import {
  Paper,
  Button,
  DialogContent,
  IconButton,
  List,
  ListItem,
  Popover,
  TextField,
} from '@mui/material';

import DevDataTree from 'plantiga-common/DevDataTree';
import Dialog from 'plantiga-common/Dialog';
import DeleteDeviceDialog from 'plantiga-component/Devices/DeleteDeviceDialog';
import { PairPodsToAthleteDialog } from 'plantiga-component/Devices/PairPodsToAthleteDialog';
import { PodChip } from 'plantiga-component/Devices/PodChip';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import { useUpdateDeviceName } from 'plantiga-firebase/Devices/useDevices';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';

import { PodVis } from './PodVis';

const useStyles = makeStyles()((theme) => ({
  pod: {
    display: 'grid',
    gap: theme.spacing(1),
    width: 'fit-content',
    background: 'transparent',
    padding: theme.spacing(1),
  },
  podHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

function EditPodNameDialog({
  pod,
  open,
  onClose,
}: {
  pod: Device;
  open: boolean;
  onClose: () => void;
}) {
  const { update: updatePodName } = useUpdateDeviceName();
  const initName = pod?.name ?? deviceNiceId(pod.id);
  const [podName, setPodName] = useState(initName);

  const handleSave = async () => {
    await updatePodName(pod.id, podName);
    onClose();
  };

  const actionButtons = [
    <Button key="action-0" onClick={onClose}>
      Cancel
    </Button>,
    <Button
      key="action-1"
      onClick={handleSave}
      color="primary"
      variant="outlined"
      disabled={podName.length < 1 || podName === initName}
    >
      Save
    </Button>,
  ];

  return (
    <Dialog open={open} onClose={onClose} title="Edit Pod" actionButtons={actionButtons}>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Pod Name"
          value={podName}
          onChange={(e) => setPodName(e.target.value)}
        />
      </DialogContent>
    </Dialog>
  );
}

function NerdsDialog({ pod, open, onClose }: { pod: Device; open: boolean; onClose: () => void }) {
  return (
    <Dialog title="Stats for Nerds" open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <DevDataTree dataId={pod.id} data={pod} />
      </DialogContent>
    </Dialog>
  );
}

type Props = { pod: Device; hideChip?: boolean; readonly?: boolean };

export function Pod({ pod, hideChip = false, readonly = false }: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [statsForNerds, setStatsForNerds] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const [pair, setPair] = React.useState(false);
  const toPair = React.useMemo(() => [pod.id] as [string], [pod]);
  return (
    <Paper
      className={classes.pod}
      elevation={hovered ? 1 : 0}
      onMouseOver={() => setHovered(!readonly)}
      onMouseOut={() => setHovered(false)}
    >
      <div className={classes.podHeader}>
        {hideChip ? null : <PodChip podId={pod.id} />}
        {readonly ? null : (
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
            style={{ opacity: hovered ? 1 : 0 }}
          >
            <MoreVert />
          </IconButton>
        )}
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          TransitionProps={{ onExited: () => setHovered(false) }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <List>
            <ListItem button onClick={() => setPair(true)}>
              Pair
            </ListItem>
            <ListItem button onClick={() => setEdit(true)}>
              Edit
            </ListItem>
            <ListItem button onClick={() => setRemove(true)}>
              Remove
            </ListItem>
            <ListItem button onClick={() => setStatsForNerds(true)}>
              Stats for nerds
            </ListItem>
          </List>
        </Popover>
        <PairPodsToAthleteDialog open={pair} onClose={() => setPair(false)} podIds={toPair} />
        <EditPodNameDialog open={edit} onClose={() => setEdit(false)} pod={pod} />
        <DeleteDeviceDialog
          open={remove}
          onClose={() => setRemove(false)}
          deviceId={pod.id}
          device={pod}
        />
        <NerdsDialog open={statsForNerds} onClose={() => setStatsForNerds(false)} pod={pod} />
      </div>
      <PodVis podId={pod.id} />
    </Paper>
  );
}
