export class HttpError extends Error {
  name: string;

  code: number;

  url: string;

  constructor(message: string, code: number, url: string) {
    super(message);
    this.message = `[${message}]: ${code} - ${url}`;
    this.code = code;
    this.url = url;
    this.name = 'HttpError';
  }
}

export default function raiseForStatus(res: Response, err?: Error) {
  if (res.status > 299 || res.status < 200) {
    if (err) throw err;
    throw new HttpError(res.statusText, res.status, res.url);
  }
}
