import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Chip, Typography } from '@mui/material';

import { POD_CHIP_HEIGHT } from './constants';

const useStyles = makeStyles()(() => ({
  chip: {
    position: 'relative',
    height: POD_CHIP_HEIGHT,
  },
  mono: {
    fontFamily: 'monospace',
  },
  noDeviceChip: {
    borderWidth: 2,
    borderStyle: 'dashed',
  },
}));

type Props = { location?: 'L' | 'R' };

function getLocationString(location?: 'L' | 'R') {
  if (location === 'L') return 'left';
  if (location === 'R') return 'right';
  return undefined;
}

export function NoPodChip({ location }: Props) {
  const { classes, cx } = useStyles();
  return (
    <Chip
      variant="outlined"
      className={cx(classes.chip, classes.noDeviceChip)}
      label={
        <Typography variant="overline" align="center" className={classes.mono}>
          no {getLocationString(location)} pod
        </Typography>
      }
    />
  );
}
