import React from 'react';

import { pick } from 'lodash-es';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useParams, useQuery } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import Finalize from 'plantiga-component/Stopwatch/Finalize/Finalize';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import useStopwatch from 'plantiga-firebase/Stopwatch/useStopwatch';

export default function FinalizeView() {
  const { swid, activityTypeId } = useParams();
  const { people } = useQuery();
  const athletes = useAthletes({ withArchived: true });
  const { stopwatch, loading: loadingStopwatch, error } = useStopwatch(swid);

  const filteredAthletes = React.useMemo(
    () => (people == null ? {} : pick(athletes, people.split(','))),
    [athletes, people],
  );

  const loading = loadingStopwatch || stopwatch == null || swid == null || people == null;

  return (
    <Wait waitingOn={loading} error={error} info="stopwatch-finalize">
      <ContentContainer>
        <Finalize
          athletes={filteredAthletes}
          activityTypeId={activityTypeId}
          swid={swid}
          stopwatch={stopwatch!}
        />
      </ContentContainer>
    </Wait>
  );
}
