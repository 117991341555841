import React from 'react';

import { LinearProgress } from '@mui/material';

type Props = {
  error?: Error;
  pastDelay?: boolean;
  timedOut?: boolean;
};

class TimedOutError extends Error {}

export default function LoadingComponent({ error, pastDelay, timedOut }: Props) {
  if (error) {
    // Bubble up the error to the top-level error handler
    throw error;
  }
  if (timedOut) {
    // Bubble up the error to the top-level error handler
    throw new TimedOutError('Loading a component took too long');
  }
  if (!pastDelay) {
    // To improve the user experience,
    // avoid showing a progress bar if the load takes less than (by default) 200 ms.
    return null;
  }

  return <LinearProgress />;
}
