import React, { useCallback } from 'react';

import { Button } from '@mui/material';

import { useLocation } from './react-router-hooks';

type Props = {
  readonly href: string;
};

export default function NavButton({ href, ...props }: Props) {
  const { navigate } = useLocation();
  const onClick = useCallback(() => navigate(href), [href, navigate]);
  return <Button {...props} onClick={onClick} />;
}
