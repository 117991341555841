import type firestore from 'firebase/firestore';
import { getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import React from 'react';

import makeDebug from 'debug';
import { fromPairs, isEqual } from 'lodash-es';

import { useToast } from '../Toast/UseToast';

const debug = makeDebug('plantiga:teamSubscribe');

// Get data, wait a few seconds until first update
const TIME_UNTIL_SUBSCRIBE = 10 * 1000;

function handleSnapshot<T>(
  ref: firestore.Query<T> | firestore.DocumentReference<T>,
  setTeamData: React.Dispatch<React.SetStateAction<Record<string, { id: string } & T>>>,
): (arg1: firestore.QuerySnapshot<T>) => void {
  return (snapshot: firestore.QuerySnapshot<T>) => {
    setTeamData((prevTeamData) => {
      const modified = { ...prevTeamData };
      snapshot.docChanges().forEach((change) => {
        // To fix these errors, the generic parameter should be the keyed element (here unknown)
        if (change.type === 'added') {
          modified[change.doc.id] = { ...change.doc.data(), id: change.doc.id };
        }
        if (change.type === 'modified') {
          modified[change.doc.id] = { ...change.doc.data(), id: change.doc.id };
        }
        if (change.type === 'removed') {
          delete modified[change.doc.id];
        }
      });
      debug(`${ref.type === 'document' ? ref.path : ref.type} modified`, modified);
      return modified;
    });
  };
}

function useSubscribe<T>(
  ref?: firestore.Query<T> | null,
  options: {
    errorMessage?: string;
    timeUntilSubscribe?: number;
  } = {},
): [Record<string, { id: string } & T>, boolean, Error | null | undefined] {
  const { errorMessage, timeUntilSubscribe } = options;
  const [teamData, setTeamData] = React.useState({} as Record<string, { id: string } & T>);
  const [loading, setLoading] = React.useState(true);
  const [error, setErrorState] = React.useState<Error | null | undefined>(null);

  const unSubRef = React.useRef<(() => void) | null>(null);
  const isUnmounted = React.useRef(false);
  const postToast = useToast();

  const setError = React.useCallback(
    (err: Error) => {
      if (isUnmounted.current) {
        return;
      }
      console.error(err);
      setErrorState(err);
      setLoading(false);
      postToast({
        message: errorMessage || 'Failed to load',
        variant: 'error',
        timeout: 1,
      });
    },
    [postToast, errorMessage],
  );

  React.useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );
  React.useEffect(() => {
    let cancelled = false;
    if (ref == null) {
      return undefined;
    }
    debug(`Loading ${ref.type}`, { ref });

    setTeamData({});
    setLoading(true);
    getDocs(ref)
      .then((snaps) => {
        if (cancelled || isUnmounted.current) {
          return;
        }

        const newTeamData = fromPairs(snaps.docs.map((d) => [d.id, { ...d.data(), id: d.id }]));
        setTeamData(newTeamData);
        setLoading(false);

        setTimeout(
          () => {
            if (cancelled || isUnmounted.current) {
              return;
            }
            debug(`Watching ${ref.type}`, { ref });
            unSubRef.current = onSnapshot(ref, handleSnapshot(ref, setTeamData), setError);
          },
          timeUntilSubscribe == null ? TIME_UNTIL_SUBSCRIBE : timeUntilSubscribe,
        );
      })
      .catch(setError);

    return () => {
      cancelled = true;
      if (unSubRef.current) {
        unSubRef.current();
      }
    };
  }, [ref, timeUntilSubscribe, postToast, setError]);

  return [teamData, loading, error];
}

/**
 *
 * @param ref (firestore.DocumentReference)
 * @param options (object): optional subscription parameters
 * @param options.errorMessage (string): the user facing error message, default is "Failed to load"
 * @param options.timeUntilSubscribe (number): time until the subscription to live updates starts, default is 10,000 ms.
 * @param options.deepCompare (boolean): whether to deeply compare the equivalence of the document object before updating it, default is "false"
 * @returns
 */
export function useSubscribeDocument<T>(
  ref?: firestore.DocumentReference<T> | null,
  options: {
    errorMessage?: string;
    timeUntilSubscribe?: number;
    deepCompare?: boolean;
  } = {},
): [T | null | undefined, boolean, Error | null | undefined] {
  const { errorMessage, timeUntilSubscribe, deepCompare } = options;
  const [teamData, setTeamData] = React.useState<T | null | undefined>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setErrorState] = React.useState<Error | null | undefined>(null);
  const postToast = useToast();

  const unSubRef = React.useRef<(() => void) | null>(null);
  const isUnmounted = React.useRef(false);

  const setError = React.useCallback(
    (err: Error) => {
      if (isUnmounted.current) {
        return;
      }
      console.error(err);
      setErrorState(err);
      postToast({
        message: errorMessage || 'Failed to load',
        variant: 'error',
        timeout: 1,
      });
    },
    [postToast, errorMessage],
  );

  React.useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  React.useEffect(() => {
    let cancelled = false;
    if (ref == null) {
      setTeamData(null);
      setLoading(false);
      return undefined;
    }
    debug(`Loading ${ref.path}`);

    const subscribe = () => {
      if (cancelled || isUnmounted.current) {
        return;
      }
      debug(`Watching ${ref.path}`);
      unSubRef.current = onSnapshot(
        ref,
        (snapshot) => {
          const data = snapshot.data();
          debug(`${ref.path} received`, data);
          if (!cancelled && !isUnmounted.current) {
            setTeamData((p) => (deepCompare && isEqual(data, p) ? p : data));
            setLoading(false);
          }
        },
        setError,
      );
    };

    setLoading(true);
    if (timeUntilSubscribe === 0) {
      subscribe();
    } else {
      getDoc(ref)
        .then((snap) => {
          if (cancelled || isUnmounted.current) {
            return;
          }

          setTeamData(snap.data());
          setLoading(false);
          setTimeout(
            subscribe,
            timeUntilSubscribe == null ? TIME_UNTIL_SUBSCRIBE : timeUntilSubscribe,
          );
        })
        .catch(setError);
    }

    return () => {
      cancelled = true;
      if (unSubRef.current) {
        unSubRef.current();
      }
    };
  }, [ref, timeUntilSubscribe, deepCompare, setError]);

  return [teamData, loading, error];
}

export default useSubscribe;
