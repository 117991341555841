import React, { useEffect } from 'react';

export default function TriggerAsyncError() {
  useEffect(() => {
    (async () => {
      throw new Error('This page can be used to check that async errors get handled correctly');
    })();
  }, []);
  return <span>Empty content</span>;
}
