import React from 'react';

import { omit } from 'lodash-es';

import { useToast } from 'plantiga-component/Toast/UseToast';
import { httpsCallablePost } from 'plantiga-firebase/util';

import type { PlantigaEvent } from './typedefs';

export default function useUpdateEvent(): {
  error: undefined;
  loading: boolean;
  update: (eventId: string, event: PlantigaEvent) => Promise<void>;
} {
  const postToast = useToast();
  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const update = React.useCallback(
    async (eventId: string, event: PlantigaEvent) => {
      setLoading(true);
      const { team_id: teamId, date } = event;
      const flatTimeEvent = { ...omit(event, 'id'), date: date.toMillis() } as const;
      try {
        const updateEventCF = httpsCallablePost('web-routes/event/updateEvent');
        const { data } = await updateEventCF({
          teamId,
          eventId,
          event: flatTimeEvent,
        });
        if (data.ok !== true) {
          throw new Error(JSON.stringify(data, null, 2));
        }
      } catch (err: any) {
        console.error(err);
        if (unMounted.current) return;
        postToast({ message: 'Could not update the event', variant: 'error' });
        setError(err);
      }
      if (unMounted.current) return;
      setLoading(false);
    },
    [postToast],
  );

  return { update, loading, error };
}
