import devices from './devices/_urls';
import people from './people/_urls';
import profile from './profile/_urls';
import purchase from './purchase/_urls';
import stopwatch from './stopwatch/_urls';

export const root = '/org/:teamId';
const deprecated = {
  teamResearch: '/org/:teamId/research',
};

export default {
  ...deprecated,
  teamLanding: root,

  activities: '/org/:teamId/activity/:activityId?/:page?/:key?',
  teamFeed: '/org/:teamId/feed',
  teamExport: '/org/:teamId/export',
  teamTestError: '/org/:teamId/_/error',
  ...devices,
  ...people,
  ...profile,
  ...purchase,
  ...stopwatch,
};
