import React from 'react';

import useCoach from 'plantiga-firebase/Coach/useCoach';

import { UserContext } from './UserProvider';

export default function useUser() {
  return React.useContext(UserContext).currentUser;
}

export function useUserClaims() {
  return React.useContext(UserContext).userClaims;
}

export function useIsIndividualUser(): boolean {
  const { coach } = useCoach();
  return (
    coach?.beta === true && (!coach?.role || coach?.role === 'none') && !coach?.super_user?.is_admin
  );
}

export function useIsUserAdmin() {
  const { coach } = useCoach();
  return coach?.super_user?.is_admin ?? false;
}
