import { HELP_PAGE_ACTIVITY_TYPES_TO_SUBDIRECTORY } from 'plantiga-assets/plantigaSite';

/** Check if a metric has a help page */
export function hasHelpPage(
  activityType: string,
  field: { help_url_slug?: string; help_url_slug_omit_activity_types?: string },
) {
  if (!(activityType in HELP_PAGE_ACTIVITY_TYPES_TO_SUBDIRECTORY)) return false;
  if (field.help_url_slug == null) return false;
  if (new RegExp(field.help_url_slug_omit_activity_types ?? '-').test(activityType)) return false;
  return true;
}

/** Create the url for the help page of a metric. */
export function buildHelpPageUrl(
  activityType: string,
  helpUrlSlug: string,
  hideSquarespaceNavigation = true,
) {
  const subDir = HELP_PAGE_ACTIVITY_TYPES_TO_SUBDIRECTORY[activityType];
  const url = `https://plantiga.com/${subDir}/${helpUrlSlug}`;
  if (hideSquarespaceNavigation) {
    return `${url}?hide_header_footer=true&hide_pagination=true`;
  }
  return url;
}
