import React from 'react';

const SMALL_DELAY = 10;

type Props = {
  children: () => React.ReactElement;
  placeholder?: React.ReactNode;
  delay?: number;
};

export default function DeferRenderWrapper({
  children,
  placeholder = null,
  delay = SMALL_DELAY,
}: Props) {
  const [isRendered, setIsRendered] = React.useState(false);
  React.useEffect(() => {
    // defer rendering expensive child Component to the callbackQueue
    const timerId = setTimeout(() => {
      setIsRendered(true);
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
    // We only want to fire the change once, regardless of if the delay changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRendered) {
    return <>{children()}</>;
  }
  return <>{placeholder}</>;
}
