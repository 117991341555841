import { useCallback } from 'react';
import { generatePath } from 'react-router';

import { format as formatDate } from 'date-fns';

import { useParams, useLocation } from 'plantiga-common/react-router-hooks';
import routes from 'plantiga-util/urls';

export function formatDateURL(date: Date | number): string {
  const day = formatDate(date, 'yyyy-MM-dd');
  return day;
}

export function generateUrl(
  route: string,
  params: {
    [key: string]: string;
  },
  queryParams?: {
    [key: string]: string;
  },
): string {
  const url = generatePath(route, params);
  if (queryParams) {
    const usp = new URLSearchParams(queryParams);
    return `${url}?${usp.toString()}`;
  }
  return url;
}

export default function useBuildURL(): (
  name: keyof typeof routes,
  params?: any,
  queryParams?: any,
) => string {
  const { teamId } = useParams();
  return useCallback(
    (name: keyof typeof routes, params: any, queryParams?: any) => {
      const parameters = params || {};
      const routeName = routes[name];
      if (routeName.toString().includes(':teamId')) parameters.teamId = teamId;
      return generateUrl(routeName, parameters, queryParams);
    },
    [teamId],
  );
}

export function useNavigateURL(): (
  hame: keyof typeof routes,
  params?: any,
  queryParams?: any,
) => void {
  const { navigate } = useLocation();
  const { teamId } = useParams();
  return useCallback(
    (name: keyof typeof routes, params: any, queryParams?: any) => {
      const parameters = params || {};
      const routeName = routes[name];
      if (parameters.teamId == null && routeName.toString().includes(':teamId')) {
        parameters.teamId = teamId;
      }
      return navigate(generateUrl(routeName, parameters, queryParams));
    },
    [navigate, teamId],
  );
}
