import React from 'react';

import CoachContext from './CoachContext';
import useCoachContext from './useCoachContext';

type Props = {
  readonly children: React.ReactNode;
};

export default function CoachProvider({ children }: Props) {
  const value = useCoachContext();
  return <CoachContext.Provider value={value}>{children}</CoachContext.Provider>;
}
