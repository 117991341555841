import React from 'react';

import { get } from 'lodash-es';

import { Typography } from '@mui/material';

import CustomLabels from 'plantiga-common/Labels/CustomLabels';
import { useEditAthlete } from 'plantiga-firebase/Athletes/actions';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

type Props = {
  athleteId: string;
};

export default function CustomActivityLabels({ athleteId }: Props) {
  const athlete = useAthlete(athleteId);
  const { editAthlete, loading } = useEditAthlete();

  const athleteLabels = get(athlete, 'labels', {});

  const handleSave = React.useCallback(
    async (newLabels: Record<string, string>) => {
      await editAthlete(athleteId, { labels: newLabels });
    },
    [athleteId, editAthlete],
  );

  if (athlete == null) {
    return null;
  }
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Labels
      </Typography>
      <CustomLabels labels={athleteLabels} loading={loading} onSave={handleSave} />
    </div>
  );
}
