/* eslint-disable no-param-reassign */
import React from 'react';

import { Zoom } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { TransitionProps } from '@mui/material/transitions';

interface DisappearProps extends TransitionProps {
  children: JSX.Element;
}

export default function Disappear(props: DisappearProps) {
  const theme = useTheme();
  const onEnter = (node: HTMLElement) => {
    const transitionProps = {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    };
    node.style.webkitTransition = theme.transitions.create(
      ['transform', 'max-width'],
      transitionProps,
    );
    node.style.transition = theme.transitions.create(['transform', 'max-width'], transitionProps);
    node.style.maxWidth = '48px';
  };
  const onExit = (node: HTMLElement) => {
    const transitionProps = {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    };
    node.style.webkitTransition = theme.transitions.create(
      ['transform', 'max-width'],
      transitionProps,
    );
    node.style.transition = theme.transitions.create(['transform', 'max-width'], transitionProps);
    node.style.maxWidth = '0px';
  };
  return <Zoom {...props} style={{ maxWidth: 0 }} onEnter={onEnter} onExit={onExit} />;
}
