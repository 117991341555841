import { getAuth } from 'firebase/auth';
import React from 'react';

export async function getToken() {
  const auth = getAuth();
  return (await auth.currentUser?.getIdToken()) ?? '-';
}

// ignore unused exports
export default function useToken() {
  const [token, setToken] = React.useState<string>();
  React.useEffect(
    () =>
      getAuth().onIdTokenChanged(async (user) => {
        setToken(await user?.getIdToken());
      }),
    [],
  );

  return token;
}
