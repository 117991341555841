import { get, isEqual } from 'lodash-es';

import type { Timezone } from '../components/Firestore/Athletes/typedefs';

// returns current timezone { minutes, name }
export function getCurrentTimezone(): Timezone {
  const timezone = {
    // Offset from UTC
    minutes: -new Date().getTimezoneOffset() || 0,
    name: Intl.DateTimeFormat().resolvedOptions().timeZone || '',
  } as const;
  return timezone;
}

export function isTimezoneEqual(
  timezoneA?: Timezone | null,
  timezoneB?: Timezone | null,
  exact: boolean = false,
): boolean {
  if (exact) {
    return isEqual(timezoneA, timezoneB);
  }
  const minutesA = get(timezoneA, 'minutes');
  const minutesB = get(timezoneB, 'minutes');
  return minutesA === minutesB;
}
