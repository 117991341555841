import React from 'react';

import { endOfMonth, format as formatDate, startOfMonth } from 'date-fns';
import { intersection, isEqual } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Badge, Tooltip } from '@mui/material';
import { DatePicker, PickersDay, type PickersDayProps } from '@mui/x-date-pickers';

import useActivitiesInDateRange from '../Firestore/Activity/useActivitiesInDateRange';

import { activitiesDateAndTypeMap } from './util';

const useStyles = makeStyles()((theme) => ({
  dayBadge: {
    top: theme.spacing(-1),
    right: theme.spacing(1),
  },
  gray: {
    backgroundColor: theme.palette.grey[500],
  },
}));

type Props = {
  readonly athleteId?: string | null;
  readonly value: Date;
  readonly requiredActivityTypes?: Array<string>;
} & React.ComponentProps<typeof DatePicker>;

function selectColor(
  haveActivityTypes: Array<string>,
  requiredActivityTypes: undefined | Array<string>,
) {
  if (requiredActivityTypes == null) {
    return 'primary';
  }
  if (haveActivityTypes == null) {
    return 'error';
  }
  const overlap = intersection(haveActivityTypes, requiredActivityTypes);
  if (isEqual(overlap, requiredActivityTypes)) {
    return 'primary';
  }
  if (overlap.length > 0) {
    return 'secondary';
  }
  return 'error';
}

type CustomDayWithActivitiesProps = {
  activitiesDateMap: { [key: string]: string[] };
  requiredActivityTypes?: string[];
};
function DayWithActivities({
  activitiesDateMap,
  requiredActivityTypes,
  day,
  ...rest
}: PickersDayProps<Date> & CustomDayWithActivitiesProps) {
  const { classes } = useStyles();
  const haveActivityTypes = activitiesDateMap[formatDate(day, 'P')];
  const color = selectColor(haveActivityTypes, requiredActivityTypes);
  return (
    <Tooltip title={color === 'secondary' ? 'Missing some required activities' : ''}>
      <div>
        <Badge
          invisible={haveActivityTypes == null || color === 'error'}
          color={color}
          overlap="circular"
          variant="dot"
          classes={{ colorError: classes.gray }}
          className={classes.dayBadge}
        >
          <PickersDay {...rest} day={day} />
        </Badge>
      </div>
    </Tooltip>
  );
}

export function ActivityDatePicker({
  athleteId = null,
  value: initialValue,
  requiredActivityTypes,
  onChange,
  ...props
}: Props) {
  const [value, setValue] = React.useState<Date | null>(new Date(initialValue));
  const [start, setStart] = React.useState<Date>(startOfMonth(initialValue));
  const end = React.useMemo<Date>(() => endOfMonth(start), [start]);

  React.useEffect(() => setValue(initialValue), [initialValue]);

  const { activities } = useActivitiesInDateRange(athleteId, start, end);

  const activitiesDateMap = React.useMemo(() => activitiesDateAndTypeMap(activities), [activities]);

  const handleMonthChange = React.useCallback(
    (s: Date | null) => setStart(s ? startOfMonth(s) : new Date()),
    [],
  );
  const handleChange = React.useCallback<NonNullable<typeof onChange>>(
    (d, c) => {
      setValue(d);
      onChange?.(d, c);
    },
    [onChange],
  );

  return (
    <DatePicker
      views={['year', 'month', 'day']}
      value={value}
      onMonthChange={handleMonthChange}
      onChange={handleChange}
      {...props}
      slotProps={{
        day: { activitiesDateMap, requiredActivityTypes } as any,
        ...(props.slotProps ?? {}),
      }}
      slots={{ day: DayWithActivities, ...(props.slots ?? {}) } as any}
    />
  );
}
