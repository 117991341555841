import React from 'react';

import makeDebug from 'debug';
import { get } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CircularProgress, LinearProgress } from '@mui/material';
import * as Sentry from '@sentry/react';
import type { Span } from '@sentry/react';

import ErrorFirebaseUnavailable from '../Error/FirebaseUnavailable';
import ErrorView from '../Error/View';

const debug = makeDebug('plantiga:wait');

const useStyles = makeStyles()(() => ({
  circularVariant: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  children: any;
  waitingOn: boolean;
  info: string;
  ErrorNode?: React.ReactNode;
  ProgressNode?: React.ReactNode;
  error: unknown;
  variant?: 'linear' | 'circular';
};

function useSentrySpan(waitingOn: boolean, info: string, error: unknown) {
  const spanRef = React.useRef<Span>();
  const start = React.useRef<number | undefined>();

  React.useEffect(() => {
    const span = spanRef.current;
    if (waitingOn) {
      start.current = performance.now();
      debug(`Waiting on ${info}`);
      spanRef.current = Sentry.getActiveSpan()?.startChild({ name: info, op: 'resource.other' });
    } else if (span != null) {
      if (error != null) {
        Sentry.captureException(error);
        span.setStatus('error');
      } else {
        span.setStatus('ok');
      }

      span.end();
      spanRef.current = undefined;
    }
    if (!waitingOn && start.current) {
      const duration = performance.now() - start.current;
      debug(`Waiting on ${info} is done (${duration.toFixed(0)}ms)`);
    }
  }, [waitingOn, info, error]);
}

export default function Wait({
  children,
  waitingOn,
  info,
  error,
  ErrorNode = null,
  variant = 'linear',
  ProgressNode = null,
}: Props) {
  const { classes } = useStyles();
  useSentrySpan(waitingOn, info, error);
  if (error != null) {
    console.error(error);
    switch (get(error, 'code') as string | undefined) {
      case 'unavailable':
        return <ErrorFirebaseUnavailable />;
      default:
        return ErrorNode == null ? <ErrorView /> : <>{ErrorNode}</>;
    }
  }

  if (waitingOn && ProgressNode) {
    return <>{ProgressNode}</>;
  }
  if (waitingOn && variant === 'linear') {
    return <LinearProgress />;
  }
  if (waitingOn && variant === 'circular') {
    return (
      <div className={classes.circularVariant}>
        <CircularProgress />
      </div>
    );
  }

  return children;
}
