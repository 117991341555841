export const WARNINGS = {
  haveBrokenDevices: 'haveBrokenDevices',
  haveLowRunTimeDevices: 'haveLowRunTimeDevices',
  haveExpiredDevices: 'haveExpiredDevices',
  haveMissingDevices: 'haveMissingDevices',
  haveActiveRecording: 'haveActiveRecording',
} as const;

export const IGNORABLE_WARNINGS: Record<keyof typeof WARNINGS, boolean> = {
  haveBrokenDevices: false,
  haveLowRunTimeDevices: true,
  haveExpiredDevices: false,
  haveMissingDevices: false,
  haveActiveRecording: false,
};
