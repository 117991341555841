import React from 'react';

import { useAthlete } from '../Firestore/Athletes/useAthletes';

type Props = {
  athleteId: string;
};

export default function AthleteName({ athleteId }: Props) {
  const athlete = useAthlete(athleteId);
  return <>{athlete ? athlete.name : '?'}</>;
}
