import React from 'react';

import { sortBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@mui/material';

import { PodChip } from 'plantiga-component/Devices/PodChip';
import getDeviceLocationFromId from 'plantiga-util/getDeviceLocationFromId';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

type Props = {
  deviceIds?: Array<string>;
};

export default function PairedPodChips({ deviceIds }: Props) {
  const { classes } = useStyles();
  if (deviceIds == null || deviceIds.length === 0) {
    return (
      <Typography variant="subtitle1" color="secondary">
        No devices
      </Typography>
    );
  }
  return (
    <div className={classes.container}>
      {sortBy(deviceIds, (id) => getDeviceLocationFromId(id)).map((deviceId) => (
        <PodChip key={deviceId} podId={deviceId} />
      ))}
    </div>
  );
}
