import { getAuth } from 'firebase/auth';
import {
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
  doc as fbDoc,
  Timestamp,
  runTransaction,
  type WithFieldValue,
} from 'firebase/firestore';
import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';

import makeDebug from 'debug';
import { intersection } from 'lodash-es';
import { useIsMounted } from 'usehooks-ts';

import { MAX_STOPWATCH_TIME } from 'plantiga-util/constants';

import { collection, doc } from '..';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import useSubscribe, { useSubscribeDocument } from '../useSubscribe';
import { httpsCallablePost } from '../util';

import type { Stopwatch, StopwatchPreDB } from './typedefs';

const debug = makeDebug('plantiga:stopwatch');

export default function useStopwatch(swid: string) {
  const db = useFirestore();
  const { teamId } = useTeam();

  const ref = React.useMemo(
    () => doc(db, 'teams', teamId, 'stopwatches', swid),
    [db, teamId, swid],
  );

  const [stopwatch, loading, error] = useSubscribeDocument(ref, {
    errorMessage: 'The stopwatch could not be loaded',
    timeUntilSubscribe: 0,
  });

  return { stopwatch, loading, error };
}

export function useUpdateStopwatch(swid: string) {
  const isMounted = useRef(true);
  useEffect(() => () => {
    isMounted.current = false;
  });

  const db = useFirestore();
  const { teamId } = useTeam();
  const [loading, setLoading] = useState(false);
  const update = useCallback(
    async (data: Partial<StopwatchPreDB>) => {
      setLoading(true);
      const ref = doc(db, 'teams', teamId, 'stopwatches', swid);
      await updateDoc(ref, data);
      if (isMounted.current) {
        setLoading(false);
      }
    },
    [db, swid, teamId],
  );
  return [loading, update] as const;
}

export function useCreateStopwatch() {
  const mounted = useIsMounted();

  const db = useFirestore();
  const { teamId } = useTeam();
  const [loading, setLoading] = useState(false);

  const create = useCallback(
    async (partialStopwatch: Omit<StopwatchPreDB, 'created_by'>) => {
      if (!mounted()) {
        return { athleteIds: [], id: null };
      }
      setLoading(true);
      const user = getAuth().currentUser;
      const ref = fbDoc(collection(db, 'teams', teamId, 'stopwatches'));
      const activeSWRef = query(
        collection(db, 'teams', teamId, 'stopwatches'),
        where('active', '==', true),
      );
      const res = runTransaction(db, async (transaction) => {
        const swSnaps = await getDocs(activeSWRef);
        const athleteIdsWithActiveStopwatches = await Promise.all(
          swSnaps.docs.map(async (swDoc) => {
            const swRef = fbDoc(db, 'teams', teamId, 'stopwatches', swDoc.id);
            const swSnap = await transaction.get(swRef);
            const athleteIds: string[] = swSnap.get('athlete_ids');
            return intersection(athleteIds, partialStopwatch.athlete_ids);
          }),
        );
        const flat = athleteIdsWithActiveStopwatches.flatMap((v) => v);
        if (flat.length > 0) {
          return { athleteIds: flat, id: null } as const;
        }
        const { start, end, ...rest } = partialStopwatch;
        const stopwatch: WithFieldValue<Stopwatch> = {
          ...rest,
          start: Timestamp.fromDate(start),
          created_by: user?.email,
        };
        if (end) stopwatch.end = Timestamp.fromDate(end);
        await transaction.set(ref, stopwatch);
        return { athleteIds: [], id: ref.id } as const;
      });

      if (mounted()) {
        setLoading(false);
      }
      return res;
    },
    [db, teamId, mounted],
  );

  return [loading, create] as const;
}

export function useSaveStopwatch(swid: string) {
  const isMounted = useRef(true);
  useEffect(() => () => {
    isMounted.current = false;
  });

  const db = useFirestore();
  const { teamId } = useTeam();
  const [loading, setLoading] = useState(false);

  const save = useCallback(
    async (
      generic: StopwatchPreDB,
      athleteSpecific: {
        readonly [key: string]: StopwatchPreDB;
      },
    ) => {
      setLoading(true);
      const ref = doc(db, 'teams', teamId, 'stopwatches', swid);
      const snap = await getDoc(ref);
      const stopwatch = snap.data() as Stopwatch;

      await updateDoc(ref, { data: generic });
      debug('stopwatch', stopwatch);
      const createActivitiesCF = httpsCallablePost('web-routes/activity/createActivities');
      const start = stopwatch.server_start?.toMillis() ?? stopwatch.start.toMillis();
      let end = stopwatch.server_end?.toMillis() ?? stopwatch.end?.toMillis() ?? Date.now();
      end = Math.min(start + MAX_STOPWATCH_TIME, end);
      const req = {
        teamId,
        swid,
        generic,
        athleteSpecific,
        athleteIds: stopwatch.athlete_ids,
        start,
        end,
      } as const;

      debug('createActivities', req);
      await createActivitiesCF(req);

      if (isMounted.current) {
        setLoading(false);
      }
    },
    [db, swid, teamId],
  );

  return [loading, save] as const;
}

export function useActiveStopwatches() {
  const db = useFirestore();
  const { teamId } = useTeam();

  const ref = useMemo(
    () => query(collection(db, 'teams', teamId, 'stopwatches'), where('active', '==', true)),
    [db, teamId],
  );

  const [stopwatches, loading] = useSubscribe(ref);

  return { stopwatches, loading };
}
