import React from 'react';

type ConnectedContextType = {
  connected: boolean;
};
const ConnectedContext: React.Context<ConnectedContextType> =
  React.createContext<ConnectedContextType>({
    connected: false,
  });

export function useConnectedContext(): {
  connected: boolean;
} {
  const [connected, setConnected] = React.useState(true);

  React.useEffect(() => {
    const updateConnectionStatus = (e: Event) => {
      if (e.type === 'online') {
        setConnected(true);
      } else if (e.type === 'offline') {
        setConnected(false);
      }
    };
    window.addEventListener('online', updateConnectionStatus);
    window.addEventListener('offline', updateConnectionStatus);
    return () => {
      window.removeEventListener('online', updateConnectionStatus);
      window.removeEventListener('offline', updateConnectionStatus);
    };
  }, []);

  return { connected };
}

export default ConnectedContext;
