import React from 'react';

import { format as formatDate } from 'date-fns';
import { isEmpty, map } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Card, CardContent, CardHeader, Typography } from '@mui/material';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';
import LabelChip from 'plantiga-common/Labels/LabelChip';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';

import DeleteEventIcon from './DeleteEventIcon';
import EditEventIcon from './EditEventIcon';
import { EVENT_TYPE_OPTIONS } from './util';

const useStyles = makeStyles()((theme) => ({
  cardHeaderContent: {
    minWidth: 0,
  },
  eventGrid: {
    display: 'grid',
    gridTemplateColumns: 'minmax(120px, auto) 1fr',
    gap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

type EventFieldProps = {
  title: string;
  value: React.ReactNode;
};

function EventField({ title, value }: EventFieldProps) {
  if (!value || isEmpty(value)) return null;
  return (
    <>
      <Typography variant="body1">
        <b>{title}</b>
      </Typography>
      <ConditionalWrapper
        condition={typeof value === 'string'}
        wrapperComponent={Typography}
        wrapperProps={{ variant: 'body1' }}
      >
        {value}
      </ConditionalWrapper>
    </>
  );
}

type Props = {
  readonly athleteId: string;
  readonly eventId: string;
  readonly event: PlantigaEvent;
  readonly onDelete?: any;
};

export default function EventCard({ athleteId, eventId, event, onDelete }: Props) {
  const { classes } = useStyles();

  const labelChips = React.useMemo(
    () =>
      isEmpty(event.labels) ? null : (
        <span>
          {map(event.labels, (v, k) => (
            <LabelChip key={k} labelKey={k} labelValue={v} />
          ))}
        </span>
      ),
    [event.labels],
  );

  const cardAction = React.useMemo(
    () => (
      <>
        <EditEventIcon athleteId={athleteId} eventId={eventId} event={event} />
        <DeleteEventIcon eventId={eventId} onDelete={onDelete} />
      </>
    ),
    [athleteId, eventId, event, onDelete],
  );

  return (
    <Card variant="outlined">
      <CardHeader
        classes={{ content: classes.cardHeaderContent }}
        title={event.title}
        titleTypographyProps={{ noWrap: true }}
        subheader={formatDate(event.date.toDate(), 'PPP')}
        action={cardAction}
      />
      <CardContent>
        <div className={classes.eventGrid}>
          <EventField title="Event Type" value={EVENT_TYPE_OPTIONS[event.event_type]} />
          {event.osiics_code != null && (
            <EventField title="OSIICS Code" value={event.osiics_code} />
          )}
          <EventField title="Location" value={event.location} />
          <EventField title="Notes" value={event.notes} />
          <EventField title="Labels" value={labelChips} />
        </div>
      </CardContent>
    </Card>
  );
}
