import React from 'react';

import ContentContainer from 'plantiga-common/ContentContainer';
import Setup from 'plantiga-component/Stopwatch/Setup/Setup';
import { useLogEventOnMount } from 'plantiga-firebase/analytics';

export default function SetupView() {
  useLogEventOnMount('stopwatch_setup');
  return (
    <ContentContainer>
      <Setup />
    </ContentContainer>
  );
}
