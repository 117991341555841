import React from 'react';

import makeDebug from 'debug';

import useApiClient from 'plantiga-util/apiClient';

import useUser from './useUser';

const debug = makeDebug('plantiga:user');

type UserSetupStatus =
  | 'INITIALIZING'
  | 'READY'
  | 'TIMEOUT'
  | 'SETTING_UP'
  | 'INVALID_TOKEN'
  | 'UNAUTHORIZED'
  | 'NO_EMAIL';

export default function useUserSetupStatus(): UserSetupStatus {
  const MAX_POLL_COUNT = 3;
  const currentUser = useUser();
  const apiClient = useApiClient();
  const [pollCount, setPollCount] = React.useState(1);
  const [statusCode, setStatusCode] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (currentUser === undefined) {
      // undefined means firebase/auth has not initialized yet, we should be
      // 'INITIALIZING'. Don't bother trying to poll yet.
      setStatusCode(null);
      // Reset polling when there is a new user
      return () => {
        setPollCount(0);
      };
    }
    if (currentUser === null) {
      // null means definitively we are not authenticated.
      // It's superfluous to poll when we do not have a token to provide.
      setStatusCode(401);
      // Reset polling when there is a new user
      return () => {
        setPollCount(0);
      };
    }

    let timeoutId: NodeJS.Timeout | null = null;
    let canceled: boolean = false;
    const pollUserStatus = async () => {
      if (pollCount > MAX_POLL_COUNT) return;
      try {
        const { status } = await apiClient.getUser();
        if (canceled) return;
        setStatusCode(status);
        if (status === 200) return;
      } catch (err: any) {
        // There seems to be an occasional error with a missing user during testing
        if (canceled) return;
      }
      timeoutId = setTimeout(() => {
        setPollCount((prev) => prev + 1);
      }, pollCount * 3000); // increase subsequent polling intervals (3,6,9)
    };

    pollUserStatus();

    return () => {
      canceled = true;
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, [apiClient, pollCount, currentUser]);

  const status = React.useMemo(() => {
    if (statusCode === null) return 'INITIALIZING';
    if (statusCode === 200) return 'READY';
    if (statusCode === 202 && pollCount > MAX_POLL_COUNT) return 'TIMEOUT';
    if (statusCode === 202) return 'SETTING_UP';
    if (statusCode === 400) return 'INVALID_TOKEN';
    if (statusCode === 401) return 'UNAUTHORIZED';
    if (statusCode === 406) return 'NO_EMAIL';
    throw new Error(`Unhandled response from /cloud-gears/user with status ${statusCode}`);
  }, [statusCode, pollCount]);

  React.useEffect(() => {
    debug(`Setup status: ${status}`);
  }, [status]);

  return status;
}
