import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card, Table, TableBody, TableContainer } from '@mui/material';

import type { Activity } from 'plantiga-firebase/Activity/typedefs';

import EditActivityTime from './EditActivityTime';
import EditActivityType from './EditActivityType';
import EditPods from './EditPods';
import EditRow from './EditRow';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  readonly teamId: string;
  readonly activityId: string;
  readonly activity: Activity;
};

export default function ActivityInfoTable({
  teamId,
  activityId,
  activity: activityWithTimestamps,
}: Props) {
  const { classes } = useStyles();

  const activity = React.useMemo(
    () => ({
      ...activityWithTimestamps,
      start: activityWithTimestamps.start.toDate(),
      end: activityWithTimestamps.end.toDate(),
    }),
    [activityWithTimestamps],
  );
  return (
    <TableContainer component={Card} variant="outlined" className={classes.container}>
      <Table>
        <TableBody>
          <EditRow
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            field="name"
            title="Name"
          />
          <EditActivityType
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            // @ts-expect-error - TS2322 - Type '{ activity: { start: any; end: any; data_last_added_to_db?: Timestamp | undefined; athlete_id: string; device_ids?: string[] | undefined; preset?: string | undefined; name?: string | undefined; ... 8 more ...; source?: "terra" | undefined; }; activityId: string; teamId: string; field: string; title: string; }' is not assignable to type 'IntrinsicAttributes & Props'.
            field="labels.type"
            title="Type"
          />
          <EditRow
            multiline
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            field="notes"
            title="Notes"
          />
          <EditActivityTime
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            field="start"
            title="Start"
          />
          <EditActivityTime
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            field="end"
            title="End"
          />
          <EditPods
            activity={activity}
            activityId={activityId}
            teamId={teamId}
            field="device_ids"
            title="Pods"
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
