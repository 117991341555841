import { getToken } from 'plantiga-firebase/useToken';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type HttpOptions = {
  query?: {
    [key: string]: string;
  };
  body?: {
    readonly [key: string]: any;
  };
};

export default class BaseAPIClient {
  apiUrl: string;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
  }

  async send(
    method: HttpMethod,
    path: string,
    options: HttpOptions = {},
    jsonReviver?: ((key: string, value?: any) => any) | null,
  ): Promise<[any, Response]> {
    const query = new URLSearchParams(options.query).toString();
    const url = `${this.apiUrl}${path}?${query}`;
    const token = await getToken();
    const fetchOptions: any = {
      method, // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      // redirect: 'error', // manual, *follow, error
    };
    if (options.body) {
      fetchOptions.body = JSON.stringify(options.body);
    }
    const res = await fetch(url, fetchOptions);
    const contentType = res.headers.get('content-type');
    if (contentType !== null && contentType.startsWith('text/html')) {
      const text = await res.text();
      return [text, res];
    }
    const json = await res.text();
    if (jsonReviver) return [JSON.parse(json, jsonReviver), res];
    return [JSON.parse(json), res];
  }
}
