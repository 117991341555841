type FieldId = string;
type TemplateItem = FieldId | { fieldId: FieldId; title: string };

export const templateFieldId = (item: TemplateItem) =>
  typeof item === 'string' ? item : item.fieldId;
export const templateTitle = (item: TemplateItem) =>
  typeof item === 'string' ? undefined : item.title;

export const teamDashboardTemplate = {
  load_plot: {
    achiral: 'analytics:load_summary__sum_max_mag_axyz',
    left: 'analytics:load_summary__sum_max_mag_axyz_left',
    right: 'analytics:load_summary__sum_max_mag_axyz_right',
  },
  load_asymmetry: {
    left: 'analytics:load_summary__sum_max_mag_axyz_left',
    right: 'analytics:load_summary__sum_max_mag_axyz_right',
    asymmetry: 'analytics:load_summary__sum_max_mag_axyz_asymmetry',
  },
  move_time: {
    move: 'analytics:mixed_activity_summary__sum_move_duration',
    idle: 'analytics:mixed_activity_summary__sum_idle_duration',
  },
  radar: {
    load: {
      fieldId: 'analytics:load_summary__sum_max_mag_axyz',
      title: 'Athlete Load™',
    },
    top_speed: {
      fieldId: 'velocity_summary__max_speed',
      title: 'Top Speed',
    },
    impact: {
      fieldId: 'analytics:absorption_summary__median_max_mag_axyz',
      title: 'Impact',
    },
    peak_decel: {
      fieldId: 'analytics:acceleration_summary__min_max_negative_acceleration_com',
      title: 'Peak Decel',
    },
    intensity: {
      fieldId: 'analytics:load_summary__mean_intensity_max_mag_axyz',
      title: 'Athlete Load™ Intensity',
    },
    foot_speed: {
      fieldId: 'analytics:acceleration_summary__max_positive_acceleration_final_speed',
      title: 'Top Foot Speed',
    },
    push_off: {
      fieldId: 'analytics:propulsion_summary__median_max_mag_axyz',
      title: 'Push-off',
    },
    peak_accel: {
      fieldId: 'analytics:acceleration_summary__max_max_positive_acceleration_com',
      title: 'Peak Accel',
    },
  },
};
