import { get } from 'lodash-es';

import { WARNINGS } from 'plantiga-component/Stopwatch/Setup/warnings';
import type { Summary } from 'plantiga-firebase/Summaries/typedefs';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';

function getIgnoredWarnings(warnings?: Array<string> | null): string | null | undefined {
  if (!Array.isArray(warnings)) {
    // warnings maybe boolean for some corrupted wtf_summaries
    return null;
  }

  const warningsSet = new Set(warnings);
  if (warningsSet.has(WARNINGS.haveBrokenDevices)) {
    return 'A broken pod was used';
  }
  if (warningsSet.has(WARNINGS.haveMissingDevices)) {
    return 'One or both pods were not paired';
  }

  return null;
}

function getDeviceIssues(summary: { [key: string]: string }): string | null | undefined {
  const issues: Array<string> = [];
  if (
    summary.left_id &&
    summary.left_battery_status != null &&
    summary.left_battery_status !== 'ok'
  ) {
    issues.push(`${deviceNiceId(summary.left_id)} died before the activity was finished`);
  }
  if (
    summary.right_id &&
    summary.right_battery_status != null &&
    summary.right_battery_status !== 'ok'
  ) {
    issues.push(`${deviceNiceId(summary.right_id)} died before the activity was finished`);
  }

  return issues.length > 0 ? issues.join('\n') : null;
}

export function getSummaryWarning(summary?: any | null): string | null | undefined {
  if (summary == null) return null;

  // device issues should be shown before the ignored warnings
  const deviceIssues = getDeviceIssues(summary);
  if (deviceIssues != null) {
    return deviceIssues;
  }

  return getIgnoredWarnings(get(summary, 'warnings_ignored'));
}

export function getSummaryValue(
  summary: Summary | null | undefined,
  fieldInfo: any,
  wtfSummary?: Summary | null,
): [number | null | undefined, string | null | undefined, 'info' | 'warning'] {
  // get the summary value and the reason the summary value is invalid if a reason is provided
  const v = get(summary, fieldInfo.key);
  let r;
  if (summary == null) {
    const warning = getSummaryWarning(get(wtfSummary, 'summary_v2'));
    if (warning != null) {
      return [v, warning, 'warning'];
    }
    r = 'This value has not yet been computed';
  } else if (!summary.finished) {
    r = 'This value is currently being computed';
  } else if (summary.error) {
    r = summary.error;
  } else {
    r = get(summary, ['invalid_values', fieldInfo.key, 'reason']);
  }
  return [v, r, 'info'];
}
