import { collection, doc } from 'firebase/firestore';
import React from 'react';

import { isEqual } from 'lodash-es';

import useFirestore from '../useFirestore';
import { useSubscribeDocument } from '../useSubscribe';

import AthletesContext from './AthletesContext';
import type { Athletes, Athlete, AthleteUser } from './typedefs';

export default function useAthletes({
  withArchived,
}: {
  withArchived?: boolean;
} = {}): Athletes {
  const v = React.useContext(AthletesContext);
  const athletes = withArchived ? v.athletes : v.unarchivedAthletes;
  const athletesRef = React.useRef(athletes);
  // deep comparison to ensure the reference doesn't change unnecessarily
  return isEqual(athletes, athletesRef.current) ? athletesRef.current : athletes;
}

export function useAthlete(athleteId: string): Athlete | undefined {
  const athlete = React.useRef<Athlete | undefined>();
  const { athletes } = React.useContext(AthletesContext);

  return React.useMemo(() => {
    // deep comparison to ensure the reference doesn't change unnecessarily
    const equal = isEqual(athletes[athleteId], athlete.current);
    if (!equal || athlete.current === undefined) athlete.current = athletes[athleteId];
    return athlete.current;
  }, [athletes, athleteId]);
}

export function useAthletesLoading(): {
  error: Error | null | undefined;
  loading: boolean;
} {
  const { loading, error } = React.useContext(AthletesContext);
  return { loading, error };
}

export function useAthleteUser(
  athleteId: string,
): [AthleteUser | null | undefined, boolean, Error | null | undefined] {
  const db = useFirestore();
  const ref = React.useMemo(() => doc(collection(db, 'athletes'), athleteId), [db, athleteId]);
  return useSubscribeDocument<AthleteUser>(ref);
}
