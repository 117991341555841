import React from 'react';

import { uniq, values } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useWantedUnit, type DisplayUnit } from 'plantiga-firebase/Team/universalUnitTranslator';

import UnitTextField from './UnitTextField';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 120px',
  },
  toggleGroup: {
    marginLeft: theme.spacing(1),
  },
  toggleGroupLabel: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

type Props = {
  readonly onChange: (arg1: number) => void;
  readonly storedUnit: string;
  readonly displayUnit: DisplayUnit;
  readonly value: number | null;
  readonly label?: string;
};

function getDisplayUnitOptions(displayUnit: DisplayUnit): string[] {
  if (typeof displayUnit === 'string') {
    return [displayUnit];
  }
  // @ts-expect-error - TS2322 - Type '(string | null)[]' is not assignable to type 'string[]'.
  return uniq(values(displayUnit).filter(Boolean));
}

export default function PickableUnitTextField({
  onChange,
  value,
  storedUnit,
  displayUnit,
  ...props
}: Props) {
  const { classes, cx } = useStyles();
  const options = getDisplayUnitOptions(displayUnit);
  const wantedUnit = useWantedUnit(storedUnit, displayUnit);
  const [selectedUnit, setSelectedUnit] = React.useState(wantedUnit);

  return (
    <div className={classes.container}>
      <UnitTextField
        {...props}
        displayUnit={selectedUnit}
        storedUnit={storedUnit}
        value={value}
        onChange={onChange}
        key={selectedUnit}
      />
      <ToggleButtonGroup
        className={cx(classes.toggleGroup, { [classes.toggleGroupLabel]: !!props.label })}
        value={selectedUnit}
        exclusive
        onChange={(_, v) => v && setSelectedUnit(v)}
      >
        {options.map((o) => (
          <ToggleButton key={o} value={o}>
            {o}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}
