import React from 'react';

import useActivity from 'plantiga-firebase/Activity/useActivity';

import Crumb from './Crumb';

type Props = { activityId: string } & Omit<React.ComponentProps<typeof Crumb>, 'text'>;

export default function ActivityCrumb({ activityId, ...props }: Props) {
  const { activity } = useActivity(activityId);
  return <Crumb {...props} url={undefined} text={activity?.name ?? '...'} />;
}
