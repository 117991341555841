import React from 'react';

const toString = Object.prototype.toString.call;

// Declare that a value should not change
export function useStableValue<T>(value: T): T {
  const ref = React.useRef(value);
  const { current } = ref;
  if (current !== value) {
    throw new Error(
      // @ts-expect-error - TS2684 - The 'this' context of type 'void' is not assignable to method's 'this' of type '(this: T) => unknown'. | TS2684 - The 'this' context of type 'void' is not assignable to method's 'this' of type '(this: T) => unknown'.
      `Value passed to useStableValue changed from '${toString(current)}' to '${toString(value)}'`,
    );
  }

  return current;
}

export function useDocumentTitle(titleOrFunc?: string | null) {
  React.useEffect(() => {
    const title = typeof titleOrFunc === 'string' ? titleOrFunc : 'Plantiga';
    const previousTitle = document.title;
    document.title = title;

    return () => {
      document.title = previousTitle;
    };
  }, [titleOrFunc]);
}
