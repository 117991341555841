/**
 * Obtains the chirality of the device from the ID.
 *
 * The last character of the device ID specifies the chirality, 0 for left and 1 for right.
 * E.g.
 *  L device id: XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX0
 *  R device id: XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX1
 */
export default function getDeviceLocationFromId(deviceId: string | undefined) {
  if (deviceId?.slice(-1) === '0') return 'L';
  if (deviceId?.slice(-1) === '1') return 'R';
  return undefined;
}
