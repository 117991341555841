import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { CardContent, Divider, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useHasPrivilege } from 'plantiga-auth/usePrivilege';
import { useSignOut } from 'plantiga-firebase/Auth/signIn';
import useUser from 'plantiga-firebase/Auth/useUser';

import ChangePasswordDialog from './ChangePasswordDialog';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles()({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
  },
});
type Props = {
  readonly handleClose: any;
};

export default function UserCard({ handleClose }: Props) {
  const { classes } = useStyles();
  const currentUser = useUser();
  const hasPrivilege = useHasPrivilege();

  const signOut = useSignOut();

  const [changePasswordOpen, setChangePasswordOpen] = React.useState(false);

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
    handleClose();
  };

  const handleChangePassword = () => {
    setChangePasswordOpen(true);
  };

  if (currentUser == null) {
    return null;
  }
  const { displayName, email } = currentUser;
  const name = displayName || email || '?';

  return (
    <>
      <CardContent className={classes.content}>
        <UserAvatar name={name} />
        <div className={classes.textBody}>
          <Typography component="h6" variant="h6">
            {name}
          </Typography>
          <Typography component="p">{email}</Typography>
        </div>
      </CardContent>
      <Divider />
      <List>
        {/*
          If the current User does not have an email, they will not have a team.
          Firebase cannot reset a password unless an email has been associated with the user.
        */}
        {!hasPrivilege('user:update') || currentUser.email == null ? null : (
          <>
            <ListItem button onClick={handleChangePassword}>
              <ListItemText primary="Change Password" />
            </ListItem>
            <ChangePasswordDialog open={changePasswordOpen} onClose={handleCloseChangePassword} />
          </>
        )}
        <ListItem button id="signOut" onClick={signOut}>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </>
  );
}
