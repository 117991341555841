import React from 'react';

import ConfirmDialog from 'plantiga-common/ConfirmDialog';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import { useToast } from 'plantiga-component/Toast/UseToast';
import { useDeleteAthlete } from 'plantiga-firebase/Athletes/actions';
import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

type Props = {
  readonly athlete: Athlete;
  readonly athleteId: string;
  readonly open: boolean;
  readonly onCancel: any;
};

export default function DeleteAthleteDialog({ athlete, athleteId, open, onCancel }: Props) {
  const postToast = useToast();
  const navigateURL = useNavigateURL();
  const deleteAthlete = useDeleteAthlete();

  const handleDeleteAthlete = React.useCallback(async () => {
    try {
      await deleteAthlete(athleteId);
      postToast({
        message: `Successfully deleted athlete ${athlete.name}`,
        variant: 'success',
      });
      navigateURL('athletes');
    } catch (error: any) {
      postToast({
        message: `Could not delete athlete ${athlete.name}`,
        variant: 'error',
      });
    }
  }, [athlete, athleteId, deleteAthlete, navigateURL, postToast]);

  return (
    <ConfirmDialog
      open={open}
      confirmationText="delete"
      color="secondary"
      message="Once the person has been deleted, it cannot be undone."
      onClose={onCancel}
      onConfirm={handleDeleteAthlete}
    />
  );
}
