import React from 'react';

import { isSameDay } from 'date-fns';
import { mapValues, maxBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Box, Typography, useTheme, Tooltip } from '@mui/material';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

import { ConstrainedToParentSize } from 'plantiga-common/ConstrainedToParentSize';
import { useField } from 'plantiga-common/Fields';
import Popover from 'plantiga-common/Popover';
import { MIN_TYPICAL_RANGE_DAYS } from 'plantiga-component/Athletes/LoadOverview/LoadOverviewCards/constants';
import { MetricIQRVis } from 'plantiga-component/Metric/MetricIQRVis';
import MetricName from 'plantiga-component/Metric/MetricName';
import { useUnitTranslator } from 'plantiga-firebase/Team/universalUnitTranslator';
import { computeStats } from 'plantiga-util/computeStats';
import { randomId } from 'plantiga-util/randomId';

import { teamDashboardTemplate } from './constants';
import type { TeamDashDatum } from './useTeamDashboard';

const useStyles = makeStyles()((theme) => ({
  box: {
    display: 'flex',
    flex: 1,
    cursor: 'pointer',
    marginRight: theme.spacing(2),
  },
  popoverContents: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  iqr: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iqrCaption: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    top: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
  },
}));

function IQRCaption({ daysCollected }: { daysCollected: number }) {
  const { classes } = useStyles();
  const daysRemaining = MIN_TYPICAL_RANGE_DAYS - daysCollected;
  return (
    <Tooltip
      title={
        daysRemaining > 0
          ? `Record data for at least ${MIN_TYPICAL_RANGE_DAYS} of the last 28 days to view your typical range`
          : 'Your typical range is based on the preceding 28 days'
      }
    >
      <div className={classes.iqrCaption}>
        <Typography variant="subtitle2" color="textSecondary" align="center">
          Typical Range
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center">
          {daysRemaining > 0
            ? `(${daysRemaining} more ${daysRemaining === 1 ? 'day' : 'days'} of data required)`
            : '(over 28 days)'}
        </Typography>
      </div>
    </Tooltip>
  );
}

function Plot({
  achiral,
  left,
  right,
  xMax = 1,
  width,
  height,
  radius = 4,
  formatValue,
}: {
  achiral: number;
  left: number;
  right: number;
  xMax: number | undefined;
  width: number;
  height: number;
  radius?: number;
  formatValue: (value: number) => string;
}) {
  const theme = useTheme();

  const xScale = scaleLinear({
    domain: [0, xMax],
    range: [0, width],
  });
  const clipId = randomId();
  return (
    <svg width={width} height={height}>
      <defs>
        <clipPath id={`achiral-${clipId}`}>
          <rect
            x={-radius}
            y={0}
            width={Math.max(radius, xScale(achiral)) + radius}
            height={height}
            ry={radius}
          />
        </clipPath>
        <clipPath id={`left-${clipId}`}>
          <rect x={-radius} y={0} width={xScale(left) + radius} height={height / 2} ry={radius} />
        </clipPath>
        <clipPath id={`right-${clipId}`}>
          <rect
            x={-radius}
            y={height / 2}
            width={Math.max(radius, xScale(right)) + radius}
            height={height / 2}
            ry={radius}
          />
        </clipPath>
      </defs>
      <Group className="achiral">
        <rect
          x={0}
          y={0}
          width={Math.max(radius, xScale(achiral))}
          height={height}
          fill={theme.palette.location.achiral}
          clipPath={`url(#achiral-${clipId})`}
        />
        <Text
          fontSize="12px"
          x={xScale(achiral)}
          y={height / 2}
          verticalAnchor="middle"
          dx={xScale(achiral) > width / 2 ? -4 : 6}
          textAnchor={xScale(achiral) > width / 2 ? 'end' : 'start'}
          fill={xScale(achiral) > width / 2 ? 'white' : 'black'}
        >
          {achiral === 0 ? 'None' : formatValue(achiral)}
        </Text>
      </Group>
      {left > 0 && (
        <Group className="left">
          <rect
            x={0}
            y={0}
            width={xScale(left)}
            height={height / 2}
            fill={theme.palette.location.left}
            clipPath={`url(#left-${clipId})`}
          />
          <Text x={4} y={height / 4} verticalAnchor="middle" fill="white">
            L
          </Text>
          <Text
            fontSize="12px"
            dx={2}
            x={xScale(left)}
            y={height / 4}
            dominantBaseline="middle"
            fill="white"
          >
            {formatValue(left)}
          </Text>
        </Group>
      )}
      {right > 0 && (
        <Group className="right">
          <rect
            x={0}
            y={height / 2}
            width={xScale(right)}
            height={height / 2}
            fill={theme.palette.location.right}
            clipPath={`url(#right-${clipId})`}
          />
          <Text x={4} y={(height * 3) / 4} verticalAnchor="middle" fill="white">
            R
          </Text>
          <Text
            fontSize="12px"
            dx={2}
            x={xScale(right)}
            y={(height * 3) / 4}
            dominantBaseline="middle"
            fill="white"
          >
            {formatValue(right)}
          </Text>
        </Group>
      )}
    </svg>
  );
}

type Props = {
  athleteId: string;
  date: Date;
  data: TeamDashDatum[] | undefined;
};

const TEMPLATE = teamDashboardTemplate.load_plot;

export function AthleteLoadPlot({ athleteId, date, data }: Props) {
  const { classes } = useStyles();
  const { storedUnit, displayUnit } = useField(TEMPLATE.achiral);
  const { translate, translateValue } = useUnitTranslator(storedUnit, displayUnit, { nDigits: 2 });

  const dateBinnedData = mapValues(TEMPLATE, (fieldId) =>
    data?.filter((v) => v.athleteId === athleteId && v.fieldId === fieldId),
  );

  const dateData = mapValues(TEMPLATE, (_, k) =>
    dateBinnedData[k as keyof typeof TEMPLATE]?.find((v) => isSameDay(v.date, date)),
  );

  const maxAthleteLoad = maxBy(
    data?.filter((v) => isSameDay(v.date, date) && v.fieldId === TEMPLATE.achiral),
    'value',
  )?.value;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  return (
    <>
      <Box
        className={classes.box}
        onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
      >
        <ConstrainedToParentSize>
          {(parent) => (
            <Plot
              achiral={dateData.achiral?.value ?? 0}
              left={dateData.left?.value ?? 0}
              right={dateData.right?.value ?? 0}
              xMax={maxAthleteLoad}
              width={parent.width}
              height={parent.height}
              formatValue={translateValue}
            />
          )}
        </ConstrainedToParentSize>
      </Box>
      {dateBinnedData && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <div className={classes.popoverContents}>
            {(['achiral', 'left', 'right'] as const).map((key) => (
              <React.Fragment key={key}>
                <MetricName fieldId={TEMPLATE[key]} activityType="open" />
                <div className={classes.iqr}>
                  <Typography variant="body1">{translate(dateData[key]?.value)}</Typography>
                  <MetricIQRVis
                    key={TEMPLATE[key]}
                    value={dateData[key]?.value}
                    width={240}
                    empty={(dateBinnedData[key]?.length ?? 0) < 3}
                    {...computeStats(dateBinnedData[key] ?? [], (v) => v.value)}
                    margin={{ top: 4, right: 16, bottom: 16, left: 16 }}
                    formatValue={translateValue}
                  />
                  <IQRCaption daysCollected={dateBinnedData[key]?.length ?? 0} />
                </div>
              </React.Fragment>
            ))}
          </div>
        </Popover>
      )}
    </>
  );
}
