import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card, CardContent, CardHeader, CardMedia } from '@mui/material';

import stepsUrl from 'plantiga-assets/steps.jpg';

const useStyles = makeStyles()((theme) => ({
  card: {
    width: '100%',
    maxWidth: theme.breakpoints.values.sm,
  },
  cardMedia: {
    height: 240,
    width: '100%',
  },
  cardHeader: {
    width: '100%',
  },
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      marginTop: theme.mixins.toolbar.minHeight,
      paddingTop: theme.spacing(2),
      alignItems: 'flex-start',
    },
  },
}));

type Props = {
  readonly title: string | React.ReactNode;
  readonly subheader?: string | React.ReactNode;
  readonly paragraphs?: Array<React.ReactElement>;
  readonly elevation?: number;
  readonly imageSrc?: string;
};

export default function CustomerCard({
  title,
  subheader = null,
  paragraphs = [],
  elevation = 0,
  imageSrc = stepsUrl,
}: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Card square className={classes.card} elevation={elevation}>
        <CardMedia image={imageSrc} className={classes.cardMedia} />
        <CardHeader title={title} subheader={subheader} className={classes.cardHeader} />
        {paragraphs.map((node) => (
          <CardContent key={node.key}>{node}</CardContent>
        ))}
      </Card>
    </div>
  );
}
