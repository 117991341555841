import React from 'react';

import { addHours, formatISO } from 'date-fns';

import { Link, Typography } from '@mui/material';

import DevDataTree from 'plantiga-common/DevDataTree';
import getProject from 'plantiga-common/getProject';
import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import useSummaries from 'plantiga-firebase/Summaries/useSummaries';

import SummariesContent from '../Summaries';

const formatGCSLink = (teamId: string, activityId: string) =>
  `https://console.cloud.google.com/storage/browser/${getProject()}.appspot.com/teams/${teamId}/activities/${activityId}`;

const formatLogLink = (activityId: string, time: Date = new Date()) =>
  `https://console.cloud.google.com/logs/query;query=resource.labels.service_name%3D%22analytics-pipeline%22%0AjsonPayload.activity_info.activity_id%3D%22${activityId}%22;cursorTimestamp=${formatISO(
    time,
  )};startTime=${formatISO(addHours(time, -1))};endTime=${formatISO(
    addHours(time, 1),
  )}?project=${getProject()}`;

type Props = {
  activity: Activity | null | undefined;
};

export default function SummariesView({ activity }: Props) {
  const { activityId, athleteId, teamId } = useParams();
  const activityIds = React.useMemo(() => [activityId], [activityId]);
  const { summaries, loading } = useSummaries(athleteId, activityIds);
  const lastCompute = Object.values(summaries)?.[0]?.last_compute?.toDate();

  return (
    <Wait waitingOn={loading} error={null} info="SummariesView">
      <Link target="_blank" rel="noopener" href={formatGCSLink(teamId, activityId)}>
        GCS bucket
      </Link>
      <Link target="_blank" rel="noopener" href={formatLogLink(activityId, lastCompute)}>
        Most recent compute pipeline logs
      </Link>
      <Typography variant="h5" gutterBottom>
        Activity
      </Typography>
      {activity ? <DevDataTree data={activity} dataId={activityId} /> : null}
      <br />
      <Typography variant="h5" gutterBottom>
        Summaries
      </Typography>
      {summaries ? <SummariesContent summaries={summaries} /> : null}
    </Wait>
  );
}
