import React from 'react';

import { formatDistance } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@mui/material';

import type { Device } from 'plantiga-firebase/Devices/typedefs';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';

import { PodChip } from './PodChip';

type Option = Device & { athleteName: string | undefined };

const useStyles = makeStyles()((theme) => ({
  deviceOptionInfo: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));

export default function AutocompleteDeviceOption({ device }: { device: Option }) {
  const { classes } = useStyles();

  const lastDocked = React.useMemo(() => {
    if (device?.last_status == null) return 'never docked';
    return `last docked ${formatDistance(device.last_status.toMillis(), Date.now())} ago`;
  }, [device?.last_status]);

  const pairedTo = React.useMemo(() => {
    if (!device.athleteName) return 'not paired';
    return `paired to ${device.athleteName}`;
  }, [device.athleteName]);

  const textProps = { variant: 'body2', color: 'textSecondary' } as const;
  return (
    <div>
      <PodChip podId={device.id} />
      <div className={classes.deviceOptionInfo}>
        {device.name ? <Typography {...textProps}>{deviceNiceId(device.id)}</Typography> : null}
        <Typography {...textProps}>{pairedTo}</Typography>
        <Typography {...textProps}>{lastDocked}</Typography>
      </div>
    </div>
  );
}
