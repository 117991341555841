import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Typography, Skeleton } from '@mui/material';

import { external } from 'plantiga-util/urls';

const MDPreview = React.lazy(async () => ({
  default: (await import('plantiga-common/Markdown')).MDPreview,
}));

const useActivityTypeInfoStyles = makeStyles()((theme) => ({
  container: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
  },
  textContainer: {
    padding: theme.spacing(2),
  },
  videoContainer: {
    paddingTop: '56.25%',
    position: 'relative',
  },
  iframe: {
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

type ActivityTypeInfoProps = {
  title: string;
  activityType: string;
  description: string;
};

export default function ActivityTypeInfo({
  title,
  activityType,
  description,
}: ActivityTypeInfoProps) {
  const { classes } = useActivityTypeInfoStyles();
  const [ref, setRef] = React.useState<HTMLIFrameElement | undefined>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let cancelled = false;
    const cleanup = () => {
      cancelled = true;
    };

    if (!ref) return cleanup;
    setLoading(true);
    ref.addEventListener('load', () => {
      if (cancelled) return;
      setLoading(false);
    });

    return cleanup;
  }, [ref]);

  const videoSrc = (external.youtube as { [key: string]: string })[activityType];

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body1">
          <React.Suspense fallback={<Skeleton variant="text" />}>
            <MDPreview source={description} style={{ fontSize: 'inherit' }} />
          </React.Suspense>
        </Typography>
      </div>
      {videoSrc ? (
        <div className={classes.videoContainer}>
          <iframe
            ref={setRef as React.RefCallback<HTMLIFrameElement>}
            title={title}
            width="100%"
            height="100%"
            className={classes.iframe}
            src={videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          {loading ? <Skeleton variant="rectangular" className={classes.iframe} /> : null}
        </div>
      ) : null}
    </div>
  );
}
