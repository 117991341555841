import type { StorageReference } from 'firebase/storage';
import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

import { Videocam as VideoIcon } from '@mui/icons-material';
import { List, ListItem, Paper, Popover } from '@mui/material';

import useBuildURL from 'plantiga-common/useBuildURL';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import useFiles from 'plantiga-firebase/Storage/useFiles';
import useTeam from 'plantiga-firebase/Team/useTeam';

type Props = {
  readonly activityId: string;
  readonly activity: Activity;
};

const useStyles = makeStyles()((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    whiteSpace: 'pre-wrap',
    maxWidth: '400px',
    padding: theme.spacing(1),
  },
  popoverAnchor: {
    display: 'inherit',
    pointerEvents: 'initial',
  },
}));
export const checkForVideo = (file: StorageReference) => /(\.mp4|\.mov)$/i.test(file.name);

function useVideos(activityId: string) {
  const { teamId } = useTeam();
  const pathPrefix = `teams/${teamId}/activities/${activityId}/extra`;
  const { files } = useFiles(pathPrefix);
  return React.useMemo(() => files.filter(checkForVideo), [files]);
}

export default function VideoDisplay({ activity, activityId }: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null | undefined>(null);
  const videos = useVideos(activityId);

  const buildURL = useBuildURL();
  if (videos.length === 0) {
    return null;
  }

  const athleteId = activity.athlete_id;
  const fileUrl = buildURL('athleteActivityFiles', { athleteId, activityId });

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Link
        className={classes.popoverAnchor}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        to={fileUrl}
      >
        <VideoIcon fontSize="small" color={open ? undefined : 'disabled'} />
      </Link>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={classes.popover}
      >
        <Paper className={classes.paper}>
          <List>
            {videos.map((file) => (
              <ListItem key={file.name}>{file.name}</ListItem>
            ))}
          </List>
        </Paper>
      </Popover>
    </>
  );
}
