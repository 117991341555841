import React from 'react';

import { floor, round } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { InputAdornment, TextField } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  inputContainer: {
    display: 'flex',
    width: '100%',
  },
  space: {
    width: theme.spacing(1),
  },
}));

type Props = {
  readonly onChange: (arg1: number) => void;
  readonly value: number | null | undefined;
};

export default function FeetInchesField({ onChange, value, ...props }: Props) {
  const { classes } = useStyles();
  const [feet, setFeet] = React.useState(floor(value ?? 0));
  const [inch, setInch] = React.useState(round(((value ?? 0) - floor(value ?? 0)) * 12, 0));

  const onFtChange = (e: any) => {
    const { value: newValue } = e.target;
    const newFeet = parseInt(newValue, 10);

    onChange(newFeet + inch / 12);
    setFeet(newFeet);
  };

  const onInChange = (e: any) => {
    const { value: newValue } = e.target;
    const newInch = parseInt(newValue, 10);

    onChange(feet + newInch / 12);
    setInch(newInch);
  };

  return (
    <div className={classes.inputContainer}>
      <TextField
        {...props}
        value={feet || 0}
        onChange={onFtChange}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">ft</InputAdornment>,
          inputProps: { min: 0 },
        }}
      />
      <div className={classes.space} />
      <TextField
        {...props}
        label={null}
        value={inch || 0}
        onChange={onInChange}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">in</InputAdornment>,
          inputProps: { min: 0, max: 11 },
        }}
      />
    </div>
  );
}
