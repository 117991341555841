import React from 'react';

import { withStyles } from 'tss-react/mui';

import { Card, CardActions, CardContent, Typography } from '@mui/material';

import NavButton from 'plantiga-common/NavButton';

const styles = (theme: any) => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
  media: {
    maxWidth: theme.breakpoints.values.sm,
  },
});

type OwnProps = {
  title?: string;
  message?: string;
};

type Props = {
  classes: any;
} & OwnProps;

function NotFoundComponent({ classes, title, message }: Props) {
  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardContent>
          <Typography component="h3" variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
        </CardContent>
        <CardActions>
          {/* @ts-expect-error - TS2322 - Type '{ children: string; href: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
          <NavButton href="/">Go Home</NavButton>
        </CardActions>
      </Card>
    </div>
  );
}

NotFoundComponent.defaultProps = {
  title: 'Page Not Found',
  message: 'The page is currently not available.',
};

export default withStyles(NotFoundComponent, styles) as React.ComponentType<OwnProps>;
