import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { CircularProgress, Drawer, Fade } from '@mui/material';

import useBreakpoint from 'plantiga-common/useBreakpoint';

const useStyles = makeStyles()((theme) => ({
  mobileIframe: {
    width: '100%',
    height: theme.breakpoints.values.sm,
  },
  desktopIframe: {
    width: theme.breakpoints.values.sm,
    height: '100%',
  },
  icon: {
    verticalAlign: 'middle',
    cursor: 'help',
  },
  /* eslint-disable tss-unused-classes/unused-classes */
  'icon-small': {
    fontSize: '1rem',
  },
  'icon-medium': {
    fontSize: '1.5rem',
  },
  'icon-large': {
    fontSize: '2rem',
  },
  'icon-inherit': {
    fontSize: 'inherit',
  },
  'icon-default': {
    fontSize: '1.2em',
  },
  /* eslint-enable tss-unused-classes/unused-classes */
  center: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  helpPageUrls: string[];
  iconProps?: React.ComponentProps<typeof InfoIcon>;
};

export default function PlantigaInfo({ helpPageUrls, iconProps = { fontSize: 'inherit' } }: Props) {
  const { classes, cx } = useStyles();
  const isMobile = useBreakpoint();
  const anchor = isMobile ? 'bottom' : 'right';

  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e: React.MouseEvent<SVGElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    setLoading(true);
    setOpen(false);
  };

  return (
    <>
      <InfoIcon
        className={cx(classes.icon, classes[`icon-${iconProps?.fontSize ?? 'default'}`])}
        color="action"
        onClick={handleOpen}
        data-html2canvas-ignore
      />
      <Drawer anchor={anchor} open={open} onClose={handleClose}>
        <Fade in={loading}>
          <div className={classes.center}>
            <CircularProgress />
          </div>
        </Fade>
        {helpPageUrls.map((url) => (
          <iframe
            allowFullScreen
            key={url}
            src={url}
            title={url}
            onLoad={() => setLoading(false)}
            className={cx({ [classes.mobileIframe]: isMobile, [classes.desktopIframe]: !isMobile })}
          />
        ))}
      </Drawer>
    </>
  );
}
