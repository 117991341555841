/* eslint-disable react/no-multi-comp */

import React from 'react';

import { sortBy, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CircularProgress, List, ListItem, ListItemText } from '@mui/material';

import { useNavigateURL } from 'plantiga-common/useBuildURL';

const useStyles = makeStyles()({
  progressContainer: {
    textAlign: 'center',
  },
  progress: {
    margin: '50px auto',
    width: '100%',
  },
});

type Props = {
  readonly onClose?: any | null | undefined;
  readonly onClick?: any | null | undefined;
  readonly teams: any;
  readonly loading: boolean;
};

export default function TeamSelectList({ onClose = null, onClick, teams, loading }: Props) {
  const { classes } = useStyles();
  const navigateURL = useNavigateURL();

  if (loading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress className={classes.progress} />
      </div>
    );
  }
  const defaultOnClick = (teamId: string) => {
    navigateURL('teamLanding', { teamId });
    localStorage.setItem('currentTeamId', teamId);
    if (onClose != null) onClose();
  };

  const sortedTeams = sortBy(toPairs(teams), ([, team]: [any, any]) => team.name.toLowerCase());

  return (
    <List>
      {sortedTeams.map(([teamId, team]: [any, any]) => (
        <ListItem
          key={teamId}
          id={`aui_${teamId}`}
          button
          onClick={() => (onClick ? onClick(teamId) : defaultOnClick(teamId))}
        >
          <ListItemText primary={team.name} />
        </ListItem>
      ))}
    </List>
  );
}
