type Options =
  | {
      dataUri: string;
      filename: string;
    }
  | { uri: string; filename: string };

export default function saveFile({ filename, ...options }: Options) {
  const uri = 'uri' in options ? options.uri : encodeURI(options.dataUri);
  const downloadLink = document.createElement('a');
  downloadLink.href = uri;
  downloadLink.download = filename;
  downloadLink.target = '_blank';

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}
