import { matchPath } from 'react-router';

import { intersection, omitBy } from 'lodash-es';

// access urls through a getter for testing
import { getUrls, type urlKey } from 'plantiga-util/urls';

import type { NavItemDefn } from './typedefs';

export const getUrlPathParams = (path: urlKey) => {
  const urls = getUrls();
  return urls[path]
    .split('/')
    .filter((v) => v[0] === ':' && v.slice(-1) !== '?')
    .map((v) => v.replace(/[:|?]/g, ''));
};

export const getNestedPaths = (items: NavItemDefn[]): urlKey[] =>
  items.flatMap((v) => {
    const paths = v.items ? getNestedPaths(v.items) : [];
    return [v.path, ...paths];
  });

type CollectParamsOptions = {
  omitUndefined?: boolean;
};

export const collectParams = (
  locationPathname: string,
  paths: urlKey[],
  { omitUndefined }: CollectParamsOptions = { omitUndefined: false },
) => {
  const urls = getUrls();
  const params = paths.reduce((acc, path) => {
    const m = matchPath(locationPathname, { path: urls[path] });
    return { ...acc, ...(m?.params ?? {}) };
  }, {} as { [param: string]: string });

  if (omitUndefined) {
    return omitBy(params, (v) => v === undefined);
  }
  return params;
};

type NavItem = { disabled: boolean } & NavItemDefn;

export function isMissingParams(item: NavItemDefn, params: { [key: string]: string }) {
  const requiredParamKeys = getUrlPathParams(item.path);
  const paramKeys = Object.keys(params);
  return intersection(paramKeys, requiredParamKeys).length !== requiredParamKeys.length;
}

export function disableItems(
  items: NavItemDefn[],
  disableItem: (item: NavItemDefn) => boolean,
  filterItem: (item: NavItemDefn) => boolean,
): NavItem[] {
  return items.filter(filterItem).map((item) => ({
    ...item,
    disabled: disableItem(item),
    items: item.items ? disableItems(item.items, disableItem, filterItem) : item.items,
  }));
}

export function badgeItems(
  items: NavItemDefn[],
  badgeItem: (item: NavItemDefn) => React.ReactNode,
): NavItemDefn[] {
  return items.map((item) => ({
    ...item,
    badgeContent: badgeItem(item) ?? item.badgeContent,
    items: item.items ? badgeItems(item.items, badgeItem) : item.items,
  }));
}
