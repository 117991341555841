import { Timestamp } from 'firebase/firestore';
import React from 'react';

import { map, orderBy, toPairs } from 'lodash-es';

import {
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

type Props = {
  data: object;
  dataId: string;
  isChild?: boolean;
};

export default function DevDataTree({ data, dataId, isChild }: Props) {
  const orderedData = React.useMemo(
    () => orderBy(toPairs(data), ([k, v]) => [typeof v === 'object', k]),
    [data],
  );

  if (orderedData.length === 0) {
    return null;
  }
  if (isChild === undefined) {
    return (
      <SimpleTreeView
        key={dataId}
        slots={{
          collapseIcon: ExpandMoreIcon,
          expandIcon: ChevronRightIcon,
        }}
      >
        <DevDataTree data={data} dataId={dataId} isChild />
      </SimpleTreeView>
    );
  }
  return (
    <TreeItem itemId={dataId} label={<b>{dataId.split('/').at(-1)}</b>}>
      {map(orderedData, ([k, v]: [string, unknown]) => {
        switch (true) {
          case v == null:
            return (
              <TreeItem
                key={k}
                itemId={`${dataId}/${k}`}
                label={
                  <>
                    {k}: <i>null</i>
                  </>
                }
              />
            );
          // custom render handler for firestore timestamps
          case v instanceof Timestamp:
            return <TreeItem key={k} itemId={`${dataId}/${k}`} label={`${k}: ${v.toDate()}`} />;
          case typeof v === 'object':
            return <DevDataTree key={k} data={v} dataId={`${dataId}/${k}`} isChild />;
          default:
            return <TreeItem key={k} itemId={`${dataId}/${k}`} label={`${k}: ${v.toString()}`} />;
        }
      })}
    </TreeItem>
  );
}
