import React from 'react';

import { isEmpty, keys, size } from 'lodash-es';

import { Card, Typography } from '@mui/material';

import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import ErrorCard from 'plantiga-component/Error/ErrorCard';
import ErrorPage from 'plantiga-component/Error/ErrorPage';
import TeamSelectList from 'plantiga-component/Nav/TeamSelectList';
import useCoach from 'plantiga-firebase/Coach/useCoach';
import useAccessRequest from 'plantiga-firebase/Team/useAccessRequest';
import { useLoadTeams } from 'plantiga-firebase/Team/useTeam';

import RequestNotAcceptedView from './RequestNotAcceptedView';

function RequestAccessLink() {
  const { coach } = useCoach();
  const { teamId: requestedTeamId } = useParams();

  if (coach?.super_user?.is_admin !== true || !requestedTeamId) return null;
  const adminURL = `https://admin.${window.plantiga.domain.replace('beta.', '')}`;
  return (
    <Typography variant="body1" paragraph>
      Request temporary access to:&nbsp;
      <a href={`${adminURL}/team/${requestedTeamId}`} target="_blank" rel="noreferrer">
        {requestedTeamId}
      </a>
    </Typography>
  );
}

type Props = {
  readonly redirect?: any;
  readonly permissionDenied?: boolean;
};

export default function RedirectToTeam({ redirect, permissionDenied = false }: Props) {
  const { status, error: statusError } = useAccessRequest();
  const { teams, loading, error: teamError } = useLoadTeams();

  React.useEffect(() => {
    const currentTeamId = localStorage.getItem('currentTeamId');
    if (redirect) {
      if (currentTeamId) {
        redirect(currentTeamId);
      }
      // If authenticated, no currentTeamId (Private browsing) and only one team, use that team
      if (!loading && size(teams) === 1) {
        const teamId = keys(teams)[0];
        redirect(teamId);
      }
    }
  }, [redirect, loading, teams]);

  const waitError = React.useMemo(() => {
    if (permissionDenied) return null;
    return statusError || teamError;
  }, [permissionDenied, teamError, statusError]);

  if (!loading && isEmpty(teams)) {
    switch (status) {
      case 'pending':
      case 'wait-listed':
        return <RequestNotAcceptedView status={status} />;
      default:
    }
  }

  return (
    <Wait waitingOn={loading} error={waitError} info="RedirectToTeam">
      <ErrorPage>
        {size(teams) === 0 ? (
          <ErrorCard
            title="You do not have access to any organizations"
            message={
              <>
                If you believe you are receiving this message in error, please try again or contact
                us at&nbsp;
                <a href="mailto:support@plantiga.com?subject=No Organization Access">
                  support@plantiga.com
                </a>
              </>
            }
          />
        ) : (
          <div>
            {permissionDenied ? (
              <>
                <Typography variant="h5">This organization is not available</Typography>
                <RequestAccessLink />
                <Typography variant="subtitle1" gutterBottom>
                  Please choose an organization you have access to:
                </Typography>
              </>
            ) : (
              <Typography variant="h5">Select your organization</Typography>
            )}
            <Card variant="outlined">
              {/* @ts-expect-error - TS2322 - Type '{ permissionDenied: true; teams: any; loading: boolean; onClick: any; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
              <TeamSelectList permissionDenied teams={teams} loading={loading} onClick={redirect} />
            </Card>
          </div>
        )}
      </ErrorPage>
    </Wait>
  );
}
