import React from 'react';

import { filter, omit, sortBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ArrowDropDown } from '@mui/icons-material';
import { Button, Popover, TextField, Autocomplete } from '@mui/material';
import { type FilterOptionsState } from '@mui/material/useAutocomplete';

import VirtualList from 'plantiga-common/VirtualList';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import { useDevices } from 'plantiga-firebase/Devices';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';
import {
  searchDevices,
  type SearchableDevice,
  makeDevicesSearchable,
  makeDeviceSortKey,
} from 'plantiga-util/devices';

import AutoCompleteDeviceOption from './AutoCompleteDeviceOption';
import { NoPodChip } from './NoPodChip';
import { PodChip } from './PodChip';

const useStyles = makeStyles()({
  button: {
    '& > *': {
      pointerEvents: 'none',
    },
  },
  popoverPaper: {
    minWidth: 240,
    overflow: 'visible',
  },
});

const filterOptions = (options: SearchableDevice[], state: FilterOptionsState<SearchableDevice>) =>
  searchDevices(options, state.inputValue);

type Props = {
  onChange: (device: Device | undefined) => void;
  deviceId?: string | undefined;
  location: 'L' | 'R';
};

export default function AutocompleteDevice({ deviceId: initialId, location, onChange }: Props) {
  const { classes } = useStyles();
  const devices = useDevices();
  const athletes = useAthletes({ withArchived: true });

  const options = React.useMemo(() => {
    const chiralDevices = filter(devices, (v) => (location ? v.location === location : true));
    const sortedDevices = sortBy(chiralDevices, makeDeviceSortKey);
    return makeDevicesSearchable(sortedDevices, athletes);
  }, [athletes, devices, location]);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [value, setValue] = React.useState(options.find((v) => v.id === initialId));

  const handleChange = React.useCallback(
    (_: any, v: SearchableDevice | null) => {
      // only allow one device to paired at a time
      setAnchorEl((prev) => (v == null ? prev : null));
      setValue(v ?? undefined);
      onChange(v ? omit(v, ['athleteName', 'shortId']) : undefined);
    },
    [onChange],
  );

  return (
    <>
      <Button
        variant="outlined"
        endIcon={<ArrowDropDown />}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        className={classes.button}
      >
        {value ? <PodChip podId={value.id} /> : <NoPodChip location={location} />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        classes={{ paper: classes.popoverPaper }}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        TransitionProps={{ onEntered: () => setOpen(true), onExited: () => setOpen(false) }}
      >
        <Autocomplete
          fullWidth
          open={open}
          options={options}
          value={value ?? null}
          filterOptions={filterOptions}
          onChange={handleChange}
          isOptionEqualToValue={(o, v) => o?.id === v?.id}
          getOptionLabel={(option) => (option?.id ? deviceNiceId(option.id) : '')}
          ListboxComponent={VirtualList}
          ListboxProps={{ itemSize: 120, overscanCount: 3, padding: 8 } as any}
          renderOption={(props, v) => (
            <li {...props}>
              <AutoCompleteDeviceOption device={v} />
            </li>
          )}
          renderInput={(p) => <TextField {...p} autoFocus size="small" variant="outlined" />}
        />
      </Popover>
    </>
  );
}
