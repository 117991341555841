import React from 'react';
import { Redirect } from 'react-router-dom';

import { format as formatDate } from 'date-fns';

import { ALL_ACTIVITIES } from 'plantiga-common/AutocompleteActivityType';
import { useParams } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';

export default function FeedRedirect() {
  const buildURL = useBuildURL();
  const params = useParams();

  const { athleteId, day } = params;
  const feedURL = buildURL('athleteDailyActivityDay', {
    athleteId,
    day: day || formatDate(new Date(), 'yyyy-MM-dd'),
    type: ALL_ACTIVITIES,
  });
  return <Redirect to={feedURL} />;
}
