import React from 'react';

import { SvgIcon } from '@mui/material';

function TennisIcon({ color }: { color?: React.ComponentProps<typeof SvgIcon>['color'] }) {
  return (
    <SvgIcon color={color} viewBox="125 25 550 550">
      <path
        d="M508.1,518.6c-3.3,0.9-5.5,1.5-8.9,2.4c1.3-2.2,1.7-3.6,2.6-4.3c6.1-4.3,5.9-10.4,6.3-17.2c0.6-9.6,2-19.2-1.9-28.3
  c-3.1-7.3-7.3-14.2-10.9-21.4c-3.8-7.7-7.7-15.3-11.1-23.1c-1.9-4.6-2.6-9.7-4.2-14.6c-1.4-4.3-3-8.5-4.7-12.7
  c-3.1-7.1-8.3-13.9-9.2-21.3c-1.6-12.5-0.3-25.3-0.7-37.9c-0.2-7.6-1.2-15.1-2.2-22.6c-0.6-4.3-2-8.4-3.2-13.1
  c-3.7,0.3-7.8,0.8-11.9,1.1c-3.3,0.2-6.9,1.3-9.7,0.3c-7.5-2.5-11.2,1.9-16,6.2c-13.8,12.4-25,26.7-35.4,41.9
  c-4.3,6.4-10.3,11.5-12.5,19.5c-0.9,3.1-4.3,5.8-7,8.1c-2.2,1.9-3,3.3-2.2,6.3c2.3,9.1,0,13.3-7.6,19.1c-4.2,3.2-7.7,7.4-11.2,11.5
  c-7.8,9.3-14.8,19.4-23.3,27.9c-6.9,6.9-14.7,4.8-18.1-3.2c-0.5-1.1-1-2.5-0.7-3.6c2-10,3.9-20,11.2-28.3c-0.7-0.8-1.6-1.8-2.4-2.7
  c0.4-0.4,0.5-0.6,0.7-0.6c12.7-0.2,16.3-11.9,23.3-19.1c2.3-2.4,1.2-8,2.1-12c1.9-9,3.8-18.1,6.3-27c1.5-5.1,4.4-9.6,10.5-10.8
  c1.2-0.2,2.3-2.5,3-4.1c7.8-17.4,15.6-34.8,23.2-52.3c0.5-1.1,0.2-2.7-0.2-3.8c-0.6-1.6-1.8-3-2.9-4.8c10.2-4.3,12.1-14.3,17-22.1
  c4.6-7.3,5.1-16.5,10.7-23.6c4.1-5.3,7.6-11.5,9.9-17.9c1.7-4.9,2.4-10.7,1.5-15.8c-2-11.2-6.8-21.9-6-33.7
  c0.2-2.8-1.6-5.7-2.6-8.6c-0.5,0-1,0-1.5-0.1c-0.6,1.5-1.1,3.2-1.9,4.6c-3.6,6.7-7.2,13.4-11.1,20c-4.9,8.4-10.3,16.6-15.2,25.1
  c-4.4,7.6-10,13.2-18.6,15.8c-1.9,0.6-4.1,3-4.7,4.9c-1.9,6.3-7.6,11.4-14.3,11.5c-6.4,0.1-12.8-0.1-18.1-0.1
  c-3.2-1.2-5.6-2.6-8.1-2.8c-19.6-1.8-37.9,2.9-55.2,11.7c-6.7,3.4-12.6,8.5-19.3,12.2c-15.1,8.5-31.1,14.4-48.9,12.8
  c-13.2-1.1-24.1-6.7-31.7-17.8c-8.5-12.4-7.3-25.5,3.5-36.1c15.6-15.2,34.5-19.6,55.4-15.3c7.2,1.5,14.4,4.1,21,7.5
  c14.7,7.7,30.2,13.1,46.5,14.9c9.6,1,19.5-0.9,29.3-1.6c3.8-0.3,7.6-0.9,11.7-1.4c-0.6-6.2,2.3-8.3,8.3-8.7c2.5-0.1,6-2.8,7.2-5.2
  c3.6-7.7,8.2-13.8,15.6-18.3c3.7-2.2,6.4-6.8,8.6-10.8c4.6-8.2,8.7-16.7,12.8-25.2c1.1-2.2,1.2-4.9,2.3-7.2
  c3.8-8.6,7.2-17.4,11.8-25.6c2.9-5.3,8.4-7.5,14.8-7.8c3.9-0.2,7.7-2.2,12-3.5c-2-4.4-3.9-7.5-4.8-10.9c-0.8-2.7-0.8-5.7-0.5-8.5
  c0.8-7.2-0.3-8.4-7.3-7.6c-1.1,0.1-2.4-0.3-4.2-1.8c3.3-2.6,6.7-5,9.9-7.7c1.5-1.3,3-3,3.9-4.8c2.5-5,4.3-10.3,7.1-15.1
  c4.3-7.6,12.8-10,23.7-6.9c4.3,1.2,8.2,1.8,12.7,0.4c5.3-1.7,11.2-2.1,15.2,2.6c4,4.6,3.8,10,1.7,15.9c-0.8,2.2-4.3,4.9-1.2,8.1
  c0.1,0.1-1.3,2-2.3,2.7c-5.5,4.1-5.8,5.9-1.2,11.9c-2.3,2.7-4.7,5.5-7.5,8.8c7.5,0,14.5,0.7,21.3-0.2c9.7-1.2,17.4,1.6,22.5,9.9
  c5,8.2,8.8,17.1,13.9,25.3c4.3,6.9,9.4,13.4,14.4,19.9c0.6,0.8,2.6,1.1,3.9,1c6.7-0.5,13.4-1.3,20-1.9c1.3-0.1,2.6-0.3,3.8,0
  c1.2,0.3,2.3,1.2,3.5,1.8c-0.9,1.2-1.5,2.9-2.7,3.4c-2.1,1-4.6,1.1-6.8,1.8c-1.3,0.4-3.1,1-3.4,1.9c-0.3,0.9,0.8,2.6,1.7,3.6
  c3.5,4,7.1,7.8,10.6,11.7c3.7,4.2,0.3,8.2-1.6,11c-1.8,2.6-6,3.5-9.5,0.5c-2-1.8-4.3-3.3-7.4-5.6c1.3,2.7,2.1,4.4,2.9,6.2
  c-10.5,4.7-12.4,4.4-19.7-3.4c-10.3-11-20.7-22-28.3-35.9c0,1.4-0.3,2.8,0.1,4c1.2,3.6,3,7,4,10.7c2,7,1,13.4-4.8,18.4
  c-0.5,0.5-1.3,1-1.4,1.6c-1.5,9.3-3.9,18.5-0.6,28c2,5.6,3,11.6,4.9,17.2c2.5,7.3,6.2,14.3,8.2,21.8c2.1,7.5,3.1,15.4,4.1,23.2
  c0.7,5,0.7,10.2,1,15.2c0.5,0,1-0.1,1.5-0.1c-0.1,0.3-0.2,0.8-0.4,1c-6.9,4.5-8.4,11.2-9.1,19c-1.4,16.7-5.4,32.9-12.3,48.4
  c-3.5,7.9-4.3,16.4-1.3,25.5c2.3,6.8,5.1,12.7,9.4,18.4c9.7,12.6,14.3,27.2,14.6,43.2c0.2,11.3,0.9,22.6,1.3,33.8
  c0.3,6.7,3.1,12,7.4,17.1c4.2,4.9,7.7,10.5,10.9,16.1c0.9,1.5,0.2,4.7-0.8,6.5c-4.2,6.9-7.1,13.7-8.2,22.1
  c-2.1,16.9-23.4,22.2-35.3,17c-4-1.8-4.4-5-2-9.6c3-5.6,5.8-11.3,8.6-17C506.6,523.5,507.1,521.4,508.1,518.6z M258.9,239.4
  c9.6-3.9,19.3-7.9,29.6-12c-10.4-2.5-20.1-4.8-29.6-7C258.9,226.8,258.9,233,258.9,239.4z"
      />
    </SvgIcon>
  );
}

TennisIcon.defaultProps = {
  color: undefined,
};

export default TennisIcon;
