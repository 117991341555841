import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card, CardActions, CardContent, Typography } from '@mui/material';

import NavButton from 'plantiga-common/NavButton';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: theme.breakpoints.values.sm,
  },
}));

type Props = {
  title?: string;
  message?: string;
};

export default function NoOfflineSupport({ title, message }: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardContent>
          <Typography component="h3" variant="h3" gutterBottom>
            {title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {message}
          </Typography>
        </CardContent>
        <CardActions>
          {/* @ts-expect-error - TS2322 - Type '{ children: string; href: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
          <NavButton href="/">Go Home</NavButton>
        </CardActions>
      </Card>
    </div>
  );
}

NoOfflineSupport.defaultProps = {
  title: 'You are offline',
  message: 'This feature is not supported offline',
};
