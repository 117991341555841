import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';
import {
  getAuth,
  updateProfile,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  getRedirectResult,
  signInWithRedirect,
  GoogleAuthProvider,
  type AuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailLink,
  updatePassword,
  isSignInWithEmailLink,
  signInWithCustomToken,
} from 'firebase/auth';
import React, { useState, useCallback } from 'react';

import makeDebug from 'debug';
import { intersection } from 'lodash-es';

import { FirebaseError } from '@firebase/util';

import { useLocation } from 'plantiga-common/react-router-hooks';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import useDemoApi from 'plantiga-util/apiClient/useDemoApiClient';

import urls from '../../../util/urls';
import { useToast } from '../../Toast/UseToast';

const debug = makeDebug('plantiga:auth');

type ProviderId = 'password' | 'google.com' | 'facebook.com';

export const raiseForExistingUserMissingProvider = async (email: string, provider: ProviderId) => {
  const methods = await fetchSignInMethodsForEmail(getAuth(), email);
  if (methods.includes(provider) || methods.length === 0) return;

  const methodMessage =
    methods.length === 1
      ? `the ${methods[0]} provider.`
      : `one of the following providers: ${methods.join(', ')}`;

  // Why does auth.signInWithEmailAndPassword not check for this!?!
  throw new FirebaseError(
    'auth/account-exists-with-different-credential',
    `An account already exists for ${email}. Please login with ${methodMessage}`,
  );
};

export const raiseForExistingProviders = async (email: string, providers: ProviderId[]) => {
  const methods = await fetchSignInMethodsForEmail(getAuth(), email);
  if (intersection(methods, providers).length > 0) {
    // Why does auth.signInWithEmailAndPassword not check for this!?!
    throw new FirebaseError(
      'auth/account-exists-with-different-credential',
      `An account already exists with the provided email. Please login with the ${methods[0]} provider.`,
    );
  }
};

const makeSignInErrors = (code: any, message: any) => {
  switch (code) {
    case 'auth/wrong-password':
      return { emailError: '', passwordError: message };
    case 'auth/user-not-found':
      return { emailError: message, passwordError: '' };
    default:
      return {
        emailError: message || 'there was an error with your request',
        passwordError: '',
      };
  }
};

export const useSignIn = (): {
  emailError: string;
  loading: boolean;
  passwordError: string;
  signIn: (email: string, password: string, next: string) => Promise<void>;
} => {
  const { navigate } = useLocation();
  const [{ emailError, passwordError, loading }, setState] = useState({
    emailError: '',
    passwordError: '',
    loading: false,
  });
  const signIn = async (email: string, password: string, next: string) => {
    debug(`signIn as ${email}`);
    const analytics = getAnalytics();
    const auth = getAuth();
    try {
      setState((prev) => ({ ...prev, loading: true }));
      await raiseForExistingUserMissingProvider(email, 'password');

      const result = await signInWithEmailAndPassword(auth, email, password);

      debug('signIn complete', result);

      setUserId(analytics, auth.currentUser?.uid ?? '');
      logEvent(analytics, 'login', { method: 'EmailAndPassword' });

      setState({ emailError: '', passwordError: '', loading: false });

      navigate(next);
    } catch (err: any) {
      const errors = makeSignInErrors(err.code, err.message);
      setState({ ...errors, loading: false });
    }
  };

  return {
    signIn,
    emailError,
    passwordError,
    loading,
  };
};

const makeRedirectResultErrors = (code: string, message: string) => {
  // Possible error codes
  // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#getredirectresult
  switch (code) {
    case 'auth/account-exists-with-different-credential':
    case 'auth/credential-already-in-use':
    case 'auth/email-already-in-use':
      return message;
    case 'auth/web-storage-unsupported':
      return `${message} If using a private browser, ensure cookies are enabled.`;
    default:
      return 'Sign in failed';
  }
};

export const useFirebaseProviderSignin = (
  provider: AuthProvider,
  next: string,
): (() => Promise<void>) => {
  const postToast = useToast();

  React.useEffect(() => {
    const analytics = getAnalytics();
    const auth = getAuth();
    const checkSignInRedirect = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          setUserId(analytics, result.user.uid);
          logEvent(analytics, 'login', {
            method: `${provider.providerId} AuthProvider`,
          });
          debug('signInWithPopup result', result);
          // Force a hard reload instead of calling `navigate`, so that the hidden iframe
          // firebase auth uses to authenticate using OAuth goes away.
          // This hidden iframe is the source of the `Network Error` exceptions we've been
          // seeing on sentry.io.
          // https://sentry.io/organizations/plantiga/issues/1244954080/?project=1757186
          // https://github.com/Plantiga/plantiga.io/issues/1626
          // https://github.com/firebase/firebase-js-sdk/blob/6b53e0058483c9002d2fe56119f86fc9fb96b56c/packages/auth/src/iframeclient/iframewrapper.js#L124
          // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location'.
          window.location = next;
        }
      } catch (err: any) {
        console.error(err);
        const message = makeRedirectResultErrors(err.code, err.message);
        postToast({
          message,
          variant: 'error',
          timeout: 30, // give users some time to read the message
        });
      }
    };

    checkSignInRedirect();
  }, [next, provider, postToast]);

  const signin = React.useCallback(async () => {
    const auth = getAuth();
    try {
      await signInWithRedirect(auth, provider);
    } catch (err: any) {
      console.error(err);
      postToast({
        message: `Sign in using ${provider.providerId} failed`,
        variant: 'error',
      });
    }
  }, [provider, postToast]);

  return signin;
};

export const useGoogleSignIn = (next: string): (() => Promise<void>) => {
  const googleProvider = React.useMemo(() => {
    const provider = new GoogleAuthProvider();
    // always prompt users for which google account to login with
    provider.setCustomParameters({ prompt: 'select_account' });
    return provider;
  }, []);
  return useFirebaseProviderSignin(googleProvider, next);
};

export const useAppleSignIn = (next: string): (() => Promise<void>) => {
  const appleProvider = React.useMemo(() => {
    const provider = new OAuthProvider('apple.com');
    // always prompt users for which google account to login with
    provider.setCustomParameters({ prompt: 'select_account' });
    return provider;
  }, []);
  return useFirebaseProviderSignin(appleProvider, next);
};

export function useDemoSignIn(next: string) {
  const client = useDemoApi();
  return React.useCallback(
    async (key: string, password: string) => {
      const auth = getAuth();
      const token = await client.getDemoToken({ key, password });
      const { user } = await signInWithCustomToken(auth, token);
      updateProfile(user, { displayName: 'Demo' });
      // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Location'.
      window.location = next;
    },
    [client, next],
  );
}

export function resetPassword(email: string) {
  const auth = getAuth();

  return sendPasswordResetEmail(auth, email);
}

export function useResetPassword() {
  const [sent, setSent] = React.useState(false);
  const [error, setError] = React.useState<FirebaseError | undefined>();
  const [loading, setLoading] = useState(false);

  const submitResetPassword = useCallback(async (email: string) => {
    setLoading(true);
    try {
      await resetPassword(email);
      setSent(true);
    } catch (err: any) {
      console.error(err);
      setError(err);
    }
    setLoading(false);
  }, []);

  return { submitResetPassword, sent, error, loading };
}

export const useSignOut = (): (() => Promise<void>) =>
  useCallback(async () => {
    await getAuth().signOut();
    window.location.href = urls.signin;
  }, []);

export const useAcceptInvite = (): {
  acceptInvite: (email: string, password: string) => Promise<void>;
  clearEmailError: () => void;
  emailError: string;
  initialEmail: any;
  isInvite: any;
  loading: boolean;
} => {
  const navigateURL = useNavigateURL();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<string>('');
  const clearEmailError = useCallback(() => setEmailError(''), []);

  const initialEmail = window.localStorage.getItem('InviteEmail') || '';
  const acceptInvite = useCallback(
    async (email: string, password: string) => {
      const auth = getAuth();
      setLoading(true);
      let credential;
      try {
        credential = await signInWithEmailLink(auth, email, window.location.href);
      } catch (err: any) {
        console.error(err);
        setEmailError(err.message);
        setLoading(false);
        return;
      }

      try {
        await updatePassword(credential.user, password);
      } catch (err: any) {
        setEmailError('Unexpected error. Please try again');
      }
      setLoading(false);
      window.localStorage.removeItem('InviteEmail');
      navigateURL('welcome');
    },
    [navigateURL],
  );

  const isInvite = React.useMemo(() => isSignInWithEmailLink(getAuth(), window.location.href), []);
  return {
    acceptInvite,
    emailError,
    loading,
    isInvite,
    clearEmailError,
    initialEmail,
  };
};
