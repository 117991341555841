import React from 'react';

import { get } from 'lodash-es';

import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import RedirectToTeam from 'plantiga-component/Welcome/RedirectToTeam';

import TeamContext, { useTeamContext } from './TeamContext';

type Props = {
  children: React.ReactNode;
  teamId?: string;
};

export default function TeamProvider({ teamId: specificTeamId, children }: Props) {
  const { teamId: urlTeamId } = useParams();
  const teamId = specificTeamId ?? urlTeamId;
  const { context, error, waitForTeam } = useTeamContext(teamId);

  const { loading } = context;
  const wait = (loading == null ? true : loading) || waitForTeam;

  if (error != null && get(error, 'code') === 'permission-denied') {
    return <RedirectToTeam permissionDenied />;
  }

  return (
    <Wait waitingOn={wait} error={error} info="Organization">
      <TeamContext.Provider value={context}>{children}</TeamContext.Provider>
    </Wait>
  );
}
