import React from 'react';

import ConfirmDialog from 'plantiga-common/ConfirmDialog';

import { deleteActivity } from '../../Firestore/Activity/actions';
import type { Activity } from '../../Firestore/Activity/typedefs';
import useTeam from '../../Firestore/Team/useTeam';
import { useToast } from '../../Toast/UseToast';

type Props = {
  readonly activity: Activity;
  readonly activityId: string;
  readonly open: boolean;
  readonly onCancel: any;
  readonly onDelete?: () => void;
};

export default function DeleteActivityDialog({
  activity,
  activityId,
  open,
  onCancel,
  onDelete,
}: Props) {
  const postToast = useToast();
  const { teamId } = useTeam();

  const handleDeleteActivity = React.useCallback(async () => {
    try {
      const result = await deleteActivity({ teamId, activityId });
      if (result.error) {
        throw new Error(result.error);
      }
      postToast({
        message: `Successfully deleted activity ${activity.name || ''}`,
        variant: 'success',
      });
      onDelete?.();
    } catch (error: any) {
      console.warn(error); // eslint-disable-line no-console
      postToast({
        message: `Could not delete activity ${activity.name || ''}`,
        variant: 'error',
      });
    }
  }, [activity, activityId, postToast, onDelete, teamId]);

  return (
    <ConfirmDialog
      open={open}
      confirmationText="delete"
      color="secondary"
      message="Once the activity has been deleted, it cannot be undone."
      onClose={onCancel}
      onConfirm={handleDeleteActivity}
    />
  );
}
