import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { FilePresent } from '@mui/icons-material';
import { Avatar, CircularProgress, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { useGenerateCSVFile } from '../../Firestore/Storage/useFiles';
import useTeam from '../../Firestore/Team/useTeam';

type Props = {
  readonly athleteId: string;
  readonly activityId: string;
  readonly files: Array<any>;
  readonly refreshFiles: any;
};

const useStyles = makeStyles()({
  progress: {
    position: 'absolute',
  },
});

export default function CSVListItem({ athleteId, activityId, files, refreshFiles }: Props) {
  const { teamId } = useTeam();
  const { classes } = useStyles();
  const { generate, status } = useGenerateCSVFile(
    teamId,
    athleteId,
    activityId,
    files,
    refreshFiles,
  );

  if (status === 'created') {
    return null;
  }
  const loading = status === 'loading';
  return (
    <ListItem button={!loading} onClick={loading ? null : generate}>
      <ListItemAvatar>
        <Avatar>
          {loading ? (
            <CircularProgress size={40} variant="indeterminate" className={classes.progress} />
          ) : null}
          <FilePresent />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary="Generate CSV" />
    </ListItem>
  );
}
