import React from 'react';

import { get, size } from 'lodash-es';

import { Delete as DeleteIcon } from '@mui/icons-material';
import { IconButton, TextField, Tooltip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import makeStyles from 'plantiga-util/MUI/makeStyles';

import { getLabelValueSuggestions } from './schema';

const useStyles = makeStyles()((theme) => ({
  labelRow: {
    display: 'flex',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textField: {
    flexGrow: 1,
    flexBasis: '50%',
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  readonly suggestions?: Array<string>;
  readonly isNew: boolean;
  readonly labelKey: string;
  readonly labelValue: string;
  readonly keyError: string | null | undefined;
  readonly labelError?: Array<any>;
  readonly onRemoveLabel: any;
  readonly onKeyChange: (key: string) => void;
  readonly onValueChange: (value: string) => void;
  readonly reserved?: boolean;
  readonly required?: boolean;
};

const EMPTY_ARRAY: string[] = [];

export default function CustomLabelField({
  suggestions: keySuggestions = EMPTY_ARRAY,
  isNew,
  labelKey,
  labelValue,
  keyError,
  labelError,
  onRemoveLabel,
  onKeyChange,
  onValueChange,
  reserved = false,
  required = false,
}: Props) {
  const { classes } = useStyles();
  const [autoKey, setAutoKey] = React.useState<string | null>(labelKey);
  const [autoValue, setAutoValue] = React.useState<string | null>(labelValue);
  const haveError = !!(labelError && size(labelError));
  const helperText = haveError
    ? get(labelError, '0.schema.description') || get(labelError, '0.message')
    : '';

  const valueSuggestions = getLabelValueSuggestions(labelKey);

  return (
    <Tooltip title={reserved ? 'This label is reserved' : ''} placement="bottom-start">
      <div className={classes.labelRow}>
        <Autocomplete
          freeSolo
          autoHighlight
          // @ts-expect-error - TS2322 - Type '{ freeSolo: true; autoHighlight: true; required: boolean; disabled: boolean; className: any; options: string[]; getOptionLabel: (option: string) => string; value: string; onChange: (_: ChangeEvent<{}>, v: string | null) => void; inputValue: string; onInputChange: (_: ChangeEvent<...>, value: string) => any; renderIn...' is not assignable to type 'IntrinsicAttributes & AutocompleteProps<string, undefined, undefined, true>'.
          required={required}
          disabled={reserved || required}
          className={classes.textField}
          options={keySuggestions}
          getOptionLabel={(option) => option}
          value={autoKey}
          onChange={(_, v) => setAutoKey(v)}
          inputValue={labelKey}
          onInputChange={(_, v) => onKeyChange(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={!!keyError}
              helperText={keyError || ''}
              label="key"
              variant="outlined"
            />
          )}
        />
        <Autocomplete
          freeSolo
          disabled={reserved}
          className={classes.textField}
          value={autoValue}
          inputValue={labelValue}
          onChange={(_, v) => setAutoValue(v)}
          onInputChange={(_, v) => onValueChange(v)}
          options={valueSuggestions}
          renderInput={(params) => (
            <TextField
              {...params}
              error={haveError}
              helperText={helperText}
              label="value"
              variant="outlined"
            />
          )}
        />
        <div>
          <IconButton
            id="removeLabelButton"
            disabled={(reserved || required) && !isNew}
            onClick={onRemoveLabel}
            size="large"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </Tooltip>
  );
}
