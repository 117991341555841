import React from 'react';

import { SvgIcon } from '@mui/material';

function QuarterbackIcon({ color }: { color?: React.ComponentProps<typeof SvgIcon>['color'] }) {
  return (
    <SvgIcon color={color} viewBox="200 100 400 400">
      <path d="M565.86,233.19c.27,3.78.5,7,.72,10-32.85,19.41-65.34,13.52-97.49-2-8.92-4.31-10.75-3.29-12.79,6.54-3.34,16.15-7.48,32.14-11.25,48.2-1.71,7.25,3.5,11,8.48,13.18,16,7,27.56,17.84,35,33.75,3.37,7.2,8.73,13.48,13.27,20.12,5.05,7.4,5.55,14.43,2.47,23.43-6,17.58-10.46,35.76-14.67,53.9-3,13,2.36,22.92,14,29.57,4.88,2.79,9.36,6.25,15.73,10.56-3.85,1.17-5.56,2.16-7.26,2.14-13.79-.2-27.58-.57-41.37-.91-6.9-.16-9.77-3.81-9-10.53a19.19,19.19,0,0,1,.78-5.08c8.43-20.74,7.29-42.87,9.67-64.5.63-5.65,2.67-11.14,3.5-16.78.95-6.35-1.58-10.61-7.75-13.7-14.12-7.06-28-14.59-41.64-22.61-6.78-4-12.43-4.2-17.76,1.51-13.48,14.45-27.29,28.63-40,43.73-8.41,10-16.85,18-31.09,16.23a12,12,0,0,0-6.55,1.59c-29.18,15.34-54.93,34.56-71.8,63.8-3.47,6-12,8.78-17.78,6s-7.67-9.72-4.21-17.13,6.63-15.4,11.25-22.22c2.5-3.71,7.85-5.43,11.78-8.24,10-7.1,19.81-14.34,29.75-21.47,11.58-8.31,23.36-16.35,34.76-24.89,7-5.24,14.81-10.19,19.88-17C358.74,351.2,371,330.9,376,306.84c1.75-8.45,6.82-16.22,10.35-24.31,2.4-5.49,6-10.84,6.76-16.55.66-5.08-1.57-10.68-3.19-15.84-.82-2.61-4-4.55-4.6-7.16-4.65-19.5-22.39-26.76-35.1-38.91-13-12.39-24-26.8-36.12-40.12-2.65-2.91-5.91-6-9.49-7.21-16.57-5.52-20-16.17-9.07-30,11.1-14.06,37.57-18.53,52.82-8.91,6.93,4.38,9.55,12.28,4.11,19.24s-13.21,12.23-20,18.16c-1.19,1-2.76,1.64-5.27,3.09,8.16,6.76,15.51,13.07,23.14,19a9.41,9.41,0,0,0,6.3,1.65c10.15-1.25,20.26-2.87,30.37-4.43,1.6-.25,3.15-.85,5.79-1.58-1.49-3.89-2.87-7.21-4.05-10.61-4.48-13-5.27-26.11,5.48-36.1,11-10.26,25-11.25,38.75-5.93,5.2,2,9.9,5.88,14.11,9.7,4,3.63,6.31,8.07,1.29,13.34-3.83,4-2.57,8,3,8.37s6.92,2.78,6.93,7.51c0,5.37,2.56,8.58,7.56,11.24,10.63,5.64,15.19,17.79,9.73,27.93-6.85,12.73-2.22,20.93,9.9,26.28,14.62,6.45,30.28,10.6,45.67,15.14,2.19.65,5.76-1.59,8-3.35C547.63,230,556.57,230.48,565.86,233.19ZM443.41,163.56l1.48,3.15,11.15,1-1.4-10.22Z" />
    </SvgIcon>
  );
}

QuarterbackIcon.defaultProps = {
  color: undefined,
};

export default QuarterbackIcon;
