import * as React from 'react';

import { makeStyles } from 'tss-react/mui';

import { ClickAwayListener, TextField } from '@mui/material';

import useBreakpoint from 'plantiga-common/useBreakpoint';

const useStyles = makeStyles()((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  input: {
    height: 24,
  },
}));

type Props = {
  readonly name: string;
  readonly size?: 'medium' | 'small' | undefined;
  readonly placeholder?: string;
  readonly endAdornment?: React.ReactNode;
  readonly onChange: (search: string) => void;
};

export default function SearchBar({
  name,
  size = 'small',
  placeholder = 'type here',
  endAdornment = null,
  onChange,
}: Props) {
  const { classes } = useStyles();
  const inputRef = React.useRef<HTMLElement | null>(null);
  const isMobile = useBreakpoint();

  const handleClick = () => {
    if (inputRef.current == null) return;

    inputRef.current.blur();
  };
  return (
    <ClickAwayListener touchEvent="onTouchStart" onClickAway={handleClick}>
      <TextField
        fullWidth
        autoFocus={!isMobile}
        size={size}
        placeholder={placeholder}
        inputRef={inputRef}
        InputProps={{
          name,
          autoComplete: 'off',
          endAdornment,
          classes: { root: classes.root, input: classes.input },
        }}
        type="search"
        variant="outlined"
        margin="none"
        onChange={(e) => onChange(e.target.value)}
      />
    </ClickAwayListener>
  );
}
