import { getAnalytics, setUserId } from 'firebase/analytics';
import { type User, getAuth, type ParsedToken } from 'firebase/auth';
import React from 'react';

import * as Sentry from '@sentry/react';

// Generate the data for the user context
export function useUserContext(): {
  currentUser: User | null | undefined;
  error: Error | null | undefined;
  userClaims: ParsedToken;
} {
  const [currentUser, setCurrentuser] = React.useState<User | null>();
  const [error, setError] = React.useState<Error | null | undefined>(null);
  const [userClaims, setUserClaims] = React.useState<ParsedToken>({});

  React.useEffect(() => {
    const analytics = getAnalytics();
    const auth = getAuth();
    let canceled = false;
    const setCurrentuserGA = async (user: User | null) => {
      if (canceled) return;
      setCurrentuser(user);
      if (user == null) {
        setUserId(analytics, '');
        Sentry.configureScope((scope) => scope.setUser(null));
        setUserClaims({});
      } else {
        setUserId(analytics, user.uid);
        Sentry.configureScope((scope) =>
          scope.setUser({
            id: user.uid,
            email: user.email ?? undefined,
          }),
        );

        const token = await user.getIdTokenResult(false);
        if (auth.currentUser === user) {
          setUserClaims(token.claims);
        }
      }
    };
    auth.onAuthStateChanged(setCurrentuserGA, setError);

    return () => {
      canceled = true;
    };
  }, []);

  return { currentUser, error, userClaims };
}
