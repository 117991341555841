import React from 'react';

import { makeStyles } from 'tss-react/mui';

// Informative blog post on why this is necessary for ios devices
// https://medium.com/@susiekim9/how-to-compensate-for-the-ios-viewport-unit-bug-46e78d54af0d

const useStyles = makeStyles()(() => ({
  flexGrowContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = {
  readonly children?: React.ReactNode;
  readonly className?: string;
  readonly variant?: 'minHeight' | 'height' | 'maxHeight';
};

export const use100vh = (): any => {
  const [height, setHeight] = React.useState(window.innerHeight);

  React.useLayoutEffect(() => {
    const getViewportSize = () => {
      if (window) {
        setHeight(window.innerHeight);
      }
    };

    getViewportSize();
    window.addEventListener('resize', getViewportSize);

    return () => {
      window.removeEventListener('resize', getViewportSize);
    };
  }, []);

  return height;
};

const Div100vh = React.forwardRef<HTMLDivElement, Props>(
  ({ className, children = null, variant = 'minHeight' }: Props, ref) => {
    const { classes } = useStyles();
    const height = use100vh();

    const style = React.useMemo(() => {
      const newStyle: Record<string, any> = {};
      newStyle[variant] = height;
      return newStyle;
    }, [variant, height]);

    return (
      <div ref={ref} style={style} className={className || classes.flexGrowContainer}>
        {children}
      </div>
    );
  },
);

export default Div100vh;
