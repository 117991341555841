import React from 'react';
import { FixedSizeList } from 'react-window';

import { List } from '@mui/material';

const ITEM_SIZE = 48; // mui default ListItem height

const renderRow = ({ data, index, style }: any) => React.cloneElement(data[index], { style });

const OuterElementContext = React.createContext({});

// OuterElementContext provides required props from Mui to work within Autocomplete
// https://mui.com/material-ui/react-autocomplete/#virtualization
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

type Props = {
  children?: React.ReactNode;
  height?: number;
  itemSize?: number;
  padding?: number;
  overscanCount?: number;
  className?: string;
};

// Adapter for react-window
const VirtualList = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      height,
      itemSize = ITEM_SIZE,
      overscanCount = 20,
      padding = 0,
      className,
      ...other
    }: Props,
    ref: any,
  ) => {
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize + padding * 2;
      }
      return itemCount * itemSize + padding * 2;
    };

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            useIsScrolling
            itemData={itemData}
            height={height == null ? getHeight() : height}
            width="100%"
            outerElementType={OuterElementType}
            innerElementType={List}
            overscanCount={overscanCount}
            itemSize={itemSize}
            itemCount={itemCount}
            className={className}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

export default VirtualList;
