import React, { useState, useMemo, useCallback } from 'react';

import { size, sortBy, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import {
  Button,
  DialogContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
  TextField,
} from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import VirtualList from 'plantiga-common/VirtualList';
import { PodVis } from 'plantiga-component/Teams/Devices/PodVis';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import { useDevices } from 'plantiga-firebase/Devices';
import usePair from 'plantiga-firebase/Devices/usePair';

import AthleteAvatar from '../Athletes/Avatar';

const useStyles = makeStyles()((theme) => ({
  content: {
    [theme.breakpoints.up('md')]: {
      width: '50em',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    width: '50vw',
  },
  pods: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
  },
}));

type Props = {
  readonly podIds: string[];
  readonly onClose: () => void;
  readonly open: boolean;
};

export function PairPodsToAthleteDialog({ open, onClose, podIds }: Props) {
  const { classes } = useStyles();
  const athletes = useAthletes();
  const [selectedAthleteId, setSelectedAthleteId] = useState(null);
  const [searchedAthlete, setSearchedAthlete] = useState('');
  const handleClose = useCallback(() => {
    onClose();
    setSelectedAthleteId(null);
    setSearchedAthlete('');
  }, [onClose]);

  const { loading, pair } = usePair();
  const devices = useDevices();

  const sortedAthletes = sortBy(toPairs(athletes), (v) => v[1].name);
  const filteredAthletes = sortedAthletes.filter(([athleteId, athlete]: [any, any]) => {
    const athleteName = athlete.name.toLowerCase();
    return athleteName.includes(searchedAthlete.toLowerCase()) || selectedAthleteId === athleteId;
  });

  const handlePair = useCallback(async () => {
    if (selectedAthleteId) {
      await pair(podIds, selectedAthleteId);
      handleClose();
    }
  }, [podIds, handleClose, pair, selectedAthleteId]);

  const actionButtons = useMemo(
    () => [
      <Button key="dialog-button-1" onClick={handleClose}>
        Cancel
      </Button>,
      <ButtonWithLoading
        key="dialog-button-2"
        color="primary"
        variant="outlined"
        loading={loading}
        disabled={selectedAthleteId == null}
        onClick={handlePair}
      >
        Pair
      </ButtonWithLoading>,
    ],
    [handleClose, handlePair, loading, selectedAthleteId],
  );

  return (
    <Dialog
      open={open}
      title="Pair Pods"
      onClose={handleClose}
      actionButtons={actionButtons}
      maxWidth="lg"
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent className={classes.content}>
        <div className={classes.pods}>
          {podIds.map((podId) => (podId in devices ? <PodVis podId={podId} /> : null))}
        </div>
        <TextField
          fullWidth
          placeholder="Search People"
          type="search"
          margin="dense"
          variant="outlined"
          onChange={(e) => setSearchedAthlete(e.target.value)}
          helperText="Select someone to pair these pods to"
        />
        {size(podIds) ? (
          <VirtualList>
            {filteredAthletes.map(([athleteId, athlete]: [any, any]) => (
              <ListItem
                key={athleteId}
                button
                selected={athleteId === selectedAthleteId}
                onClick={() => setSelectedAthleteId(athleteId)}
                disabled={athlete.secondary_team}
              >
                <ListItemAvatar>
                  <AthleteAvatar athlete={athlete} />
                </ListItemAvatar>
                <ListItemText primary={athlete.name} />
                <Radio
                  key={athleteId}
                  value={athleteId}
                  checked={athleteId === selectedAthleteId}
                />
              </ListItem>
            ))}
          </VirtualList>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
