import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Apple, Google } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { useGoogleSignIn, useAppleSignIn } from 'plantiga-firebase/Auth/signIn';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

type Props = {
  readonly next: string;
};

function AppleLogin({ next }: Props) {
  const appleLogin = useAppleSignIn(next);
  return (
    <IconButton onClick={appleLogin} size="large">
      <Apple />
    </IconButton>
  );
}

function GoogleLogin({ next }: Props) {
  const googleSignIn = useGoogleSignIn(next);
  return (
    <IconButton onClick={googleSignIn} size="large">
      <Google />
    </IconButton>
  );
}

export default function SocialLogin({ next }: Props) {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <GoogleLogin next={next} />
      <AppleLogin next={next} />
    </div>
  );
}
