import React from 'react';
import { Redirect } from 'react-router-dom';

import { keys, size } from 'lodash-es';

import { useLoadTeams } from '../Firestore/Team/useTeam';

import { useParams } from './react-router-hooks';
import Wait from './Wait';

const PAGE_MAP = {
  team: 'profile',
  athletes: 'people',
  activity: 'activity',
  measure: 'measure',
} as const;

const makeNewURL = (newTeamId: string, page: any, rest: any) => {
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly team: "profile"; readonly athletes: "people"; readonly activity: "activity"; readonly measure: "measure"; }'.
  const pageName = PAGE_MAP[page];
  let to = `/org/${newTeamId}/${pageName}`;
  if (rest != null) {
    to += `/${rest}`;
  }
  return to;
};

export default function TeamRedirect() {
  const { teams, loading, error } = useLoadTeams();
  const { page, rest } = useParams();

  const currentTeamId = localStorage.getItem('currentTeamId');
  if (currentTeamId) {
    const to = makeNewURL(currentTeamId, page, rest);
    return <Redirect to={to} />;
  }

  let to;
  if (size(teams) === 0) {
    to = '/';
  } else {
    const teamId = keys(teams)[0];
    to = makeNewURL(teamId, page, rest);
  }

  return (
    <Wait waitingOn={loading} error={error} info="Teams">
      <Redirect to={to} />
    </Wait>
  );
}
