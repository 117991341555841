import React from 'react';

import { useParams } from 'plantiga-common/react-router-hooks';
import useTeam from 'plantiga-firebase/Team/useTeam';

import ListActivityFiles from '../Storage/ListActivityFiles';

export default function FilesView() {
  const { teamId } = useTeam();
  const { activityId, athleteId } = useParams();
  return <ListActivityFiles teamId={teamId} activityId={activityId} athleteId={athleteId} />;
}
