import React from 'react';

import WaitForUserSetup from 'plantiga-component/Firestore/Auth/WaitForUserSetup';
import CoachProvider from 'plantiga-component/Firestore/Coach/CoachProvider';
import Loading from 'plantiga-component/Loading';
import { UserProvider } from 'plantiga-firebase/Auth/UserProvider';

type Props = {
  children: React.ReactNode;
};

export default function Authenticated({ children }: Props) {
  return (
    <UserProvider>
      <CoachProvider>
        <WaitForUserSetup>
          <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
        </WaitForUserSetup>
      </CoachProvider>
    </UserProvider>
  );
}
