import React from 'react';

import { SvgIcon } from '@mui/material';

function SmallCircleIcon(props: React.ComponentProps<typeof SvgIcon>) {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="4" />
    </SvgIcon>
  );
}

export default SmallCircleIcon;
