import React, { useState } from 'react';

import { makeStyles } from 'tss-react/mui';

import { Button, Grid, Typography } from '@mui/material';

import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

import DeleteAthleteDialog from './DeleteAthleteDialog';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
}));

type Props = {
  readonly athlete: Athlete;
  readonly athleteId: string;
};

export default function DeleteAthlete({ athlete, athleteId }: Props) {
  const [requestedDelete, setRequestedDelete] = useState(false);
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="subtitle1">Delete this person</Typography>
          <Typography variant="subtitle2">
            Once you delete a person, the action cannot be undone.
          </Typography>
        </Grid>
        <Grid item xs={4} container direction="row" justifyContent="flex-end" alignItems="center">
          {/* TODO: add an common error button component in plantiga-common */}
          <Button
            variant="contained"
            classes={{ root: classes.errorButton }}
            disabled={requestedDelete}
            onClick={() => setRequestedDelete(true)}
          >
            Delete this person
          </Button>
        </Grid>
      </Grid>

      <DeleteAthleteDialog
        athlete={athlete}
        athleteId={athleteId}
        open={requestedDelete}
        onCancel={() => setRequestedDelete(false)}
      />
    </div>
  );
}
