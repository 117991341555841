import React from 'react';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useParams } from 'plantiga-common/react-router-hooks';

import CustomAthleteLabels from './CustomAthleteLabels';

export default function LabelsView() {
  const { athleteId } = useParams();

  return (
    <ContentContainer id="aui_profile_labels">
      <CustomAthleteLabels athleteId={athleteId} />
    </ContentContainer>
  );
}
