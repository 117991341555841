import React from 'react';

import { alpha, useTheme } from '@mui/material';
import { Axis } from '@visx/axis';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';

const DEFAULT_MARGIN = { top: 20, left: 20, bottom: 20, right: 20 };

export function MetricIQRVis({
  width,
  formatValue,
  innerBarHeight = 10,
  outerBarHeight = 20,
  margin = DEFAULT_MARGIN,
  showMedian = false,
  empty = false,
  ...stats
}: {
  showMedian?: boolean;
  value?: number;
  min?: number;
  firstQuartile?: number;
  median?: number;
  thirdQuartile?: number;
  max?: number;
  empty?: boolean;
  width: number;
  formatValue: (v: number) => string;
  innerBarHeight?: number;
  outerBarHeight?: number;
  margin?: typeof DEFAULT_MARGIN;
}) {
  const theme = useTheme();
  const innerHeight = outerBarHeight;
  const height = innerHeight + margin.top + margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  const [value, min, firstQuartile, median, thirdQuartile, max] = [
    stats.value,
    stats.min,
    stats.firstQuartile,
    stats.median,
    stats.thirdQuartile,
    // if the min and max are the same
    // make the max different so we can show something
    stats.max === stats.min ? (stats.min ?? 0) + 1 : stats.max,
  ].map((v) => v ?? 0);

  const xScale = scaleLinear<number>({
    range: [0, innerWidth],
    domain: [min, max],
  });

  return (
    <svg height={height} width={width}>
      <Group top={margin.top} left={margin.left} width={innerWidth} height={innerHeight}>
        <rect
          y={(innerHeight - innerBarHeight) / 2}
          x={0}
          width={xScale(max) - xScale(min)}
          height={innerBarHeight}
          stroke={theme.palette.plantiga.lightCharcoal}
          fill={theme.palette.background.default}
          rx={3}
        />
        {!empty && (
          <rect
            y={(innerHeight - outerBarHeight) / 2}
            x={xScale(firstQuartile)}
            width={xScale(thirdQuartile) - xScale(firstQuartile)}
            height={outerBarHeight}
            strokeWidth={2}
            stroke={theme.palette.location.achiral}
            fill={alpha(theme.palette.location.achiral, 0.5)}
            rx={3}
          />
        )}
        {!empty && showMedian && (
          <line
            x1={xScale(median)}
            x2={xScale(median)}
            y1={margin.top}
            y2={margin.top - outerBarHeight}
            stroke={theme.palette.background.default}
          />
        )}
        {!empty && (
          <circle
            r={innerBarHeight / 2}
            cx={xScale(value)}
            cy={innerHeight / 2}
            strokeWidth={2}
            stroke={theme.palette.location.achiral}
            fill={theme.palette.background.default}
          />
        )}
      </Group>
      {!empty && (
        <Axis
          hideAxisLine
          hideTicks
          top={margin.top + outerBarHeight - 8}
          left={margin.left}
          scale={xScale}
          tickValues={[min, max]}
          tickFormat={(v) => formatValue(v.valueOf())}
        />
      )}
    </svg>
  );
}
