import React from 'react';

import { mapValues, pickBy } from 'lodash-es';

import useAsync from 'plantiga-common/useAsync';
import { useToast } from 'plantiga-component/Toast/UseToast';
import useTeam from 'plantiga-firebase/Team/useTeam';
import { UserError } from 'plantiga-util/apiClient/OrgApiClient';
import useOrgApi from 'plantiga-util/apiClient/useOrgApi';

import { ActivityTypeDefn, type ActivityTypeDefns } from './ActivityTypeDefn';
import { ActivityTypesContext } from './ActivityTypesContext';

export default function useActivityTypes(options: { enabled?: boolean } = {}): ActivityTypeDefns {
  const { enabled } = { enabled: true, ...options };
  const { activityTypes } = React.useContext(ActivityTypesContext);

  return React.useMemo(() => {
    const activityTypesFiltered = pickBy(activityTypes, (v) => (enabled ? v.enabled : true));
    return mapValues(activityTypesFiltered, (_, id) => new ActivityTypeDefn(id, activityTypes));
  }, [activityTypes, enabled]);
}

export function useActivityType(activityType?: string | null): ActivityTypeDefn | undefined {
  const activityTypes = useActivityTypes({ enabled: false });
  return activityType ? activityTypes[activityType] : undefined;
}

export function useUpdateActivityType() {
  const client = useOrgApi();
  const postToast = useToast();
  const { getData, loading, error } = useAsync(client.updateActivityType);

  React.useEffect(() => {
    if (error instanceof UserError) {
      postToast({ message: error.message, variant: 'error' });
    }
  }, [error, postToast]);

  return { updateActivityType: getData, loading, error };
}

export function useCreateActivityType() {
  const client = useOrgApi();
  const { getData, loading, error } = useAsync(client.createActivityType);
  return { createActivityType: getData, loading, error };
}

export function useActivityTypesLoading(): {
  error: Error | null | undefined;
  loading: boolean;
  initialized: boolean;
} {
  const { initialized, loading, error } = React.useContext(ActivityTypesContext);
  return { initialized, loading, error };
}

export function useReloadActivityTypes() {
  const { reload, loading } = React.useContext(ActivityTypesContext);
  return { reload, loading };
}

export function useAutoDetectActivityType() {
  const client = useOrgApi();
  const { teamId } = useTeam();
  const { data, getData, loading, error } = useAsync(client.getAutoDetectActivityType, teamId);

  const activityType = useActivityType(data?.type_id);
  return { activityType, reload: getData, loading, error };
}

export function useSetAutoDetectActivityType() {
  const client = useOrgApi();
  const postToast = useToast();
  const { getData, loading, error } = useAsync(client.setAutoDetectActivityType);

  React.useEffect(() => {
    if (error instanceof UserError) {
      postToast({ message: error.message, variant: 'error' });
    }
  }, [error, postToast]);

  return { set: getData, loading, error };
}
