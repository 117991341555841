import React from 'react';

import { Button, DialogContent, TextField, Typography } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';

import Dialog from './Dialog';

type Props = {
  readonly open: boolean;
  readonly color?: 'primary' | 'secondary';
  readonly title?: string;
  readonly confirmationText?: string;
  readonly message?: null | string;
  readonly helperText?: null | string;
  readonly onClose: () => void;
  readonly onConfirm: () => Promise<void> | undefined;
  [key: string]: any;
};

/**
 * A dialog requiring the user to input a specified value to proceed.
 * All extra props are forwarded to the Dialog component
 */
export default function ConfirmDialog({
  open,
  color = 'primary',
  title = 'Are you sure?',
  confirmationText = 'confirm',
  helperText = null,
  message = null,
  onClose,
  onConfirm,
  ...props
}: Props) {
  const [value, setValue] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = React.useCallback(async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  }, [onConfirm]);

  const actionButtons = React.useMemo(
    () => [
      <Button key="confirm_cancel" onClick={onClose}>
        Cancel
      </Button>,
      <ButtonWithLoading
        key="confirm"
        disabled={confirmationText !== value}
        variant="contained"
        color={color}
        loading={loading}
        onClick={handleConfirm}
      >
        Confirm
      </ButtonWithLoading>,
    ],
    [color, onClose, value, confirmationText, handleConfirm, loading],
  );

  return (
    <Dialog
      {...props}
      open={open}
      color={color}
      title={title}
      actionButtons={actionButtons}
      onClose={onClose}
    >
      <DialogContent>
        {message ? (
          <Typography variant="body1" paragraph>
            {message}
          </Typography>
        ) : null}
        <TextField
          value={value}
          required
          fullWidth
          placeholder={confirmationText}
          label={confirmationText}
          helperText={helperText || `Type '${confirmationText}' to confirm`}
          variant="outlined"
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
    </Dialog>
  );
}
