import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { AddLocation as MarkIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

import type { Stopwatch } from '../../Firestore/Stopwatch/typedefs';

import MarkDialog from './MarkDialog';

const useStyles = makeStyles()({
  fab: {
    backgroundColor: '#000',
    '&:hover': {
      backgroundColor: '#333',
    },
  },
  icon: {
    color: '#FFF',
  },
});

type Props = {
  readonly swid: string;
  readonly stopwatch: Stopwatch;
};

export default function MarkButton({ swid, stopwatch }: Props) {
  const { classes } = useStyles();

  const [markTime, setMarkTime] = React.useState<Date | null | undefined>(null);

  return (
    <>
      <Fab size="medium" className={classes.fab} onClick={() => setMarkTime(new Date())}>
        <MarkIcon className={classes.icon} />
      </Fab>
      <MarkDialog
        onClose={() => setMarkTime(null)}
        markTime={markTime}
        swid={swid}
        stopwatch={stopwatch}
      />
    </>
  );
}
