import React from 'react';

import { memoize } from 'lodash-es';

import ActivitySummariesClient from '@plantiga/activity-summaries';

import { getToken } from 'plantiga-firebase/useToken';

import getProject from './getProject';

// a client singleton allows us to use the client methods as keys for react-swr
const getClient = memoize(
  (baseURL?: string) => new ActivitySummariesClient({ getToken, baseURL, project: getProject() }),
);

export default function useActivitySummariesClient(baseURL?: string) {
  return React.useMemo(() => getClient(baseURL), [baseURL]);
}
