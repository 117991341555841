import React from 'react';

import Wait from 'plantiga-common/Wait';

import FeatureFlagsContext from './FeatureFlagsContext';

export function WaitForFeatureFlags({ children }: React.PropsWithChildren<{}>) {
  const { initialized, error } = React.useContext(FeatureFlagsContext);
  return (
    <Wait waitingOn={!initialized} error={error} info="FeatureFlags">
      {children}
    </Wait>
  );
}
