import React from 'react';

import Fuse from 'fuse.js';
import { makeStyles } from 'tss-react/mui';

import { Visibility as ShowIcon, VisibilityOff as HideIcon } from '@mui/icons-material';
import { Checkbox, List, ListItem, ListItemText } from '@mui/material';
import { ParentSize } from '@visx/responsive';

import VirtualList from 'plantiga-common/VirtualList';
import Wait from 'plantiga-common/Wait';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import type { Athlete } from 'plantiga-firebase/schema';
import useTeam from 'plantiga-firebase/Team/useTeam';

import { Header, AthleteListItem } from './AthleteListItem';
import { useTeamDashboard } from './useTeamDashboard';

const MemoListItem = React.memo(AthleteListItem);

const useStyles = makeStyles()((theme) => ({
  list: {
    padding: 0,
  },
  virtualList: {
    paddingTop: theme.spacing(2),
  },
}));

const SORT_OPTIONS = {
  keys: ['name', 'id'],
  threshold: 0.2,
};

const searchAthletes =
  (search: string) =>
  (athletes: Athlete[]): Athlete[] => {
    if (search.length === 0) return athletes;
    const fuseSearch = new Fuse(athletes, SORT_OPTIONS);
    return fuseSearch.search(search).map((v) => v.item);
  };

type Props = { date: Date; searchQuery: string };

export function TeamDashboard({ date, searchQuery }: Props) {
  const { classes } = useStyles();
  const { teamId } = useTeam();
  const [showArchived, setShowArchived] = React.useState(false);
  const athletes = useAthletes({ withArchived: showArchived });

  const { data, datums, isLoading, initializing, error } = useTeamDashboard(date, showArchived);

  const athleteSet = new Set(
    searchAthletes(searchQuery)?.(Object.values(athletes))?.map((v) => v.id),
  );

  return (
    <>
      <List className={classes.list} dense>
        <Header />
      </List>
      <Wait waitingOn={initializing} error={error} info="team-dashboard-initialization">
        <ParentSize>
          {(parent) => (
            <VirtualList height={parent.height} itemSize={80} className={classes.virtualList}>
              {data
                ?.filter((v) => athleteSet.has(v.athleteId))
                .map((v) => (
                  <MemoListItem
                    key={v.athleteId}
                    athleteId={v.athleteId}
                    teamId={teamId}
                    date={date}
                    data={datums}
                    isLoading={v.data !== null && isLoading}
                  />
                ))}
              {athleteSet.size === 0 && (
                <ListItem>
                  <ListItemText>
                    <b>No one matches that name</b>
                  </ListItemText>
                </ListItem>
              )}
              <ListItem button onClick={() => setShowArchived((prev) => !prev)}>
                <ListItemText>{showArchived ? 'Hide' : 'Show'} Archived</ListItemText>
                <Checkbox checked={showArchived} icon={<HideIcon />} checkedIcon={<ShowIcon />} />
              </ListItem>
            </VirtualList>
          )}
        </ParentSize>
      </Wait>
    </>
  );
}
