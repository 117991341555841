import React from 'react';

import { formatISO, isValid, parseISO } from 'date-fns';
import { isEmpty, debounce } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { useLocalStorage } from 'usehooks-ts';

import { FormControlLabel, Switch } from '@mui/material';

import ContentContainer from 'plantiga-common/ContentContainer';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';
import { useQueryState } from 'plantiga-common/react-router-hooks';
import SearchBar from 'plantiga-common/SearchBar';
import Wait from 'plantiga-common/Wait';
import CreatePersonButton from 'plantiga-component/Athletes/CreatePersonButton';
import { ActivityDatePicker } from 'plantiga-component/Daily/ActivityDatePicker';
import GettingStartedAlert from 'plantiga-component/Dashboard/GettingStartedAlert';
import PersonList from 'plantiga-component/Dashboard/PersonList';
import { TeamDashboard } from 'plantiga-component/Dashboard/TeamDashboard';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import { useDevicesLoading } from 'plantiga-firebase/Devices/useDevices';
import useFeatureFlag from 'plantiga-firebase/FeatureFlags/useFeatureFlag';

const useStyles = makeStyles()((theme) => ({
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
    '& > *': {
      background: theme.palette.background.default,
    },
    '& > *:first-child': {
      flex: 1,
    },
  },
}));

export default function PeopleView() {
  const { classes } = useStyles();
  const athletes = useAthletes({ withArchived: true });
  const [searchTerm, setSearchTerm] = React.useState('');
  const { loading: loadingDevices, error: devicesError } = useDevicesLoading();
  const teamDashboardFF = useFeatureFlag('team_dashboard');
  const [checked, setChecked] = useLocalStorage('plantiga-team-dashboard-view', false);
  const [query, setQuery] = useQueryState();

  const date = React.useMemo(() => (query.date ? parseISO(query.date) : new Date()), [query.date]);
  const handleDateChange = React.useCallback(
    (d: Date | null) => {
      if (d == null || !isValid(d)) return;
      setQuery((p) => ({ ...p, date: formatISO(d, { representation: 'date' }) }));
    },
    [setQuery],
  );

  return (
    <Wait waitingOn={loadingDevices} info="Devices" error={devicesError}>
      <ContentContainer>
        <PageGutterPortal gutter="top">
          <SearchBar
            placeholder="Search People"
            name="search_people"
            onChange={debounce((value) => setSearchTerm(value), 200)}
          />
          <div className={classes.actions}>
            <CreatePersonButton />
            {checked && (
              <ActivityDatePicker
                value={date}
                onChange={handleDateChange}
                slotProps={{ textField: { fullWidth: false } }}
              />
            )}
            {teamDashboardFF && (
              <FormControlLabel
                labelPlacement="bottom"
                control={
                  <Switch
                    color="primary"
                    size="small"
                    checked={checked}
                    onChange={() => setChecked((p) => !p)}
                  />
                }
                label="Team View"
              />
            )}
          </div>
        </PageGutterPortal>
        <GettingStartedAlert />
        {!isEmpty(athletes) && !checked && (
          <PersonList athletes={athletes} searchTerm={searchTerm} />
        )}
        {checked && <TeamDashboard date={date} searchQuery={searchTerm} />}
      </ContentContainer>
    </Wait>
  );
}
