/**
 * Wait for users to be fully setup. This means they have a team, coach, and athlete where the
 * athlete_id matches the firebase.auth().currentUser.id.
 */

import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { CircularProgress, Typography } from '@mui/material';

import timeoutImg from 'plantiga-assets/timeout-512.png';
import ErrorCard from 'plantiga-component/Error/ErrorCard';
import ErrorPage from 'plantiga-component/Error/ErrorPage';
import SignInView from 'plantiga-component/SignIn/View';

import { useCoach } from '../Coach';

import useUser from './useUser';
import useUserSetupStatus from './useUserSetupStatus';

const useStyles = makeStyles()(() => ({
  fullPage: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

type Props = {
  children: React.ReactNode;
};

function ContactUs() {
  return (
    <Typography>
      If you believe to be receiving this message in error please contact us at&nbsp;
      <a href="mailto:support@plantiga.com?subject=Account%20has%20no%20associated%20email">
        support@plantiga.com
      </a>
      .
    </Typography>
  );
}

function NoEmailAssociated() {
  return (
    <ErrorPage>
      <ErrorCard
        title="We could not associate an email with your account"
        message={
          <>
            <Typography paragraph>
              This is likely due to signing up with a facebook account without a primary email.
              <b>&nbsp;Please create a new account with a valid email association.</b>
            </Typography>
            <ContactUs />
          </>
        }
      />
    </ErrorPage>
  );
}

function SetupLoader() {
  const { classes } = useStyles();
  return (
    <div className={classes.fullPage}>
      <Typography variant="h5" gutterBottom align="center">
        Please wait while we set up your account
      </Typography>
      <CircularProgress />
    </div>
  );
}

function SetupTimeout() {
  return (
    <ErrorPage>
      <ErrorCard
        img={timeoutImg}
        title="Set up is taking longer than expected"
        message={
          <>
            <Typography paragraph>Please try again later.</Typography>
            <ContactUs />
          </>
        }
      />
    </ErrorPage>
  );
}

export default function WaitForUserSetup({ children }: Props) {
  const user = useUser();
  const { coach } = useCoach();
  const status = useUserSetupStatus();

  if (coach?.demo) {
    return <>{children}</>;
  }

  if (status === 'UNAUTHORIZED' || status === 'INVALID_TOKEN') {
    return <SignInView />;
  }
  if (status === 'NO_EMAIL' || (user != null && user.email == null)) {
    return <NoEmailAssociated />;
  }
  if (status === 'SETTING_UP') {
    return <SetupLoader />;
  }
  if (status === 'TIMEOUT') {
    return <SetupTimeout />;
  }
  if (status === 'READY') {
    return <>{children}</>;
  }
  // status === 'INITIALIZING'
  if (coach != null) {
    // We haven't received feedback from the API that we are setup, but we
    // have loaded enough that we expect a "READY" status.
    return <>{children}</>;
  }
  return null;
}
