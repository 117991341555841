import React from 'react';

import { size, map } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { IconButton, TextField, MenuItem } from '@mui/material';

import usePreventScrollOnFocus from 'plantiga-common/usePreventScrollOnFocus';
import type { Activities, Activity } from 'plantiga-firebase/Activity/typedefs';

import ActivityItem from './ActivityItem';

type Props = {
  activities: Activities;
  selectedActivity: (Activity & { activity_id?: string }) | null | undefined;
  setSelectedActivity: (activityId: string) => void;
};

const useStyles = makeStyles()((theme) => ({
  select: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  iconComponent: {
    position: 'absolute',
    right: theme.spacing(2),
    pointerEvents: 'none', // use default interaction
  },
}));

function IconComponent() {
  const { classes } = useStyles();
  return (
    <IconButton
      className={classes.iconComponent}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => e.preventDefault}
      size="small"
    >
      <ExpandMoreIcon />
    </IconButton>
  );
}

export default function ActivityPicker({
  activities,
  selectedActivity,
  setSelectedActivity,
}: Props) {
  const { classes } = useStyles();
  const preventScrollOnFocus = usePreventScrollOnFocus();

  const renderValue = React.useCallback(
    (activityId: unknown) =>
      (
        <ActivityItem
          disablePadding
          disableGutters
          activityId={activityId as string}
          activity={activities[activityId as string]}
          showCompare={false}
          showValue={false}
        />
      ) as React.ReactNode,
    [activities],
  );

  const value = selectedActivity?.activity_id ?? '';
  const disabled = size(activities) <= 1;
  return (
    <TextField
      select
      fullWidth
      size="small"
      variant="outlined"
      value={value}
      onChange={(e) => setSelectedActivity(e.target.value)}
      SelectProps={{
        native: false,
        displayEmpty: true,
        renderValue,
        IconComponent: disabled ? () => null : IconComponent,
        onFocus: preventScrollOnFocus,
        MenuProps: {
          onFocus: preventScrollOnFocus,
        },
        classes: { root: classes.select },
      }}
    >
      {map(activities, (activity, activityId) => (
        <MenuItem
          component={ActivityItem as React.ElementType}
          key={activityId}
          value={activityId}
          activityId={activityId}
          activity={activity}
          listItemProps={{ selected: activity === selectedActivity, dense: true }}
        />
      ))}
    </TextField>
  );
}
