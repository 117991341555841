export function formatDuration(start: Date | undefined, end: Date | undefined) {
  if (start === undefined || end === undefined) return '?';

  const msBetween = +end - +start;
  const d = new Date(msBetween);

  if (d.getUTCHours() > 24) return 'over 24h';
  if (d.getUTCHours() > 0) return `${d.getUTCHours()}h ${d.getUTCMinutes()}m`;
  if (d.getUTCMinutes()) return `${d.getUTCMinutes()}m ${d.getUTCSeconds()}s`;
  return `${d.getUTCSeconds()}s`;
}
