import { onSnapshot, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React from 'react';

import { fromPairs } from 'lodash-es';

import { collection } from '..';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';

import type { Activities } from './typedefs';

export default function useActivitiesInDateRange(athleteId: string | null, start: Date, end: Date) {
  const [activities, setActivities] = React.useState<Activities>({});
  const [loading, setLoading] = React.useState(true);
  const [initialized, setInitialized] = React.useState(false);
  const [error, setError] = React.useState<Error | null | undefined>(null);
  const db = useFirestore();
  const { teamId } = useTeam();
  // Use reference-equality comparable integers instead of complex `Date` objects.
  const startTime = start.getTime();
  const endTime = end.getTime();

  React.useEffect(() => {
    let didCancel = false;
    setLoading(true);
    setActivities({});
    let unsubscribe = () => {};

    const ref = athleteId
      ? query(
          collection(db, 'teams', teamId, 'assessments'),
          where('athlete_id', '==', athleteId),
          orderBy('start', 'desc'),
          where('start', '>=', Timestamp.fromMillis(startTime)),
          where('start', '<=', Timestamp.fromMillis(endTime)),
        )
      : query(
          collection(db, 'teams', teamId, 'assessments'),
          orderBy('start', 'desc'),
          where('start', '>=', Timestamp.fromMillis(startTime)),
          where('start', '<=', Timestamp.fromMillis(endTime)),
        );

    unsubscribe = onSnapshot(
      ref,
      (snaps) => {
        if (didCancel) return;
        setActivities(
          fromPairs(snaps.docs.map((snap) => [snap.id, { ...snap.data(), activity_id: snap.id }])),
        );
        setLoading(false);
        setInitialized(true);
      },

      (err) => setError(err),
    );
    return () => {
      didCancel = true;
      unsubscribe();
    };
  }, [athleteId, db, startTime, endTime, teamId]);
  return { activities, initialized, loading, error };
}
