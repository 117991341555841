export const root = '/org/:teamId/devices';

const deprecated = {
  teamDevices: '/org/:teamId/pods',
};

export default {
  ...deprecated,
  teamPods: `${root}/pods`,
  teamDocks: `${root}/docks`,
};
