import React from 'react';

import { keys, pick, pickBy, reduce, mapValues } from 'lodash-es';

import { useFeatureFlags } from 'plantiga-firebase/FeatureFlags/useFeatureFlag';

import TEMPLATES from './mc_activity_report_templates.yml';
import type { ReportSectionDefinition, MCActivityReportTemplate } from './typedefs';

function omitMissingFeatures(
  template: MCActivityReportTemplate,
  featureFlags: ReturnType<typeof useFeatureFlags>,
) {
  return {
    ...template,
    sections: template.sections.filter((section) =>
      section.feature_flag ? featureFlags[section.feature_flag] ?? featureFlags.default : true,
    ),
  };
}

export const useTemplates = () => {
  const featureFlags = useFeatureFlags();
  const templates = TEMPLATES as {
    default: MCActivityReportTemplate;
    [activityType: string]: MCActivityReportTemplate;
  };

  return React.useMemo(() => {
    // omit feature flagged templates
    const viewableTemplates = pickBy(
      templates,
      (template) =>
        template.feature_flag == null ||
        featureFlags[template.feature_flag] === true ||
        featureFlags.default,
    );
    // omit feature flagged sections
    return mapValues(viewableTemplates, (template) => omitMissingFeatures(template, featureFlags));
  }, [templates, featureFlags]);
};

function reportTemplateActivityTypes(template?: MCActivityReportTemplate | null): Set<string> {
  const types = new Set<string>();
  if (template == null) return types;
  if (template.activity_type != null) {
    types.add(template.activity_type);
    return types;
  }
  template.sections.forEach(({ metrics }) =>
    metrics.forEach(({ activity_type: activityType }) => types.add(activityType)),
  );
  return types;
}

export default function useMCActivityReportTemplate(activityType: string) {
  const templates = useTemplates();
  return React.useMemo(() => {
    const template = templates?.[activityType];
    if (template) return template;

    // Update each section metric definition of the default report template with the activity type
    return {
      ...templates.default,
      sections: templates.default.sections.reduce(
        (sections: ReportSectionDefinition[], section) => {
          const metrics = section.metrics.map((v) =>
            v.activity_type ? v : { ...v, activity_type: activityType },
          );
          sections.push({ ...section, metrics });
          return sections;
        },
        [],
      ),
    };
  }, [templates, activityType]);
}

export function useMCMultiActivityReportTemplates(activityTypes: Set<string>) {
  const templates = useTemplates();
  return React.useMemo(() => {
    // get the set of unique activity types found in each template
    const templateActivityTypes = reduce(
      templates,
      (m, template, key: string) => ({ ...m, [key]: reportTemplateActivityTypes(template) }),
      {},
    );
    // select the templates with two or more matching activity types
    const templateIds = keys(
      pickBy(
        templateActivityTypes as {
          [key: string]: Set<string>;
        },
        (v: Set<string>) => v.size > 1 && [...v].filter((vv) => activityTypes?.has(vv)).length > 1,
      ),
    );
    return pick(templates, templateIds);
  }, [templates, activityTypes]);
}

export function useMCActivityReportTemplateById(
  templateId: string,
): MCActivityReportTemplate | undefined {
  return useTemplates()[templateId];
}
