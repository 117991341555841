import React from 'react';

import { Typography } from '@mui/material';

import ContentContainer from 'plantiga-common/ContentContainer';
import CustomerCard from 'plantiga-common/CustomerCard';

import ErrorPage from '../Error/ErrorPage';
import useUser from '../Firestore/Auth/useUser';

type Props = {
  readonly status: 'pending' | 'wait-listed';
};

export default function RequestNotAcceptedView({ status }: Props) {
  const { email } = useUser() ?? {};

  const titleStatus =
    status === 'wait-listed' ? 'Your request has been waitlisted' : 'Your request is underway';

  const paragraphs =
    status === 'wait-listed'
      ? [
          <Typography variant="body1">
            Thank you for filling out our survey! Since you are interested in Plantiga for personal
            use, we will be adding you to our waitlist. At this time we are working exclusively with
            clinics, teams, and businesses.
          </Typography>,
          <Typography variant="body1">
            We will add you to our mailing list so that you can keep up to date with all things
            Plantiga. We will let you know as soon as possible when we relaunch Plantiga for
            individual use.
          </Typography>,
          <Typography variant="body1">
            If you are part of a clinic, business or team please contact us at{' '}
            <strong>sales@plantiga.com</strong> and we would be happy to discuss further.
          </Typography>,
        ]
      : [
          <Typography>
            We are currently processing your request to access Plantiga. We will send an email to{' '}
            <strong>{email}</strong> soon.
          </Typography>,
        ];

  return (
    <ErrorPage>
      <ContentContainer>
        <CustomerCard title={titleStatus} paragraphs={paragraphs} />
      </ContentContainer>
    </ErrorPage>
  );
}
