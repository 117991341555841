import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Tooltip, Typography } from '@mui/material';

import { useField, formatFieldName } from 'plantiga-common/Fields';
import { getTeamPreferences } from 'plantiga-firebase/Team/preferences';
import useTeam from 'plantiga-firebase/Team/useTeam';

import MetricInfo from './MetricInfo';

const useStyles = makeStyles()(() => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  name: {
    textTransform: 'capitalize',
  },
}));

type Props = {
  fieldId: string;
  activityType: string;
  typographyProps?: React.ComponentProps<typeof Typography>;
  formatOptions?: Parameters<typeof formatFieldName>[2];
  nameOverride?: string;
  hideInfo?: boolean;
} & Omit<React.ComponentProps<typeof MetricInfo>, 'metrics'>;

export default function MetricName({
  fieldId,
  activityType,
  iconProps,
  formatOptions,
  typographyProps = { noWrap: true },
  nameOverride = undefined,
  hideInfo = false,
}: Props) {
  const { classes } = useStyles();
  const field = useField(fieldId);
  const { team } = useTeam();
  const { measures } = getTeamPreferences(team);
  const name = formatFieldName(field, measures.unitType, formatOptions);
  const fullName = formatFieldName(field, measures.unitType);

  return (
    <Tooltip title={nameOverride ?? fullName}>
      <div className={classes.container}>
        <Typography {...typographyProps} className={classes.name}>
          {nameOverride ?? name}
        </Typography>
        {hideInfo ? null : (
          <div style={{ paddingLeft: 8 }}>
            <MetricInfo
              metrics={[
                { activity_type: activityType, split_activity_type: null, field_id: fieldId },
              ]}
              iconProps={iconProps}
            />
          </div>
        )}
      </div>
    </Tooltip>
  );
}
