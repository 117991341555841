import { format as formatDate } from 'date-fns';
import { groupBy, mapValues, uniq, values } from 'lodash-es';

// return an object with {[day]: listOfTypes, ... }
export function activitiesDateAndTypeMap(
  activities: any,
  dateFormat: string = 'P',
): {
  [key: string]: Array<string>;
} {
  const groupedByDate = groupBy(values(activities), (activity) =>
    formatDate(activity.start.toDate(), dateFormat),
  );
  return mapValues(groupedByDate, (acts) =>
    uniq(acts.map((a) => a?.labels?.type_id ?? a?.labels?.type ?? 'open')),
  );
}
