import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { useIsMounted } from 'usehooks-ts';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import useCoach from 'plantiga-firebase/Coach/useCoach';
import useDismissAlert from 'plantiga-firebase/Coach/useDismissAlert';

const useStyles = makeStyles()((theme) => ({
  alert: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexWrap: 'wrap',
  },
}));

type Props = {
  children: React.ReactNode;
  dismissId: string;
  title: string;
  severity?: 'info' | 'warning';
  actions?: React.ReactNode;
};

export default function DismissableAlert({
  children,
  dismissId,
  title,
  severity = 'info',
  actions = null,
}: Props) {
  const { classes } = useStyles();
  const { loading: loadingCoach } = useCoach();
  const { dismissedAlerts, dismissAlert, loading: dismissing } = useDismissAlert();
  const [hide, setHide] = React.useState(false);
  const mounted = useIsMounted();

  const handleDismiss = React.useCallback(async () => {
    if (dismissId == null) return;

    await dismissAlert(dismissId);
    if (!mounted()) return;
    setHide(true);
  }, [dismissId, dismissAlert, mounted]);

  const action = React.useMemo(
    () => (
      <>
        {actions}
        <ButtonWithLoading loading={dismissing} disabled={dismissing} onClick={handleDismiss}>
          Dismiss
        </ButtonWithLoading>
      </>
    ),
    [actions, dismissing, handleDismiss],
  );

  const dismissed = dismissedAlerts?.[dismissId] ?? false;
  if (hide === true || loadingCoach || dismissed === true) {
    return null;
  }

  return (
    <Alert severity={severity} action={action} className={classes.alert}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </Alert>
  );
}
