import React from 'react';

import Fuse from 'fuse.js';
import { filter, sortBy, values } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Visibility as ShowIcon, VisibilityOff as HideIcon } from '@mui/icons-material';
import { Checkbox, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';

import useResize from 'plantiga-common/useResize';
import VirtualList from 'plantiga-common/VirtualList';
import type { Athletes, Athlete } from 'plantiga-firebase/Athletes/typedefs';

import { PersonListItem } from './PersonListItem';

const MemoPersonListItem = React.memo(PersonListItem);

const useStyles = makeStyles()((theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  readonly athletes: Athletes;
  readonly searchTerm: string;
};

const ATHLETE_SORT_OPTIONS = {
  keys: ['name'],
  threshold: 0.2,
};

function searchAthletes(athletes: Array<any>, searchTerm: string): Set<string> {
  if (searchTerm.length === 0) {
    return new Set(athletes.map((athlete) => athlete.id));
  }
  const fuseSearch = new Fuse(athletes, ATHLETE_SORT_OPTIONS);
  return new Set(fuseSearch.search(searchTerm).map((result) => result.item.id));
}

export default function PersonList({ athletes, searchTerm }: Props) {
  const { classes } = useStyles();

  const [archived, setArchived] = React.useState(false);
  const searchedAthletes = React.useMemo(
    () => searchAthletes(values(athletes), searchTerm),
    [athletes, searchTerm],
  );

  const sortedAthletes = React.useMemo((): Athlete[] => {
    const filteredAthletes = filter(athletes, (athlete) => searchedAthletes.has(athlete.id));

    return sortBy(
      filteredAthletes.map((v) => ({ ...v, archived: !!v.archived })),
      ['archived', 'name'],
    );
  }, [athletes, searchedAthletes]);

  const items = React.useMemo(
    () => sortedAthletes.filter((v) => archived || !v.archived).map((v) => v.id),
    [sortedAthletes, archived],
  );

  const [rect, setRect] = React.useState(new DOMRect());
  const { ref } = useResize<HTMLDivElement>((el) => setRect(el.getBoundingClientRect()));

  return (
    <div className={classes.container} ref={ref}>
      <VirtualList
        height={rect.height}
        itemSize={items.length === 0 ? 56 : 56 + 49}
        key={items.length}
      >
        {items.map((athleteId) => (
          <div key={athleteId}>
            <MemoPersonListItem athleteId={athleteId} />
          </div>
        ))}
        {items.length === 0 && (
          <div>
            <ListItem>
              <ListItemText>
                <b>No one matches that name</b>
              </ListItemText>
            </ListItem>
          </div>
        )}
        <div>
          <ListItem button onClick={() => setArchived((prev) => !prev)}>
            <ListItemText>{archived ? 'Hide' : 'Show'} Archived</ListItemText>
            <ListItemSecondaryAction>
              <Checkbox checked={archived} icon={<HideIcon />} checkedIcon={<ShowIcon />} />
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      </VirtualList>
    </div>
  );
}
