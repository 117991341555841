import React from 'react';

import { scaleLinear } from 'd3';

import { useTheme } from '@mui/material';

/* metadata for questions */
export function useMetadataForQuestions() {
  const theme = useTheme();
  const { quality } = theme.palette.plantiga;
  return React.useMemo(
    () => ({
      'questions:plantiga_body_scan': {
        colorScale: scaleLinear<string>()
          .domain([1, 4, 5, 7, 10])
          .range([quality.high, quality.high, quality.medium, quality.low, quality.low])
          .clamp(true),
        labels: [
          'Worst Ever',
          'Very bad',
          'Very bad',
          'Bad',
          'Poor',
          'Ok',
          'Good',
          'Good',
          'Very Good',
          'Best Ever',
        ],
      },
      'questions:plantiga_energy_level': {
        colorScale: scaleLinear<string>()
          .domain([1, 4, 6, 8, 10])
          .range([quality.high, quality.high, quality.medium, quality.low, quality.low])
          .clamp(true),
        labels: [
          'Worst Ever',
          'Very Low',
          'Very Low',
          'Low',
          'Moderate',
          'Moderate',
          'Ok',
          'Good',
          'Very Good',
          'Best Ever',
        ],
      },
      'questions:plantiga_rpe': {
        colorScale: scaleLinear<string>()
          .domain([1, 3, 4, 5, 6, 7, 10])
          .range([
            '#bbdefb',
            '#bbdefb',
            quality.low,
            quality.low,
            quality.medium,
            quality.medium,
            quality.high,
          ])
          .clamp(true),
        labels: [
          'Very Easy',
          'Easy',
          'Easy',
          'Moderate',
          'Moderate',
          'Hard',
          'Hard',
          'Very Hard',
          'Very Hard',
          'Max Effort',
        ],
      },
    }),
    [quality],
  );
}

const isKey = <O extends object>(key: string | number | symbol, o: O): key is keyof O => key in o;

export function useQuestionMeta(fieldId: string) {
  const meta = useMetadataForQuestions();

  return isKey(fieldId, meta) ? meta[fieldId] : undefined;
}
