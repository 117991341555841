import { getDocs, limit, orderBy, query, Timestamp, where } from 'firebase/firestore';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { formatISO } from 'date-fns';
import { compact, fromPairs } from 'lodash-es';
import useSWR from 'swr';

import { Typography } from '@mui/material';

import useBuildURL from 'plantiga-common/useBuildURL';
import { useToast } from 'plantiga-component/Toast/UseToast';
import useActivityTypes from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import useRecordApi from 'plantiga-util/apiClient/useRecordApi';
import makeStyles from 'plantiga-util/MUI/makeStyles';
import { getCurrentTimezone } from 'plantiga-util/timezone';

import { collection, doc } from '..';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import useSubscribe, { useSubscribeDocument } from '../useSubscribe';

import { updateActivity } from './actions';
import type { Activity, EditableActivityFields } from './typedefs';

export default function useActivity(activityId: string | undefined) {
  const { teamId } = useTeam();
  const db = useFirestore();
  const ref = React.useMemo(
    () => (activityId ? doc(db, `teams`, teamId, 'assessments', activityId) : null),
    [activityId, db, teamId],
  );

  const [activity, loading, error] = useSubscribeDocument(ref, { deepCompare: true });
  return {
    activity,
    loading,
    error,
  };
}

export function useActivitySplits(activityId: string) {
  const { teamId } = useTeam();
  const db = useFirestore();

  const fetcher = async (args: { key: string; teamId: string; activityId: string }) => {
    const ref = query(
      collection(db, 'teams', args.teamId, 'assessments'),
      where('labels.source_activity', '==', args.activityId),
    );
    const snaps = await getDocs(ref);
    return snaps.docs.reduce((p, v) => {
      Object.assign(p, { [v.id]: v.data() });
      return p;
    }, {} as { [activityId: string]: ReturnType<(typeof snaps)['docs'][0]['data']> });
  };

  return useSWR({ key: 'teams/assessments', teamId, activityId }, fetcher);
}

function queryLatestActivities(
  db: ReturnType<typeof useFirestore>,
  teamId: string,
  athleteId: string,
  options?: { before?: Date | Timestamp; activityType?: string; limit?: number },
) {
  return query(
    collection(db, 'teams', teamId, 'assessments'),
    ...compact([
      where('athlete_id', '==', athleteId),
      orderBy('start', 'desc'),
      limit(options?.limit ?? 1),
      options?.before ? where('start', '<', options.before) : null,
      options?.activityType ? where('labels.type', '==', options.activityType) : null,
    ]),
  );
}

/**
 * Fetch the most recent activity.
 *
 * @param {string} athleteId - the id of the user/athlete
 * @param {*} [options]
 * @param {*} [options.before] - get the latest activity before this time
 * @param {*} [options.activityType] - get the latest activity of this type
 * @param {*} [options.limit=1] - get `limit` number of most recent activities
 */
export function useLatestActivity(
  athleteId: string | undefined,
  options?: {
    before?: Date | Timestamp;
    activityType?: string;
    limit?: number;
  },
): {
  activity: Activity | null | undefined;
  activityId: string;
  error: Error | null | undefined;
  loading: boolean;
} {
  const { teamId } = useTeam();
  const db = useFirestore();
  const q = React.useMemo(
    () => (athleteId == null ? null : queryLatestActivities(db, teamId, athleteId, options)),
    [db, teamId, athleteId, options?.before, options?.limit, options?.activityType],
  );
  const [teamData, loading, error] = useSubscribe(q);

  const activityId = Object.keys(teamData)[0];
  const activity = teamData ? teamData[activityId] : null;
  return React.useMemo(
    () => ({ activity, activityId, loading, error }),
    [activity, activityId, loading, error],
  );
}

export function useLatestActivities(
  athleteId: string,
  activityTypes: Array<string>,
  options?: {
    before?: Date;
    limit?: number;
  },
) {
  const [initialized, setInitialized] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null | undefined>(null);
  const [activities, setActivities] = React.useState<{
    [type: string]: { activityId: string; activity: Activity };
  }>({});
  const db = useFirestore();
  const { teamId } = useTeam();
  React.useEffect(() => {
    let isMounted = true;
    setLoading(true);
    const activityListPromise = Promise.all(
      activityTypes.map(
        async (activityType): Promise<[string, (typeof activities)[string]] | null> => {
          const q = queryLatestActivities(db, teamId, athleteId, {
            activityType,
            before: options?.before,
            limit: options?.limit,
          });
          const snaps = await getDocs(q);
          if (snaps.docs.length > 0) {
            const activity = { activityId: snaps.docs[0].id, activity: snaps.docs[0].data() };
            return [activityType, activity];
          }
          return null;
        },
      ),
    );
    activityListPromise
      .then((activityList) => {
        if (!isMounted) {
          return;
        }
        setActivities(fromPairs(compact(activityList)));
      })
      .catch((err) => {
        setError(err);
      })
      .then(() => {
        if (!isMounted) {
          return;
        }
        setLoading(false);
        setInitialized(true);
      });
    return () => {
      isMounted = false;
    };
  }, [athleteId, activityTypes, db, teamId, options?.before, options?.limit]);
  return {
    initialized,
    loading,
    activities,
    error,
  };
}

export function useUpdateActivity(activityId: string): {
  error: undefined;
  loading: boolean;
  update: (data: EditableActivityFields) => Promise<void>;
} {
  const { teamId } = useTeam();
  const { activity } = useActivity(activityId);
  const postToast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );

  const update = React.useCallback(
    async (data: EditableActivityFields) => {
      if (activity == null) return;
      setLoading(true);
      try {
        const formattedActivity = {
          ...activity,
          start: activity.start.toDate(),
          end: activity.end.toDate(),
          ...data,
        } as const;
        await updateActivity({
          teamId,
          activityId,
          activity: formattedActivity,
        });
      } catch (err: any) {
        console.error(err);
        if (unMounted.current) return;
        setError(err);
        postToast({ message: 'Could not update activity', variant: 'error' });
      }
      if (unMounted.current) return;
      setLoading(false);
    },
    [activityId, activity, teamId, postToast],
  );

  return { update, loading, error };
}

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: 'underline',
    color: theme.palette.primary.contrastText,
  },
}));

export function useCreateSplitActivity() {
  const { classes } = useStyles();
  const actTypes = useActivityTypes();
  const athletes = useAthletes();
  const recordApi = useRecordApi();
  const postToast = useToast();
  const buildURL = useBuildURL();
  const [loading, setLoading] = React.useState(false);

  const createSplitActivity = async (
    source: Activity,
    sourceId: string,
    activityTypeId: string,
    domain: readonly [Date, Date],
  ) => {
    const actType = actTypes[activityTypeId];
    const athlete = athletes[source.athlete_id];
    // TODO: toast? this should not be reachable, technically
    if (!actType || !athlete) return;

    setLoading(true);
    try {
      const activityData = {
        athlete_id: source.athlete_id,
        activity_type: activityTypeId,
        name: `${actType.name} - ${source.name ?? source.labels.type}`,
        // TODO: this serialization should be defined in the API client
        start: formatISO(domain[0]),
        end: formatISO(domain[1]),
        source: 'split',
        labels: {
          ...actType.defn.activity_labels,
          type_id: activityTypeId,
          split: 'true',
          source_activity: sourceId,
          // TODO: this is needed to avoid the API padding the activity with 3 seconds idle time
          'summary-start': '00:00:00.000',
        },
        timezone: source.timezone ?? athlete.timezone ?? getCurrentTimezone(),
        device_ids: source.device_ids,
      };
      const newActivityId = await recordApi.createActivity(activityData);
      postToast({
        message: (
          <>
            Created a new{' '}
            <RouterLink
              to={buildURL('athleteActivityExplore', {
                athleteId: source.athlete_id,
                activityId: newActivityId,
              })}
            >
              <Typography className={classes.link} component="span">
                activity
              </Typography>
            </RouterLink>
          </>
        ),
        variant: 'success',
        timeout: 25,
      });
    } catch {
      postToast({
        message: 'Failed to create new activity',
        variant: 'error',
        timeout: 5,
      });
    }

    setLoading(false);
  };

  return { loading, createSplitActivity };
}
