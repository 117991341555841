import React from 'react';

import { Checkbox, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import AthleteName from 'plantiga-common/AthleteName';
import { PodChip } from 'plantiga-component/Devices/PodChip';
import type { Device } from 'plantiga-firebase/Devices/typedefs';

type Props = {
  readonly deviceId: string;
  readonly device: Device;
  readonly values: Array<string>;
  readonly onChange: any;
};

export default function DeviceListItem({ device, deviceId, values, onChange }: Props) {
  const handleToggle = React.useCallback(() => {
    const currentIndex = values.indexOf(deviceId);
    const newChecked = [...values];

    if (currentIndex === -1) {
      newChecked.push(deviceId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onChange(newChecked);
  }, [deviceId, onChange, values]);

  const selected = values.indexOf(deviceId) !== -1;
  const athleteId = device.athlete_id === '?' ? null : device.athlete_id;

  return (
    <ListItem button onClick={handleToggle}>
      <ListItemIcon>
        <Checkbox onChange={handleToggle} checked={selected} />
      </ListItemIcon>
      <ListItemText
        secondary={
          <>
            {athleteId ? 'Paired to' : 'Unpaired'}&nbsp;
            {athleteId ? <AthleteName athleteId={athleteId} /> : ''}
          </>
        }
      >
        <PodChip podId={deviceId} />
      </ListItemText>
    </ListItem>
  );
}
