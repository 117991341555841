import React from 'react';
import { Redirect } from 'react-router-dom';

import { size } from 'lodash-es';

import useBreakpoint from 'plantiga-common/useBreakpoint';
import useBuildURL from 'plantiga-common/useBuildURL';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import { useIsIndividualUser } from 'plantiga-firebase/Auth/useUser';

export default function TeamLandingRedirect() {
  const isMobile = useBreakpoint();
  const isIndividualUser = useIsIndividualUser();
  const athletes = useAthletes();
  const buildURL = useBuildURL();

  if (isIndividualUser && size(athletes) === 1) {
    const athleteId = Object.keys(athletes)[0];
    return <Redirect to={buildURL('athlete', { athleteId })} />;
  }

  if (isMobile) {
    return <Redirect to={buildURL('record')} />;
  }

  return <Redirect to={buildURL('athletes')} />;
}
