import React from 'react';

import type { ScaleOrdinal } from 'd3';
import { makeStyles } from 'tss-react/mui';

import { Typography } from '@mui/material';
import { defaultStyles } from '@visx/tooltip';

import { useFields } from 'plantiga-common/Fields';
import { useUnitTranslator2 } from 'plantiga-firebase/Team/universalUnitTranslator';

type BaseDatum = { label: string; fieldId: string; value: number };

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    zIndex: theme.zIndex.tooltip,
    maxWidth: 240,
    position: 'absolute',
  },
  legend: {
    display: 'grid',
    columnGap: theme.spacing(1),
    gridTemplateColumns: '12px max-content',
    alignItems: 'center',
  },
}));

type Props = {
  top: number;
  width: number;
  height: number;
  athleteName: string;
  colorScale: ScaleOrdinal<string, string>;
  athleteDatum: BaseDatum | undefined;
  teamDatum: BaseDatum | undefined;
};

export function Tooltip({
  top,
  width,
  height,
  athleteName,
  colorScale,
  athleteDatum,
  teamDatum,
}: Props) {
  const { classes } = useStyles();

  const { translate } = useUnitTranslator2();
  const { getField } = useFields();

  const formatValue = (d: BaseDatum | undefined) => {
    if (d == null) return null;
    const field = getField(d.fieldId);
    return translate(d.value, field.storedUnit, field.displayUnit, { nDigits: 3 });
  };

  const athleteValue = athleteDatum?.value ?? 0;
  const teamValue = teamDatum?.value ?? 0;

  if (athleteDatum == null && teamDatum == null) return null;

  const style = {
    left: width / 2,
    top: height / 2,
    transform: top < height / 2 ? 'translate(-50%, 16px)' : 'translate(-50%, calc(-100% - 16px))',
  };

  return (
    <div className={classes.tooltip} style={{ ...defaultStyles, ...style }}>
      {athleteValue !== teamValue && (
        <>
          <Typography>
            <b>{athleteName}</b> is <b>{athleteValue > teamValue ? 'above' : 'below'}</b> the team
            average by{' '}
            <b>{Math.abs((100 * (athleteValue - teamValue)) / (teamValue || 1)).toPrecision(3)}%</b>
          </Typography>
          <br />
        </>
      )}
      <div className={classes.legend}>
        <svg width={8} height={8}>
          <circle r={4} cx={4} cy={4} fill={colorScale('athlete')} />
        </svg>
        <Typography>
          <b>{formatValue(athleteDatum)}</b>
        </Typography>
        <svg width={8} height={8}>
          <circle r={4} cx={4} cy={4} fill={colorScale('team')} />
        </svg>
        <Typography>
          <b>{formatValue(teamDatum)}</b>
        </Typography>
      </div>
    </div>
  );
}
