import React from 'react';

import { makeStyles } from 'tss-react/mui';

import Arc5Silhouette from 'plantiga-assets/arc-5-silhouette.svg?react';

const useStyles = makeStyles()((theme) => ({
  mask: {
    transition: theme.transitions.create(['bottom'], {
      duration: theme.transitions.duration.standard,
    }),
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    anchorEdge: 'left bottom',
  },
  container: {
    position: 'relative',
    height: '100%',
    transform: 'rotate(180deg)',
  },
  arc5Pod: {
    '&.solid': {
      '& .arc-5-silhouette_svg__outline': {
        fill: 'black',
        stroke: 'black',
      },
      '& .arc-5-silhouette_svg__pad': {
        fill: 'gold',
        stroke: 'black',
      },
      '& .arc-5-silhouette_svg__sticker': {
        fill: 'white',
        stroke: 'black',
      },
      '&.left .arc-5-silhouette_svg__sticker': {
        fill: theme.palette.plantiga.orange,
      },
      '&.right .arc-5-silhouette_svg__sticker': {
        fill: theme.palette.plantiga.green,
      },
    },
    '&.outline': {
      '& .arc-5-silhouette_svg__outline': {
        fill: 'white',
        stroke: 'black',
      },
      '& .arc-5-silhouette_svg__pad': {
        fill: 'white',
        stroke: 'black',
      },
      '& .arc-5-silhouette_svg__sticker': {
        fill: 'white',
        stroke: 'black',
      },
    },
  },
}));

export function Arc5Pod({
  location,
  progress: _progress = 100,
  jitter = 0,
}: {
  location: 'left' | 'right';
  progress?: number;
  jitter?: number;
}) {
  const { classes, cx } = useStyles();
  // clamp progress within 0 - 100
  const progress = Math.min(Math.max(_progress - jitter * Math.random(), 0), 100);

  return (
    <div className={classes.container}>
      <Arc5Silhouette className={cx(classes.arc5Pod, 'outline')} />
      <div className={classes.mask} style={{ bottom: `${100 - progress}%` }}>
        <Arc5Silhouette className={cx(classes.arc5Pod, 'solid', location)} />
      </div>
    </div>
  );
}
