import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { ContainerProvider } from './ContainerContext';
import { useFullWidthPage } from './FullWidthPage';
import useBreakpoint from './useBreakpoint';

const useStyles = makeStyles()((theme) => ({
  flexGrowContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    width: '100%',
    paddingBottom: `env(safe-area-inset-bottom)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
    backgroundColor: theme.palette.background.default,
  },
  sideGutter: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    paddingTop: theme.spacing(1),
  },
  centerColumn: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: theme.breakpoints.values.lg,
    maxWidth: theme.breakpoints.values.lg,
    minWidth: 0,
  },
  fullWidth: {
    flexBasis: '100%',
    maxWidth: '100%',
  },
  centerContent: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    overflowY: 'hidden',
    overflowX: 'auto', // required for fixed position elements to behave properly on mobile
    gap: theme.spacing(1),
  },
  gutter: {
    zIndex: theme.zIndex.appBar,
    position: 'sticky',
    top:
      (typeof theme.mixins.appBar.height === 'number' ? theme.mixins.appBar.height : 0) +
      parseInt(theme.spacing(1), 10),
    // Don't stretch pinned content
    flexGrow: 0,
    gap: theme.spacing(1),
    display: 'grid',
  },
  leftGutter: {
    '& > *': {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
        marginLeft: 0,
        position: 'static',
      },
    },
  },
  rightGutter: {
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  topGutter: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  bottomGutter: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    bottom: theme.spacing(1),
  },
}));

type Props = {
  readonly id?: string;
  readonly spacing?: number;
  readonly children: React.ReactNode;
};

export default function Container({ id, spacing = 0, children }: Props) {
  const { classes, cx, css, theme } = useStyles();

  const [fullWidth] = useFullWidthPage();
  const [leftGutterRef, setLeftGutterRef] = React.useState<HTMLDivElement | undefined>();
  const [rightGutterRef, setRightGutterRef] = React.useState<HTMLDivElement | undefined>();
  const [bottomRef, setBottomRef] = React.useState<HTMLDivElement | undefined>();
  const [topRef, setTopRef] = React.useState<HTMLDivElement | undefined>();

  const centerColumnClasses = cx(
    classes.centerColumn,
    classes.flexGrowContainer,
    fullWidth ? classes.fullWidth : undefined,
  );
  const centerContentClasses = cx(
    classes.centerContent,
    classes.flexGrowContainer,
    css({ gap: theme.spacing(spacing) }),
  );
  const leftGutterClasses = cx(classes.gutter, classes.leftGutter);
  const rightGutterClasses = cx(classes.gutter, classes.rightGutter);
  const topGutterClasses = cx(classes.gutter, classes.topGutter);
  const bottomGutterClasses = cx(classes.gutter, classes.bottomGutter);
  const hideGutters = useBreakpoint();

  return (
    <ContainerProvider
      leftGutterRef={leftGutterRef}
      rightGutterRef={rightGutterRef}
      topRef={topRef}
      bottomRef={bottomRef}
    >
      <div id={id} className={classes.flexRow}>
        <div className={classes.sideGutter}>
          {hideGutters || (
            <div
              className={leftGutterClasses}
              ref={setLeftGutterRef as React.RefCallback<HTMLDivElement>}
            />
          )}
        </div>
        <div className={centerColumnClasses}>
          <div className={topGutterClasses} ref={setTopRef as React.RefCallback<HTMLDivElement>} />
          <div className={centerContentClasses}>{children}</div>
          <div
            className={bottomGutterClasses}
            ref={setBottomRef as React.RefCallback<HTMLDivElement>}
          />
        </div>
        <div className={classes.sideGutter}>
          {hideGutters || (
            <div
              className={rightGutterClasses}
              ref={setRightGutterRef as React.RefCallback<HTMLDivElement>}
            />
          )}
        </div>
      </div>
    </ContainerProvider>
  );
}
