/* eslint-disable no-continue */
import React from 'react';

import { compact, startCase } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';

import { useLocation } from 'plantiga-common/react-router-hooks';
import useBreakpoint from 'plantiga-common/useBreakpoint';
import { getUrls, findPathMatch, type UrlParams } from 'plantiga-util/urls';

import { Crumb, PersonCrumb, ActivityCrumb, ReportTemplateCrumb } from './Crumbs';

const useStyles = makeStyles()(() => ({
  breadcrumbs: {
    '& li': {
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
    },
  },
}));

// check if the parameter has a custom breadcrumb component
function getDynamicPathParameterCrumb(
  paramKey: keyof UrlParams,
  params: UrlParams,
  url: string | undefined,
) {
  const param = params[paramKey];
  if (param == null) return null;

  switch (paramKey) {
    case 'athleteId':
      return <PersonCrumb key="person-crumb" athleteId={param} url={url} />;
    case 'activityId':
      return <ActivityCrumb key="activity-crumb" activityId={param} url={url} />;
    case 'day':
      return <Crumb key="day-crumb" text={param} url={url} />;
    case 'page':
      return <Crumb key={`page-crumb_${url}`} text={startCase(param)} url={url} />;
    case 'templateId':
      return <ReportTemplateCrumb key="templateId" templateId={param} url={url} />;
    default:
      return null;
  }
}

function getStaticPathFragmentCrumb(page: string, url: string | undefined) {
  switch (page) {
    case 'org':
    case 'activityType':
    case 'activity':
      return null;
    case 'activity-report':
      // show a disabled crumb for multi-activity reports
      return <Crumb key={url ?? page} text={startCase(page)} url={undefined} />;
    // eslint-disable-next-line no-fallthrough
    default:
      return <Crumb key={url ?? page} text={startCase(page)} url={url} />;
  }
}

export default function Breadcrumbs() {
  const { classes } = useStyles();
  const isMobile = useBreakpoint();
  const { location } = useLocation();

  const breadcrumbs = React.useMemo(() => {
    const match = findPathMatch(
      location.pathname,
      Object.keys(getUrls()) as (keyof ReturnType<typeof getUrls>)[],
    );
    if (!match) return [];

    const pathParts = compact(match.path.split('/'));
    const urlParts = compact(match.url.split('/'));

    const crumbs = [];
    // compose a list of breadcrumbs for each part of the url
    for (let i = 0; i < pathParts.length; i += 1) {
      const page = pathParts[i];
      if (!page) continue;
      // the last breadcrumb represents the current page and should not be a link
      const url = i === pathParts.length - 1 ? undefined : `/${urlParts.slice(0, i + 1).join('/')}`;
      // handle dynamic path parameters
      if (page.startsWith(':')) {
        // remove the leading ':' and trailing '?' characters from the dynamic url parameter
        const paramKey = (
          page.endsWith('?') ? page.slice(1, -1) : page.slice(1)
        ) as keyof UrlParams;
        crumbs.push(getDynamicPathParameterCrumb(paramKey, match.params, url));
      }
      // handle static path parameters
      else {
        crumbs.push(getStaticPathFragmentCrumb(page, url));
      }
    }
    return crumbs;
  }, [location.pathname]);

  return (
    <MuiBreadcrumbs
      className={classes.breadcrumbs}
      maxItems={isMobile ? 3 : 7}
      itemsBeforeCollapse={isMobile ? 0 : 1}
      itemsAfterCollapse={isMobile ? 2 : 4}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
}
