import React from 'react';

import { sortBy } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CallMerge as MergeIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import { useNavigateURL } from 'plantiga-common/useBuildURL';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import getDeviceLocationFromId from 'plantiga-util/getDeviceLocationFromId';

import { PairAthleteToPodsDialog } from './PairAthleteToPodsDialog';
import { PodChip } from './PodChip';

const useStyles = makeStyles()((theme) => ({
  chipContainer: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'all',
    gap: theme.spacing(1),
  },
  devices: {
    whiteSpace: 'nowrap',
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      display: 'none',
    },
  },
  showChipsMobile: {
    display: 'flex',
  },
}));

type Props = {
  readonly athleteId: string;
  readonly onStatusChange?: React.ComponentProps<typeof PodChip>['onStatusChange'];
  readonly hidePairButton?: boolean;
  readonly showChipsMobile?: boolean;
};

export function PairedPodChips({
  athleteId,
  onStatusChange,
  hidePairButton = false,
  showChipsMobile = false,
}: Props) {
  const { classes, cx } = useStyles();
  const devices = useDevices(athleteId);
  const navigateURL = useNavigateURL();
  const navDevices = React.useCallback(
    (ev: React.SyntheticEvent<SVGSVGElement>) => {
      navigateURL('teamPods', undefined, { athleteId });
      ev.stopPropagation();
    },
    [athleteId, navigateURL],
  );
  const [openPair, setOpenPair] = React.useState<any>(null);
  return (
    <Box className={classes.chipContainer} onClick={(e: Event) => e.stopPropagation()}>
      <div
        className={cx(classes.devices, {
          [classes.showChipsMobile]: showChipsMobile,
        })}
      >
        {sortBy(devices, (d) => getDeviceLocationFromId(d.id)).map((device) => (
          <PodChip
            key={device.id}
            podId={device.id}
            onClick={navDevices}
            onStatusChange={onStatusChange}
          />
        ))}
      </div>
      {!hidePairButton && (
        <>
          <IconButton
            aria-label="Pair Pods"
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              setOpenPair(athleteId);
            }}
          >
            <MergeIcon />
          </IconButton>
          <PairAthleteToPodsDialog
            athleteId={athleteId}
            open={openPair === athleteId}
            onClose={() => setOpenPair(null)}
          />
        </>
      )}
    </Box>
  );
}
