import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Card, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import { useEditAthlete } from 'plantiga-firebase/Athletes/actions';
import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

import EditChoiceRow from './EditChoiceRow';
import EditDateRow from './EditDateRow';
import EditRow from './EditRow';
import EditUnitRow from './EditUnitRow';

const useStyles = makeStyles()({
  content: {
    width: '100%',
  },
  title: {
    fontWeight: 'bold',
  },
});

type Props = {
  readonly athlete: Athlete;
  readonly athleteId: string;
};
export default function AthleteTable({ athleteId, athlete }: Props) {
  const athleteTimezone = athlete.timezone;

  const { classes } = useStyles();

  const { editAthlete } = useEditAthlete();

  return (
    <TableContainer component={Card} variant="outlined">
      <Table>
        <TableBody>
          <EditRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Name"
            field="name"
          />
          <EditRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Email"
            field="email"
          />
          <EditRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Notes"
            field="notes"
            multiline
          />
          <EditChoiceRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Gender"
            field="gender"
            options={[
              { name: 'Female', value: 'female' },
              { name: 'Male', value: 'male' },
              { name: 'Other', value: 'other' },
              { name: 'Prefer not to say', value: 'undisclosed' },
            ]}
          />
          <EditDateRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Date of Birth"
            field="date_of_birth"
          />
          <EditUnitRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Height"
            field="height"
            // TODO: the stored values are in either meters or centimeters, depending on the
            // preference of the team when the value was set.
            storedUnit={athlete.height != null && athlete.height > 3 ? 'cm' : 'm'}
            displayUnit={{
              imperial: 'ft/in',
              metric: 'cm',
              research: 'cm',
            }}
          />
          <EditUnitRow
            athleteId={athleteId}
            athlete={athlete}
            editAthlete={editAthlete}
            title="Weight"
            field="weight"
            storedUnit="kg"
            displayUnit={{
              imperial: 'lbs',
              metric: 'kg',
              research: 'kg',
            }}
          />
          <TableRow>
            <TableCell className={classes.title}>Timezone</TableCell>
            <TableCell className={classes.content}>
              {athleteTimezone ? athleteTimezone.name : '-'}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
