import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import Toast from './Toast';

/**
 * param {object}: attributes
 *  - message    (required):   string to appear in toast
 *  - variant    (optional):   'default' (grey), 'success' (green), 'warning' (yellow), 'error' (red),
 *                             defaults to 'default'
 *  - action     (optional):   function to be executed after timeout, defaults to null
 *  - undoAction (optional):   function to be executed if undo is called
 *  - timeout    (optional):   number of seconds before toast disappears, defaults to 10s
 */

type Params = Partial<React.ComponentProps<typeof Toast>>;
type Options = { preventDuplicate?: boolean };

// exported here for testing purposes
export function useToast(options?: Options): (arg1: Params) => void {
  const preventDuplicate = options?.preventDuplicate ?? true;
  const { enqueueSnackbar } = useSnackbar();

  const postToast = useCallback(
    ({
      message,
      action = null,
      undoAction = null,
      variant = 'default',
      timeout = 3,
      undoText = 'Undo',
    }: Params) => {
      enqueueSnackbar('', {
        preventDuplicate,
        autoHideDuration: timeout * 1000,
        content: (key) => (
          <Toast
            snackbarId={key}
            message={message}
            variant={variant}
            action={action}
            undoAction={undoAction}
            timeout={timeout}
            undoText={undoText}
          />
        ),
      });
    },
    [preventDuplicate, enqueueSnackbar],
  );

  return postToast;
}
