import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Chat as ChatIcon } from '@mui/icons-material';
import { Link, Card, CardContent, Popover, Typography, CardHeader } from '@mui/material';

import useTeam from 'plantiga-firebase/Team/useTeam';

import DrawerItem from './DrawerItem';

const useStyles = makeStyles()(() => ({
  card: {
    maxWidth: 320,
  },
}));

export function ContactDrawerItem() {
  const { classes } = useStyles();
  const { teamId, team } = useTeam();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <DrawerItem
        id="nav-contact-us-button"
        icon={<ChatIcon />}
        text="Contact Us"
        onClick={handleOpen}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card className={classes.card}>
          <CardHeader title="Contact Us" />
          <CardContent>
            <Typography variant="body1">
              For questions or concerns, reach out to&nbsp;
              <Link
                href={`mailto:support@plantiga.com?subject=Support Request - ${
                  team?.name ?? teamId
                }`}
              >
                support@plantiga.com
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}
