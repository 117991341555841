import React from 'react';

import { Add as AddIcon, Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import {
  Button,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import Dialog from 'plantiga-common/Dialog';
import EditEventForm from 'plantiga-component/Athletes/Profile/Events/EditEventForm';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';

function EventDialog({
  event,
  open,
  onClose,
}: {
  event?: PlantigaEvent;
  open: boolean;
  onClose: (event?: PlantigaEvent) => void;
}) {
  const [localEvent, setLocalEvent] = React.useState<PlantigaEvent | undefined>(event);
  const [valid, setValid] = React.useState(false);

  const handleChange = React.useCallback((v: PlantigaEvent, isValid: boolean) => {
    setLocalEvent(v);
    setValid(isValid);
  }, []);

  const actionButtons = React.useMemo(
    () => [
      <Button key="cancel" onClick={() => onClose()}>
        Cancel
      </Button>,
      <Button
        key="confirm"
        color="primary"
        variant="contained"
        disabled={!valid}
        onClick={() => onClose(localEvent)}
      >
        Confirm
      </Button>,
    ],
    [valid, localEvent, onClose],
  );

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      title="Injury/Surgery Events"
      open={open}
      onClose={() => onClose()}
      actionButtons={actionButtons}
    >
      <DialogContent>
        <EditEventForm
          athleteId=""
          event={localEvent}
          eventTypes={['injury', 'surgery']}
          onFormChanged={handleChange}
        />
      </DialogContent>
    </Dialog>
  );
}

type Props = {
  events: PlantigaEvent[];
  setEvents: React.Dispatch<React.SetStateAction<PlantigaEvent[]>>;
};

export default function InjuriesForm({ events, setEvents }: Props) {
  const [createEv, setCreateEv] = React.useState(false);
  const [hovered, setHovered] = React.useState<number | null>(null);
  const [selected, setSelected] = React.useState<number | null>(null);

  const handleUpdate = React.useCallback(
    (event?: PlantigaEvent) => {
      if (event) setEvents((p) => p.map((v, i) => (i === selected ? event : v)));
      setSelected(null);
    },
    [selected, setEvents],
  );

  const handleCreate = React.useCallback(
    (event?: PlantigaEvent) => {
      if (event) setEvents([...events, event]);
      setCreateEv(false);
    },
    [events, setEvents],
  );

  const handleRemove = React.useCallback(
    (i: number) => {
      setEvents([...events.slice(0, i), ...events.slice(i + 1, events.length)]);
    },
    [events, setEvents],
  );

  return (
    <>
      <List disablePadding>
        <ListItem button onClick={() => setCreateEv(true)}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Add Injury or Surgery" />
        </ListItem>
        {events.map((event, i) => (
          <ListItem
            key={event.title}
            onMouseOver={() => setHovered(i)}
            onMouseLeave={() => setHovered(null)}
          >
            <ListItemText primary={event.title} secondary={event.iso_date} />
            {hovered === i ? (
              <ListItemSecondaryAction>
                <IconButton onClick={() => setSelected(i)} size="large">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleRemove(i)} size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>
      {createEv ? <EventDialog open={createEv} onClose={handleCreate} /> : null}
      {selected == null ? null : (
        <EventDialog open={selected != null} onClose={handleUpdate} event={events[selected]} />
      )}
    </>
  );
}
