/* eslint-disable no-continue */
import React from 'react';

import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

import Crumb from './Crumb';

type Props = { athleteId: string } & Omit<React.ComponentProps<typeof Crumb>, 'text'>;

export default function PersonCrumb({ athleteId, ...props }: Props) {
  const athlete = useAthlete(athleteId);
  return <Crumb {...props} text={athlete?.name ?? '...'} />;
}
