import React from 'react';

/**
 * This "hack" ensures the window resumes it's last scroll position after the browser scrolls the
 * focused element into view.
 *
 * Ideally the onFocus callback would be overridden with
 * ".focus({preventScroll: true})"; however, "preventScroll" is not supported by all browsers.
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/focus#browser_compatibility
 */
export default function usePreventScrollOnFocus() {
  const scrollPos = React.useRef<[number, number]>([0, 0]);

  React.useEffect(() => {
    const scrollHandler = () => {
      scrollPos.current[0] = window.scrollX;
      scrollPos.current[1] = window.scrollY;
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  const handleFocus = React.useCallback(() => {
    window.scrollTo(...scrollPos.current);
  }, []);

  return handleFocus;
}
