import React from 'react';

import { format as formatDate } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { Edit, Save } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },

  content: {
    width: '100%',
  },
});

type Props = {
  readonly athleteId: string;
  readonly athlete: Athlete;
  readonly title: string;
  readonly field: string;
  readonly editAthlete: any;
};

export default function EditDateRow({ athleteId, athlete, title, field, editAthlete }: Props) {
  const { classes } = useStyles();
  const [editing, setEditing] = React.useState(false);

  const [date, setDate] = React.useState<Date | null>(
    // @ts-expect-error
    athlete[field as keyof typeof athlete]?.toDate() ?? null,
  );

  const handleClick = React.useCallback(async () => {
    if (editing) {
      await editAthlete(athleteId, { [field]: date });
      setEditing((p) => !p);
    } else {
      setEditing((p) => !p);
    }
  }, [athleteId, date, field, editAthlete, editing]);

  return (
    <TableRow>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing && <DatePicker disableFuture value={date} onChange={(d) => setDate(d)} />}
        {!editing && date && formatDate(date, 'PP')}
        {!editing && !date && '-'}
      </TableCell>
      <TableCell>
        <IconButton onClick={handleClick} size="large">
          {editing ? <Save /> : <Edit />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
