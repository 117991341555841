import React from 'react';

type CreateElementComponent =
  | ((...args: any) => JSX.Element | null)
  | React.FC
  | React.ComponentClass;

type Props<C extends CreateElementComponent> = {
  condition: boolean;
  wrapperComponent: C;
  wrapperProps?: Omit<React.ComponentProps<C>, 'children'>;
  children: React.ReactNode;
};

export default function ConditionalWrapper<C extends CreateElementComponent>({
  condition,
  wrapperComponent,
  wrapperProps,
  children,
}: Props<C>) {
  if (condition) {
    return React.createElement(wrapperComponent as unknown as React.FC<{}>, wrapperProps, children);
  }
  return <>{children}</>;
}
