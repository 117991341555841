// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEPRECATED_FLAGS = {
  condensed_summary: 'show a condensed summary value',
  show_injuries_card: 'show the latest injuries and surgeries on the daily and trends pages',
  daily_compare: 'show compare dropdown on daily feed',
  crop_control: 'show the summary mask and "create activity" button on explore page',
  n_one: 'show n=one tab on the Trends Page',
  explore_smooth_default: 'show the smoothing control on by default in explore page',
  explore_smooth: 'show the smoothing control in explore page',
  team_role: 'toggle features based on the organization role',
  mc_activity_report: 'access individual activity reports',
  trends_v2: 'show the v2 trends plot',
  trends_data_table: 'compare crosshair values in a data table',
  asymmetry_circle: 'show the asymmetry circle in the condensed summary value',
  individual_dashboard: 'show the load overview',
  colors_v2: 'show the v2 colors',
};

export const flagDescriptions = {
  team_feed: 'show team feed with activities for all people', // TODO need to enable this
  team_research: 'show the research drawer item in the app drawer',
  show_pending_fields: 'display fields marked as pending in reports',
  show_stride_length: 'display stride length plots/values',
  wtf: 'show battery status errors when values are not computed',
  landing_takeoff_acceleration:
    'enable landing/takeoff acceleration and disable old gait acceleration',
  landing_takeoff_rotation: 'enable landing/takeoff rotation',
  cumsum_distance: 'show the cumulative distance for an activity on the explore page',
  knee_score: 'show the knee score for an activity on the explore page',
  activity_mask: 'show the activity mask overlaid on the explore page',
  impact_force: 'show the impact force on the explore page',
  activity_report_org_logo: 'allow organizations to add their logos to the activity reports',
  three_d: 'show the 3d visualizaton',
  explore_download: 'enable download of CSV values from explore page',
  explore_mixed: 'enable viewing of mixed activity plots on explore page',
  mixed_activity_run: 'show the run section of the mixed activity report',
  game_report: 'show the game report',
  team_dashboard: 'show the team dashboard',
  custom_timeseries_ranges: 'allow custom timeseries-ranges',
  site_tiers: 'show the available site tiers',
  activity_questions: 'show activity questions',
} as const;
