import React from 'react';

import { isSameDay } from 'date-fns';

import { Typography } from '@mui/material';

import { useField } from 'plantiga-common/Fields';
import { useUnitTranslator } from 'plantiga-firebase/Team/universalUnitTranslator';
import { computeAsymmetry } from 'plantiga-util/asymmetry';

import { teamDashboardTemplate } from './constants';
import type { TeamDashDatum } from './useTeamDashboard';

type Props = {
  athleteId: string;
  date: Date;
  data: TeamDashDatum[] | undefined;
};

const TEMPLATE = teamDashboardTemplate.load_asymmetry;

export function AthleteLoadAsymmetry({ athleteId, date, data }: Props) {
  const { storedUnit, displayUnit } = useField(TEMPLATE.asymmetry);
  const { translate } = useUnitTranslator(storedUnit, displayUnit, { nDigits: 2 });

  const lDatum = data?.find(
    (v) => v.fieldId === TEMPLATE.left && v.athleteId === athleteId && isSameDay(v.date, date),
  );

  const rDatum = data?.find(
    (v) => v.fieldId === TEMPLATE.right && v.athleteId === athleteId && isSameDay(v.date, date),
  );

  if (lDatum == null && rDatum == null) return null;
  return (
    <Typography>
      {translate(computeAsymmetry(lDatum?.value ?? 0, rDatum?.value ?? 0) * 100)}
    </Typography>
  );
}
