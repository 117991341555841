import React from 'react';
import { Redirect } from 'react-router-dom';

import { formatISO } from 'date-fns';

import { useParams, useQuery } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';
import NotFound from 'plantiga-component/Error/NotFound';

import { ActivityContext } from './_root';

export default function ActivityRedirect() {
  const buildURL = useBuildURL();
  const { activity } = React.useContext(ActivityContext);
  const { athleteId, activityId, page } = useParams();
  const query = useQuery();

  if (activity == null) {
    return <NotFound title="Activity Not Found" />;
  }

  const url = buildURL(
    'activity',
    {
      athleteId,
      day: formatISO(activity.start.toDate(), { representation: 'date' }),
      activityId,
      page,
    },
    query,
  );
  return <Redirect to={url} />;
}
