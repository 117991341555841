import React from 'react';

import { format as formatDate, isEqual as isDateEqual } from 'date-fns';
import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import { IconButtonWithLoading } from 'plantiga-common/ButtonWithLoading';
import { updateActivity } from 'plantiga-firebase/Activity/actions';
import type { ActivityWithDate } from 'plantiga-firebase/Activity/typedefs';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
  },
  tableRow: {
    height: 80,
  },
  dateFieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

type Props = {
  readonly activity: ActivityWithDate;
  readonly activityId: string;
  readonly teamId: string;
  readonly title: string;
  readonly field: 'start' | 'end';
};

export default function EditActivityTime({ activity, activityId, teamId, title, field }: Props) {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [date, setDate] = React.useState<Date | null>(activity[field] ?? null);

  const handleSave = async () => {
    if (date != null && !isDateEqual(date, activity[field])) {
      setLoading(true);
      await updateActivity({
        teamId,
        activityId,
        activity: { ...activity, [field]: date },
      });
      setLoading(false);
    }
    setEditing(false);
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <div className={classes.dateFieldContainer}>
            <DateTimePicker
              label="Time"
              value={date}
              onChange={(d) => setDate(d)}
              views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
            />
          </div>
        ) : (
          <span>{date ? formatDate(date, 'PPpp') : '-'}</span>
        )}
      </TableCell>
      {editing ? (
        <TableCell>
          <IconButtonWithLoading loading={loading} disabled={loading} onClick={handleSave}>
            <SaveIcon />
          </IconButtonWithLoading>
        </TableCell>
      ) : (
        <TableCell>
          <IconButton onClick={() => setEditing(true)} size="large">
            <EditIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
