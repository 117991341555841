import { getAnalytics, setUserProperties } from 'firebase/analytics';
import React, { useState, useEffect, useCallback, useRef } from 'react';

import * as Sentry from '@sentry/react';

import type { Team } from '../schema';
import useFirestore from '../useFirestore';

import { getTeam } from './helpers';

type TeamContextType = {
  readonly currentTeamId?: string | null | undefined;
  readonly team?: Team | null | undefined;
  readonly setTeam?: any;
  readonly loading?: boolean;
};

const TeamContext: React.Context<TeamContextType> = React.createContext<TeamContextType>({});

export const useTeamContext = (
  teamId: string,
): {
  context: TeamContextType;
  error: Error | null | undefined;
  waitForTeam: boolean;
} => {
  const [context, setContext] = useState<TeamContextType>({});
  const [waitForTeam, setWaitForTeam] = useState(true);
  const contextRef = useRef(context);
  contextRef.current = context;
  const [error, setError] = useState<Error | null | undefined>(null);
  const db = useFirestore();
  const setTeam = useCallback((newTeam: Team) => {
    setContext({ ...contextRef.current, team: newTeam });
  }, []);

  useEffect(() => {
    const doEffect = async () => {
      Sentry.configureScope((scope) => {
        scope.setTag('teamId', teamId);
      });
      setUserProperties(getAnalytics(), { team_id: teamId });

      setContext({ ...contextRef.current, loading: true });
      let team;

      try {
        team = await getTeam(db, teamId);
      } catch (err: any) {
        setError(err);
        setContext({ ...contextRef.current, loading: false });
        return;
      }

      const newContext = {
        currentTeamId: teamId,
        team,
        setTeam,
        loading: false,
      } as const;
      setContext(newContext);

      setError(null);

      setWaitForTeam(false);
    };
    doEffect();
  }, [db, setTeam, teamId]);

  return { context, error, waitForTeam };
};

export default TeamContext;
