import React, { type CSSProperties } from 'react';

import { makeStyles } from 'tss-react/mui';

import { ListItem, Typography, IconButton, ListItemAvatar, Skeleton } from '@mui/material';

import useBreakpoint from 'plantiga-common/useBreakpoint';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import AthleteAvatar from 'plantiga-component/Athletes/Avatar';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

import { AthleteLoadAsymmetry } from './AthleteLoadAsymmetry';
import { AthleteLoadPlot } from './AthleteLoadPlot';
import { CompareRadarPlot } from './CompareRadar/CompareRadar';
import MoreMenuGuard from './MoreMenu';
import { MoveTime } from './MoveTime';
import type { TeamDashDatum } from './useTeamDashboard';

const useStyles = makeStyles<void, 'athlete'>()((theme, _params, classes) => ({
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    position: 'absolute',
    left: theme.spacing(7),
    right: theme.spacing(2),
    top: theme.spacing(0),
  },
  listItem: {
    display: 'grid',
    gridTemplateColumns: '48px 2fr 1fr 1fr 1fr 48px',
    gap: theme.spacing(1),
    [`&.${classes.athlete}`]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      gridTemplateColumns: '48px 1fr',
    },
  },
  athlete: {},
  cell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 48,
  },
}));

function Cell({
  loading = false,
  noMobile = false,
  children,
}: React.PropsWithChildren<{ loading?: boolean; noMobile?: boolean }>) {
  const { classes } = useStyles();
  const isMobile = useBreakpoint();

  if (noMobile && isMobile) return null;

  return <div className={classes.cell}>{loading ? <Skeleton height="100%" /> : children}</div>;
}

type Props = {
  teamId: string;
  athleteId: string;
  date: Date;
  isLoading: boolean;
  data: TeamDashDatum[] | undefined;
  style?: CSSProperties;
};

export function AthleteListItem({ teamId, athleteId, date, data, isLoading, style }: Props) {
  const { classes, cx } = useStyles();
  const athlete = useAthlete(athleteId);
  const navigateURL = useNavigateURL();

  const common = { athleteId, date, data };

  return (
    <ListItem style={style} className={cx(classes.listItem, classes.athlete)} disableGutters>
      <ListItemAvatar className={classes.avatar}>
        <IconButton size="small" onClick={() => navigateURL('athlete', { teamId, athleteId })}>
          {athlete && <AthleteAvatar athlete={athlete} />}
        </IconButton>
      </ListItemAvatar>
      <Typography className={classes.title}>
        <b>{athlete ? athlete.name : '?'}</b>
      </Typography>
      <Cell loading={isLoading}>
        <AthleteLoadPlot {...common} />
      </Cell>
      <Cell loading={isLoading} noMobile>
        <AthleteLoadAsymmetry {...common} />
      </Cell>
      <Cell loading={isLoading} noMobile>
        <MoveTime {...common} />
      </Cell>
      <Cell loading={isLoading} noMobile>
        <CompareRadarPlot {...common} />
      </Cell>
      <MoreMenuGuard athleteId={athleteId} />
    </ListItem>
  );
}

export function Header() {
  const { classes } = useStyles();
  return (
    <ListItem className={classes.listItem} disableGutters divider>
      <ListItemAvatar className={classes.avatar}>
        <div />
      </ListItemAvatar>
      <Cell>
        <Typography variant="body2">Athlete Load&trade;</Typography>
      </Cell>
      <Cell noMobile>
        <Typography variant="body2">Athlete Load&trade; Asym</Typography>
      </Cell>
      <Cell noMobile>
        <Typography variant="body2">Move Time</Typography>
      </Cell>
      <Cell noMobile>
        <Typography variant="body2">Compare</Typography>
      </Cell>
      <div />
    </ListItem>
  );
}
