import React from 'react';

import {
  CloudDone,
  CloudOff,
  CloudQueue as CloudQueueIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import type { Activity, WTFSummary } from 'plantiga-firebase/schema';
import { useSummary } from 'plantiga-firebase/Summaries/useSummary';

type Props = {
  readonly activityId: string;
  readonly athleteId: string;
  readonly activity: Activity;
  readonly fontSize?: 'inherit' | 'default' | 'small' | 'large';
};

// If there is a given chunk of missing data, then the battery probably gave out
// before the activity completed
const missingDataThresholdMillis = 5 * 60 * 1000;
// If a large percentage of the data is missing, then the battery probably gave
// out before the activity completed
const missingDataThresholdRatio = 0.8;

function hasMissingData(activity: Activity, summary: WTFSummary) {
  const dataGap = summary?.summary_v2?.max_data_gap;
  if (dataGap == null) {
    return false;
  }

  if (dataGap > missingDataThresholdMillis) {
    return true;
  }

  const activityDuration = activity.end.toMillis() - activity.start.toMillis();
  if (dataGap / activityDuration > missingDataThresholdRatio) {
    return true;
  }

  return false;
}

export default function DataStatus({
  activityId,
  athleteId,
  activity,
  fontSize = 'inherit',
}: Props) {
  const theme = useTheme();
  const status = activity.data_status;
  const { summary } = useSummary<WTFSummary>(athleteId, activityId, 'wtf_summary');

  if (status === 'waiting_for_upload') {
    return (
      <Tooltip title="Dock pods to upload">
        <CloudQueueIcon fontSize={fontSize} color="action" />
      </Tooltip>
    );
  }
  if (status === 'uploading') {
    return (
      <Tooltip title="Uploading data...">
        <CloudUploadIcon fontSize={fontSize} htmlColor={theme.palette.graphing.blue} />
      </Tooltip>
    );
  }
  if (summary && hasMissingData(activity, summary)) {
    return (
      <Tooltip title="Data is missing">
        <CloudOff fontSize={fontSize} color="secondary" />
      </Tooltip>
    );
  }
  if (status === 'upload_finished') {
    return (
      <Tooltip title="Data uploaded">
        <CloudDone fontSize={fontSize} color="primary" />
      </Tooltip>
    );
  }
  return null;
}
