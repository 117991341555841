import React from 'react';

import { every } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';
import { useIsMounted } from 'usehooks-ts';

import { Block as BlockIcon, Check as CheckIcon } from '@mui/icons-material';
import { Step, StepContent, StepLabel, Stepper } from '@mui/material';

import useBreakpoint from 'plantiga-common/useBreakpoint';
import { useLogEvent } from 'plantiga-firebase/analytics';
import { useAddAthlete } from 'plantiga-firebase/Athletes/actions';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';
import useCreateEvent from 'plantiga-firebase/Events/useCreateEvent';

import InjuriesForm from './InjuriesForm';
import NameForm from './NameForm';
import PersonalInfoForm from './PersonalInfoForm';
import StepperButtons from './StepperButtons';
import type { PersonalInfo } from './typedefs';
import { STEPS } from './util';

const useStyles = makeStyles()((theme) => ({
  iconContainer: {
    backgroundColor: theme.palette.primary.main,
    height: theme.spacing(4),
    width: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      paddingRight: 0,
    },
  },
  labelContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      paddingLeft: theme.spacing(1),
    },
  },
  skipped: {
    backgroundColor: theme.palette.text.secondary,
  },
}));

const INITIAL_PERSON_STATE: PersonalInfo = {
  gender: '',
  dateOfBirth: null,
  weight: null,
  height: null,
};

type Props = {
  readonly onClose: any;
};

export default function NewPersonStepper({ onClose }: Props) {
  const { classes, cx } = useStyles();
  const isMobile = useBreakpoint();
  const addAthlete = useAddAthlete();
  const { create: createEvent } = useCreateEvent();
  const skippedSteps = React.useRef(new Set<number>());
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const logEvent = useLogEvent();

  const [name, setName] = React.useState('');
  const [personalInfo, setPersonalInfo] = React.useState<PersonalInfo>(INITIAL_PERSON_STATE);
  const [events, setEvents] = React.useState<PlantigaEvent[]>([]);
  const isMounted = useIsMounted();

  const [componentForStep, disableNext, skipAction] = React.useMemo(() => {
    switch (activeStep) {
      case 0:
        return [
          <NameForm title={STEPS[0].label} isMobile={isMobile} name={name} setName={setName} />,
          name.length === 0,
          null,
        ];
      case 1:
        return [
          <PersonalInfoForm
            title={STEPS[1].label}
            isMobile={isMobile}
            personalInfo={personalInfo}
            setPersonalInfo={setPersonalInfo}
          />,
          every(personalInfo, (v) => !v),
          () => setPersonalInfo(INITIAL_PERSON_STATE),
        ];
      case 2:
        return [<InjuriesForm events={events} setEvents={setEvents} />, false, null];
      default:
        return [null, false, null];
    }
  }, [activeStep, isMobile, name, personalInfo, events, setEvents]);

  const createPerson = React.useCallback(async (): Promise<string | null | undefined> => {
    const { gender, dateOfBirth, height, weight } = personalInfo;
    const { athleteId } = await addAthlete(name, gender, dateOfBirth, height ?? 0, weight ?? 0);
    return athleteId;
  }, [addAthlete, name, personalInfo]);

  const closeAndClearState = React.useCallback(() => {
    if (!isMounted()) return;
    skippedSteps.current.clear();
    setActiveStep(0);
    setName('');
    setLoading(false);
    onClose();
  }, [onClose, isMounted]);

  const handleFinish = React.useCallback(async () => {
    setLoading(true);
    const athleteId = await createPerson();
    if (athleteId != null) {
      await Promise.all(
        events.map(async (event) => createEvent({ ...event, athlete_id: athleteId })),
      );
    }

    closeAndClearState();
  }, [createPerson, closeAndClearState, createEvent, events]);

  const handleSkip = React.useCallback(() => {
    if (skipAction != null) {
      skipAction();
    }
    skippedSteps.current.add(activeStep);
    setActiveStep((prev) => (prev < STEPS.length - 1 ? prev + 1 : prev));
  }, [activeStep, skipAction]);

  const handleNext = React.useCallback(() => {
    skippedSteps.current.delete(activeStep);
    logEvent(STEPS[activeStep].analyticsEvent);
    setActiveStep((prev) => (prev < STEPS.length - 1 ? prev + 1 : prev));
  }, [activeStep, logEvent]);

  return (
    <div>
      <Stepper
        alternativeLabel={!isMobile}
        orientation={isMobile ? 'vertical' : 'horizontal'}
        activeStep={activeStep}
      >
        {STEPS.map(({ label, icon }, index) => {
          const skipped = skippedSteps.current.has(index) && activeStep > index;
          const stepIcon = index < activeStep ? CheckIcon : icon;
          return (
            <Step key={label}>
              <StepLabel
                // @ts-expect-error
                StepIconComponent={skipped ? BlockIcon : stepIcon}
                classes={{
                  iconContainer: cx({
                    [classes.iconContainer]: true,
                    [classes.skipped]: skipped,
                  }),
                  labelContainer: classes.labelContainer,
                }}
              >
                {label}
              </StepLabel>
              {isMobile && (
                <StepContent>
                  {componentForStep}
                  <StepperButtons
                    activeStep={activeStep}
                    isMobile={isMobile}
                    loading={loading}
                    disableNext={disableNext}
                    setActiveStep={setActiveStep}
                    handleSkip={handleSkip}
                    handleNext={handleNext}
                    handleFinish={handleFinish}
                  />
                </StepContent>
              )}
            </Step>
          );
        })}
      </Stepper>
      {!isMobile && (
        <>
          {componentForStep}
          <StepperButtons
            activeStep={activeStep}
            isMobile={isMobile}
            loading={loading}
            disableNext={disableNext}
            setActiveStep={setActiveStep}
            handleSkip={handleSkip}
            handleNext={handleNext}
            handleFinish={handleFinish}
          />
        </>
      )}
    </div>
  );
}
