import React from 'react';
import { useWatch, useFieldArray } from 'react-hook-form';
import type { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { some, filter, isEmpty } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Collapse } from '@mui/material';

import AutocompletePeople from 'plantiga-common/AutocompletePeople';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';

import { ControlledAthleteWarnings } from './ControlledAthleteWarnings';
import type { AthleteWarningItem } from './getAthleteWarnings';
import type getFormValues from './getFormValues';

const MemoControlledAthleteWarnings = React.memo(ControlledAthleteWarnings);

const useStyles = makeStyles()((theme) => ({
  wrapperInner: {
    display: 'grid',
    gap: theme.spacing(1),
  },
}));

type Props = {
  control: Control<ReturnType<typeof getFormValues>>;
  register: UseFormRegister<ReturnType<typeof getFormValues>>;
  setValue: UseFormSetValue<ReturnType<typeof getFormValues>>;
};

const validate =
  (ignoreAll: boolean) =>
  (athleteWarnings: ReturnType<typeof getFormValues>['athleteWarnings']) => {
    if (athleteWarnings.length === 0) return 'Please select at least one person';
    const unhandledWarningAthletes = filter(athleteWarnings, ({ warnings }) => some(warnings));
    return (
      ignoreAll ||
      isEmpty(unhandledWarningAthletes) ||
      `Please resolve warnings for: ${unhandledWarningAthletes.map(({ name }) => name).join(', ')}`
    );
  };

export default function ControlledPeopleSelect({ control, register, setValue }: Props) {
  const { classes } = useStyles();
  const athletes = useAthletes();
  const [open, setOpen] = React.useState(false);
  const [ignoreAll, activityType] = useWatch({ control, name: ['ignoreAll', 'activityType'] });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'athleteWarnings',
    rules: { validate: validate(ignoreAll) },
  });

  const handleAthleteSelect = React.useCallback(
    (athleteIds: string[]) => {
      const fieldAthleteIds = fields.map(({ athleteId: id }) => id);

      const toAppend = athleteIds.reduce<AthleteWarningItem[]>((acc, athleteId) => {
        if (!fieldAthleteIds.includes(athleteId))
          acc.push({ athleteId, name: athletes[athleteId].name, warnings: {} });
        return acc;
      }, []);
      append(toAppend);

      const toRemove = fieldAthleteIds.reduce<number[]>((acc, athleteId, i) => {
        if (!athleteIds.includes(athleteId)) acc.push(i);
        return acc;
      }, []);
      remove(toRemove);
    },
    [append, remove, athletes, fields],
  );

  return (
    <Collapse
      in={Boolean(activityType) || fields.length > 0}
      classes={{ wrapperInner: classes.wrapperInner }}
      onEntered={() => setOpen(Boolean(activityType) && fields.length === 0)}
    >
      <AutocompletePeople
        multiple
        selectAll
        disableSecondaryTeamIndividuals
        open={open}
        athleteIds={fields.map(({ athleteId }) => athleteId)}
        onChange={handleAthleteSelect}
      />
      {fields.map((item, index) => (
        <MemoControlledAthleteWarnings
          key={item.athleteId}
          athleteId={item.athleteId}
          index={index}
          control={control}
          register={register}
          setValue={setValue}
          onRemove={() => remove(index)}
        />
      ))}
    </Collapse>
  );
}
