import React from 'react';

import { map, size, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  Paper,
  List,
  ListItem,
  IconButton,
  Typography,
  ListItemSecondaryAction,
} from '@mui/material';

import { useActiveStopwatches } from '../../Firestore/Stopwatch/useStopwatch';

import ActiveStopwatchListItem from './ActiveStopwatchListItem';

const useStyles = makeStyles()((theme) => ({
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  horizontalSpace: {
    flex: 1,
  },
}));

export default function ActiveStopwatchesListItem() {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const { stopwatches } = useActiveStopwatches();
  const sizeRunningStopwatches = React.useMemo(() => size(stopwatches), [stopwatches]);

  const handleClick = () => {
    setOpen(!open);
  };

  if (sizeRunningStopwatches === 0) {
    return null;
  }

  return (
    <Paper variant="outlined">
      <List disablePadding>
        <ListItem button disabled={sizeRunningStopwatches === 0} onClick={handleClick}>
          <Avatar className={classes.avatar}>
            <Typography variant="body2">{sizeRunningStopwatches}</Typography>
          </Avatar>
          <Typography>Active Recordings</Typography>
          <div className={classes.horizontalSpace} />
          <ListItemSecondaryAction onClick={handleClick}>
            <IconButton size="small">{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {map(toPairs(stopwatches), ([swid, stopwatch]) => (
            <ActiveStopwatchListItem key={swid} swid={swid} stopwatch={stopwatch} />
          ))}
        </List>
      </Collapse>
    </Paper>
  );
}
