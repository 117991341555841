import BaseAPIClient from './BaseApiClient';
import type { RecordRequest, RecordResponse } from './RecordApiClientTypedefs';

export default class RecordApiClient extends BaseAPIClient {
  createActivity = async ({ athlete_id: userId, ...payload }: RecordRequest) => {
    const path = `/cloud-gears/user/${userId}/activity`;
    const options = { body: payload } as const;
    const [json, res] = await this.send('POST', path, options);
    if (res.status === 200) {
      const { activity_id: activityId } = json as RecordResponse;
      return activityId;
    }
    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };
}
