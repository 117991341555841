import { merge } from 'lodash-es';

import useTeam from 'plantiga-firebase/Team/useTeam';

export type Feature = 'editAthlete';
export type Features = Partial<Record<Feature, boolean>>;

const DEFAULT_TYPE: Features = {
  editAthlete: true,
  // Example permissions
  // hasViews: true,
  // hasTrends: false,
};

const TEAM_TYPE_FEATURES = {
  archived: {
    editAthlete: false,
  },
  individual_user: {
    editAthlete: false,
  },
  business: {
    editAthlete: true,
  },
  business_legacy: {
    editAthlete: true,
  },
  unknown: {
    editAthlete: false,
  },
};

export function useTeamFeatures(): Features {
  const { team } = useTeam();

  const teamType = team?.team_type ?? 'unknown';
  const specificType = TEAM_TYPE_FEATURES[teamType] ?? DEFAULT_TYPE;
  const featuresForType = merge({}, DEFAULT_TYPE, specificType);
  return featuresForType;
}
