import { setCurrentScreen, logEvent } from 'firebase/analytics';
import React from 'react';

import { useHistory } from 'plantiga-common/react-router-hooks';
import { useAnalytics } from 'plantiga-firebase/analytics';
import { getURLKey } from 'plantiga-util/urls';

export default function FirebaseAnalytics(): null {
  const history = useHistory();
  const analytics = useAnalytics();

  React.useEffect(() => {
    const unlisten = history.listen((location: Location) => {
      const URLKey = getURLKey(location.pathname);
      setCurrentScreen(analytics, URLKey);
      logEvent(analytics, 'screen_view');
    }, []);

    return unlisten;
  }, [history, analytics]);
  return null;
}
