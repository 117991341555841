import type { urlKey } from 'plantiga-util/urls';

import {
  TeamFeatureClaim,
  SuperUserClaim,
  FeatureFlagClaim,
  UserRoleClaim,
  TeamRoleClaim,
  ViewClaim,
} from './claims';
import type { Claim, Principal } from './principal';

const all =
  (...claims: Claim[]) =>
  (...args: Parameters<Claim>) => {
    for (let i = 0; i < claims.length; i += 1) {
      if (!claims[i](...args)) return false;
    }
    return true;
  };

const teamEditor = TeamRoleClaim('editor');
const movementCoach = UserRoleClaim('movement_coach');

const NAVIGATE_FEATURE_PERMISSIONS = {
  activityInfo: teamEditor,
  activityLabels: teamEditor,
  activityFiles: teamEditor,
  teamDevices: teamEditor,
  teamProfile: teamEditor,
  record: teamEditor,
  athleteProfile: teamEditor,
};

export const FEATURE_PERMISSIONS = {
  navigate: (p: Principal, routeName: string, _path: unknown) => {
    const routePredicate =
      NAVIGATE_FEATURE_PERMISSIONS[routeName as keyof typeof NAVIGATE_FEATURE_PERMISSIONS];
    return routePredicate == null ? true : routePredicate(p);
  },
};

export type FeaturePermission = keyof typeof FEATURE_PERMISSIONS;

const NAVIGATE_PERMISSIONS: { [T in urlKey]?: Claim } = {
  activitySummaries: SuperUserClaim,
  activityStatsForNerds: SuperUserClaim,
  teamFeed: FeatureFlagClaim('team_feed'),
  teamResearch: FeatureFlagClaim('team_research'),
};

export const PERMISSIONS = {
  'chat:read': all(teamEditor, movementCoach),
  'user:update': teamEditor,
  'person:create': all(teamEditor, TeamFeatureClaim('editAthlete')),
  'person:update': all(teamEditor, TeamFeatureClaim('editAthlete')),
  'person:delete': all(teamEditor, TeamFeatureClaim('editAthlete')),
  'activity:create': teamEditor,
  'activity:update': teamEditor,
  'activity:delete': teamEditor,
  'activity:record': teamEditor,
  'activity:download': all(teamEditor, FeatureFlagClaim('explore_download')),
  'activity:updatePods': SuperUserClaim,
  'plots:update': teamEditor,
  'plots:delete': teamEditor,
  'report:update': teamEditor,
  'event:update': teamEditor,
  'event:delete': teamEditor,
  navigate: (p: Principal, routeName: string, _path: unknown) => {
    const routePredicate = NAVIGATE_PERMISSIONS[routeName as keyof typeof NAVIGATE_PERMISSIONS];

    if (!ViewClaim(routeName)(p)) return false;
    if (routePredicate == null) return true;
    return routePredicate(p);
  },
};

export type Permission = keyof typeof PERMISSIONS;
