import React from 'react';

import SummariesView from 'plantiga-component/Activity/Pages/SummariesView';

import { ActivityContext } from './_root';

export default function ActivityStatsForNerds() {
  const { activity } = React.useContext(ActivityContext);
  return <SummariesView activity={activity} />;
}
