import React from 'react';

import { isEmpty, orderBy, size, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import Timeline from '@mui/lab/Timeline';
import { Typography } from '@mui/material';

import Wait from 'plantiga-common/Wait';
import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';
import useEvents from 'plantiga-firebase/Events/useEvents';

import EventTimelineItem from './EventsTimelineItem';

const useStyles = makeStyles()(() => ({
  timelineContainer: {
    overflowX: 'auto', // required for fixed position elements to behave properly
  },
  timeline: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
}));

type Props = {
  readonly athleteId: string;
};

export default function EventsTimeline({ athleteId }: Props) {
  const { classes } = useStyles();
  const { events, loading, error } = useEvents(athleteId);

  const sortedEvents: Array<[string, PlantigaEvent]> = React.useMemo(
    () => orderBy(toPairs(events), ([, v]) => v.date.toMillis(), 'desc'),
    [events],
  );

  return (
    <Wait waitingOn={loading} info="EventsTimeline" error={error} variant="circular">
      {isEmpty(events) ? (
        <Typography variant="h5" align="center" color="textSecondary">
          No Events
        </Typography>
      ) : (
        <div className={classes.timelineContainer}>
          <Timeline className={classes.timeline}>
            {sortedEvents.map(([k, v], i) => (
              <EventTimelineItem
                key={k}
                athleteId={athleteId}
                eventId={k}
                event={v}
                last={i === size(events) - 1}
              />
            ))}
          </Timeline>
        </div>
      )}
    </Wait>
  );
}
