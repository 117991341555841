import { Timestamp } from 'firebase/firestore';
import React from 'react';

import { isValid as isDateValid } from 'date-fns';
import { findKey, keys, map, pick } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Collapse, Divider, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import CustomLabels from 'plantiga-common/Labels/CustomLabels';
import type { EventType, PlantigaEvent } from 'plantiga-firebase/Events/typedefs';
import useTeam from 'plantiga-firebase/Team/useTeam';
import { utcIsoDate } from 'plantiga-util/dates';

import { EVENT_TYPE_OPTIONS } from './util';

const useStyles = makeStyles()((theme) => ({
  verticalSpacing: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%',
  },
}));

type Props = {
  athleteId: string;
  event?: Partial<PlantigaEvent>;
  eventTypes?: EventType[];
  onFormChanged: (event: any, isValid: boolean) => any;
};

export default function EditEventForm({
  athleteId,
  event = {},
  eventTypes = keys(EVENT_TYPE_OPTIONS) as EventType[],
  onFormChanged,
}: Props) {
  const { classes } = useStyles();
  const { teamId } = useTeam();
  const [invalidLabel, setInvalidLabel] = React.useState(false);
  const [title, setTitle] = React.useState(event.title || '');
  const [location, setLocation] = React.useState(event.location || '');
  const [eventType, setEventType] = React.useState(event.event_type || '');
  const [date, setDate] = React.useState(event.date ? event.date.toDate() : null);
  const [labels, setLabels] = React.useState(event.labels || {});
  const [notes, setNotes] = React.useState(event.notes || '');

  const handleChange = React.useCallback(() => {
    const isValid = !!title && !!eventType && isDateValid(date) && !invalidLabel;
    const updatedEvent = {
      team_id: teamId,
      athlete_id: athleteId,
      title,
      event_type: eventType,
      date: date == null ? null : Timestamp.fromDate(date),
      iso_date: date == null || !isValid ? '' : utcIsoDate(date), // YYYY-MM-DD
      osiics_code: event.osiics_code ?? null,
      location: location || null,
      notes,
      labels,
    } as const;
    onFormChanged(updatedEvent, isValid);
  }, [
    athleteId,
    teamId,
    date,
    event.osiics_code,
    location,
    invalidLabel,
    onFormChanged,
    title,
    eventType,
    labels,
    notes,
  ]);

  React.useEffect(() => {
    handleChange();
  }, [onFormChanged, handleChange]);

  const handleEventType = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (title === '' || findKey(EVENT_TYPE_OPTIONS, (v) => v === title) != null) {
        // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Partial<Record<EventType, string>>'.
        setTitle(EVENT_TYPE_OPTIONS[value]);
      }
      setEventType(value);
    },
    [title],
  );

  const handleLabels = React.useCallback((newLabels: any, badLabel: boolean) => {
    setInvalidLabel(badLabel);
    setLabels(newLabels);
  }, []);

  return (
    <div>
      <TextField
        select
        required
        fullWidth
        variant="outlined"
        className={classes.verticalSpacing}
        label="Event Type"
        value={eventType}
        onChange={handleEventType}
      >
        {map(pick(EVENT_TYPE_OPTIONS, eventTypes), (value, key) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
      <Collapse in={eventType === 'injury' || eventType === 'surgery'}>
        <TextField
          select
          fullWidth
          className={classes.verticalSpacing}
          variant="outlined"
          label="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        >
          <MenuItem value="left">Left</MenuItem>
          <MenuItem value="right">Right</MenuItem>
        </TextField>
        <Divider className={classes.verticalSpacing} />
      </Collapse>
      <TextField
        required
        fullWidth
        className={classes.verticalSpacing}
        variant="outlined"
        label="Event Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <DatePicker
        disableFuture
        className={classes.verticalSpacing}
        label="Date"
        value={date}
        onChange={(d) => setDate(d)}
      />
      <TextField
        multiline
        fullWidth
        className={classes.verticalSpacing}
        variant="outlined"
        label="Notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <CustomLabels labels={labels} onLabelsChanged={handleLabels} />
    </div>
  );
}
