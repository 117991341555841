import React from 'react';
import { useWatch } from 'react-hook-form';
import type { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import DeferRenderWrapper from 'plantiga-common/DeferRenderWrapper';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import type { StopwatchWarnings as Warnings } from 'plantiga-firebase/Stopwatch/typedefs';
import { useActiveStopwatches } from 'plantiga-firebase/Stopwatch/useStopwatch';

import AthleteWarnings from './AthleteWarnings';
import { getAthleteWarnings } from './getAthleteWarnings';
import type getFormValues from './getFormValues';

// remove resolved warnings and retain ignored warnings
const mergeWarningsWithLast = (next: Warnings, prev: Warnings) =>
  Object.entries(next).reduce((warnings, [k, v]) => {
    if (k in prev) {
      Object.assign(warnings, { [k]: prev[k as keyof Warnings] });
    } else {
      Object.assign(warnings, { [k]: v });
    }
    return warnings;
  }, {} as Warnings);

type ControlledAthleteWarningProps = {
  athleteId: string;
  index: number;
  control: Control<ReturnType<typeof getFormValues>>;
  register: UseFormRegister<ReturnType<typeof getFormValues>>;
  setValue: UseFormSetValue<ReturnType<typeof getFormValues>>;
  onRemove: () => void;
};

export function ControlledAthleteWarnings({
  athleteId,
  index,
  control,
  register,
  setValue,
  onRemove,
}: ControlledAthleteWarningProps) {
  const athlete = useAthlete(athleteId);
  const devices = useDevices(athleteId);
  const { stopwatches } = useActiveStopwatches();
  const warningsName = `athleteWarnings.${index}.warnings` as const;
  const prevState = React.useRef<{ name: string; warnings: Warnings }>({
    name: warningsName,
    warnings: {},
  });

  const [warnings, ignoreAll] = useWatch({ control, name: [warningsName, 'ignoreAll'] });
  React.useEffect(() => {
    register(warningsName);
  }, [register, warningsName]);

  const setWarnings = React.useCallback(
    (v: Warnings) => {
      // if the index changes, restore the previous warnings
      const w = prevState.current.name === warningsName ? v : prevState.current.warnings;
      setValue(warningsName, w, { shouldValidate: true });
      prevState.current.name = warningsName;
      prevState.current.warnings = w;
    },
    [warningsName, setValue],
  );

  React.useEffect(() => {
    // listen for changes to the warning dependencies
    const w = getAthleteWarnings(athleteId, devices, stopwatches);
    const next = mergeWarningsWithLast(w, prevState.current.warnings);
    setWarnings(next);
  }, [athleteId, athlete, devices, stopwatches, setWarnings]);

  const handleIgnore = React.useCallback(
    (k: keyof Warnings) => {
      const w = { ...warnings, [k]: !warnings[k] };
      setWarnings(w);
      if (warnings[k]) setValue('ignoreAll', false);
    },
    [warnings, setWarnings, setValue],
  );

  return (
    <DeferRenderWrapper>
      {() => (
        <AthleteWarnings
          athleteId={athleteId}
          warnings={warnings}
          ignoreWarnings={ignoreAll}
          onIgnore={handleIgnore}
          onRemoveAthlete={onRemove}
        />
      )}
    </DeferRenderWrapper>
  );
}
