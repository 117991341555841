/* eslint-disable no-continue */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';

import { Link, Tooltip, Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  crumb: {
    maxWidth: 120,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    background: 'transparent',
    [theme.breakpoints.down(theme.breakpoints.values.mobile)]: {
      maxWidth: 80,
    },
  },
}));

type TypographyLinkProps = { href: string | undefined } & React.ComponentProps<typeof Link>;

function TypographyLink({ href, ...props }: TypographyLinkProps) {
  // @ts-expect-error
  return <Link component={RouterLink} to={href} {...props} />;
}

type CrumbProps = { text: string; url: string | undefined };

export default function Crumb({ text, url }: CrumbProps) {
  const { classes } = useStyles();
  const CrumbComponent = url ? TypographyLink : Typography;
  return (
    <CrumbComponent
      href={url}
      variant="body2"
      underline={url ? 'hover' : 'none'}
      color={url ? 'textPrimary' : 'textSecondary'}
      className={classes.crumb}
    >
      <Tooltip title={text} enterDelay={500} placement="bottom-start">
        <span>{text}</span>
      </Tooltip>
    </CrumbComponent>
  );
}
