import React from 'react';

import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@mui/material';

import NewEventDialog from './NewEventDialog';

type Props = {
  readonly athleteId: string;
};

export default function NewEventDialogButton({ athleteId }: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined" endIcon={<AddIcon />}>
        Add Event
      </Button>
      <NewEventDialog
        key={`new_event_dialog_${open.toString()}`}
        athleteId={athleteId}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
}
