import React, { useCallback, useState } from 'react';

import { get } from 'lodash-es';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import { useParams } from 'plantiga-common/react-router-hooks';
import { useAthleteActions } from 'plantiga-firebase/Athletes/actions';
import type { Timezone } from 'plantiga-firebase/Athletes/typedefs';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';

import { getCurrentTimezone } from '../../util/timezone';
import { useToast } from '../Toast/UseToast';

function useUpdateTimezone(): {
  loading: boolean;
  currentTimezone: Timezone;
  updateTimezone: (arg1: string) => Promise<void>;
} {
  const postToast = useToast();
  const athleteActions = useAthleteActions();
  const currentTimezone = getCurrentTimezone();
  const [loading, setLoading] = useState(false);
  const updateTimezone = useCallback(
    async (athleteId: string) => {
      try {
        setLoading(true);
        await athleteActions.updateTimezone(athleteId, currentTimezone);
        postToast({
          message: `Successfully updated timezone to ${currentTimezone.name}`,
          variant: 'success',
        });
      } catch (err: any) {
        console.error(err);
        postToast({ message: 'Failed to update timezone', variant: 'error' });
      }
      setLoading(false);
    },
    [athleteActions, currentTimezone, postToast],
  );

  return { loading, currentTimezone, updateTimezone };
}

export default function AthleteTimezoneWarning() {
  const { athleteId } = useParams();
  const athlete = useAthlete(athleteId);
  const athleteTimezone = athlete?.timezone;
  const { currentTimezone, updateTimezone, loading } = useUpdateTimezone();

  const action = React.useMemo(
    () => (
      <ButtonWithLoading
        color="primary"
        variant="contained"
        onClick={() => updateTimezone(athleteId)}
        loading={loading}
        disabled={loading}
      >
        {`Set timezone to ${currentTimezone.name}`}
      </ButtonWithLoading>
    ),
    [athleteId, currentTimezone.name, loading, updateTimezone],
  );

  const secondaryText = React.useMemo(() => {
    if (athleteTimezone == null) {
      return "This person's timezone has not been set";
    }
    const timezonesMatch = get(currentTimezone, 'name') === get(athleteTimezone, 'name');
    const offsetsMatch = get(currentTimezone, 'minutes') === get(athleteTimezone, 'minutes');
    if (timezonesMatch && !offsetsMatch) {
      return "This person's timezone needs to be updated";
    }
    return `This person's timezone is set as ${athleteTimezone.name}, but your browser timezone is ${currentTimezone.name}`;
  }, [currentTimezone, athleteTimezone]);

  return (
    <Alert severity="warning" action={action}>
      <AlertTitle>Timezone</AlertTitle>
      {secondaryText}
    </Alert>
  );
}
