import { updateDoc } from 'firebase/firestore';
import { useState, useEffect, useContext, useCallback } from 'react';

import makeDebug from 'debug';
import { set } from 'lodash-es';

import useCoach from 'plantiga-firebase/Coach/useCoach';

import { doc } from '..';
import { useToast } from '../../Toast/UseToast';
import type { Team } from '../schema';
import useFirestore from '../useFirestore';

import { getTeams } from './helpers';
import TeamContext from './TeamContext';

const debug = makeDebug('plantiga:team');

type PrefKey = 'measures' | 'name' | 'display.injury_history' | 'display.tier';

export default function useTeam(): {
  team: Team | null | undefined;
  teamId: string;
  updatePrefs: (key: PrefKey, value?: any) => Promise<void>;
} {
  const { currentTeamId, team, setTeam } = useContext(TeamContext);

  const postToast = useToast();
  const db = useFirestore();

  const updatePrefs = useCallback(
    async (key: PrefKey, value: any) => {
      if (currentTeamId == null) return;

      const ref = doc(db, 'teams', currentTeamId);
      const previousTeam = { ...team };
      const updatedTeam = { ...team };
      set(updatedTeam, key, value);
      if (setTeam != null) {
        setTeam(updatedTeam);
      }
      try {
        await updateDoc(ref, { [key]: value });
      } catch (err: any) {
        console.error(err);
        postToast({
          message: 'Failed to update organization',
          variant: 'error',
        });
        if (setTeam != null) {
          setTeam(previousTeam);
        }
      }
    },
    [currentTeamId, db, postToast, setTeam, team],
  );

  return {
    teamId: currentTeamId || '',
    team,
    updatePrefs,
  };
}

export const useLoadTeams = () => {
  const [teams, setTeams] = useState<{ [teamId: string]: Team }>({});
  const [error, setError] = useState<Error | null | undefined>(null);
  const [loading, setLoading] = useState(true);
  const { coach } = useCoach();

  const db = useFirestore();
  useEffect(() => {
    let canceled = false;
    const doEffect = async () => {
      if (canceled) return;
      setLoading(true);

      try {
        debug('Authorized Teams by Coach:', coach?.teams);
        const loadedTeams = await getTeams(db, coach);
        debug('LoadedTeams:', loadedTeams);
        if (canceled) return;
        setTeams(loadedTeams);
      } catch (err: any) {
        console.warn(err);
        if (canceled) return;
        setError(err);
        setLoading(false);
        return;
      }

      if (canceled) return;
      setLoading(false);
    };
    doEffect();
    return () => {
      canceled = true;
    };
  }, [coach, db]);

  return {
    teams,
    loading,
    error,
  };
};
