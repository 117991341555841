import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';

import PickableUnitTextField from 'plantiga-common/PickableUnitTextField';
import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';
import {
  useUnitTranslator,
  type DisplayUnit,
} from 'plantiga-firebase/Team/universalUnitTranslator';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },

  content: {
    width: '100%',
  },
});

type Props = {
  readonly athleteId: string;
  readonly athlete: Athlete;
  readonly title: string;
  readonly field: string;
  readonly editAthlete: any;
  readonly storedUnit: string;
  readonly displayUnit: DisplayUnit;
};
export default function EditUnitRow({
  athleteId,
  athlete,
  title,
  field,
  editAthlete,
  storedUnit,
  displayUnit,
}: Props) {
  const { classes } = useStyles();
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Athlete'.
  const [newValue, setNewValue] = React.useState(athlete[field] || 0);
  const { translate } = useUnitTranslator(storedUnit, displayUnit);

  const doSave = async () => {
    setSaving(true);
    await editAthlete(athleteId, { [field]: newValue });
    setEditing(!editing);
    setSaving(false);
  };
  return (
    <TableRow>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <PickableUnitTextField
            storedUnit={storedUnit}
            displayUnit={displayUnit}
            // @ts-expect-error - TS2322 - Type '{ storedUnit: string; displayUnit: DisplayUnit; variant: string; autoFocus: true; fullWidth: true; value: any; onKeyDown: (e: any) => void; onChange: (v: number) => void; }' is not assignable to type 'IntrinsicAttributes & Props'.
            variant="outlined"
            autoFocus
            fullWidth
            value={newValue}
            // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                doSave();
              }
            }}
            onChange={(v) => setNewValue(v)}
          />
        ) : (
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Athlete'.
          translate(athlete[field])
        )}
      </TableCell>
      <TableCell>
        <IconButton disabled={saving} onClick={doSave} size="large">
          {editing ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
