import React from 'react';

import { ParentSize } from '@visx/responsive';

type Parent = Parameters<React.ComponentProps<typeof ParentSize>['children']>[0];
type Props = {
  children: (parent: Parent) => React.ReactNode;
};

/**
 * Renders the child in an absolutely positioned container
 * so the container's size is determined by its parent and not its children
 *
 * This allows control of the `overflow` behavior of the child element without changing the size the of the parent element.
 * If the parent needs to adjust with the size of the child, use `ParentSize` instead.
 */
export function ConstrainedToParentSize({ children }: Props) {
  return (
    <ParentSize style={{ position: 'relative', width: '100%', height: '100%' }}>
      {(parent) => <div style={{ position: 'absolute', inset: 0 }}>{children(parent)}</div>}
    </ParentSize>
  );
}
