import { getToken } from 'plantiga-firebase/useToken';

type Options = {
  readonly domain?: string;
};

export default function cloudRunFunction(name: string, options: Options = {}) {
  const { projectId } = window.plantiga.firebaseConfig;
  const { domain: defaultDomain } = window.plantiga;
  const domain = options.domain || defaultDomain || `${projectId}.firebaseapp.com`;
  const url = `https://${domain}/run/${name}`;

  const fetchOptions: any = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    credentials: 'omit', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    // redirect: 'error', // manual, *follow, error
  };
  return async (data: any) => {
    const token = await getToken();
    fetchOptions.headers.Authorization = `Bearer ${token}`;
    fetchOptions.body = JSON.stringify(data);
    const res = await fetch(url, fetchOptions);
    return res.json();
  };
}
