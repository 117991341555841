import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

import Pod3629SVG from 'plantiga-assets/Pod3629.svg?react';

import { POD3629DIMENSIONS } from './constants';

const useStyles = makeStyles<void, 'charging'>()((theme, _params, classes) => ({
  pod: {
    [`&.${classes.charging}`]: {
      '& #Pod3629_svg__light': {
        fill: theme.palette.plantiga.orange,
      },
    },
  },
  charging: {},
}));

export function PodVirtualClone({
  podId,
  charging = false,
  width = 120,
}: {
  podId: string;
  charging?: boolean;
  width?: number;
}) {
  const { classes, cx } = useStyles();
  const height = (width * POD3629DIMENSIONS.height) / POD3629DIMENSIONS.width;

  // map the width of the rendered pod to mm
  const xScale = scaleLinear({
    domain: [0, width],
    range: [0, POD3629DIMENSIONS.width],
  });

  // map the height of the rendered pod to mm
  const yScale = scaleLinear({
    domain: [0, height],
    range: [0, POD3629DIMENSIONS.height],
  });

  const prefix = podId.endsWith('0') ? 'L' : 'R';
  return (
    <svg height={height} width={width}>
      <Pod3629SVG
        width={width}
        height={height}
        className={cx(classes.pod, { [classes.charging]: charging })}
      />
      {/* These values were discovered through experimentation */}
      <Group top={yScale.invert(30.25)} left={xScale.invert(12)}>
        <Text
          scaleToFit
          width={xScale.invert(10)}
          fontFamily="monospace"
          style={{ fill: 'white' }}
          dominantBaseline="middle"
        >
          {`${prefix} ${podId.slice(0, 4)}`}
        </Text>
      </Group>
    </svg>
  );
}
