import React from 'react';

import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import ActivityPageWrapper from 'plantiga-component/Activity/Pages/ActivityPageWrapper';
import useActivity from 'plantiga-firebase/Activity/useActivity';
import type { Activity } from 'plantiga-firebase/schema';

// TODO: not sure if this is a good fit for the router model, but this is
// the simplest way to preserve the current behavior without more refactor
export const ActivityContext = React.createContext({
  activity: null as Activity | null | undefined,
  loading: false,
  error: null as Error | null | undefined,
});

type Props = {
  children: React.ReactNode;
};

export default function ActivityIdLayout({ children }: Props) {
  const { activityId } = useParams();
  const { activity, loading, error } = useActivity(activityId);
  const ctx = React.useMemo(() => ({ activity, loading, error }), [activity, loading, error]);

  return (
    <Wait waitingOn={loading} error={error} info="ActivityRouter">
      <ActivityPageWrapper activity={activity} activityId={activityId}>
        <ActivityContext.Provider value={ctx}>{children}</ActivityContext.Provider>
      </ActivityPageWrapper>
    </Wait>
  );
}
