import React from 'react';

import { makeStyles } from 'tss-react/mui';

import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';

import type { PlantigaEvent } from 'plantiga-firebase/Events/typedefs';

import EventCard from './EventCard';

const TIMELINE_DOT_SIZE = 12;

const useStyles = makeStyles()((theme) => ({
  timelineItem: {
    width: '100%',
  },
  missingOppositeContent: {
    '&:before': {
      padding: 0,
      flex: 'unset',
    },
  },
  timelineDot: {
    width: TIMELINE_DOT_SIZE,
    height: TIMELINE_DOT_SIZE,
  },
  timelineContent: {
    paddingLeft: theme.spacing(1),
    paddingRight: 0,
    width: `calc(100% - ${TIMELINE_DOT_SIZE}px)`,
  },
}));

type Props = {
  readonly athleteId: string;
  readonly eventId: string;
  readonly event: PlantigaEvent;
  readonly last: boolean;
};

export default function EventsTimelineItem({ athleteId, eventId, event, last }: Props) {
  const { classes } = useStyles();
  const [removed, setRemoved] = React.useState(false);

  if (removed) {
    return null;
  }

  return (
    <TimelineItem
      className={classes.timelineItem}
      classes={{ missingOppositeContent: classes.missingOppositeContent }}
    >
      <TimelineSeparator>
        <TimelineDot className={classes.timelineDot} />
        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <EventCard
          athleteId={athleteId}
          eventId={eventId}
          event={event}
          onDelete={() => setRemoved(true)}
        />
      </TimelineContent>
    </TimelineItem>
  );
}
