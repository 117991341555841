import { httpsCallablePost } from '../util';

import type { ActivityWithDate } from './typedefs';

type DeleteActivity = {
  readonly activityId: string;
  readonly teamId: string;
};

type UpdateActivity = {
  readonly activityId: string;
  readonly teamId: string;
  readonly activity: ActivityWithDate;
};

export async function deleteActivity({ teamId, activityId }: DeleteActivity): Promise<any> {
  const deleteActivtyCF = httpsCallablePost('web-routes/activity/deleteActivity');
  const req = {
    activityId,
    teamId,
  } as const;
  const { data } = await deleteActivtyCF(req);
  return data;
}

export async function updateActivity({
  teamId,
  activityId,
  activity,
}: UpdateActivity): Promise<any> {
  const flatTimeUpdatedActivity = {
    ...activity,
    start: activity.start.getTime(),
    end: activity.end.getTime(),
  } as const;

  const updateActivityCF = httpsCallablePost('web-routes/activity/updateActivity');
  const req = {
    teamId,
    activityId,
    updatedActivity: flatTimeUpdatedActivity,
  } as const;

  const { data } = await updateActivityCF(req);
  return data;
}
