import React from 'react';
import { Redirect } from 'react-router-dom';

import { get } from 'lodash-es';

import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import NotFound from 'plantiga-component/Error/NotFound';
import useActivity from 'plantiga-firebase/Activity/useActivity';

function ActivityNotFound() {
  return <NotFound title="Activity Not Found" />;
}

export default function ActivityRoute() {
  const { teamId, activityId, page, key } = useParams();
  const { activity, loading, error } = useActivity(activityId || 'not_found');

  // This is a redirect, should not have an issue with unstable render.
  // eslint-disable-next-line react/no-unstable-nested-components
  function InnerComponent() {
    if (activity == null) {
      return <ActivityNotFound />;
    }
    const athleteId = get(activity, 'athlete_id');
    const prefix = `/org/${teamId}/people/${athleteId}/activity/${activityId}`;
    return <Redirect to={`${prefix}/${page || 'info'}${key ? `/${key}` : ''}`} />;
  }

  return (
    <Wait waitingOn={loading} info="ActivityRedirect" error={error}>
      {!loading && InnerComponent()}
    </Wait>
  );
}
