import type { FieldDefinition } from './typedefs';

export const MISSING_FIELD_DEFINITION: FieldDefinition = {
  id: 'not_found',
  activity_types: '.*',
  name: 'Metric Not Found',
  base: 'not_found',
  summary_type: 'not_found',
  key: 'not_found',
  status: 'released',
  service: undefined,
};
