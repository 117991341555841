import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Note } from '@mui/icons-material';
import { Paper, Popover } from '@mui/material';

import type { Activity } from 'plantiga-firebase/Activity/typedefs';

type Props = {
  readonly activity: Activity;
};

const useStyles = makeStyles()((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    whiteSpace: 'pre-wrap',
    maxWidth: '400px',
    padding: theme.spacing(1),
  },
  popoverAnchor: {
    display: 'inherit',
    pointerEvents: 'initial',
  },
}));

export default function NoteDisplay({ activity }: Props) {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null | undefined>(null);
  if (!activity.notes) {
    return null;
  }

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <span
        className={classes.popoverAnchor}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Note fontSize="small" color={open ? undefined : 'disabled'} />
      </span>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        className={classes.popover}
      >
        <Paper className={classes.paper}>{activity.notes}</Paper>
      </Popover>
    </>
  );
}
