import React from 'react';

import { useUserContext } from './useUserContext';

export const UserContext = React.createContext<ReturnType<typeof useUserContext>>({
  currentUser: undefined,
  userClaims: {},
  error: null,
});

export function UserProvider({ children }: { children: React.ReactNode }) {
  const ctx = useUserContext();
  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
}
