import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  Button,
  type ButtonProps,
  CircularProgress,
  IconButton,
  type IconButtonProps,
} from '@mui/material';

const useStyles = makeStyles()(() => ({
  root: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  progress: {
    position: 'absolute',
    // Prevent scroll bar from showing up when button close to edge
    overflow: 'hidden',
  },
}));

function useProgressStyle(): [React.CSSProperties, React.RefCallback<HTMLButtonElement>] {
  const [height, setHeight] = React.useState(0);

  const updateHeight = React.useCallback((node: HTMLButtonElement) => {
    const { clientHeight } = node || {};
    setHeight(clientHeight || 0);
  }, []);

  return [{ height: height * 0.75, width: height * 0.75 }, updateHeight];
}

type Props = {
  readonly loading: boolean;
  readonly disabled?: boolean;
  readonly onClick: any;
  readonly children: React.ReactNode;
  readonly className?: string;
};

export default function ButtonWithLoading({
  loading,
  onClick,
  disabled,
  children,
  className,
  ...buttonProps
}: ButtonProps & Props) {
  const { classes } = useStyles();
  const [progressStyle, ref] = useProgressStyle();
  return (
    <Button
      {...buttonProps}
      className={className}
      disabled={disabled === true || loading}
      onClick={onClick}
      ref={ref}
    >
      <div className={classes.root}>
        {loading && <CircularProgress className={classes.progress} style={progressStyle} />}
        {children}
      </div>
    </Button>
  );
}

export function IconButtonWithLoading({
  loading,
  onClick,
  disabled,
  children,
  className,
  ...buttonProps
}: IconButtonProps & Props) {
  const { classes } = useStyles();
  const [progressStyle, ref] = useProgressStyle();
  return (
    <IconButton
      {...buttonProps}
      className={className}
      disabled={disabled === true || loading}
      onClick={onClick}
      ref={ref}
      size="large"
    >
      <div className={classes.root}>
        {loading && <CircularProgress className={classes.progress} style={progressStyle} />}
        {children}
      </div>
    </IconButton>
  );
}
