import type { ActivityTypes } from './typedefs';

type PseudoBaseActivityType = 'walk' | 'run' | 'unilateral_jump' | 'bilateral_jump' | 'open';
// these are reasonable groupings for activity types, but do not reflect actual base activity types
const PSEUDO_BASE_TYPES: {
  [activityType: string]: PseudoBaseActivityType;
} = {
  height_single_leg_jump: 'unilateral_jump',
  single_leg_jump_distance: 'unilateral_jump',
  single_leg_jump_rsi: 'unilateral_jump',
  Cyclic_jump: 'bilateral_jump',
  consecutive_cmj: 'bilateral_jump',
  sprint: 'run',
  auto_detect: 'open',
};

export class ActivityTypeDefn {
  private ID: string;

  private defns: ActivityTypes;

  constructor(id: string, defns: ActivityTypes) {
    this.ID = id;
    this.defns = defns;
  }

  get id() {
    return this.ID;
  }

  get base() {
    return this.defns[this.id].type;
  }

  /**
   * A reasonable base activity-type that does not necessarily reflect the actual base-type.
   *
   * WARNING:
   * `pseudoBase` is primarily intended for UI purposes.
   * Use it carefully as it may result in unexpected behaviour
   *
   * e.g. For `height_single_leg_jump`
   * ```
   * base -> height_single_leg_jump //  not unilateral_jump for legacy reasons
   * pseudoBase -> unilateral_jump
   * ```
   */
  get pseudoBase() {
    return PSEUDO_BASE_TYPES[this.base] ?? this.base;
  }

  get name() {
    return this.defns[this.id].name;
  }

  get enabled() {
    return this.defns[this.id].enabled;
  }

  get defn() {
    return this.defns[this.id];
  }

  get isBase() {
    return this.base === this.id;
  }

  toString() {
    return this.id;
  }

  toJson() {
    return JSON.stringify(this.defn);
  }
}

export type ActivityTypeDefns = {
  [activityTypeId: string]: ActivityTypeDefn;
};
