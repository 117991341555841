import React from 'react';

import { useToast } from '../../Toast/UseToast';
import useTeam from '../Team/useTeam';
import { httpsCallablePost } from '../util';

export default function usePair(): {
  loading: boolean;
  pair: (deviceIds: Array<string>, athleteId: string) => Promise<void>;
} {
  const { teamId } = useTeam();
  const postToast = useToast();

  const [loading, setLoading] = React.useState(false);

  const pair = React.useCallback(
    async (deviceIds: Array<string>, athleteId: string) => {
      setLoading(true);
      const postErr = () =>
        postToast({
          message: 'There was an error pairing the pods to the person',
          variant: 'error',
        });
      try {
        const cloudPair = httpsCallablePost('web-routes/team/pair');

        const { data } = await cloudPair({ teamId, athleteId, deviceIds });
        if (data.errors) {
          console.error(data.errors);
          postErr();
        }
      } catch (err: any) {
        console.error(err);
        postErr();
      }
      setLoading(false);
    },
    [postToast, teamId],
  );

  return { loading, pair };
}

export function useUnpair(): {
  loading: boolean;
  unpair: (deviceId: string) => Promise<void>;
} {
  const { teamId } = useTeam();
  const postToast = useToast();
  const [loading, setLoading] = React.useState(false);
  const reloadPage = React.useCallback(() => window.location.reload(), []);

  const unpair = React.useCallback(
    async (deviceId: string) => {
      setLoading(true);
      try {
        const cloudUnpair = httpsCallablePost('web-routes/team/unpair');
        const { data } = await cloudUnpair({ teamId, deviceId });
        const { errors } = data;

        if (errors) {
          if (errors.type === 'Needs refresh') {
            postToast({
              message: errors.message,
              variant: 'error',
              action: reloadPage,
            });
          }
          console.error(errors);
        }
      } catch (err: any) {
        console.error(err);
        postToast({
          message: 'There was an error unpairing',
          variant: 'error',
        });
      }
      setLoading(false);
    },
    [postToast, reloadPage, teamId],
  );

  return { loading, unpair };
}
