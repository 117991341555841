import React from 'react';

import useSWR from 'swr';

import type {
  MetricValueDefn,
  MetricValues,
  MetricValue,
  MetricValueWithNull,
} from '@plantiga/activity-summaries';

import useActivitySummariesClient from 'plantiga-common/useActivitySummariesClient';
import useTeam from 'plantiga-firebase/Team/useTeam';

const initData = <MetricValueType extends MetricValue | MetricValueWithNull>(
  metrics: MetricValueDefn[],
) => metrics.map((v) => ({ ...v, values: [] as MetricValueType[] }));

/**
 * Utility function to get the flat metric value data across all activity-types, split-activity-types, and field-ids
 */
export function flattenMetricValues(data: ReturnType<typeof useMetricsTrends>['data']) {
  return data.flatMap((v) => v.values);
}

type Options = { shouldFetch?: boolean } & Omit<
  Parameters<ReturnType<typeof useActivitySummariesClient>['getUserMetrics']>[0],
  'userId' | 'orgId' | 'metricDefinitions'
>;

/**
 * Get historical metric values
 */
export function useMetricsTrends<MetricValueType extends MetricValue | MetricValueWithNull>(
  athleteId: string,
  metrics: MetricValueDefn[],
  options?: Options,
) {
  const { teamId } = useTeam();

  const client = useActivitySummariesClient();

  type FetcherArgs = [
    func: typeof client.getUserMetrics,
    userId: string,
    orgId: string,
    metricDefinitions: MetricValueDefn[],
    afterTimestamp: number | undefined,
    beforeTimestamp: number | undefined,
    allowNull: Options['allowNull'],
    limit: Options['limit'],
    sourceActivityOnly: Options['sourceActivityOnly'],
  ];

  const fetcher = React.useCallback(
    async ([
      func,
      userId,
      orgId,
      metricDefinitions,
      afterTimestamp,
      beforeTimestamp,
      allowNull,
      limit,
      sourceActivityOnly,
    ]: FetcherArgs) => {
      const { data } = await func({
        userId,
        orgId,
        metricDefinitions,
        after: afterTimestamp ? new Date(afterTimestamp) : undefined,
        before: beforeTimestamp ? new Date(beforeTimestamp) : undefined,
        allowNull,
        limit,
        sourceActivityOnly,
      });
      return data as MetricValues<MetricValueType>[];
    },
    [],
  );

  const args: FetcherArgs = [
    client.getUserMetrics,
    athleteId,
    teamId,
    metrics,
    options?.after ? +options.after : undefined,
    options?.before ? +options.before : undefined,
    options?.allowNull,
    options?.limit,
    options?.sourceActivityOnly,
  ];

  const swrOptions = React.useMemo(
    () => ({
      fallbackData: initData<MetricValueType>(metrics),
      dedupingInterval: 60 * 1000,
      revalidateOnFocus: false,
      keepPreviousData: true,
    }),
    [metrics],
  );

  const shouldFetch = options?.shouldFetch ?? true;
  return useSWR(shouldFetch ? args : null, fetcher, swrOptions);
}
