import React from 'react';

import { Popover as BasePopover, type PopoverActions } from '@mui/material';

export default React.forwardRef<HTMLDivElement, React.ComponentProps<typeof BasePopover>>(
  // eslint-disable-next-line react/prop-types
  ({ children, ...props }, popoverRef) => {
    const actionsRef = React.useRef<PopoverActions | null>(null);
    const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

    // Handle dynamic content size by repositioning the popover.
    React.useEffect(() => {
      if (ref == null) return () => undefined;
      const observer = new ResizeObserver(() => {
        actionsRef.current?.updatePosition();
      });
      observer.observe(ref);
      return () => observer.unobserve(ref);
    }, [ref]);

    return (
      <BasePopover
        ref={popoverRef}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...props}
        action={actionsRef}
      >
        <div ref={setRef}>{children}</div>
      </BasePopover>
    );
  },
);
