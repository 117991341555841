import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TeamRedirect from 'plantiga-common/TeamRedirect';

import AuthLayout from '../__auth';

import urls from './_urls';
import currentOrg from './current-org';
import newTeam from './new-team';
import teamRoutes from './org/[teamId]/_routes';
import { root as teamRoot } from './org/[teamId]/_urls';

import index from './index';

export default function AuthenticatedRoutes() {
  return (
    <AuthLayout>
      <Switch>
        <Route path={urls.welcome} exact component={index} />
        <Route path={urls.team} exact component={index} />
        <Route path={urls.teamProfileRedirect} component={TeamRedirect} />
        <Route path={urls.currentTeam} component={currentOrg} />
        {/* TODO move to admin */}
        <Route path={urls.newTeam} exact component={newTeam} />
        <Route path={teamRoot} component={teamRoutes} />
      </Switch>
    </AuthLayout>
  );
}
