import { fromPairs } from 'lodash-es';

import type { ActivityType } from 'plantiga-firebase/schema';

import BaseAPIClient from './BaseApiClient';

export class UserError extends Error {
  constructor(message: string) {
    super(message);
    this.message = message;
  }
}

export class OrgApiClient extends BaseAPIClient {
  listActivityTypes = async ({ team_id: teamId }: { team_id: string }) => {
    const path = `/cloud-gears/org/${teamId}/activity-types`;
    const [json, res] = await this.send('GET', path);
    if (res.status === 200) {
      const { activity_types: activityTypes } = json;
      return fromPairs(activityTypes.map((v: ActivityType) => [v.type_id, v])) as {
        [activityId: string]: ActivityType;
      };
    }
    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };

  createActivityType = async (
    teamId: string,
    payload: {
      name: string;
      enabled: boolean;
      activity_labels: { [key: string]: string };
    },
  ) => {
    const path = `/cloud-gears/org/${teamId}/activity-types`;
    const options = { body: payload } as const;
    const [_, res] = await this.send('POST', path, options);
    if (res.status === 200) return;

    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };

  updateActivityType = async (
    teamId: string,
    activityTypeId: string,
    payload: {
      name?: string;
      enabled?: boolean;
      archived?: boolean;
      activity_labels?: { [key: string]: string };
    },
  ) => {
    const path = `/cloud-gears/org/${teamId}/activity-types/${activityTypeId}`;
    const options = { body: payload } as const;
    const [json, res] = await this.send('PATCH', path, options);
    if (res.status === 200) return;
    if (json?.message) throw new UserError(json.message);

    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };

  getAutoDetectActivityType = async (teamId: string) => {
    const path = `/cloud-gears/org/${teamId}/activity-types/auto-detect`;
    const [json, res] = await this.send('GET', path);
    if (res.status === 200) {
      return json.activity_type as ActivityType;
    }
    if (json?.message) throw new Error(json.message);

    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };

  setAutoDetectActivityType = async (teamId: string, activityTypeId: string | null) => {
    const path = `/cloud-gears/org/${teamId}/activity-types/auto-detect`;
    const options = { body: { activity_type: activityTypeId } };
    const [json, res] = await this.send('POST', path, options);
    if (res.status === 200) return undefined;
    if (json?.message) throw new UserError(json.message);

    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };

  updateDock = async (
    teamId: string,
    dockId: string,
    payload: {
      device_name?: string;
      archived?: boolean;
    },
  ) => {
    const path = `/cloud-gears/org/${teamId}/docks/${dockId}`;
    const options = { body: payload };
    const [_, res] = await this.send('PATCH', path, options);
    if (res.status === 200) return undefined;

    throw new Error(`[${res.statusText}]: ${res.status} - ${res.url}`);
  };
}
