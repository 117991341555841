import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Fullscreen, FullscreenExit, Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Hidden, IconButton, Toolbar, Tooltip } from '@mui/material';
import { alpha } from '@mui/material/styles';

import { useFullWidthPage } from 'plantiga-common/FullWidthPage';

import { DRAWER_WIDTH_OPEN } from '../AppDrawer';

import Breadcrumbs from './Breadcrumbs';
import Disappear from './Disappear';
import { useTeamAppBarSetRef } from './useTeamAppBar';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    backgroundColor: alpha(theme.palette.background.default, 0.5),
    backdropFilter: `blur(${theme.spacing(1)})`,
    paddingTop: `env(safe-area-inset-top)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
  },
  appBarClosed: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
      marginLeft: DRAWER_WIDTH_OPEN,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  toolbar: {
    width: '100%',
    display: 'flex',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  hSpacer: {
    flex: 1,
  },
  rightContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

type Props = {
  readonly open?: boolean;
  readonly handleDrawerOpen?: any;
};

export default function TeamAppBar({ open = false, handleDrawerOpen }: Props) {
  const [fullWidth, toggleFullWidth] = useFullWidthPage();
  const setAppBarRef = useTeamAppBarSetRef();
  const { classes, cx } = useStyles();

  return (
    <AppBar
      elevation={0}
      position="sticky"
      className={cx(classes.appBar, { [classes.appBarShift]: open, [classes.appBarClosed]: !open })}
    >
      <Toolbar className={classes.toolbar}>
        <Disappear in={!open}>
          <div>
            <IconButton edge="start" onClick={handleDrawerOpen} size="large">
              <MenuIcon />
            </IconButton>
          </div>
        </Disappear>
        <Breadcrumbs />
        <div className={classes.hSpacer} />
        <div className={classes.rightContent}>
          <div ref={setAppBarRef} />
          <Hidden smDown>
            <Tooltip title={`${fullWidth ? 'Disable' : 'Enable'} full width`}>
              <IconButton edge="end" size="small" onClick={toggleFullWidth}>
                {fullWidth ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Tooltip>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
}
