import React from 'react';

import { useIsMounted } from 'usehooks-ts';

export default function useLatestVersion() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState(true);
  const [latestVersion, setLatestVersion] = React.useState('?');

  const fetchLatestVersion = React.useCallback(async () => {
    try {
      const response = await fetch(`/assets/version.json?v=${Date.now()}`);
      const data = (await response.json()) as { version?: string | unknown };
      if (!isMounted()) return;
      if (typeof data?.version === 'string') setLatestVersion(data.version);
    } catch {
      // do nothing
    }

    if (!isMounted()) return;
    setLoading(false);
  }, [isMounted]);

  React.useEffect(() => {
    fetchLatestVersion();
  }, [fetchLatestVersion]);

  const currentVersion = React.useMemo(() => process.env.BUILD_VERSION, []);

  return { latestVersion, currentVersion, loading };
}
