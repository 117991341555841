import { Firestore, getDoc } from 'firebase/firestore';

import { fromPairs, keys, toPairs } from 'lodash-es';

import { doc } from '..';
import type { Coach, Team } from '../schema';

type SuperUser = Coach['super_user'];

const getSuperUserTeams = (superUser: SuperUser | undefined): { [teamId: string]: true } =>
  superUser
    ? toPairs(superUser.teams).reduce(
        (newObj, [key, timeStamp]) =>
          // This conditional should match firestore rules
          superUser.is_admin && +timeStamp.toDate() > Date.now()
            ? {
                ...newObj,
                [key]: true,
              }
            : { ...newObj },
        {},
      )
    : {};

const mergeCoachTeamsKeysWithSuperUserTeamsKeys = (
  teams:
    | {
        [key: string]: boolean;
      }
    | undefined,
  superUser: SuperUser | undefined,
) => {
  const tempTeams = getSuperUserTeams(superUser);
  const teamsKeys = keys(teams).filter((key) => teams?.[key]);
  const tempKeys = superUser ? keys(tempTeams).filter((key) => tempTeams[key]) : [];
  const uniqueKeys = [...new Set([...teamsKeys, ...tempKeys])];
  return uniqueKeys;
};

async function getTeamsInfo(db: Firestore, teamsKeys: Array<string>) {
  const teamPairs = await Promise.all(
    teamsKeys.map(async (teamId) => {
      const ref = doc(db, 'teams', teamId);
      try {
        const snap = await getDoc(ref);
        return [snap.id, snap.data()];
      } catch (err: any) {
        console.warn(err.toString());
        return [teamId, null];
      }
    }),
  );

  return fromPairs(teamPairs.filter(([, team]) => team != null));
}

export async function getTeams(
  db: Firestore,
  coach: Coach | undefined | null,
): Promise<Record<string, Team>> {
  if (coach == null) {
    return {};
  }
  const mergedTeamsKeys = mergeCoachTeamsKeysWithSuperUserTeamsKeys(coach.teams, coach.super_user);

  const teams = await getTeamsInfo(db, mergedTeamsKeys);
  return teams;
}

export async function getTeam(db: Firestore, currentTeamId: string): Promise<Team> {
  const ref = doc(db, 'teams', currentTeamId);
  const snap = await getDoc(ref);
  // TODO: undefined
  return snap.data() as Team;
}
