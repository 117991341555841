import React from 'react';

import { find, pickBy, mapValues } from 'lodash-es';

import { useLatestActivity } from 'plantiga-firebase/Activity/useActivity';
import { useDevice } from 'plantiga-firebase/Devices';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import type { Activity } from 'plantiga-firebase/schema';
import type { Stopwatches } from 'plantiga-firebase/Stopwatch/typedefs';
import { useActiveStopwatches } from 'plantiga-firebase/Stopwatch/useStopwatch';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';

const WARNINGS = {
  missing: (deviceId: string) => `${deviceNiceId(deviceId)} is missing or deleted.`,
  broken: (deviceId: string) => `${deviceNiceId(deviceId)} is broken.`,
  recording: (deviceId: string) => `${deviceNiceId(deviceId)} is recording an activity.`,
  uploading: (deviceId: string) => `${deviceNiceId(deviceId)} is uploading data.`,
  uploadPending: (deviceId: string) => `${deviceNiceId(deviceId)} has data to upload.`,
};

function checkDeviceMissing(device: Device | null) {
  return device == null;
}

function checkDeviceBroken(device: Device) {
  return !!device.broken;
}

function checkDeviceRecording(device: Device, stopwatches: Stopwatches) {
  return !!find(stopwatches, (s) => s.athlete_ids.includes(device?.athlete_id ?? ''));
}

function checkDeviceUploading(device: Device) {
  return device.status === 'uploading';
}

function checkDeviceUploadPending(device: Device, activity: Activity | null | undefined) {
  if (device.last_upload?.time == null || activity == null) return false;
  return activity.end.toMillis() > device.last_upload.time.toMillis();
}

export default function useDeviceWarnings(deviceId: string) {
  const device = useDevice(deviceId);
  const { stopwatches } = useActiveStopwatches();
  const { activity } = useLatestActivity(device?.athlete_id ?? undefined);

  return React.useMemo(() => {
    const warnings = {
      missing: checkDeviceMissing(device),
      broken: device != null && checkDeviceBroken(device),
      recording: device != null && checkDeviceRecording(device, stopwatches),
      uploading: device != null && checkDeviceUploading(device),
      uploadPending: device != null && checkDeviceUploadPending(device, activity),
    };
    const active = pickBy<typeof warnings>(warnings, Boolean);
    return mapValues(active, (_: boolean, k: keyof typeof WARNINGS) => WARNINGS[k](deviceId));
  }, [activity, device, deviceId, stopwatches]);
}
