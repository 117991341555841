import activity from './activity/_urls';

export const root = '/org/:teamId/people/:athleteId/daily/:day';

export default {
  athleteDailyActivityDay: `${root}/activityType/:type`,
  athleteMultiActivityReport: `${root}/activity-report/:templateId`,

  ...activity,
} as const;
