import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  NavigateBefore as NavigateBeforeIcon,
  NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import { Button, DialogActions } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';

import { STEPS } from './util';

const useStyles = makeStyles()((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  activeStep: number;
  isMobile: boolean;
  loading: boolean;
  disableNext: boolean;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleSkip: any | null | undefined;
  handleFinish: any;
  handleNext: any;
};

export default function StepperButtons({
  activeStep,
  isMobile,
  setActiveStep,
  loading,
  disableNext,
  handleSkip,
  handleFinish,
  handleNext,
}: Props) {
  const { classes } = useStyles();
  const size = isMobile ? 'small' : 'medium';

  return (
    <ConditionalWrapper condition={!isMobile} wrapperComponent={DialogActions}>
      {activeStep > 0 && (
        <Button
          size={size}
          variant="outlined"
          className={classes.button}
          startIcon={<NavigateBeforeIcon />}
          onClick={() => setActiveStep((prev) => (prev > 0 ? prev - 1 : prev))}
        >
          Back
        </Button>
      )}
      {activeStep > 0 && activeStep < STEPS.length - 1 && (
        <Button size={size} variant="outlined" className={classes.button} onClick={handleSkip}>
          Skip
        </Button>
      )}
      {(activeStep === 0 || activeStep === STEPS.length - 1) && (
        <ButtonWithLoading
          size={size}
          className={classes.button}
          variant="contained"
          color={activeStep === 0 ? 'inherit' : 'primary'}
          loading={loading}
          disabled={disableNext}
          onClick={handleFinish}
        >
          Finish
        </ButtonWithLoading>
      )}
      {activeStep < STEPS.length - 1 && (
        <Button
          size={size}
          className={classes.button}
          endIcon={<NavigateNextIcon />}
          variant="contained"
          color="primary"
          disabled={disableNext}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </ConditionalWrapper>
  );
}
