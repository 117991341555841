import {
  Healing,
  Accessibility as AccessibilityIcon,
  Person as PersonIcon,
} from '@mui/icons-material';

export const textFieldProps = {
  margin: 'normal',
  variant: 'outlined',
  fullWidth: true,
} as const;

export const STEPS = [
  {
    label: 'Full Name',
    icon: PersonIcon,
    analyticsEvent: 'new_person_stepper__name_filled',
  },
  {
    label: 'Personal Info',
    icon: AccessibilityIcon,
    analyticsEvent: 'new_person_stepper__profile_info_filled',
  },
  {
    label: 'Injuries',
    icon: Healing,
    analyticsEvent: 'new_person_stepper__injuries_customized',
  },
] as const;
