import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';

import type { Athlete } from 'plantiga-firebase/Athletes/typedefs';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },

  content: {
    width: '100%',
  },
  pre: {
    whiteSpace: 'pre',
  },
});

type Props = {
  readonly athleteId: string;
  readonly athlete: Athlete;
  readonly title: string;
  readonly field: string;
  readonly editAthlete: any;
  readonly multiline?: boolean;
};
export default function EditRow({
  athleteId,
  athlete,
  title,
  field,
  editAthlete,
  multiline = false,
}: Props) {
  const { classes, cx } = useStyles();
  const [editing, setEditing] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Athlete'.
  const [newValue, setNewValue] = React.useState(athlete[field] || '');

  const doSave = async () => {
    setSaving(true);
    await editAthlete(athleteId, { [field]: newValue });
    setEditing(!editing);
    setSaving(false);
  };
  return (
    <TableRow>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <TextField
            variant="outlined"
            autoFocus
            fullWidth
            multiline={multiline}
            value={newValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !multiline) {
                doSave();
              }
            }}
            onChange={(e) => setNewValue(e.target.value)}
          />
        ) : (
          // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Athlete'.
          <span className={cx({ [classes.pre]: multiline })}>{athlete[field]}</span>
        )}
      </TableCell>
      <TableCell>
        <IconButton disabled={saving} onClick={doSave} size="large">
          {editing ? <SaveIcon /> : <EditIcon />}
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
