import { query, orderBy, where } from 'firebase/firestore';
import React from 'react';

import useTeam from 'plantiga-firebase/Team/useTeam';
import useFirestore from 'plantiga-firebase/useFirestore';
import useSubscribe from 'plantiga-firebase/useSubscribe';

import { collection } from '..';

export default function useEvents(athleteId: string) {
  const db = useFirestore();
  const { teamId } = useTeam();
  const ref = React.useMemo(
    () =>
      query(
        collection(db, 'events'),
        where('team_id', '==', teamId),
        where('athlete_id', '==', athleteId),
        orderBy('date', 'desc'),
      ),
    [athleteId, db, teamId],
  );

  const [events, loading, error] = useSubscribe(ref);

  return { events, loading, error };
}
