import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

type Props = {
  location: 'R' | 'L' | undefined;
  radius?: number;
  strokeWidth?: number;
};

const useStyles = makeStyles()({
  base: {
    fontSize: '1rem',
    fontWeight: 600,
    fontFamily: 'monospace',
    overflow: 'visible',
  },
});

export function ChiralityAvatar({ location, strokeWidth = 1, radius: _radius = 12 }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const loc = () => {
    if (location === 'L') return 'left';
    if (location === 'R') return 'right';
    return location ?? 'left';
  };
  const radius = _radius;
  const size = _radius * 2 + strokeWidth * 2;
  return (
    <svg width={size} height={size} className={classes.base}>
      <Group top={size / 2} left={size / 2}>
        <circle
          r={radius}
          fill={theme.palette.location[loc()]}
          stroke={theme.palette.location[loc()]}
          strokeWidth={strokeWidth}
        />
        <Text verticalAnchor="middle" textAnchor="middle" fill="#fff" fontSize={_radius * 1.5}>
          {location}
        </Text>
      </Group>
    </svg>
  );
}
