import React from 'react';

import ErrorCard from './ErrorCard';
import ErrorPage from './ErrorPage';

export default function ErrorView() {
  return (
    <ErrorPage>
      <ErrorCard />
    </ErrorPage>
  );
}
