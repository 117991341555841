import React, { useState } from 'react';

import { InputAdornment, TextField } from '@mui/material';

import {
  type DisplayUnit,
  useUnitTranslator,
} from 'plantiga-firebase/Team/universalUnitTranslator';

import FeetInchesField from './FeetInchesField';

type Props = {
  readonly onChange: (arg1: number) => void;
  readonly storedUnit: string;
  readonly displayUnit: DisplayUnit | null | undefined;
  readonly value: number | null;
};

function round(value?: number | null): number | null | undefined {
  if (value == null) return value;
  return Math.round(value);
}

export default function UnitTextField({
  onChange,
  value,
  storedUnit,
  displayUnit,
  ...props
}: Props) {
  const ut = useUnitTranslator(storedUnit, displayUnit);
  // Convert from stored to display value
  const [displayValue, setDisplayValue] = useState(round(ut.convert(value)));

  const onValueChange = (newValue: number) => {
    setDisplayValue(newValue);
    // When saving, convert back
    onChange(ut.unconvert(newValue));
  };

  if (ut.wantedUnit === 'ft/in') {
    return (
      <FeetInchesField {...props} value={ut.convert(value)} onChange={(v) => onValueChange(v)} />
    );
  }

  return (
    <TextField
      {...props}
      value={displayValue}
      // @ts-expect-error - TS2345 - Argument of type 'string' is not assignable to parameter of type 'number'.
      onChange={(event) => onValueChange(event.target.value)}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">{ut.wantedUnit}</InputAdornment>,
      }}
    />
  );
}
