import React from 'react';

import { map, sortBy as orderBy, toPairs } from 'lodash-es';

import DevDataTree from 'plantiga-common/DevDataTree';
import type { Summary } from 'plantiga-firebase/Summaries/typedefs';

type Props = {
  summaries: {
    [key: string]: Summary;
  };
};

export default function SummariesContent({ summaries }: Props) {
  const orderedSummaries = React.useMemo(
    () => orderBy(toPairs(summaries), ([k]: [any]) => [k]),
    [summaries],
  );
  return (
    <div>
      {map(orderedSummaries, ([summaryId, summary]: [any, any]) => (
        <DevDataTree key={summaryId} data={summary} dataId={summaryId} />
      ))}
    </div>
  );
}
