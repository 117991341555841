import { serverTimestamp } from 'firebase/firestore';
import React from 'react';
import { useWatch, useForm, type FieldErrorsImpl } from 'react-hook-form';

import { isArray, size, compact } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Warning } from '@mui/icons-material';
import { Card, CardContent, Collapse, Tooltip, Typography } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';
import { useQuery } from 'plantiga-common/react-router-hooks';
import ControlledAutocompleteActivity from 'plantiga-common/ReactHookForm/ControlledAutocompleteActivity';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import useResize from 'plantiga-common/useResize';
import { useToast } from 'plantiga-component/Toast/UseToast';
import { ActivityTypeDefn } from 'plantiga-firebase/ActivityTypes/ActivityTypeDefn';
import useActivityTypes from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import type {
  StopwatchWarnings as Warnings,
  StopwatchPreDB,
} from 'plantiga-firebase/Stopwatch/typedefs';
import { useActiveStopwatches, useCreateStopwatch } from 'plantiga-firebase/Stopwatch/useStopwatch';

import ActiveStopwatchesListItem from '../ActiveStopwatches/ActiveStopwatchesListItem';

import ControlledPeopleSelect from './ControlledPeopleSelect';
import getFormValues from './getFormValues';

const useStyles = makeStyles()((theme) => ({
  button: {
    minWidth: 96,
    borderRadius: 20,
  },
  pageGutterBottomContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'initial',
    },
  },
  form: {
    display: 'grid',
    gap: theme.spacing(2),
  },
  buttonContainer: {
    margin: 'auto',
    width: 'fit-content',
    padding: theme.spacing(2),
  },
}));

const stopwatchFactory = (
  actType: ActivityTypeDefn,
  data: ReturnType<typeof getFormValues>,
): Omit<StopwatchPreDB, 'created_by'> => ({
  preset: '',
  notes: '',
  athlete_ids: data.athleteWarnings.map(({ athleteId }) => athleteId),
  mark_labels: {},
  type_id: data.activityType,
  start: new Date(),
  server_start: serverTimestamp(),
  run_state: 'running',
  active: true,
  // get all unique keys of ignored warnings
  warnings_ignored: [
    ...(data?.athleteWarnings.reduce((acc, { warnings }) => {
      Object.keys(warnings).forEach((w) => acc.add(w as keyof Warnings));
      return acc;
    }, new Set<keyof Warnings>()) ?? []),
  ],
  name: actType.name,
  activity_labels: {
    ...data.labels,
    ...actType.defn.activity_labels,
    type_id: data.activityType,
  },
});

const collectErrorMessages = (errors: FieldErrorsImpl<ReturnType<typeof getFormValues>>) => {
  const msgs = Object.values(errors).flatMap((fieldError) => {
    const errMsgs = [];
    if ('message' in fieldError) errMsgs.push(fieldError.message as string);
    else if ('root' in fieldError) errMsgs.push(fieldError.root?.message as string | undefined);
    else if (isArray(fieldError)) {
      // only push top-level error messages
      fieldError.forEach((v = {}) => {
        if ('message' in v) errMsgs.push(v.message as string);
      });
    }
    return errMsgs;
  });
  return compact(msgs);
};

export default function StopwatchSetup() {
  const { classes } = useStyles();
  const postToast = useToast();
  const queryParams = useQuery();
  const athletes = useAthletes();
  const devices = useDevices();
  const { stopwatches } = useActiveStopwatches();
  const {
    control,
    register,
    trigger,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: getFormValues(queryParams, athletes, devices, stopwatches),
  });
  const [activityType, athleteWarnings, ignoreAll] = useWatch({
    control,
    name: ['activityType', 'athleteWarnings', 'ignoreAll'],
  });

  const actTypes = useActivityTypes();
  const [creating, createStopwatch] = useCreateStopwatch();
  const navigateURL = useNavigateURL();

  React.useEffect(() => {
    // check athleteWarnings for errors when the activity type changes
    trigger(['athleteWarnings', 'activityType']);
  }, [activityType, trigger]);

  React.useEffect(() => {
    // check athleteWarnings for errors on change
    trigger('athleteWarnings');
  }, [athleteWarnings, ignoreAll, trigger]);

  React.useEffect(() => {
    // track the form state in the url query parameters
    const usp = new URLSearchParams(queryParams);
    usp.set('activityType', activityType);
    usp.set('people', athleteWarnings.map(({ athleteId }) => athleteId).join(','));
    usp.set('ignoreAll', ignoreAll.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${usp.toString()}`);
  }, [athleteWarnings, activityType, ignoreAll, queryParams]);

  const handleConfirm = React.useCallback(
    async (data: ReturnType<typeof getFormValues>) => {
      const actType = actTypes[data.activityType];
      const stopwatch = stopwatchFactory(actType, data);
      const res = await createStopwatch(stopwatch);
      if (res.id == null) {
        postToast({
          message: `${res.athleteIds.map((aid) => athletes[aid]?.name).join(', ')} ${
            res.athleteIds.length > 1 ? 'have active recordings' : 'has an active recording'
          }`,
          variant: 'error',
        });
      } else {
        const people = data.athleteWarnings.map(({ athleteId }) => athleteId).join(',');
        navigateURL(
          'recordActive',
          { activityTypeId: data.activityType, swid: res.id },
          { ...queryParams, activityType: data.activityType, people, ignoreAll: data.ignoreAll },
        );
      }
    },
    [actTypes, athletes, queryParams, createStopwatch, navigateURL, postToast],
  );

  // react-hook-form maintains a single errors object instance, so don't memoize this
  const msgs = collectErrorMessages(errors);
  const errorTitle = msgs.length === 0 ? '' : msgs.map((msg) => <div key={msg}>{msg}</div>);

  const hasWarnings = athleteWarnings.some((v) => size(v.warnings) > 0);

  const [bottomGutterHeight, setHeight] = React.useState<number | undefined>();
  const { ref: bottomGutterRef } = useResize((el: HTMLDivElement) =>
    setHeight(el.getBoundingClientRect()?.height),
  );
  return (
    <form className={classes.form}>
      <ActiveStopwatchesListItem />
      <ControlledAutocompleteActivity
        name="activityType"
        control={control}
        rules={{ required: 'Please select an activity type', pattern: /.+/ }}
        innerProps={{
          textFieldProps: {
            fullWidth: true,
            autoFocus: !activityType,
            margin: 'dense',
            size: 'small',
          },
        }}
      />
      <ControlledPeopleSelect control={control} register={register} setValue={setValue} />
      {/* spacer to ensure the page content is not blocked by the bottom gutter */}
      <div style={{ height: bottomGutterHeight ?? 0, width: '100%' }} />
      <PageGutterPortal gutter="bottom">
        <div className={classes.pageGutterBottomContainer} ref={bottomGutterRef}>
          <Card variant="outlined" style={{ width: 320 }}>
            <Collapse in={hasWarnings}>
              <div style={{ padding: 16, gap: 8, display: 'grid' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Warning color="secondary" />
                </div>
                <Typography>Please address all of the warnings above</Typography>
              </div>
            </Collapse>
            <Collapse in={msgs.length === 0}>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  <b>Before you start:</b>
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Ensure everyone&apos;s <b>Pods are in their shoes</b>.
                </Typography>
                <Typography variant="body2">
                  Ensure everyone is <b>standing still</b>.
                </Typography>
              </CardContent>
            </Collapse>
            <Tooltip title={errorTitle}>
              <div className={classes.buttonContainer}>
                <ButtonWithLoading
                  id="stopwatch-start-button"
                  className={classes.button}
                  disabled={msgs.length > 0}
                  loading={creating}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleConfirm, (v) => console.error(v))}
                >
                  Start
                </ButtonWithLoading>
              </div>
            </Tooltip>
          </Card>
        </div>
      </PageGutterPortal>
    </form>
  );
}
