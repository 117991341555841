import React from 'react';

import { doc } from '..';
import useUser, { useUserClaims } from '../Auth/useUser';
import type { Coach } from '../schema';
import useFirestore from '../useFirestore';
import { useSubscribeDocument } from '../useSubscribe';

export default function useCoachContext(): {
  coach: Coach | null | undefined;
  error: Error | null | undefined;
  loading: boolean;
} {
  const currentUser = useUser();
  const userClaims = useUserClaims();

  const db = useFirestore();

  const ref = React.useMemo(() => {
    if (currentUser?.email == null) return null;
    return doc(db, 'coaches', currentUser.email);
  }, [db, currentUser]);

  const [coach_, loading_, error_] = useSubscribeDocument(ref, { timeUntilSubscribe: 0 });

  const [coach, loading, error] = React.useMemo(() => {
    if (userClaims?.demo) {
      return [{ teams: { demo_org: true }, demo: true } as Coach, false, null];
    }
    return [coach_, loading_, error_];
  }, [userClaims, coach_, loading_, error_]);

  return { coach, loading, error };
}
