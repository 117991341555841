import auth from './__auth/_urls';
import account from './account/_urls';
import util from './util/_urls';

export const root = '/';

export default {
  ...account,
  ...auth,
  ...util,
};
