import { onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { get } from 'lodash-es';

import { doc } from '..';
import useUser from '../Auth/useUser';
import useFirestore from '../useFirestore';

export type RequestStatus = 'pending' | 'wait-listed' | 'accepted' | null | undefined;

const getRequestStatusFromLocalStorage = (): RequestStatus => {
  const status = localStorage.getItem('requestAccessStatus');
  return status != null
    ? get(
        {
          accepted: 'accepted',
          pending: 'pending',
          'wait-listed': 'wait-listed',
        },
        status,
      )
    : null;
};

export default function useAccessRequest(): {
  error: Error | null | undefined;
  status: RequestStatus;
} {
  const db = useFirestore();
  const currentUser = useUser();
  const [status, setStatus] = useState<RequestStatus>(getRequestStatusFromLocalStorage());
  const [error, setError] = useState<Error | null | undefined>(null);

  useEffect(() => {
    let canceled = false;
    let unsubscribe = () => {};
    const email = get(currentUser, 'email');
    if (email) {
      const accessRequestRef = doc(db, 'access_requests', email);
      unsubscribe = onSnapshot(
        accessRequestRef,
        (snap) => {
          if (snap.exists()) {
            const { status: requestStatus } = snap.data() ?? {};
            if (canceled) return;
            setStatus(requestStatus || 'pending');
            localStorage.setItem('requestAccessStatus', requestStatus || 'pending');
          } else {
            if (canceled) return;
            setStatus(undefined);
            localStorage.removeItem('requestAccessStatus');
          }
        },

        (err) => {
          setError(err);
        },
      );
    }

    return () => {
      canceled = true;
      unsubscribe();
    };
  }, [db, currentUser]);

  return { status, error };
}
