import React from 'react';

import { makeStyles } from 'tss-react/mui';

import ContentContainer from 'plantiga-common/ContentContainer';
import { useParams } from 'plantiga-common/react-router-hooks';

import EventsTimeline from './EventsTimeline';
import NewEventDialogButton from './NewEventDialogButton';

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    marginTop: theme.spacing(1),
    display: 'flex',
  },
}));

export default function EventsView() {
  const { classes } = useStyles();
  const { athleteId } = useParams();

  return (
    <ContentContainer id="aui_profile_events">
      <div className={classes.toolbar}>
        <NewEventDialogButton athleteId={athleteId} />
      </div>
      <EventsTimeline athleteId={athleteId} />
    </ContentContainer>
  );
}
