import React from 'react';

import TeamAppBarContext from './TeamAppBarContext';

export function useTeamAppBarSetRef() {
  const [, setRef] = React.useContext(TeamAppBarContext);
  return setRef;
}

export default function useTeamAppBar() {
  const [ref] = React.useContext(TeamAppBarContext);

  return ref;
}
