import React from 'react';

import { size } from 'lodash-es';

import { useDockedDevices } from 'plantiga-firebase/Devices/useDevices';
import { useActiveStopwatches } from 'plantiga-firebase/Stopwatch/useStopwatch';

export function useNotificationsContext() {
  const { stopwatches } = useActiveStopwatches();
  const { devices } = useDockedDevices();

  const [activeCount, badgeContent] = React.useMemo(() => {
    const swCount = size(stopwatches) > 0 ? size(stopwatches).toString() : '';
    const count = size(stopwatches) + size(devices);
    const content = `${swCount}`;
    return [count, content];
  }, [devices, stopwatches]);

  return { activeCount, badgeContent, stopwatches };
}

type NotificationsContextType = ReturnType<typeof useNotificationsContext>;

const NotificationsContext: React.Context<NotificationsContextType> =
  React.createContext<NotificationsContextType>({
    activeCount: 0,
    badgeContent: '',
    stopwatches: {},
  });

export default NotificationsContext;
