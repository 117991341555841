import React from 'react';

import { noop } from 'lodash-es';

export default function useResize<R extends HTMLElement>(onResize: (el: R) => void) {
  const ref = React.useRef<R>(null);

  React.useEffect(() => {
    const el = ref.current;
    if (el == null) return noop;

    const observer = new ResizeObserver(() => onResize(el));
    observer.observe(el);
    return () => observer.unobserve(el);
  }, [onResize]);

  return { ref };
}
