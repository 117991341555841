import { keys, map, omitBy, pickBy, sortBy, without } from 'lodash-es';

import makeFirestoreSafe from 'plantiga-util/makeFirestoreSafe';

import { isReserved } from './schema';

export type CustomLabelType = {
  key: string;
  value: string;
  keyError: string | null | undefined;
};

export type CustomLabelsType = Array<CustomLabelType>;

export const NEW_LABEL: CustomLabelType = {
  key: '',
  value: '',
  keyError: null,
};

const STANDARD_LABEL_KEYS = ['type', 'type_id'];

export const KEY_ERRORS = {
  invalidChars: 'This label uses only invalid characters',
  reserved: 'This label is reserved',
  duplicate: 'This label already exists',
} as const;

type keyErrorOptions = {
  otherKeys?: Array<string>;
  isAdmin?: boolean;
};
export const getLabelKeyError = (key: string, options: keyErrorOptions = {}): null | string => {
  const { otherKeys, isAdmin } = options;
  const safeKey = makeFirestoreSafe(key);
  if (safeKey === '' && key !== '') {
    return KEY_ERRORS.invalidChars;
  }
  if (isReserved(key, { isAdmin })) {
    return KEY_ERRORS.reserved;
  }
  if (otherKeys != null && otherKeys.includes(safeKey)) {
    return KEY_ERRORS.duplicate;
  }
  return null;
};

const filterStandardLabels = (value: string, key: string) =>
  STANDARD_LABEL_KEYS.includes(key) || value == null;

const sortLabels = (labels: CustomLabelsType) => sortBy(labels, 'key');

export const initializeStandardLabels = (labels: { [label: string]: string }) =>
  pickBy(labels, filterStandardLabels) as typeof labels;

export const initializeCustomLabels = (
  labels: { [label: string]: string },
  requiredLabels: Array<string>,
): Array<CustomLabelType> => {
  const originalLabels = omitBy(labels, filterStandardLabels);
  // remove legacy "should_compute_*" labels
  // This should be removed when and if we remove these legacy labels from all existing activities
  const filteredLabels = pickBy(
    originalLabels,
    (_, key: string) => !key.startsWith('should_compute'),
  );
  const customLabels = map(filteredLabels, (value, key: string) => ({
    key,
    value: value.toString(),
    keyError: null,
  }));
  const labelPlaceholders: Array<CustomLabelType> =
    customLabels.length === 0 ? [NEW_LABEL] : sortLabels(customLabels);
  const missingLabels: Array<string> = without(
    requiredLabels,
    ...keys(originalLabels),
    ...map(customLabels, ({ key }) => key),
  );
  missingLabels.forEach((label) => {
    labelPlaceholders.unshift({
      key: label,
      value: '',
      keyError: '',
    });
  });
  return labelPlaceholders;
};
