import React from 'react';

import { kebabCase } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Collapse, IconButton, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useLocation } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';
import { findPathMatch } from 'plantiga-util/urls';

import DrawerItem from './DrawerItem';
import { getNestedPaths } from './navItemUtils';
import type { NavItemDefnInner } from './typedefs';

const ICON_SIZE = 16;
const useStyles = makeStyles()((theme) => ({
  moreIcon: {
    fontSize: ICON_SIZE,
    transition: theme.transitions.create('transform'),
  },
  rotate90: {
    transform: 'rotate(-90deg)',
  },
  invisible: {
    opacity: 0,
  },
}));

function buildQuery(path: NavItemDefnInner<any>['path'], params?: { [key: string]: string }) {
  const { athleteId } = params ?? {};
  if (path === 'record' && athleteId != null && window.location.pathname.includes(athleteId)) {
    return { people: athleteId };
  }
  return undefined;
}

interface PropsInner<I> extends NavItemDefnInner<I> {
  items?: I[];
  disabled?: boolean;
  selected?: boolean;
  depth?: number;
  params?: { [key: string]: string };
}

export interface Props extends PropsInner<Props> {}

export default function DrawerTreeItem({
  text,
  path,
  disabled = false,
  params = {},
  items = [],
  altPaths = [],
  depth = 0,
  icon = null,
  initOpen = true,
  badgeContent = undefined,
  renderSecondaryAction = () => undefined,
  renderSubheader = () => undefined,
}: Props) {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const buildURL = useBuildURL();
  const { location } = useLocation();
  const url = React.useMemo(
    () => (disabled ? undefined : buildURL(path, params, buildQuery(path, params))),
    [disabled, buildURL, path, params],
  );

  const hasItems = React.useMemo(() => items.filter((item) => !item.disabled).length > 0, [items]);

  // match against the current item's path (or altPaths)
  const selected = React.useMemo(
    () => findPathMatch(location.pathname, [path, ...altPaths])?.isExact ?? false,
    [location.pathname, path, altPaths],
  );

  // check if any nested items are selected
  const hasNestedMatch = React.useMemo(() => {
    const enabledItems = items.filter((item) => !item.disabled);
    const paths = getNestedPaths(enabledItems);
    return !!findPathMatch(location.pathname, paths);
  }, [items, location.pathname]);

  // open nested items if a nested item is selected
  const [open, setOpen] = React.useState(initOpen && items.length > 0);
  React.useEffect(() => {
    setOpen(hasNestedMatch);
  }, [items, hasNestedMatch]);

  const toggleItems = React.useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setOpen((p) => !p);
  }, []);
  const showItems = open && hasItems;

  return (
    <>
      <DrawerItem
        // consistent id for UI testing
        id={`nav-${kebabCase(path)}`}
        text={text}
        badgeContent={badgeContent}
        dropDownIcon={
          <IconButton
            size="small"
            edge="end"
            onClick={toggleItems}
            // make the icon invisible if there are no nested items (preserve the icon spacing)
            className={cx({ [classes.invisible]: disabled || !hasItems })}
          >
            <ExpandMoreIcon
              fontSize="inherit"
              className={cx(classes.moreIcon, { [classes.rotate90]: !showItems })}
            />
          </IconButton>
        }
        secondaryAction={renderSecondaryAction(params)}
        icon={icon}
        selected={selected}
        disabled={disabled}
        depth={depth}
        to={url}
      />
      <Collapse in={!disabled && showItems} timeout="auto">
        <div style={{ paddingLeft: theme.spacing(depth * 3 + 6) }}>{renderSubheader(params)}</div>
        <List disablePadding>
          {items.map((item) => (
            <DrawerTreeItem key={item.path} depth={depth + 1} params={params} {...item} />
          ))}
        </List>
      </Collapse>
    </>
  );
}
