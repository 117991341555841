import { merge } from 'lodash-es';

import type { Team, TeamPreferences } from 'plantiga-firebase/schema';

export const RESEARCH = 'research';
export const METRIC = 'metric';
export const IMPERIAL = 'imperial';

const DEFAULT_PREFS: TeamPreferences = {
  display: { injury_history: false, tier: 3 },
  measures: { unitType: RESEARCH },
};

/* Get preferences from a team, inserting defaults if no preferences are set
 */
export const getTeamPreferences = (team?: Team | null): TeamPreferences =>
  merge(
    DEFAULT_PREFS,
    team
      ? {
          display: team.display,
          measures: team.measures,
        }
      : {},
  );
