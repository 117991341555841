import React from 'react';

import { Avatar, SvgIcon } from '@mui/material';

import BasketballIcon from '../../icons/BasketballIcon';
import CMJIcon from '../../icons/CMJIcon';
import DLineIcon from '../../icons/DLineIcon';
import FreeIcon from '../../icons/FreeIcon';
import HittingIcon from '../../icons/HittingIcon';
import HopIcon from '../../icons/HopIcon';
import JumpIcon from '../../icons/JumpIcon';
import OLineIcon from '../../icons/OLineIcon';
import PitchingIcon from '../../icons/PitchingIcon';
import QuarterbackIcon from '../../icons/QuarterbackIcon';
import RunIcon from '../../icons/RunIcon';
import RunningBackIcon from '../../icons/RunningBackIcon';
import TennisIcon from '../../icons/TennisIcon';
import TennisServeIcon from '../../icons/TennisServeIcon';
import WalkIcon from '../../icons/WalkIcon';

type Props = {
  activityType: string | null | undefined;
} & React.ComponentProps<typeof SvgIcon>;

export default function ActivityIcon({ activityType, ...props }: Props) {
  switch (activityType) {
    case 'Cyclic_jump':
      return <HopIcon {...props} />;
    case 'consecutive_cmj':
      return <CMJIcon {...props} />;
    case 'jump':
      return <JumpIcon {...props} />;
    case 'run':
      return <RunIcon {...props} />;
    case 'walk':
      return <WalkIcon {...props} />;
    case 'pitching':
      return <PitchingIcon {...props} />;
    case 'hitting':
      return <HittingIcon {...props} />;
    case 'full_court_practice':
      return <BasketballIcon {...props} />;
    case 'tennis_practice':
      return <TennisIcon {...props} />;
    case 'tennis_serve':
      return <TennisServeIcon {...props} />;
    case 'o-line':
      return <OLineIcon {...props} />;
    case 'd-line':
      return <DLineIcon {...props} />;
    case 'quarterback':
      return <QuarterbackIcon {...props} />;
    case 'running_back':
      return <RunningBackIcon {...props} />;
    case 'wide_receiver':
      return <Avatar {...props}>WR</Avatar>;
    default:
      return <FreeIcon {...props} />;
  }
}
