import React from 'react';
import { Controller, type FieldValues, type Path, type ControllerProps } from 'react-hook-form';

import { AutocompleteActivityType } from 'plantiga-common/AutocompleteActivityType';

type Props<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  control: ControllerProps<TFieldValues>['control'];
  rules?: ControllerProps<TFieldValues>['rules'];
  innerProps?: Omit<
    React.ComponentProps<typeof AutocompleteActivityType>,
    'activityTypeId' | 'onChange'
  >;
};

export default function ControlledAutocompleteActivity<TFieldValues extends FieldValues>({
  name,
  rules,
  control,
  innerProps,
}: Props<TFieldValues>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value: activityTypeId, onChange } }) => (
        <AutocompleteActivityType
          {...innerProps}
          textFieldProps={{ ...(innerProps?.textFieldProps ?? {}), required: !!rules?.required }}
          activityTypeId={activityTypeId}
          onChange={(type: string | null) => onChange(type)}
        />
      )}
    />
  );
}
