import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Avatar, colors } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: colors.blue[100],
    color: colors.blue[600],
    margin: theme.spacing(1),
  },
  /* eslint-disable tss-unused-classes/unused-classes */
  sm: {
    width: 24,
    height: 24,
    fontSize: 12,
    margin: 0,
  },
  lg: {
    width: 72,
    height: 72,
    fontSize: 36,
  },
  /* eslint-enable tss-unused-classes/unused-classes */
}));

type Props = {
  readonly name: string;
  readonly size?: 'sm' | 'lg';
};

export default function UserAvatar({ name, size = 'lg' }: Props) {
  const { classes, cx } = useStyles();
  const [first, last] = (name || '?').split(' ');
  const F = first ? first[0].toUpperCase() : '';
  const L = last ? last[0].toUpperCase() : '';
  const initials = `${F}${L}`;

  return <Avatar className={cx(classes.root, [classes[size]])}>{initials}</Avatar>;
}
