import { omit, pick } from 'lodash-es';

export const splitDefaultAthletesFromAthletes = (teamId: string, athletes: any): Array<any> => {
  const defaultAthleteIds = ['JennyHart', 'NormanMcKay', 'SarahDarcy'].map(
    (id) => `${teamId}_${id}`,
  );
  const defaultAthletes = pick(athletes, defaultAthleteIds);
  const teamAthletes = omit(athletes, defaultAthleteIds);
  return [defaultAthletes, teamAthletes];
};
