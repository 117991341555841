import React from 'react';

import { uniq } from 'lodash-es';
import { useLocalStorage } from 'usehooks-ts';

/** store `cacheSize` values in lru order */
export function useLocalLRU<T>(cacheKey: string, cacheSize: number = 3) {
  const [value, setValue] = useLocalStorage<T[]>(cacheKey, []);

  const addKeys = React.useCallback(
    (values: T[]) => setValue((p) => uniq([...values, ...p]).slice(0, cacheSize)),
    [cacheSize, setValue],
  );

  return [value, addKeys] as const;
}
