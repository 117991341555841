import { sum, quantile } from 'd3';

export function computeStats<D>(data: D[], getValue: (v: D) => number) {
  if (data.length === 0) return undefined;
  const sorted = data.map((v) => getValue(v)).toSorted((a, b) => a - b);
  return {
    count: sorted.length,
    min: sorted.at(0)!,
    firstQuartile: quantile(sorted, 0.25) ?? 0,
    median: quantile(sorted, 0.5) ?? 0,
    thirdQuartile: quantile(sorted, 0.75) ?? 0,
    max: sorted.at(-1)!,
    sum: sum(sorted),
  };
}
