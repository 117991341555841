import React from 'react';

import { size, toPairs } from 'lodash-es';

import { List, ListSubheader } from '@mui/material';

import makeStyles from 'plantiga-util/MUI/makeStyles';

import ActiveStopwatchListItem from '../../Stopwatch/ActiveStopwatches/ActiveStopwatchListItem';

import useNotifications from './useNotifications';

const useStyles = makeStyles()(() => ({
  listHeader: {
    background: 'white',
    // Edge doesn't work well with sticky
    '@supports (-ms-ime-align:auto)': {
      position: 'relative',
    },
  },
}));

type Props = {
  onClick?: () => void;
};

export default function ActiveStopwatchList({ onClick }: Props) {
  const { classes } = useStyles();
  const { stopwatches } = useNotifications();

  if (size(stopwatches) === 0) return null;

  return (
    <List
      id="active-stopwatches-list"
      subheader={
        <ListSubheader disableSticky className={classes.listHeader}>
          Active Recordings
        </ListSubheader>
      }
    >
      {toPairs(stopwatches).map(([swid, stopwatch]) => (
        <ActiveStopwatchListItem key={swid} swid={swid} stopwatch={stopwatch} onClick={onClick} />
      ))}
    </List>
  );
}
