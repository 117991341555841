import React from 'react';

import { useParams } from 'plantiga-common/react-router-hooks';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';

import { DeleteActivity } from '../Delete/DeleteActivity';
import ActivityInfoTable from '../Info/ActivityInfoTable';

type Props = {
  readonly activity: Activity | null | undefined;
};

export default function InfoView({ activity }: Props) {
  const { teamId, activityId } = useParams();
  if (activity == null) return null;
  return (
    <>
      <ActivityInfoTable activity={activity} activityId={activityId} teamId={teamId} />
      <DeleteActivity activity={activity} activityId={activityId} />
    </>
  );
}
