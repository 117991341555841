import { uniq } from 'lodash-es';

import type { MetricValueDefn } from '@plantiga/activity-summaries';

import type { FieldDefinition } from 'plantiga-common/Fields';

type ChiralSuffix = 'left' | 'right';

export const isAsymmetryMetric = (fieldId: string) => fieldId.endsWith('asymmetry');

export const stripServicePrefix = (fieldKey: string) => fieldKey.split(':').at(-1)!;

export const isChiralMetric = (
  fieldId: string,
  chirality: ChiralSuffix | ChiralSuffix[] = ['left', 'right'],
) => {
  if (typeof chirality === 'string') return fieldId.endsWith(chirality);
  return chirality.some((chiralSuffix) => fieldId.endsWith(chiralSuffix));
};

export const metricChiralitySuffix = (fieldId: string) => {
  if (isChiralMetric(fieldId, 'left')) return 'left';
  if (isChiralMetric(fieldId, 'right')) return 'right';
  if (isAsymmetryMetric(fieldId)) return 'asymmetry';
  return 'achiral';
};

export const metricAggregation = (
  fieldId: string,
  getField?: (fieldId: string) => FieldDefinition,
) => {
  const agg = getField?.(fieldId)?.timeseries?.aggregation;
  if (agg != null) return agg;

  // if the timeseries aggregation is not defined in the field definition
  // try to extract it from the field id
  // e.g. <service-type>:<summary-type>_<aggregation>_<base>_<chirality>
  return fieldId.split(':').at(-1)?.split('__').at(-1)?.split('_').at(0);
};

export const assertMetricsShareFieldId = (metricDefns: MetricValueDefn[]) => {
  const fieldIds = uniq(metricDefns.map((m) => m.field_id));
  if (fieldIds.length !== 1) {
    throw new Error(`Expected single fieldId, received ${fieldIds}`);
  }
};
