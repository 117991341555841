import { getAuth, updatePassword } from 'firebase/auth';
import React from 'react';
import { useForm } from 'react-hook-form';

import { Button, DialogActions, DialogContent, TextField } from '@mui/material';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import Dialog from 'plantiga-common/Dialog';
import urls from 'plantiga-util/urls';

import { useToast } from '../Toast/UseToast';

type Props = {
  readonly open: boolean;
  readonly onClose: any;
};

export default function ChangePasswordDialog({ open, onClose }: Props) {
  const postToast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [fieldState, setFieldState] = React.useState({
    newPassword: '',
    confirmPassword: '',
  });
  const [openSignout, setOpenSignout] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    reValidateMode: 'onSubmit',
    defaultValues: fieldState,
  });

  const doChangePassword = handleSubmit(({ newPassword }) => {
    setLoading(true);
    const { currentUser } = getAuth();
    if (currentUser == null) {
      return;
    }
    updatePassword(currentUser, newPassword)
      .then(() => {
        postToast({
          message: `Your password has been updated.`,
          variant: 'success',
        });
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        if (err?.code === 'auth/weak-password') {
          setError(
            'newPassword',
            {
              message: 'A stronger password is required',
            },
            { shouldFocus: true },
          );
        } else if (err?.code === 'auth/requires-recent-login') {
          setOpenSignout(true);
        }
      });
  });

  const handleSignout = async () => {
    await getAuth().signOut();
    window.location.href = urls.signin;
  };

  const handleCancelSignout = () => {
    setOpenSignout(false);
  };

  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setFieldState((s) => ({ ...s, [name]: value }));
  }, []);

  return (
    <>
      <Dialog id="change-password-dialog" open={open} onClose={onClose} title="Change Password">
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            // @ts-expect-error - TS2322 - Type 'false' is not assignable to type 'string | undefined'.
            autoComplete={false}
            name="new-password"
            label="New password"
            value={fieldState.newPassword}
            id="new-password"
            type="password"
            error={errors.newPassword != null}
            helperText={errors.newPassword?.message}
            inputProps={register('newPassword', { required: 'This field is required' })}
            onChange={onChange}
          />
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            // @ts-expect-error - TS2322 - Type 'false' is not assignable to type 'string | undefined'.
            autoComplete={false}
            name="confirm-password"
            label="Confirm password"
            value={fieldState.confirmPassword}
            id="confirm-password"
            type="password"
            error={errors.confirmPassword != null}
            helperText={errors.confirmPassword?.message}
            inputProps={register('confirmPassword', {
              required: 'This field is required',
              validate: (pass) => pass === fieldState.newPassword || 'Password fields must match',
            })}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>cancel</Button>
          <ButtonWithLoading
            id="change-password-submit"
            loading={loading}
            disabled={loading}
            variant="outlined"
            color="primary"
            onClick={doChangePassword}
          >
            Change Password
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
      <Dialog title="Re-authentication Required" open={openSignout} onClose={handleCancelSignout}>
        <DialogContent>Please log in again to change your password.</DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSignout}>Cancel</Button>
          <Button variant="outlined" color="primary" onClick={handleSignout}>
            Sign out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
