import React from 'react';

import { differenceInSeconds, formatDistance } from 'date-fns';

import { Typography } from '@mui/material';

import { PodChip } from 'plantiga-component/Devices/PodChip';
import { useToast } from 'plantiga-component/Toast/UseToast';
import useDevices from 'plantiga-firebase/Devices/useDevices';
import { predictPodRunTime } from 'plantiga-util/devices';

const COOLDOWN_SECONDS = 30;

export function useUndockNotify() {
  const postToast = useToast({ preventDuplicate: false });
  const pods = useDevices();
  const podToastTime = React.useRef<{ [podId: string]: Date }>({});

  React.useEffect(() => {
    const recentlyUndocked = Object.values(pods).filter((v) =>
      !v.docked && v.last_status
        ? differenceInSeconds(new Date(), v.last_status?.toDate()) < COOLDOWN_SECONDS
        : false,
    );
    recentlyUndocked.forEach((pod) => {
      if (
        pod.id in podToastTime.current &&
        differenceInSeconds(new Date(), podToastTime.current[pod.id]) < COOLDOWN_SECONDS
      )
        return;

      podToastTime.current[pod.id] = new Date();
      const runTime = predictPodRunTime(pod);
      let variant: 'success' | 'warning' | 'error' = 'success';
      if (runTime < 5 * 60 * 60 * 1000) variant = 'warning';
      if (runTime < 2 * 60 * 60 * 1000) variant = 'error';
      postToast({
        message: (
          <React.Fragment key={pod.id}>
            <PodChip podId={pod.id} />
            <Typography variant="body1">
              <b>{variant === 'error' ? 'Data loss likely! ' : ''}</b>
              Undocked with
              <b>{` ${formatDistance(new Date(runTime), new Date(0))} of run time `}</b>
              remaining.
            </Typography>
          </React.Fragment>
        ),
        timeout: 10,
        variant,
      });
    });
  }, [postToast, pods]);
}
