import React from 'react';

type ContainerContextType = {
  readonly leftGutterRef: HTMLElement | null | undefined;
  readonly rightGutterRef: HTMLElement | null | undefined;
  readonly topRef: HTMLElement | null | undefined;
  readonly bottomRef: HTMLElement | null | undefined;
};

const ContainerContext: React.Context<ContainerContextType> =
  React.createContext<ContainerContextType>({
    leftGutterRef: null,
    rightGutterRef: null,
    topRef: null,
    bottomRef: null,
  });

type Props = {
  readonly children: React.ReactNode;
} & ContainerContextType;

export function ContainerProvider({ children, ...value }: Props) {
  return <ContainerContext.Provider value={value}>{children}</ContainerContext.Provider>;
}

export default ContainerContext;
