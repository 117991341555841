import React from 'react';

import { makeStyles } from 'tss-react/mui';

import {
  BatteryCharging20,
  BatteryCharging50,
  BatteryCharging80,
  BatteryChargingFull,
  BatteryFull,
  Check as CheckIcon,
  ClearAll as ClearAllIcon,
  CloudUpload as CloudUploadIcon,
  Dock as DockIcon,
  Eject as EjectIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';
import { Tooltip, alpha, useTheme } from '@mui/material';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';

import { useDevice } from 'plantiga-firebase/Devices';
import type { Device } from 'plantiga-firebase/Devices/typedefs';
import { deviceNiceId } from 'plantiga-util/deviceNiceId';
import { podBattery } from 'plantiga-util/devices';
import getDeviceStatus from 'plantiga-util/getDeviceStatus';
import { randomId } from 'plantiga-util/randomId';

import { ChiralityAvatar } from './Avatar';
import { POD_CHIP_HEIGHT as dimH, POD_CHIP_WIDTH as dimW } from './constants';

const LOW_BATTERY = 20;

const useIconStyles = makeStyles()((theme) => ({
  icon: {
    height: '1rem',
    color: theme.palette.action.active,
  },
  lowBattery: {
    color: theme.palette.error.main,
  },
  broken: {
    color: theme.palette.error.main,
  },
}));

const chargeIcon = (charge: number) => {
  if (charge < LOW_BATTERY) return BatteryCharging20;
  if (charge < 65) return BatteryCharging50;
  if (charge < 90) return BatteryCharging80;
  if (charge < 98) return BatteryChargingFull;
  return BatteryFull;
};

function Icon({ device }: { device: Device | null | undefined }) {
  const { classes, cx } = useIconStyles();
  const status = device ? getDeviceStatus(device) : 'unknown';
  const charge = device ? podBattery(device) : 0;
  const BatteryIcon = chargeIcon(charge);
  switch (status) {
    case 'broken':
      return (
        <Tooltip title="Pod is broken" placement="top">
          <ErrorIcon className={cx(classes.icon, classes.broken)} />
        </Tooltip>
      );
    case 'ready':
      return (
        <Tooltip title="Pod is ready" placement="top">
          <CheckIcon className={classes.icon} />
        </Tooltip>
      );
    case 'docked':
      return (
        <Tooltip title="Pod is docked" placement="top">
          <DockIcon className={classes.icon} />
        </Tooltip>
      );
    case 'uploading':
      return (
        <Tooltip title="Pod is uploading" placement="top">
          <CloudUploadIcon className={classes.icon} />
        </Tooltip>
      );
    case 'erasing':
      return (
        <Tooltip title="Pod is busy" placement="top">
          <ClearAllIcon className={classes.icon} />
        </Tooltip>
      );
    case 'data uploaded':
      return (
        <Tooltip title={`${charge.toFixed(0)}%`} placement="top">
          <BatteryIcon
            className={cx(classes.icon, { [classes.lowBattery]: charge < LOW_BATTERY })}
          />
        </Tooltip>
      );
    case 'undocked':
    default:
      return (
        <Tooltip title="Pod is undocked" placement="top">
          <EjectIcon className={classes.icon} />
        </Tooltip>
      );
  }
}

const useStyles = makeStyles<void, 'hovered' | 'interactive'>()((theme, _params, classes) => ({
  svg: {
    overflow: 'visible',
    [`.${classes.interactive}`]: {
      cursor: 'pointer',
    },
  },
  podChip: {
    fill: theme.palette.background.paper,
    stroke: theme.palette.action.active,
    [`&.${classes.hovered}.${classes.interactive}`]: {
      fill: theme.palette.action.hover,
      stroke: theme.palette.action.active,
    },
  },
  interactive: {},
  hovered: {},
  icon: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

type Props = {
  readonly width?: number;
  readonly charsToShow?: number;
  readonly podId: string;
  readonly onClick?: (arg1: React.SyntheticEvent<SVGSVGElement>) => void;
  readonly onStatusChange?: (deviceId: string, status: ReturnType<typeof getDeviceStatus>) => void;
};

export function PodChip({ podId, onClick, onStatusChange, width: _width, charsToShow = 9 }: Props) {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const xScale = scaleLinear({ domain: [0, _width ?? dimW], range: [0, dimW] });
  const yScale = scaleLinear({ domain: [0, (dimH * (_width ?? dimW)) / dimW], range: [0, dimH] });
  const width = xScale(_width ?? dimW);
  const height = dimH;

  const podIdSafe = podId.replaceAll('-', '');
  const isLeft = podIdSafe.endsWith('0');
  const device = useDevice(podIdSafe);
  const podName = (device?.name ?? deviceNiceId(podIdSafe)).slice(0, charsToShow);
  const strokeWidth = 1;

  const status = device ? getDeviceStatus(device) : 'unknown';
  const lastUpload = device?.last_upload;
  const start = lastUpload?.chunk_start ?? 0;
  const total = lastUpload?.chunk_total ?? 100;
  const completedScale = scaleLinear({ domain: [0, total], range: [height / 2, width] });
  const uploadProgressId = randomId();

  React.useEffect(() => {
    onStatusChange?.(podId, status);
  }, [podId, status, onStatusChange]);

  const [hovered, setHovered] = React.useState(false);
  const iconSize = height;

  return (
    <Tooltip title={podId} enterDelay={500} enterNextDelay={500}>
      {/* the aspect ratio is defined by dimW and dimH while the actual size is defined by _width */}
      <svg
        viewBox={`0 0 ${dimW} ${dimH}`}
        width={_width ?? dimW}
        height={yScale.invert(dimH)}
        className={classes.svg}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      >
        <rect
          x={0}
          y={0}
          rx={height / 2}
          strokeWidth={strokeWidth}
          width={width}
          height={height}
          className={cx(classes.podChip, {
            [classes.hovered]: hovered,
            [classes.interactive]: onClick != null,
          })}
        />
        {status === 'uploading' && (
          <>
            <defs>
              <clipPath id={uploadProgressId}>
                <rect x={0} y={0} width={completedScale(start)} height={height} />
              </clipPath>
            </defs>
            <rect
              x={0}
              y={0}
              rx={height / 2}
              strokeWidth={strokeWidth}
              width={width}
              height={height}
              fill={alpha(theme.palette.info.light, 0.25)}
              stroke={theme.palette.info.light}
              clipPath={`url(#${uploadProgressId})`}
            />
          </>
        )}
        <Group left={-1} top={-1}>
          <ChiralityAvatar location={isLeft ? 'L' : 'R'} radius={iconSize / 2} />
        </Group>
        <Group top={iconSize / 2} left={width / 2 + 3}>
          <Text
            dy={strokeWidth}
            width={width - iconSize * 2}
            scaleToFit="shrink-only"
            fontSize=".75rem"
            textAnchor="middle"
            dominantBaseline="middle"
            fontFamily="monospace"
          >
            {podName}
          </Text>
        </Group>
        <foreignObject x={width - iconSize} y={0} width={iconSize} height={iconSize}>
          <div className={classes.icon}>
            <Icon device={device} />
          </div>
        </foreignObject>
      </svg>
    </Tooltip>
  );
}
