import React from 'react';

import type { urlKey } from 'plantiga-util/urls';

import type { FeaturePermission, Permission } from './permissions';
import { FEATURE_PERMISSIONS, PERMISSIONS } from './permissions';
import { usePrincipal } from './principal';

export function useHasFeature() {
  const principal = usePrincipal();
  return React.useCallback(
    (permission: FeaturePermission, resourceId: string = '', resource: unknown = null) => {
      const hasPermission = FEATURE_PERMISSIONS[permission];
      return principal ? hasPermission(principal, resourceId ?? '', resource) : false;
    },
    [principal],
  );
}

/** Permission based access to features */
// ignore unused exports
export function useFeature(
  permission: FeaturePermission,
  resourceId: string = '',
  resource: unknown = null,
) {
  const hasFeature = useHasFeature();
  return hasFeature(permission, resourceId, resource);
}

export function useHasPrivilege() {
  const principal = usePrincipal();
  return React.useCallback(
    (permission: Permission, resourceId: string = '', resource: unknown = null) => {
      const hasPermission = PERMISSIONS[permission];
      return principal ? hasPermission(principal, resourceId, resource) : false;
    },
    [principal],
  );
}

export function usePrivilege(
  permission: Permission,
  resourceId: string = '',
  resource: unknown = null,
) {
  const hasPrivilege = useHasPrivilege();
  return hasPrivilege(permission, resourceId, resource);
}

export function useView(view: urlKey) {
  const hasPrivilege = useHasPrivilege();
  return hasPrivilege('navigate', view);
}
