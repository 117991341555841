// react-router-hooks.js from https://github.com/ReactTraining/react-router/issues/6430

import React, { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';

import { isFunction, omitBy, isEqual } from 'lodash-es';

export function useRouter(): any {
  return useContext(RouterContext);
}

export function useParams(): any {
  const { match } = useRouter();
  return match.params;
}

export function useHistory(): any {
  const { history } = useRouter();
  return history;
}

export function useLocation(): {
  location: any;
  navigate: (
    to: string,
    options?:
      | {
          replace: boolean;
        }
      | undefined,
  ) => void;
} {
  const { location, history } = useRouter();

  const navigate = React.useCallback(
    (
      to: string,
      {
        replace = false,
      }: {
        replace?: boolean;
      } = {},
    ) => {
      if (replace) {
        history.replace(to);
      } else {
        history.push(to);
      }
    },
    [history],
  );

  return {
    location,
    navigate,
  };
}

export function useQuery(): QueryParams {
  const { location } = useRouter();
  const query = React.useMemo(() => {
    const params = new URLSearchParams(location.search.slice(1));
    const qq: QueryParams = {};
    params.forEach((v, k) => {
      qq[k] = v;
    });
    return qq;
  }, [location.search]);

  return query;
}

type QueryParams = {
  [key: string]: string;
};

type SetQueryParams = (arg1: QueryParams | ((arg1: QueryParams) => QueryParams)) => void;

/**
 * Realize the query string value as a "state", which can be updated with a
 * simple setState call.
 * @param {QueryParams} initialValue initial value for the query string
 * @returns the current value of the query string, and a setter for the state
 */
export function useQueryState(initialValue?: QueryParams): [QueryParams, SetQueryParams] {
  const history = useHistory();
  const queryParams = useQuery() || initialValue;
  const setParams = React.useCallback(
    (newParams: ((p: typeof queryParams) => typeof queryParams) | typeof queryParams) => {
      // Allow either a literal value, or a function similar to setState
      const params = omitBy(
        isFunction(newParams)
          ? (newParams as any)(queryParams)
          : { ...queryParams, ...(newParams as any) },
        (param) => param === '',
      );
      // skip updating the history when the parameters have not changed.
      if (isEqual(params, queryParams)) return;

      const searchParams = new URLSearchParams(params);
      // Here we've chosen to `replace`, rather than `push`, to avoid adding to the history.
      // This could be an option to this function, if history is desired.
      history.replace({ search: `?${searchParams.toString()}` });
    },
    [history, queryParams],
  );

  // Ensure that our current query string is filled in with any provided initial values,
  // without wiping out the existing query string.
  React.useEffect(() => {
    setParams({ ...initialValue, ...queryParams });
    // We only reset the query string once, on initial load.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [queryParams, setParams];
}
