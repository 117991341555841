import React, { useState, useCallback } from 'react';

import { filter, size, sortBy, toPairs } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { CallMerge as MergeIcon } from '@mui/icons-material';
import { Button, List, ListSubheader } from '@mui/material';

import type { useDockedDevices } from 'plantiga-firebase/Devices/useDevices';

import { PairPodsToAthleteDialog } from '../../Devices/PairPodsToAthleteDialog';

import DeviceListItem from './DeviceListItem';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
    minWidth: 320,
  },
  pairButton: {
    borderRadius: 0,
    position: 'sticky',
    bottom: 0,
    zIndex: theme.zIndex.modal + 1,
    width: '100%',
    // Edge doesn't work well with sticky
    '@supports (-ms-ime-align:auto)': {
      position: 'fixed',
    },
  },
  list: {
    // Edge needs some extra padding with fixed
    '@supports (-ms-ime-align:auto)': {
      paddingBottom: theme.spacing(5),
    },
  },
  listHeader: {
    background: 'white',
    // Edge doesn't work well with sticky
    '@supports (-ms-ime-align:auto)': {
      position: 'relative',
    },
  },
}));

type Props = {
  dockedDevices: ReturnType<typeof useDockedDevices>;
};

export default function DockedDevicesList({ dockedDevices }: Props) {
  const { classes } = useStyles();
  const [selected, setSelected] = useState<string[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const onChange = useCallback(
    (values: string[]) => {
      const updateSelected = filter(values, (d) => dockedDevices[d] !== undefined);
      setSelected(updateSelected);
    },
    [dockedDevices],
  );

  return (
    <div className={classes.root}>
      <List
        className={classes.list}
        subheader={<ListSubheader className={classes.listHeader}>Docked Pods</ListSubheader>}
      >
        {sortBy(toPairs(dockedDevices), (v) => v[1].athlete_id).map(([deviceId, device]) => (
          <DeviceListItem
            key={deviceId}
            device={device}
            deviceId={deviceId}
            onChange={onChange}
            values={selected}
          />
        ))}
      </List>
      {size(dockedDevices) ? (
        <Button
          fullWidth
          variant="contained"
          style={{ pointerEvents: 'all' }} // HACK: prevent clicking through disabled button
          disabled={selected.length === 0}
          color="primary"
          onClick={() => {
            if (selected.length > 0) {
              setDialogOpen(true);
            }
          }}
          className={classes.pairButton}
        >
          <MergeIcon fontSize="small" />
        </Button>
      ) : null}
      <PairPodsToAthleteDialog
        podIds={selected}
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          setSelected([]);
        }}
      />
    </div>
  );
}
