import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Chip, Tooltip } from '@mui/material';

const useStyles = makeStyles()(() => ({
  labelChip: {
    margin: 2,
    maxWidth: 'inherit',
  },
}));

type Props = {
  readonly labelKey: string;
  readonly labelValue: string;
};

export default function LabelChip({ labelKey, labelValue }: Props) {
  const { classes } = useStyles();
  const chipValue = `${labelKey}: ${labelValue}`;
  return (
    <Tooltip enterTouchDelay={0} title={chipValue}>
      <Chip size="small" className={classes.labelChip} label={chipValue} />
    </Tooltip>
  );
}
