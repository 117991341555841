import { some, omitBy, pickBy } from 'lodash-es';

import type { Device, Devices } from 'plantiga-firebase/Devices/typedefs';
import type {
  Stopwatches,
  StopwatchWarnings as Warnings,
} from 'plantiga-firebase/Stopwatch/typedefs';
import { predictPodRunTime } from 'plantiga-util/devices';

export type AthleteWarningItem = {
  athleteId: string;
  name: string;
  warnings: Warnings;
};

const checkBrokenDevices = (devices: Devices) => some(devices, 'broken');

/** Devices not docked within 24 hours are either dead or very badly de-synced  */
const checkExpiredDevices = (devices: Devices) => {
  const now = new Date();
  // TODO: update this for next gen hardware
  const batteryTimeout = 24 * 60 * 60 * 1000;
  return some(
    devices,
    ({ last_status: status }) => !status || +now - status.toMillis() > batteryTimeout,
  );
};

/** Devices with less than one hour of run time are likely to die during operation */
const checkLowRunTimeDevices = (devices: Devices) =>
  some(devices, (d) => predictPodRunTime(d) < 60 * 60 * 1000);

const checkMissingDevices = (devices: Devices) => {
  const hasLeft = some(devices, ({ location }) => location === 'L');
  const hasRight = some(devices, ({ location }) => location === 'R');
  return !(hasLeft && hasRight);
};

const checkActiveStopwatches = (athleteId: string, stopwatches: Stopwatches) =>
  !!Object.values(stopwatches)
    .flatMap((s) => s.athlete_ids)
    .find((id) => id === athleteId);

export function getAthleteWarnings(athleteId: string, devices: Devices, stopwatches: Stopwatches) {
  const athleteDevices = pickBy(devices, (device: Device) => device.athlete_id === athleteId);
  const athleteWarnings: Record<keyof Warnings, boolean> = {
    haveBrokenDevices: checkBrokenDevices(athleteDevices),
    haveLowRunTimeDevices: checkLowRunTimeDevices(athleteDevices),
    haveExpiredDevices: checkExpiredDevices(athleteDevices),
    haveMissingDevices: checkMissingDevices(athleteDevices),
    haveActiveRecording: checkActiveStopwatches(athleteId, stopwatches),
  };
  return omitBy(athleteWarnings, (v) => !v) as Warnings;
}
