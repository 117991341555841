import React from 'react';
import { useRouteMatch } from 'react-router';

import { formatISO } from 'date-fns';
import { isEmpty, compact } from 'lodash-es';

import ContentContainer from 'plantiga-common/ContentContainer';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import ActivityPicker from 'plantiga-component/Activity/ActivityPicker';
import InjuriesCard from 'plantiga-component/Athletes/InjuriesCard';
import NotFound from 'plantiga-component/Error/NotFound';
import MultiActivityReports from 'plantiga-component/Feed/MultiActivityReports';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';
import useActivitiesForDayOf from 'plantiga-firebase/Activity/useActivitiesForDayOf';
import urls from 'plantiga-util/urls';

import { GUTTER_ORDER } from '../Report/gutterOrder';

const ACTIVITY_PAGES = 'activity';

type ActivityCardProps = {
  activityId: string;
  activities: ReturnType<typeof useActivitiesForDayOf>['activities'];
  match: ReturnType<typeof useRouteMatch>;
};

function ActivityCard({ match, activityId, activities }: ActivityCardProps) {
  const navigateURL = useNavigateURL();

  const handleActivitySelect = React.useCallback(
    (id: string) => navigateURL(ACTIVITY_PAGES, { ...(match?.params ?? {}), activityId: id }),
    [match, navigateURL],
  );

  return (
    <ActivityPicker
      activities={activities}
      selectedActivity={activities[activityId]}
      setSelectedActivity={handleActivitySelect}
    />
  );
}

type ActivityPageWrapperProps = {
  readonly activity: Activity;
  readonly activityId: string;
  readonly children: React.ReactNode;
};

function ActivityPageWrapper({ children, activity, activityId }: ActivityPageWrapperProps) {
  const match = useRouteMatch<{ day?: string; page?: string }>(urls[ACTIVITY_PAGES]);
  const { activities } = useActivitiesForDayOf(activity.athlete_id, activity.start.toDate());
  const date = React.useMemo(() => activity.start.toDate(), [activity.start]);
  const dateString = formatISO(date, { representation: 'date' });

  const activityTypes = React.useMemo(() => {
    if (activities == null) return new Set<string>();
    const types = Object.values(activities).map((v) => v?.labels?.type);
    return new Set(compact(types));
  }, [activities]);

  return (
    <ContentContainer id="aui_profile_info" spacing={1}>
      <InjuriesCard athleteId={activity.athlete_id} />
      <ActivityCard match={match} activityId={activityId} activities={activities} />
      <PageGutterPortal gutter="right">
        <div style={{ order: GUTTER_ORDER.multiActivityReport }}>
          <MultiActivityReports activityTypes={activityTypes} dateString={dateString} />
        </div>
      </PageGutterPortal>
      {children}
    </ContentContainer>
  );
}

type Props = {
  readonly activity: Activity | null | undefined;
  readonly activityId: string;
  readonly children: React.ReactNode;
};

export default function Guard({ children, activity, activityId }: Props) {
  if (isEmpty(activity)) {
    return <NotFound title="Activity Not Found" />;
  }
  return (
    <ActivityPageWrapper activity={activity} activityId={activityId}>
      {children}
    </ActivityPageWrapper>
  );
}
