import React from 'react';

import type { DisplayUnit } from 'plantiga-common/Fields/typedefs';

import Units from '../../../assets/units.json';

import { getTeamPreferences } from './preferences';
import useTeam from './useTeam';

export type FormatOptions = {
  nDigits?: number;
  UndefinedValue?: string;
  NullValue?: string;
};

class UnitConversionError extends Error {}

const EPSILON = 0.001;

export type { DisplayUnit };

function isInt(n: number) {
  return Math.abs(n % 1) <= EPSILON;
}

function getScale(storedUnit: string, wantedUnit?: string | null): number {
  if (wantedUnit == null) {
    return 1;
  }
  if (wantedUnit === storedUnit) {
    return 1;
  }

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ m: { ft: number; "ft/in": number; in: number; cm: number; km: number; miles: number; }; cm: { ft: number; "ft/in": number; in: number; m: number; }; "ft/in": { m: number; cm: number; }; ft: { in: number; }; "m/s": { "km/h": number; mph: number; "min/km": number; "min/mile": number; }; ... 7 more ...; "%": { ...; }...'.
  if (Units.unitConversions[storedUnit] == null) {
    throw new UnitConversionError(`Can not convert from unknown unit ${storedUnit}`);
  }
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ m: { ft: number; "ft/in": number; in: number; cm: number; km: number; miles: number; }; cm: { ft: number; "ft/in": number; in: number; m: number; }; "ft/in": { m: number; cm: number; }; ft: { in: number; }; "m/s": { "km/h": number; mph: number; "min/km": number; "min/mile": number; }; ... 7 more ...; "%": { ...; }...'.
  if (Units.unitConversions[storedUnit][wantedUnit] == null) {
    throw new UnitConversionError(
      `Can not convert from ${storedUnit} to unknown unit ${wantedUnit}`,
    );
  }
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ m: { ft: number; "ft/in": number; in: number; cm: number; km: number; miles: number; }; cm: { ft: number; "ft/in": number; in: number; m: number; }; "ft/in": { m: number; cm: number; }; ft: { in: number; }; "m/s": { "km/h": number; mph: number; "min/km": number; "min/mile": number; }; ... 7 more ...; "%": { ...; }...'.
  return Units.unitConversions[storedUnit][wantedUnit];
}

function ftinUnitFormatter(value: number) {
  const inScale = getScale('ft', 'in');
  const ft = Math.floor(value);
  const inches = (value - ft) * inScale;
  return `${ft ? `${ft.toFixed(0)}' ` : ''}${inches.toFixed(isInt(inches) ? 0 : 1)}"`;
}

function asymmetryUnitFormatter(value: number, fmt: (arg1: number) => string) {
  const isLeft = value < 0;
  const absAsymmetry = fmt(Math.abs(value));
  const customUnit = value === 0 ? '%' : `% ${isLeft ? 'L' : 'R'}`;
  return [absAsymmetry, customUnit];
}

function scaleValue(value: number, storedUnit?: string | null, wantedUnit?: string | null): number {
  if (storedUnit == null || wantedUnit == null) {
    return value;
  }

  const scale = getScale(storedUnit, wantedUnit);
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "min/km": boolean; "min/mile": boolean; }'.
  if (Units.invert?.[wantedUnit] ?? false) {
    return scale / value;
  }
  return value * scale;
}

function makeNDigits(sigFigs?: number | null) {
  const toNDigits = (v: number) => {
    if (sigFigs == null) {
      return v.toFixed(isInt(Math.round(v * 100) / 100) ? 0 : 2);
    }
    // number of digits left of the decimal place
    const mag = v === 0 ? 0 : Math.floor(Math.log10(Math.abs(v))) + 1;
    // value rounded to the number of desired digits
    const vv = Math.round(v * 10 ** sigFigs) / 10 ** sigFigs;
    // adjust for leading zeros
    return vv.toFixed(isInt(vv) ? 0 : Math.max(sigFigs - mag - +(v < 1), 0));
  };
  return toNDigits;
}

export function unitFormatter(
  value?: number | null,
  unit?: string | null,
  opts?: FormatOptions,
): [string, string | null | undefined] {
  const nDigits = opts?.nDigits;
  const UndefinedValue = opts?.UndefinedValue ?? '-';
  const NullValue = opts?.NullValue ?? 'NA';
  if (value === undefined) {
    return [UndefinedValue, null];
  }
  if (value === null) {
    return [NullValue, null];
  }
  if (!Number.isFinite(value)) {
    return ['NaN', null];
  }

  const toNDigits = makeNDigits(nDigits);
  if (unit == null) {
    return [toNDigits(value), null];
  }

  if (unit === 'ft/in') {
    return [ftinUnitFormatter(value), null];
  }

  if (unit === '% L/R') {
    // @ts-expect-error - TS2322 - Type 'string[]' is not assignable to type '[string, string | null | undefined]'.
    return asymmetryUnitFormatter(value, toNDigits);
  }

  let [vStr, vUnit] = [toNDigits(value), unit];

  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "min/km": boolean; "min/mile": boolean; }'.
  if (Units.decimalTime?.[unit] ?? false) {
    let sign = '';
    let v = value;
    if (value < 0) {
      sign = '-';
      v = Math.abs(value);
    }

    vStr = `${sign}${Math.floor(v).toFixed(0)}:${((v % 1) * 0.6).toFixed(2).split('.')[1]}`;
  }

  // scale to k g or k gpm
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "g": boolean; gpm: boolean; m: boolean; }'.
  if (Units.scalable[unit] && value > 1000) {
    if ((Units.scaleUnit as { [unit: string]: boolean })[unit]) {
      vStr = toNDigits(value / 1000);
      vUnit = `k${unit}`;
    } else {
      vStr = `${toNDigits(value / 1000)}k`;
    }
  }

  return [vStr, ` ${vUnit}`];
}

/* Change a metric unit int on imperial unit

  // explicit
  makeWantedUnit('cm', {imperial: 'in', metric: 'm'}, 'imperial') => 'in'
  makeWantedUnit('cm', {imperial: 'in', metric: 'm'}, 'metric') => 'm'

  // implicit
  makeWantedUnit('m/s', null, 'imperial') => 'mph'
  makeWantedUnit('m/s', null, 'metric') => 'm/s'
*/
export function makeWantedUnit(
  storedUnit: string | null | undefined,
  displayUnit: DisplayUnit | null | undefined,
  teamUnitType: 'imperial' | 'metric' | 'research',
): string | null | undefined {
  if (displayUnit == null) {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "m/s": string; m: string; cm: string; kg: string; ratio: string; "m/s^2": string; }'.
    if (storedUnit && teamUnitType === 'imperial' && Units.defaultConversions[storedUnit]) {
      // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ "m/s": string; m: string; cm: string; kg: string; ratio: string; "m/s^2": string; }'.
      return Units.defaultConversions[storedUnit];
    }
    return storedUnit;
  }
  if (typeof displayUnit === 'string') {
    return displayUnit;
  }
  return displayUnit[teamUnitType];
}

export function useWantedUnit(
  storedUnit?: string | null,
  displayUnit?: DisplayUnit | null,
): string | null | undefined {
  const { team } = useTeam();
  const { measures } = getTeamPreferences(team);
  const { unitType: teamUnitType } = measures;

  return makeWantedUnit(storedUnit, displayUnit, teamUnitType);
}

/**
 * Exposes the same functions as `useUnitTranslator` but with unit parameters
 * and options specified at invocation.
 * @returns `unitTranslator.translate` converts and formats a value with units for humans
 * @returns `unitTranslator.convert` converts a value
 * @returns `unitTranslator.format` formats a value with units for humans
 * @returns `unitTranslator.getWantedUnit` returns the unit based on team preferences
 */
export function useUnitTranslator2() {
  const { team } = useTeam();
  const { measures } = getTeamPreferences(team);
  const { unitType: teamUnitType } = measures;

  const getWantedUnit = React.useCallback(
    (storedUnit: string | null | undefined, displayUnit: DisplayUnit | null | undefined) =>
      makeWantedUnit(storedUnit, displayUnit, teamUnitType),
    [teamUnitType],
  );

  const convert = React.useCallback(
    (
      value: number | null | undefined,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
    ) => {
      if (value == null) return undefined;
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      return scaleValue(value, storedUnit, wantedUnit);
    },
    [getWantedUnit],
  );

  const unconvert = React.useCallback(
    (
      value: number,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
    ) => {
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      return scaleValue(value, wantedUnit, storedUnit);
    },
    [getWantedUnit],
  );

  const translate = React.useCallback(
    (
      value: number | null | undefined,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
      opts?: FormatOptions,
    ) => {
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      const cv = convert(value, storedUnit, displayUnit);
      return unitFormatter(cv, wantedUnit, opts)
        .filter((v) => v != null)
        .join('');
    },
    [getWantedUnit, convert],
  );

  const translateValue = React.useCallback(
    (
      value: number | null | undefined,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
      opts?: FormatOptions,
    ) => {
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      const cv = convert(value, storedUnit, displayUnit);
      return unitFormatter(cv, wantedUnit, opts)[0];
    },
    [convert, getWantedUnit],
  );

  const translateUnit = React.useCallback(
    (
      value: number | null | undefined,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
      opts?: FormatOptions,
    ) => {
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      const cv = convert(value, storedUnit, displayUnit);
      return unitFormatter(cv, wantedUnit, opts)[1];
    },
    [convert, getWantedUnit],
  );

  const format = React.useCallback(
    (
      value: number | null | undefined,
      storedUnit: string | null | undefined,
      displayUnit: DisplayUnit | null | undefined,
      opts?: FormatOptions,
    ) => {
      const wantedUnit = getWantedUnit(storedUnit, displayUnit);
      return unitFormatter(value, wantedUnit, opts);
    },
    [getWantedUnit],
  );

  return { convert, unconvert, translate, translateValue, translateUnit, format, getWantedUnit };
}

export function useUnitTranslator(
  storedUnit?: string | null,
  displayUnit?: DisplayUnit | null,
  opts?: FormatOptions,
) {
  const { convert, unconvert, translate, translateValue, translateUnit, format, getWantedUnit } =
    useUnitTranslator2();

  return React.useMemo(
    () => ({
      convert: (value: Parameters<typeof convert>[0]) => convert(value, storedUnit, displayUnit),
      unconvert: (value: Parameters<typeof unconvert>[0]) =>
        unconvert(value, storedUnit, displayUnit),
      translate: (value: Parameters<typeof translate>[0]) =>
        translate(value, storedUnit, displayUnit, opts),
      translateValue: (value: Parameters<typeof translateValue>[0]) =>
        translateValue(value, storedUnit, displayUnit, opts),
      translateUnit: (value: Parameters<typeof translateUnit>[0]) =>
        translateUnit(value, storedUnit, displayUnit, opts),
      format: (value: Parameters<typeof format>[0]) => format(value, storedUnit, displayUnit, opts),
      wantedUnit: getWantedUnit(storedUnit, displayUnit),
    }),
    [
      convert,
      unconvert,
      translate,
      translateValue,
      translateUnit,
      format,
      getWantedUnit,
      storedUnit,
      displayUnit,
      opts,
    ],
  );
}
