import * as Sentry from '@sentry/react';

import { useQuery } from 'plantiga-common/react-router-hooks';

export default () => {
  Sentry.addBreadcrumb({
    message: 'This is a test error',
  });
  const { errorName, message } = useQuery();

  const error = new Error(
    message || 'This page can be used to check that render errors get handled correctly',
  );
  if (errorName) {
    error.name = errorName;
  }

  throw error;
};
