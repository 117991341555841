import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { IconButton, TableCell, TableRow, TextField } from '@mui/material';

import { IconButtonWithLoading } from 'plantiga-common/ButtonWithLoading';
import { updateActivity } from 'plantiga-firebase/Activity/actions';
import type { ActivityWithDate } from 'plantiga-firebase/Activity/typedefs';

const useStyles = makeStyles()({
  title: {
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
  },
  tableRow: {
    height: 80,
  },
  pre: {
    whiteSpace: 'pre-wrap',
  },
});

type Props = {
  readonly activity: ActivityWithDate;
  readonly activityId: string;
  readonly teamId: string;
  readonly title: string;
  readonly field: string;
  readonly multiline?: boolean;
};
export default function EditRow({
  activity,
  activityId,
  teamId,
  title,
  field,
  multiline = false,
}: Props) {
  const { classes, cx } = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Activity'.
  const [value, setValue] = React.useState(activity[field] || '');

  const handleSave = async () => {
    // @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type 'Activity'.
    if (activity[field] !== value) {
      setLoading(true);
      await updateActivity({
        teamId,
        activityId,
        activity: { ...activity, [field]: value },
      });
      setLoading(false);
    }
    setEditing(false);
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell className={classes.title}>{title}</TableCell>
      <TableCell className={classes.content}>
        {editing ? (
          <TextField
            variant="outlined"
            fullWidth
            size="small"
            multiline={multiline}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        ) : (
          <span className={cx({ [classes.pre]: multiline })}>{value}</span>
        )}
      </TableCell>
      {editing ? (
        <TableCell>
          <IconButtonWithLoading loading={loading} disabled={loading} onClick={handleSave}>
            <SaveIcon />
          </IconButtonWithLoading>
        </TableCell>
      ) : (
        <TableCell>
          <IconButton onClick={() => setEditing(true)} size="large">
            <EditIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}
