import { httpsCallablePost } from '../util';

type DeleteDevice = {
  readonly deviceId: string;
  readonly teamId: string;
};

export async function deleteDevice({ teamId, deviceId }: DeleteDevice): Promise<any> {
  const deleteDeviceCF = httpsCallablePost('web-routes/device/deleteDevice');
  const req = {
    deviceId,
    teamId,
  } as const;
  const { data } = await deleteDeviceCF(req);
  return data;
}
