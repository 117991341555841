import { useMediaQuery } from '@mui/material';
import { type Theme } from '@mui/material/styles';

import useTheme from '../../util/MUI/useTheme';

// we are not server side rendering, and we need the initial value to be accurate to use
// this logic within redirects.
//
// options.noSsr (Boolean [optional]): Defaults to false.
// In order to perform the server-side rendering reconciliation, it needs to render twice.
// A first time with nothing and a second time with the children.
// This double pass rendering cycle comes with a drawback.
// It's slower. You can set this flag to true if you are not doing server-side rendering.
// https://v4.mui.com/components/use-media-query/#usemediaquery-query-options-matches
const OPTIONS = { noSsr: true };

export default function useBreakpoint(bp: keyof Theme['breakpoints']['values'] = 'mobile') {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(bp), OPTIONS);
  return fullScreen;
}
