import React from 'react';

import { Refresh as RefreshIcon } from '@mui/icons-material';
import {
  Badge,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import { SwContext } from 'plantiga-common/ServiceWorker/SwContext';

import useLatestVersion from './useLatestVersion';

export default function VersionDisplay() {
  const sw = React.useContext(SwContext);
  const { latestVersion, currentVersion, loading } = useLatestVersion();
  const updateAvailable = sw?.needRefresh[0];
  const versionsMatch = latestVersion === currentVersion;
  const primary = React.useMemo(() => {
    if (loading) {
      return '...';
    }
    return currentVersion ?? '?';
  }, [loading, currentVersion]);

  const secondary = React.useMemo(() => {
    if (loading) {
      return '-';
    }
    if (versionsMatch) {
      if (updateAvailable) {
        return 'update available';
      }
      return 'latest';
    }
    return `${latestVersion} available`;
  }, [loading, latestVersion, versionsMatch, updateAvailable]);

  const hardRefresh = React.useCallback(() => {
    sw?.updateServiceWorker(true);
  }, [sw]);

  return (
    <List>
      <ListItem>
        <ListItemText primary={primary} secondary={secondary} />
        {updateAvailable && (
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={hardRefresh} size="large">
              <Badge color="secondary" variant="dot">
                <RefreshIcon />
              </Badge>
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </List>
  );
}
