import React from 'react';

import { format as formatDate } from 'date-fns';
import { size } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import ButtonWithLoading from 'plantiga-common/ButtonWithLoading';
import { useQuery, useLocation } from 'plantiga-common/react-router-hooks';
import { useNavigateURL } from 'plantiga-common/useBuildURL';
import type { Athletes } from 'plantiga-firebase/Athletes/typedefs';
import type { Stopwatch } from 'plantiga-firebase/Stopwatch/typedefs';
import { useSaveStopwatch, useUpdateStopwatch } from 'plantiga-firebase/Stopwatch/useStopwatch';

const useStyles = makeStyles()((theme) => ({
  button: {
    minWidth: 96,
    borderRadius: 20,
  },
  pageGutterBottomContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'initial',
    },
  },
}));

type Props = {
  activityTypeId: string;
  swid: string;
  stopwatch: Stopwatch;
  athletes: Athletes;
  generic: any;
  specific: any;
  finalized: boolean;
  disabled: boolean;
};

export default function FinalizeActions({
  activityTypeId,
  athletes,
  swid,
  stopwatch,
  generic,
  specific,
  finalized,
  disabled,
}: Props) {
  const { classes } = useStyles();
  const queryParams = useQuery();
  const [saving, saveStopwatch] = useSaveStopwatch(swid);
  const [updating, updateStopwatch] = useUpdateStopwatch(swid);
  const { navigate } = useLocation();

  const [button, setButton] = React.useState<string | null>(null);
  const navigateURL = useNavigateURL();

  const handleSave = React.useCallback(async () => {
    if (finalized) return;
    // TODO: refactor Labels Component (add label button should created the new label)
    await saveStopwatch(generic, specific);
  }, [finalized, generic, saveStopwatch, specific]);

  const handleDone = React.useCallback(async () => {
    setButton('done');
    await handleSave();
    if (queryParams.next) {
      navigate(queryParams.next);
    } else if (size(athletes) === 1) {
      const day = formatDate(new Date(), 'yyyy-MM-dd');
      navigateURL('athleteDaily', { athleteId: stopwatch.athlete_ids[0], day });
    } else {
      navigateURL('athletes');
    }
  }, [athletes, handleSave, stopwatch, queryParams, navigate, navigateURL]);

  const handleNext = React.useCallback(async () => {
    setButton('next');
    await handleSave();
    if (queryParams.next) {
      navigate(queryParams.next);
    } else {
      navigateURL('stopwatch', {}, { ...queryParams, activityType: activityTypeId });
    }
  }, [activityTypeId, handleSave, navigate, navigateURL, queryParams]);

  const handleReset = React.useCallback(() => {
    updateStopwatch({ active: false });
    navigateURL('stopwatch', {}, { ...queryParams, activityType: activityTypeId });
  }, [activityTypeId, navigateURL, queryParams, updateStopwatch]);

  const loading = saving || updating;

  return (
    <div className={classes.pageGutterBottomContainer}>
      <ButtonWithLoading
        id="stopwatch-done-button"
        variant="contained"
        className={classes.button}
        disabled={loading || disabled}
        loading={saving && button === 'done'}
        onClick={handleDone}
        color="secondary"
      >
        Done
      </ButtonWithLoading>
      <ButtonWithLoading
        id="stopwatch-next-button"
        variant="contained"
        className={classes.button}
        disabled={loading || disabled}
        loading={saving && button === 'next'}
        onClick={handleNext}
        color="primary"
      >
        Next
      </ButtonWithLoading>
      {!finalized && (
        <ButtonWithLoading
          id="stopwatch-reset-button"
          variant="contained"
          color="inherit"
          className={classes.button}
          disabled={loading || disabled}
          loading={false}
          onClick={handleReset}
        >
          Reset
        </ButtonWithLoading>
      )}
    </div>
  );
}
