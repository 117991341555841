import React, { useEffect } from 'react';

export default function TriggerTimeoutError() {
  useEffect(() => {
    setTimeout(() => {
      throw new Error('This page can be used to check that uncaught errors get handled correctly');
    }, 0);
  }, []);
  return <span>Empty content</span>;
}
