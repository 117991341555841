import React from 'react';

import { Button } from '@mui/material';

import DismissableAlert from 'plantiga-common/DismissableAlert';
import { external } from 'plantiga-util/urls';

export default function GettingStartedAlert() {
  return (
    <DismissableAlert
      title="New to Plantiga?"
      dismissId="getting_started"
      actions={
        <Button component="a" href={external.help.guides} target="_blank">
          Show me
        </Button>
      }
    >
      Visit our <b>Knowledge base</b> for more information on how to use the Plantiga system.
    </DismissableAlert>
  );
}
