import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from 'plantiga-component/Error/NotFound';
import Loading from 'plantiga-component/Loading';

import urls from './_urls';
import acceptInvite from './accept-invite';
import demoSignin from './demo-signin';
import passwordReset from './password-reset';
import signin from './signin';
import signup from './signup';

export default function AccountRoutes() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Switch>
        <Route path={urls.signup} exact component={signup} />
        <Route path={urls.signin} exact component={signin} />
        <Route path={urls.demoSignin} exact component={demoSignin} />
        <Route path={urls.passwordReset} exact component={passwordReset} />
        <Route path={urls.acceptInvite} exact component={acceptInvite} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </React.Suspense>
  );
}
