import React from 'react';
import { Switch, Route } from 'react-router-dom';

import TriggerAsyncError from 'plantiga-component/Error/TriggerAsyncError';
import TriggerFirestoreError from 'plantiga-component/Error/TriggerFirestoreError';
import TriggerRenderError from 'plantiga-component/Error/TriggerRenderError';
import TriggerTimeoutError from 'plantiga-component/Error/TriggerTimeoutError';

import urls, { root } from './_urls';

export default function ErrorRoutes() {
  return (
    <Switch>
      <Route path={root} exact component={TriggerRenderError} />
      <Route path={urls.errorAsync} exact component={TriggerAsyncError} />
      <Route path={urls.errorTimeout} exact component={TriggerTimeoutError} />
      <Route path={urls.errorFirestore} exact component={TriggerFirestoreError} />
    </Switch>
  );
}
