import React from 'react';

import { useToast } from 'plantiga-component/Toast/UseToast';
import { httpsCallablePost } from 'plantiga-firebase/util';

export default function useDeleteEvent(): {
  error: undefined;
  loading: boolean;
  remove: (teamId: string, eventId: string) => Promise<void>;
} {
  const postToast = useToast();
  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const remove = React.useCallback(
    async (teamId: string, eventId: string) => {
      setLoading(true);
      try {
        const deleteEventCF = httpsCallablePost('web-routes/event/deleteEvent');
        const { data } = await deleteEventCF({ teamId, eventId });
        if (data.ok !== true) {
          throw new Error(JSON.stringify(data, null, 2));
        }
      } catch (err: any) {
        console.error(err);
        if (unMounted.current) return;
        postToast({ message: 'Could not delete the event', variant: 'error' });
        setError(err);
      }
      if (unMounted.current) return;
      setLoading(false);
    },
    [postToast],
  );

  return { remove, loading, error };
}
