export const root = '/org/:teamId/profile/';

const deprecated = {
  teamCloudAdapters: '/org/:teamId/profile/cloud-adapters',
};

export default {
  ...deprecated,
  teamProfile: root,
  teamGettingStarted: '/org/:teamId/profile/getting-started',
  teamActivityTypes: '/org/:teamId/profile/activity-types',
  teamCoaches: '/org/:teamId/profile/coaches',
  teamUsers: '/org/:teamId/profile/users',
  teamPreferences: '/org/:teamId/profile/preferences',
  teamTimeline: '/org/:teamId/profile/timeline',
  teamDevicesDeprecated: '/org/:teamId/profile/pods',
  teamDownloads: '/org/:teamId/profile/downloads',
};
