import React from 'react';

import { size } from 'lodash-es';

import { Dock as DockIcon } from '@mui/icons-material';
import { IconButton, Badge, Fade, Tooltip } from '@mui/material';

import Popover from 'plantiga-common/Popover';
import { useDockedDevices } from 'plantiga-firebase/Devices/useDevices';
import { useUndockNotify } from 'plantiga-firebase/Devices/useUndockNotify';

import DockedDevicesList from '../../Notifications/DockedDevicesList';

import useSecondaryActionStyles from './useSecondaryActionStyles';

export default function PodsSecondaryAction() {
  const { classes } = useSecondaryActionStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const dockedDevices = useDockedDevices();
  useUndockNotify();

  return (
    <>
      <Fade in={size(dockedDevices) > 0}>
        <Tooltip title="Docked Pods">
          <IconButton
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
          >
            <Badge badgeContent={size(dockedDevices)} classes={{ badge: classes.badge }}>
              <DockIcon fontSize="inherit" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Fade>
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        classes={{ paper: classes.popoverPaper }}
      >
        <DockedDevicesList dockedDevices={dockedDevices} />
      </Popover>
    </>
  );
}
