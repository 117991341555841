import React from 'react';

import { merge, pick, sortBy } from 'lodash-es';

import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import {
  Drawer,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  TextField,
  Autocomplete,
} from '@mui/material';

import ActivityIcon from 'plantiga-common/ActivityIcon';
import ActivityTypeInfo from 'plantiga-common/ActivitySelect/ActivityTypeInfo';
import useBreakpoint from 'plantiga-common/useBreakpoint';
import type { ActivityTypeDefns } from 'plantiga-firebase/ActivityTypes/ActivityTypeDefn';
import useActivityTypes from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import { useLocalLRU } from 'plantiga-util/useLocalLRU';

import { ALL_ACTIVITIES } from './constants';

type Option = {
  activityTypeId: string;
  order: number | undefined;
  group: string;
  name: string;
} & ({ description: string } | { description: undefined });

const ALL_ACTIVITIES_OPTION: Option = {
  activityTypeId: ALL_ACTIVITIES,
  order: undefined,
  group: '',
  name: 'All Activities',
  description: undefined,
};

const activityOptions = (activityTypeDefns: ActivityTypeDefns, lru: string[]): Option[] => {
  const options = Object.values(activityTypeDefns).map((actType): Option => {
    const baseActivityType = actType.pseudoBase;
    // if the base activity type is disabled, fallback to the specific activity type
    const baseDefn = activityTypeDefns[baseActivityType] ?? activityTypeDefns[actType.id];
    return {
      activityTypeId: actType.id,
      order: actType.defn.order,
      name: actType.name,
      group: `${baseDefn.name} Activities`,
      description: actType.defn.description,
    };
  });
  return sortBy(options, [
    (o) => {
      const i = lru.indexOf(o.activityTypeId);
      return i < 0 ? Infinity : i;
    },
    'group',
    'order',
    'name',
  ]);
};

const renderOption = (
  props: any,
  option: Option,
  setActivityInfo: (activityTypeId: string) => void,
  omitAvatar: boolean,
) => (
  <li {...props}>
    <div style={{ display: 'flex', position: 'relative', width: '100%', alignItems: 'center' }}>
      {!omitAvatar && (
        <ListItemAvatar>
          <ActivityIcon activityType={option.activityTypeId} />
        </ListItemAvatar>
      )}
      <ListItemText primaryTypographyProps={{ noWrap: true }}>{option.name}</ListItemText>
      {option.description && (
        <IconButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            setActivityInfo(option.activityTypeId);
          }}
          edge="end"
          size="large"
        >
          <InfoIcon />
        </IconButton>
      )}
    </div>
  </li>
);

const renderInput = (props: React.ComponentProps<typeof TextField> | undefined) =>
  function (params: React.ComponentProps<typeof TextField>) {
    merge(params, props);
    return <TextField variant="outlined" label="Select Activity" {...params} />;
  };

type Props = {
  hideSubHeaders?: boolean;
  from?: readonly string[];
  textFieldProps?: React.ComponentProps<typeof TextField>;
  disabled?: boolean;
  enabledActivityTypesOnly?: boolean;
  clearedOption?: Option;
} & (
  | {
      activityTypeId: string;
      clearable?: false;
      onChange: (activityType: string) => void;
    }
  | {
      activityTypeId: string | null;
      clearable: true;
      onChange: (activityType: string | null) => void;
    }
);

export function AutocompleteActivityType({
  from,
  hideSubHeaders = false,
  onChange,
  activityTypeId,
  textFieldProps: _textFieldProps,
  clearable,
  clearedOption = ALL_ACTIVITIES_OPTION,
  disabled,
  enabledActivityTypesOnly = true,
}: Props) {
  const [lru, updateLru] = useLocalLRU<string>('autocomplete-activity-types-lru');
  const activityTypeDefns = useActivityTypes({ enabled: enabledActivityTypesOnly });
  const [selected, setSelected] = React.useState<string | undefined>(
    clearable ? activityTypeId ?? clearedOption.activityTypeId : activityTypeId,
  );

  const options = React.useMemo(() => {
    const opts = clearable ? [clearedOption] : [];
    return [
      ...opts,
      ...activityOptions(from ? pick(activityTypeDefns, from) : activityTypeDefns, lru),
    ];
  }, [activityTypeDefns, lru, from, clearable, clearedOption]);

  const handleChange = async (_: any, option: Option | undefined | null, reason: string) => {
    // the user should be explicit in their selection
    // ignore 'clear' which can be triggered when the user deletes all text in the input
    if (reason === 'clear') return;

    setSelected(() => {
      if (clearable === true && option?.activityTypeId === clearedOption.activityTypeId) {
        onChange(null);
      } else if (option) {
        onChange(option.activityTypeId);
        updateLru([option.activityTypeId]);
      }
      return option?.activityTypeId ?? ALL_ACTIVITIES;
    });
  };

  const textFieldProps = React.useMemo(
    () =>
      ({
        ...(_textFieldProps ?? {}),
        InputProps: {
          startAdornment:
            selected === clearedOption.activityTypeId || !selected ? null : (
              <ActivityIcon activityType={selected} />
            ),
          ...(_textFieldProps?.InputProps ?? {}),
        },
      } as const),
    [_textFieldProps, selected, clearedOption.activityTypeId],
  );

  const isMobile = useBreakpoint('xs');
  const anchor = isMobile ? 'bottom' : 'right';
  const [infoActivityType, setInfoActivityType] = React.useState<string>();
  const infoValue = options.find((option) => option.activityTypeId === infoActivityType);
  const value = options.find((option) => option.activityTypeId === selected) ?? null;

  return (
    <>
      <Autocomplete
        openOnFocus
        autoHighlight
        className="aui_autocomplete_activity_type"
        disabled={disabled}
        disableClearable={!clearable}
        ListboxComponent={List}
        value={value}
        options={options}
        groupBy={(option) => {
          if (hideSubHeaders) return '';
          if (lru.find((v) => v === option.activityTypeId)) return 'Recent';
          return option.group;
        }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option) => selected === option.activityTypeId}
        onChange={handleChange}
        renderInput={renderInput(textFieldProps)}
        renderOption={(props, option) =>
          renderOption(
            props,
            option,
            setInfoActivityType,
            option.activityTypeId === clearedOption.activityTypeId,
          )
        }
      />
      <Drawer anchor={anchor} open={!!infoValue} onClose={() => setInfoActivityType(undefined)}>
        {infoValue && infoValue.description && (
          <React.Suspense fallback="Loading...">
            <ActivityTypeInfo
              title={infoValue.name}
              activityType={infoValue.activityTypeId}
              description={infoValue.description}
            />
          </React.Suspense>
        )}
      </Drawer>
    </>
  );
}
