import {
  doc as fbDoc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  Timestamp,
  where,
  query,
  orderBy,
  getDocs,
  serverTimestamp,
} from 'firebase/firestore';
import React, { useCallback } from 'react';

import { collection, doc } from '..';
import type { Stopwatch } from '../schema';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';

import type { Mark } from './typedefs';

type UseMarksParams = {
  readonly athleteId: string;
  readonly start: Date;
  readonly end: Date;
};

export default function useMarks({ athleteId, start, end }: UseMarksParams): Mark[] {
  const db = useFirestore();
  const { teamId } = useTeam();
  const isMounted = React.useRef(true);
  const [marks, setMarks] = React.useState<Mark[]>([]);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  React.useEffect(() => {
    const fetchMarks = async () => {
      const ref = query(
        collection(db, 'teams', teamId, 'marks'),
        where('athlete_id', '==', athleteId),
        orderBy('dt'),
        where('dt', '>=', start),
        where('dt', '<=', end),
      );

      const snap = await getDocs(ref);

      const result = await Promise.all(snap.docs.map(async (d) => d.data()));
      if (!isMounted.current) return;
      setMarks(result);
    };
    fetchMarks();
  }, [athleteId, db, end, start, teamId]);
  return marks;
}

type UseAddMarkParams = {
  readonly swid: string;
  readonly dt: Date;
  readonly labels: Mark['labels'];
  readonly notes: Mark['notes'];
};

type UseUpdateMarkParam = {
  readonly markIds: string[];
  readonly labels: Mark['labels'];
  readonly notes: Mark['notes'];
};

export function useMark(): {
  addMark: (arg1: UseAddMarkParams) => Promise<string[]>;
  deleteMark: (markIds: string[]) => Promise<void>;
  finalizeMark: (arg1: UseUpdateMarkParam) => Promise<void>;
} {
  const db = useFirestore();
  const { teamId } = useTeam();

  const addMark = useCallback(
    async ({ swid, dt, labels, notes }: UseAddMarkParams) => {
      const ref = doc(db, 'teams', teamId, 'stopwatches', swid);
      const snap = await getDoc(ref);
      const sw = snap.data() as Stopwatch; // TODO: deal with undefined
      const markIds: string[] = [];

      await Promise.all(
        sw.athlete_ids.map((athleteId) => {
          // TODO: permit more parameter like the builtin fbDoc
          const markRef = fbDoc(collection(db, 'teams', teamId, 'marks'));
          markIds.push(markRef.id);
          return setDoc(markRef, {
            athlete_id: athleteId,
            dt: Timestamp.fromDate(dt),
            server_dt: serverTimestamp(),
            labels,
            notes,
          });
        }),
      );
      return markIds;
    },
    [db, teamId],
  );

  const deleteMark = useCallback(
    async (markIds: Array<string>) => {
      await Promise.all(
        markIds.map((markId) => {
          const markRef = doc(db, 'teams', teamId, 'marks', markId);
          return deleteDoc(markRef);
        }),
      );
    },
    [db, teamId],
  );

  const finalizeMark = useCallback(
    // TODO: stopwatchFactory ignores mark_labels, so let's ignore these for now
    async ({ markIds, labels, notes }: UseUpdateMarkParam) => {
      await Promise.all(
        markIds.map((markId) => {
          const markRef = doc(db, 'teams', teamId, 'marks', markId);
          return updateDoc(markRef, {
            labels,
            notes,
          });
        }),
      );
    },
    [db, teamId],
  );

  return { addMark, deleteMark, finalizeMark };
}
