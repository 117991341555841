import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Close as CloseIcon } from '@mui/icons-material';
import {
  ButtonGroup,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogTitle as MuiDialogTitle,
  IconButton,
} from '@mui/material';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';

import useBreakpoint from './useBreakpoint';

const useDialogTitleStyles = makeStyles()((theme) => ({
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(1),
  },
}));

type DialogTitleProps = React.ComponentProps<typeof MuiDialogTitle> &
  Pick<React.ComponentProps<typeof Dialog>, 'onClose'>;

function DialogTitle({ children, onClose, ...props }: DialogTitleProps) {
  const { classes } = useDialogTitleStyles();
  return (
    <MuiDialogTitle {...props}>
      {children}
      {onClose ? (
        <IconButton size="small" onClick={onClose} aria-label="Close" className={classes.close}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

const useDialogActionsStyles = makeStyles()({
  actionsNoPadding: {
    padding: 0,
  },
});

type DialogActionsProps = {
  children: React.ReactNode | React.ReactNodeArray;
  fullScreen: boolean;
};

function DialogActions({ children, fullScreen }: DialogActionsProps) {
  const { classes, cx } = useDialogActionsStyles();

  const buttons = React.useMemo(() => {
    if (React.isValidElement(children)) return [children];
    if (Array.isArray(children)) return children as React.ReactNodeArray;
    return [null];
  }, [children]);

  if (children == null) return null;
  return (
    <MuiDialogActions className={cx({ [classes.actionsNoPadding]: fullScreen })}>
      {/* ButtonGroup will not accept React.Fragments, so we pass it an array of elements */}
      <ConditionalWrapper
        condition={fullScreen}
        wrapperComponent={ButtonGroup}
        wrapperProps={{ fullWidth: true }}
      >
        {buttons}
      </ConditionalWrapper>
    </MuiDialogActions>
  );
}

const useDialogStyles = makeStyles()({
  dialog: {
    overflow: 'hidden',
    paddingTop: `env(safe-area-inset-top)`,
    paddingBottom: `env(safe-area-inset-bottom)`,
    paddingLeft: `env(safe-area-inset-left)`,
    paddingRight: `env(safe-area-inset-right)`,
  },
});

type Props = React.ComponentProps<typeof MuiDialog> & {
  readonly title: string;
  readonly children: React.ReactNode;
  readonly actionButtons?: React.ReactNode;
  readonly color?: 'primary' | 'secondary';
  readonly onClose: any;
};

export default function Dialog({
  children,
  title,
  onClose,
  actionButtons = null,
  ...props
}: Props) {
  const { classes } = useDialogStyles();
  const fullScreen = useBreakpoint();
  return (
    <MuiDialog {...props} onClose={onClose} fullScreen={fullScreen} className={classes.dialog}>
      <DialogTitle onClose={onClose}>{title}</DialogTitle>
      {children}
      <DialogActions fullScreen={fullScreen}>{actionButtons}</DialogActions>
    </MuiDialog>
  );
}
