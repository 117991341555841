import React from 'react';

import { Button, Snackbar } from '@mui/material';

import useHardRefresh from 'plantiga-component/Error/useHardRefresh';
import { useConnected } from 'plantiga-firebase/Connected/useConnected';

function RefreshButton() {
  const hardRefresh = useHardRefresh();
  return (
    <Button color="inherit" onClick={hardRefresh}>
      Refresh
    </Button>
  );
}

export default function ConnectedSnackbar() {
  const { connected } = useConnected();

  return (
    <Snackbar open={!connected} message="You are working offline." action={<RefreshButton />} />
  );
}
