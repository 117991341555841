import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Collapse, ListItem, ListItemText, Paper } from '@mui/material';

import ConditionalWrapper from 'plantiga-common/ConditionalWrapper';
import ErrorBoundary from 'plantiga-component/Error/Boundary';
import { useLatestActivity } from 'plantiga-component/Firestore/Activity/useActivity';
import type { Activity } from 'plantiga-firebase/Activity/typedefs';

import ActivityListItem from './ActivityListItem';

const AsymmetryQuadrants = React.lazy(() => import('plantiga-component/Metric/AsymmetryQuadrants'));

const useStyles = makeStyles<void, 'selected'>()((theme, _params, classes) => ({
  collapseContent: {
    marginTop: theme.spacing(1),
  },
  content: {
    display: 'grid',
    gap: theme.spacing(1),
  },
  disableGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  disablePadding: {
    padding: 0,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    [`&.${classes.selected}`]: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  selected: {},
}));

function usePriorActivityId(activity: Activity) {
  const activityTime = activity.start.toMillis();
  const activityType = activity.labels.type ?? 'open';

  const options = React.useMemo(
    () => ({
      before: new Date(activityTime),
      activityType,
    }),
    [activityTime, activityType],
  );
  const { activity: priorActivity, activityId } = useLatestActivity(activity.athlete_id, options);

  // not initialized
  if (priorActivity === undefined) return undefined;
  // not found
  if (priorActivity === null) return null;
  // found
  return activityId;
}

type ContentProps = {
  athleteId: string;
  activityId: string;
  activityType: string;
  compareActivityId: string | null | undefined;
  onCompare: (activityId: string | undefined) => void;
  selectedActivityType: string;
  onSelectActivityType: (activityType: string) => void;
  activity: Activity;
  onDelete: (() => void) | undefined;
};

type Props = {
  readonly activityId: string;
  readonly activity: Activity;
  readonly paper?: boolean;
  readonly variant?: 'daily' | 'full-date';
  readonly children?: React.ReactNode;
  readonly disableGutters?: boolean;
  readonly disablePadding?: boolean;
  readonly selected?: boolean | null | undefined;
  readonly disabled?: boolean | null | undefined;
  readonly listItemProps?: React.ComponentProps<typeof ListItem>;
  readonly showCompare?: boolean;
  renderContent?: (p: ContentProps) => React.ReactNode;
  renderButtons?: (p: ContentProps) => React.ReactNode;
  readonly showValue?: boolean;
  readonly showNameOfPerson?: boolean;
  readonly onClick?: any;
  readonly onDelete?: () => void;
};

const InnerComponent = React.forwardRef<typeof ListItem, Props>(
  (
    {
      activityId,
      activity,
      variant,
      paper = false,
      children = null,
      disableGutters = false,
      disablePadding = false,
      selected = false,
      disabled = false,
      onClick = undefined,
      renderContent = undefined,
      renderButtons = undefined,
      showValue = true,
      showNameOfPerson = false,
      listItemProps = { button: undefined },
      onDelete,
    }: Props,
    ref,
  ) => {
    const { classes, cx } = useStyles();
    const priorActivityId = usePriorActivityId(activity);

    const [compareActivityId, setCompareActivityId] = React.useState<string | undefined | null>(
      null,
    );

    const activityType = activity.labels?.type_id ?? activity.labels?.type ?? 'open';
    const [selectedActivityType, setSelectedActivityType] = React.useState(activityType);

    const handleClick = React.useCallback(
      (e: React.MouseEvent) => {
        if (onClick == null) {
          return;
        }
        onClick(e);
      },
      [onClick],
    );

    const handleCompare = React.useCallback(
      (newActivityId: string | undefined) => {
        setCompareActivityId(newActivityId === activityId ? priorActivityId : newActivityId);
      },
      [activityId, priorActivityId],
    );

    const handleActivityTypeSelect = React.useCallback(
      (v: string) => setSelectedActivityType(v),
      [],
    );

    const open = selected || renderContent == null || renderButtons == null;

    const contentProps = {
      athleteId: activity.athlete_id,
      activityId,
      activityType,
      compareActivityId: compareActivityId ?? priorActivityId,
      onCompare: handleCompare,
      selectedActivityType,
      onSelectActivityType: handleActivityTypeSelect,
      activity,
      onDelete,
    };

    return (
      <div className={classes.container}>
        <ConditionalWrapper
          condition={paper}
          wrapperComponent={Paper}
          wrapperProps={{
            variant: open ? undefined : 'outlined',
            className: cx(classes.paper, { [classes.selected]: open }),
          }}
        >
          <ActivityListItem
            className={disablePadding ? classes.disablePadding : undefined}
            activity={activity}
            activityId={activityId}
            disableGutters={disableGutters}
            // @ts-expect-error
            ref={ref}
            onClick={handleClick}
            showNameOfPerson={showNameOfPerson}
            variant={variant}
            showValue={showValue}
            {...listItemProps}
            // @ts-expect-error
            button={!disabled && onClick != null ? true : undefined}
            secondaryAction={
              <React.Suspense fallback={null}>
                <AsymmetryQuadrants
                  athleteId={activity.athlete_id}
                  activityId={activityId}
                  activityType={selectedActivityType}
                />
              </React.Suspense>
            }
          />
          {children}
        </ConditionalWrapper>
        {(renderContent || renderButtons) && (
          <Collapse in={open} collapsedSize={0}>
            <div
              className={cx(classes.content, classes.collapseContent, {
                [classes.disableGutters]: disableGutters,
              })}
            >
              {renderContent?.(contentProps)}
              {renderButtons?.(contentProps)}
            </div>
          </Collapse>
        )}
      </div>
    );
  },
);

type ActivityItemProps = Omit<Props, 'activityId' | 'activity'> & {
  readonly activityId: string | null | undefined;
  readonly activity: Activity | null | undefined;
};

const ActivityItem = React.forwardRef<typeof ListItem, ActivityItemProps>(
  ({ activityId, activity, children = null, onClick = null, ...props }: ActivityItemProps, ref) => {
    if (activity == null || activityId == null) {
      return (
        <ListItem {...props} onClick={onClick} ref={ref}>
          <ListItemText primary="No activity found" />
          {children}
        </ListItem>
      );
    }

    return (
      <ErrorBoundary>
        <InnerComponent
          ref={ref}
          activityId={activityId}
          activity={activity}
          onClick={onClick}
          {...props}
        >
          {children}
        </InnerComponent>
      </ErrorBoundary>
    );
  },
);

export default ActivityItem;
