import React from 'react';

import { sortBy, toPairs } from 'lodash-es';

import { MenuItem, TextField } from '@mui/material';

import { useNavigateURL } from 'plantiga-common/useBuildURL';
import useTeam, { useLoadTeams } from 'plantiga-firebase/Team/useTeam';

export default function TeamSelect() {
  const { teamId } = useTeam();
  const { teams, loading } = useLoadTeams();

  const navigateURL = useNavigateURL();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    navigateURL('teamLanding', { teamId: value });
    localStorage.setItem('currentTeamId', value);
  };

  const sortedTeams = sortBy(toPairs(teams), ([, team]) => team?.name?.toLowerCase() ?? '');

  return (
    <TextField
      select
      fullWidth
      id="aui-team-select"
      SelectProps={{ MenuProps: { id: 'aui-team-select-menu' } }}
      margin="none"
      label="Organization"
      variant="outlined"
      size="small"
      value={loading ? '...' : teamId}
      onChange={handleChange}
    >
      {loading ? <MenuItem value="...">...</MenuItem> : null}
      {sortedTeams.map(([id, team]) => (
        <MenuItem key={id} id={`aui_${id}`} value={id}>
          {team?.name ?? ''}
        </MenuItem>
      ))}
    </TextField>
  );
}
