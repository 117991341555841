import React from 'react';

import { useParams } from 'plantiga-common/react-router-hooks';

import CustomActivityLabels from '../CustomActivityLabels';

export default function LabelsView() {
  const { activityId } = useParams();
  return <CustomActivityLabels activityId={activityId} />;
}
