import React from 'react';

import { map } from 'lodash-es';

import { MenuItem, TextField } from '@mui/material';

import type { Athletes } from '../../Firestore/Athletes/typedefs';

type Props = {
  readonly currentItem: string | null | undefined;
  readonly items: Athletes;
  readonly onChange: (arg1: string) => void;
  readonly unselectedLabelText: string;
  readonly disabled: boolean;
};

export default function FinalizeDropdown({
  currentItem,
  items,
  onChange,
  unselectedLabelText,
  disabled,
}: Props) {
  return (
    <TextField
      select
      fullWidth
      margin="normal"
      variant="outlined"
      label={currentItem ? '' : unselectedLabelText}
      disabled={disabled}
      value={currentItem || ''}
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={{
        shrink: false,
      }}
    >
      <MenuItem value={null}>{unselectedLabelText}</MenuItem>
      {map(items, (option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
}
