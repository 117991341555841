import { query, updateDoc, deleteField, where } from 'firebase/firestore';
import React, { useContext, useMemo, useCallback, useState } from 'react';

import { isEqual, omitBy, pickBy } from 'lodash-es';

import { collection, doc } from '..';
import getDeviceStatus from '../../../util/getDeviceStatus';
import { useToast } from '../../Toast/UseToast';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import useSubscribe from '../useSubscribe';

import DevicesContext from './DevicesContext';
import type { Devices } from './typedefs';

export default function useDevices(athleteId?: string): Devices {
  const { devices } = useContext(DevicesContext);
  const memo = React.useRef(devices);
  return React.useMemo(() => {
    if (athleteId) {
      const athleteDevices = pickBy(devices, (device) => device.athlete_id === athleteId);
      if (memo.current != null && isEqual(athleteDevices, memo.current)) return memo.current;
      memo.current = athleteDevices;
      return athleteDevices;
    }
    return devices;
  }, [athleteId, devices]);
}
export function useDevicesLoading(): {
  error: Error | null | undefined;
  loading: boolean;
} {
  const { loading, error } = useContext(DevicesContext);
  return { loading, error };
}

export function useDockedDevices() {
  const db = useFirestore();
  const { teamId } = useTeam();
  const ref = useMemo(
    () => query(collection(db, 'teams', teamId, 'devices'), where('docked', '>', '')),
    [db, teamId],
  );
  const [devices] = useSubscribe(ref);

  const removeUnknownDevices: Devices = useMemo(
    () => omitBy(devices, (device) => getDeviceStatus(device) === 'unknown'),
    [devices],
  );
  return removeUnknownDevices;
}

export function useUpdateDeviceName(): {
  loading: boolean;
  update: (deviceId: string, name: string) => Promise<void>;
} {
  const db = useFirestore();
  const { teamId } = useTeam();
  const postToast = useToast();
  const [loading, setLoading] = useState(false);

  const update = useCallback(
    async (deviceId: string, name: string) => {
      setLoading(true);
      const ref = doc(db, 'teams', teamId, 'devices', deviceId);
      try {
        if (name.length > 0) {
          await updateDoc(ref, { name: name.slice(0, 9) });
        } else {
          await updateDoc(ref, { name: deleteField() });
        }
      } catch (error: any) {
        postToast({ message: 'Failed to update pod name', variant: 'error' });
        console.warn(error);
      }
      setLoading(false);
    },
    [db, postToast, teamId],
  );

  return { loading, update };
}
