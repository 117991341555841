import React from 'react';

import { useParams } from 'plantiga-common/react-router-hooks';
import { useAthleteTitle } from 'plantiga-common/titles';

type Props = {
  children: React.ReactNode;
};

export default function ProfileLayout({ children }: Props) {
  const { athleteId } = useParams();

  useAthleteTitle(athleteId, 'Profile');

  return <>{children}</>;
}
