import React from 'react';

import { TimerOutlined as TimerIcon } from '@mui/icons-material';
import { IconButton, Tooltip, Fade, Badge } from '@mui/material';

import Popover from 'plantiga-common/Popover';

import ActiveStopwatchList from '../../Notifications/ActiveStopwatchList';
import useNotifications from '../../Notifications/useNotifications';

import useSecondaryActionStyles from './useSecondaryActionStyles';

export default function RecordSecondaryAction() {
  const { classes } = useSecondaryActionStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { badgeContent } = useNotifications();

  return (
    <>
      <Fade in={Boolean(badgeContent)}>
        <Tooltip title="Active Recordings">
          <IconButton
            id="nav-active-stopwatches-button"
            aria-label="current activity recordings"
            size="small"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
          >
            <Badge badgeContent={badgeContent} color="secondary" classes={{ badge: classes.badge }}>
              <TimerIcon fontSize="inherit" />
            </Badge>
          </IconButton>
        </Tooltip>
      </Fade>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ paper: classes.popoverPaper }}
      >
        <ActiveStopwatchList onClick={() => setAnchorEl(null)} />
      </Popover>
    </>
  );
}
