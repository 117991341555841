import React from 'react';

import useAsync from 'plantiga-common/useAsync';
import useOrgApi from 'plantiga-util/apiClient/useOrgApi';

import useTeam from '../Team/useTeam';

import type { ActivityTypes } from './typedefs';

type ActivityTypesContextType = {
  readonly error: Error | null | undefined;
  readonly initialized: boolean;
  readonly loading: boolean;
  readonly activityTypes: ActivityTypes;
  readonly reload: () => Promise<Promise<ActivityTypes> | undefined>;
};

const EMPTY_ACTIVITY_DEFNS = {};

export const ActivityTypesContext: React.Context<ActivityTypesContextType> =
  React.createContext<ActivityTypesContextType>({
    error: null,
    initialized: false,
    loading: true,
    activityTypes: EMPTY_ACTIVITY_DEFNS,
    reload: () => new Promise(() => {}),
  });

export function useActivityTypesContext(): ActivityTypesContextType {
  const { teamId } = useTeam();
  const client = useOrgApi();
  const { data, getData, error, initialized, loading } = useAsync(client.listActivityTypes, {
    team_id: teamId,
  });
  return React.useMemo(
    () => ({
      activityTypes: data ?? EMPTY_ACTIVITY_DEFNS,
      reload: getData,
      error,
      initialized,
      loading,
    }),
    [data, getData, error, initialized, loading],
  );
}
