import {
  collection as fbCollection,
  CollectionReference,
  doc as fbDoc,
  DocumentReference,
} from 'firebase/firestore';

import type { firestore_schema } from './schema';
import type useFirestore from './useFirestore';

type Firestore = ReturnType<typeof useFirestore>;

type NestedCollection<TFields, TCollections> = {
  fields: TFields;
  collections: TCollections;
};

type Collection<TFields, TCollections extends { [name: string]: unknown }> = {
  [id: string]: TFields | NestedCollection<TFields, TCollections>;
};

type _CollectionFields<T> = T extends NestedCollection<infer TFields, infer _TCollections>
  ? TFields
  : T;
type CollectionFields<T> = T extends Collection<infer TFields, infer _TCollections>
  ? _CollectionFields<TFields>
  : never;

type SubCollectionKeys<T> = T extends Collection<infer _TFields, infer TCollections>
  ? keyof TCollections
  : never;

type SubCollectionKey<TCollections, TKey> = TKey extends keyof TCollections
  ? TCollections[TKey]
  : never;
type SubCollection<TColl, TKey> = TColl extends Collection<infer _TFields, infer TCollections>
  ? SubCollectionKey<TCollections, TKey>
  : never;

export function collection<TKey extends keyof firestore_schema>(
  db: Firestore,
  ...path: [TKey]
): CollectionReference<CollectionFields<firestore_schema[TKey]>>;

export function collection<
  TKey1 extends keyof firestore_schema,
  TKey2 extends SubCollectionKeys<firestore_schema[TKey1]>,
>(
  db: Firestore,
  ...path: [TKey1, string, TKey2]
): CollectionReference<CollectionFields<SubCollection<firestore_schema[TKey1], TKey2>>>;

export function collection(db: Firestore, path: string, ...pathSegments: string[]) {
  return fbCollection(db, path, ...pathSegments);
}

export function doc<TKey extends keyof firestore_schema>(
  db: Firestore,
  ...path: [TKey, string]
): DocumentReference<CollectionFields<firestore_schema[TKey]>>;

export function doc<
  TKey1 extends keyof firestore_schema,
  TKey2 extends SubCollectionKeys<firestore_schema[TKey1]>,
>(
  db: Firestore,
  ...path: [TKey1, string, TKey2, string]
): DocumentReference<CollectionFields<SubCollection<firestore_schema[TKey1], TKey2>>>;

export function doc(db: Firestore, path: string, ...pathSegments: string[]) {
  return fbDoc(db, path, ...pathSegments);
}
