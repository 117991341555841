import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Paper, Typography } from '@mui/material';

import { usePrivilege } from 'plantiga-auth/usePrivilege';
import { useParams } from 'plantiga-common/react-router-hooks';
import { useAthlete } from 'plantiga-firebase/Athletes/useAthletes';
import useTeam from 'plantiga-firebase/Team/useTeam';

import DeleteAthlete from './Delete/DeleteAthlete';

const useStyles = makeStyles()((theme) => ({
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dangerZone: {
    border: `1px solid ${theme.palette.error.main}`,
    padding: theme.spacing(2),
  },
}));

export default function DangerZone() {
  const { classes } = useStyles();
  const { teamId } = useTeam();
  const { athleteId } = useParams();
  const athlete = useAthlete(athleteId);
  const canDeletePerson = usePrivilege('person:delete');
  if (!canDeletePerson) return null;
  return (
    <div className={classes.container}>
      <Typography component="h5" variant="h5" gutterBottom>
        Danger Zone
      </Typography>
      <Paper elevation={0} classes={{ root: classes.dangerZone }}>
        {/* @ts-expect-error - TS2322 - Type '{ athlete: Athlete; athleteId: any; teamId: string; }' is not assignable to type 'IntrinsicAttributes & Props'. */}
        <DeleteAthlete athlete={athlete} athleteId={athleteId} teamId={teamId} />
      </Paper>
    </div>
  );
}
