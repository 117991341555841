import BaseApiClient from './BaseApiClient';
import raiseForStatus from './raiseForStatus';

export default class DemoAPIClient extends BaseApiClient {
  getDemoToken = async (payload: { key: string; password: string }) => {
    const path = `/cloud-gears/demos/token`;
    const options = { body: payload };
    const [token, status] = await this.send('POST', path, options);
    raiseForStatus(status);
    return token;
  };
}
