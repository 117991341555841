import React from 'react';

import { pickBy } from 'lodash-es';

import { collection } from '..';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import useSubscribe from '../useSubscribe';

import type { Athletes } from './typedefs';

type AthletesContextType = {
  readonly loading: boolean;
  readonly athletes: Athletes;
  readonly unarchivedAthletes: Athletes;
  readonly error: Error | null | undefined;
};

const AthletesContext: React.Context<AthletesContextType> =
  React.createContext<AthletesContextType>({
    loading: true,
    athletes: {},
    unarchivedAthletes: {},
    error: null,
  });

export function useAthletesContext(): AthletesContextType {
  const { teamId } = useTeam();
  const db = useFirestore();

  const ref = React.useMemo(() => collection(db, 'teams', teamId, 'athletes'), [db, teamId]);
  const [athletes, loading, error] = useSubscribe(ref);
  const unarchivedAthletes = React.useMemo(() => pickBy(athletes, (v) => !v.archived), [athletes]);

  return { loading, athletes, unarchivedAthletes, error };
}

export default AthletesContext;
