import type { GetDataRequest, GenerateCsvRequest, GetChatToken } from './ApiClientTypedefs';
import BaseAPIClient from './BaseApiClient';
import raiseForStatus from './raiseForStatus';

// TODO: rowansdabomb break this up further
export default class ApiClient extends BaseAPIClient {
  getUser = async () => {
    const path = '/cloud-gears/user';
    const [data, { status }] = await this.send('GET', path);
    return { data, status };
  };

  getData = async ({
    teamId,
    activityId,
    athleteId,
    start,
    end,
    aggregation,
    bin,
    axis,
  }: GetDataRequest) => {
    const path = `/analytics/person/${athleteId}/activity/${activityId}/data`;
    const options = {
      query: {
        team_id: teamId,
        start: start.toISOString(),
        end: end.toISOString(),
        aggregation,
        bin,
        axis,
      },
    } as const;
    const [json, res] = await this.send('GET', path, options);
    raiseForStatus(res);
    return json as {
      data: { data: { L: { x: number; y: number }[]; R: { x: number; y: number }[] } };
    };
  };

  generateCsv = async ({ teamId, activityId, athleteId }: GenerateCsvRequest) => {
    const path = `/analytics/person/${athleteId}/activity/${activityId}/files/csv`;
    const options = {
      query: {
        team_id: teamId,
      },
    } as const;
    const [json] = await this.send('POST', path, options);
    return json;
  };

  getChatToken = async ({ userId }: GetChatToken) => {
    const path = `/cloud-gears/user/${userId}/chat/token`;
    const [token] = await this.send('GET', path);
    return token as string;
  };
}
