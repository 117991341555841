import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material';
import { Group } from '@visx/group';

import { POD3629DIMENSIONS } from './constants';
import { PodVirtualClone } from './PodVirtualClone';

const useStyles = makeStyles()((theme) => ({
  podContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'end',
  },
}));

export function PodVis({ podId }: { podId: string }) {
  const { classes } = useStyles();
  const theme = useTheme();
  const fill = podId.endsWith('0') ? theme.palette.plantiga.orange : theme.palette.plantiga.green;
  return (
    <div className={classes.podContainer}>
      <svg width={120 + 30}>
        <Group top={60} left={90}>
          <svg
            viewBox={`0 0 ${POD3629DIMENSIONS.width} ${POD3629DIMENSIONS.height}`}
            width={60}
            height={(60 * POD3629DIMENSIONS.height) / POD3629DIMENSIONS.width}
          >
            <rect
              x={0}
              y={0}
              width={POD3629DIMENSIONS.width}
              height={POD3629DIMENSIONS.height}
              rx={3}
              fill={theme.palette.plantiga.lightCharcoal}
            />
            <rect x={2} y={16} width={25} height={18} rx={3} fill={fill} />
            {new Array(6).fill(0).map((_, i) => (
              <circle cx={8 + i * 2.5} cy={8} r={1} fill="gold" />
            ))}
          </svg>
        </Group>
        <PodVirtualClone podId={podId} />
      </svg>
    </div>
  );
}
