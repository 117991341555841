import React from 'react';

import { Link as LinkIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

function getName(provider: string): string {
  switch (provider) {
    case 'GARMIN':
      return 'Garmin';

    case 'POLAR':
      return 'Polar';

    case 'GOOGLE':
      return 'Google Fit';

    case 'WITHINGS':
      return 'Withings';

    case 'FITBIT':
      return 'Fitbit';

    default:
      return 'an external source';
  }
}

type Props = {
  provider: string;
};

export default function ActivityProvider({ provider }: Props) {
  const name = getName(provider);
  return (
    <Tooltip title={`This activity was created by ${name}`}>
      <LinkIcon color="disabled" />
    </Tooltip>
  );
}
