import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Chip } from '@mui/material';

import AthleteAvatar from '../Athletes/Avatar';
import type { Athlete } from '../Firestore/Athletes/typedefs';

const useStyles = makeStyles()((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  athleteId: string;
  athlete: Athlete | null | undefined;
};

export default function AthleteChip({ athleteId, athlete }: Props) {
  const { classes } = useStyles();
  if (!athlete) {
    // Missing athlete from store
    return <span />;
  }
  return (
    <Chip
      key={athleteId}
      label={athlete.name}
      className={classes.chip}
      avatar={<AthleteAvatar athlete={athlete} chip />}
    />
  );
}
