import React from 'react';

import type { KeyedMutator } from 'swr';

import type { MetricDefns } from '@plantiga/activity-summaries';

import fields from 'plantiga-util/fields';

import type { Fields } from './typedefs';

type FieldsContextType = {
  fields: Fields;
  loading: boolean;
  error: Error | undefined;
  mutate: KeyedMutator<MetricDefns>;
};

const FieldsContext: React.Context<FieldsContextType> = React.createContext<FieldsContextType>({
  fields,
  loading: true,
  error: undefined,
  mutate: async () => undefined,
});

export default FieldsContext;
