import React from 'react';

import { Typography } from '@mui/material';

import CustomLabels from 'plantiga-common/Labels/CustomLabels';
import type { ActivityLabels } from 'plantiga-firebase/Activity/typedefs';
import useActivity, { useUpdateActivity } from 'plantiga-firebase/Activity/useActivity';

type Props = {
  activityId: string;
};

export default function CustomActivityLabels({ activityId }: Props) {
  const { activity } = useActivity(activityId);
  const { update, loading } = useUpdateActivity(activityId);

  const handleSave = React.useCallback(
    async (newLabels: ActivityLabels) => {
      await update({ labels: newLabels });
    },
    [update],
  );

  if (activity == null) {
    return null;
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Labels
      </Typography>
      <CustomLabels
        labels={activity?.labels ?? {}}
        variant="activity"
        requiredLabelVariant={activity?.labels?.type === 'load_carriage' ? 'load_carriage' : 'none'}
        onSave={handleSave}
        loading={loading}
      />
    </div>
  );
}
