import React from 'react';

import { pickBy } from 'lodash-es';

import { useFields, type Fields } from 'plantiga-common/Fields';

import { useMetadataForQuestions } from './useQuestionMeta';

export function useQuestionDefns() {
  const { fields } = useFields();
  const qm = useMetadataForQuestions();
  return React.useMemo(() => pickBy(fields, (_, id) => id in qm) as Fields, [fields, qm]);
}
