import React from 'react';

import { startOfDay, endOfDay } from 'date-fns';

import useActivitiesInDateRange from './useActivitiesInDateRange';

export default function useActivitiesForDayOf(athleteId: string | null, date: Date) {
  const [start, end] = React.useMemo(() => [startOfDay(date), endOfDay(date)], [date]);
  return useActivitiesInDateRange(athleteId, start, end);
}
