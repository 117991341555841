import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import NotFound from 'plantiga-component/Error/NotFound';
import Loading from 'plantiga-component/Loading';

import ActivityIdLayout from './_root';
import urls, { root } from './_urls';
import ActivityRedirect from './ActivityRedirect';
import files from './files';
import info from './info';
import labels from './labels';
import summaries from './summaries';

const explore = React.lazy(() => import('./explore'));
const report = React.lazy(() => import('./report'));

export default function ActivityIdRoutes() {
  return (
    <ActivityIdLayout>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Redirect from={urls.athleteActivityRaw} to={urls.athleteActivityExplore} />
          <Redirect exact from={root} to={urls.activityExplore} />
          <Redirect exact from={urls.activitySummaries} to={urls.activityStatsForNerds} />
          <Route path={urls.athleteActivities} component={ActivityRedirect} />

          <Route path={urls.activityExplore} exact component={explore} />
          <Route path={urls.activityInfo} exact component={info} />
          <Route path={urls.activityLabels} exact component={labels} />
          <Route path={urls.activityFiles} exact component={files} />
          <Route path={urls.activityReport} exact component={report} />
          <Route path={urls.activityStatsForNerds} exact component={summaries} />
          <Route path={urls.activity} component={NotFound} />
        </Switch>
      </React.Suspense>
    </ActivityIdLayout>
  );
}
