import React from 'react';

import InfoView from 'plantiga-component/Activity/Pages/InfoView';

import { ActivityContext } from './_root';

export default function () {
  const { activity } = React.useContext(ActivityContext);
  return <InfoView activity={activity} />;
}
