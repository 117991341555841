import { format as formatDate } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import { getCurrentTimezone } from 'plantiga-util/timezone';

export const dailyDayUrlFormat = (date: Date): string => formatDate(date, 'yyyy-MM-dd');

export const utcIsoDate = (date: Date): string => {
  const currentTimezone = getCurrentTimezone();
  const athleteTimezone = currentTimezone;
  const utcDate = zonedTimeToUtc(date, currentTimezone.name);
  const zonedDate = utcToZonedTime(utcDate, athleteTimezone.name);
  return formatDate(zonedDate, 'yyyy-MM-dd', {
    // @ts-expect-error - TS2345 - Argument of type '{ timeZone: string; }' is not assignable to parameter of type '{ locale?: Locale | undefined; weekStartsOn?: 0 | 3 | 1 | 5 | 4 | 2 | 6 | undefined; firstWeekContainsDate?: number | undefined; useAdditionalWeekYearTokens?: boolean | undefined; useAdditionalDayOfYearTokens?: boolean | undefined; }'.
    timeZone: athleteTimezone.name,
  });
};
