import React, { useMemo } from 'react';

import { collection } from '..';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import useSubscribe from '../useSubscribe';

import type { Devices } from './typedefs';

type DevicesContextType = {
  readonly loading: boolean;
  readonly devices: Devices;
  readonly error: Error | null | undefined;
};

const DevicesContext: React.Context<DevicesContextType> = React.createContext<DevicesContextType>({
  loading: true,
  devices: {},
  error: null,
});

export function useDevicesContext(): DevicesContextType {
  const { teamId } = useTeam();

  const db = useFirestore();
  const ref = useMemo(() => collection(db, 'teams', teamId, 'devices'), [db, teamId]);
  const [devices, loading, error] = useSubscribe(ref);

  return { loading, devices, error };
}

export default DevicesContext;
