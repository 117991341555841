import React from 'react';

import { keys, merge, size, set } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

import { Collapse, TextField, Typography } from '@mui/material';

import { AutocompleteActivityType } from 'plantiga-common/AutocompleteActivityType';
import PageGutterPortal from 'plantiga-common/PageGutterPortal';
import { QuestionSlider } from 'plantiga-component/Questions/QuestionSlider';
import { useQuestionDefns } from 'plantiga-component/Questions/useQuestionDefns';
import useActivityTypes from 'plantiga-firebase/ActivityTypes/useActivityTypes';
import type { Athletes } from 'plantiga-firebase/Athletes/typedefs';
import useFeatureFlag from 'plantiga-firebase/FeatureFlags/useFeatureFlag';
import type { Stopwatch, StopwatchPreDB } from 'plantiga-firebase/Stopwatch/typedefs';
import { stripServicePrefix } from 'plantiga-util/metric';

import FinalizeActions from './FinalizeActions';
import FinalizeDropdown from './FinalizeDropdown';

const useStyles = makeStyles()((theme) => ({
  selectDescription: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  readonly athletes: Athletes;
  readonly activityTypeId: string;
  readonly swid: string;
  readonly stopwatch: Stopwatch;
};
type Specific = Record<string, StopwatchPreDB>;

const initSpecific = (stopwatch: Stopwatch): Specific => {
  const specific: Record<string, any> = {};
  stopwatch.athlete_ids.forEach((id) => {
    specific[id] = {
      activity_labels: {},
    };
  });
  return specific;
};

const initGeneric = (stopwatch: Stopwatch): Partial<StopwatchPreDB> => ({
  name: stopwatch.name,
  notes: stopwatch.notes,
  activity_labels: { ...stopwatch.activity_labels },
});

export default function Finalize({ athletes, activityTypeId, swid, stopwatch }: Props) {
  const { classes } = useStyles();
  const [specific, setSpecific] = React.useState(initSpecific(stopwatch));
  const [generic, setGeneric] = React.useState(initGeneric(stopwatch));
  const [currentAthlete, setCurrentAthlete] = React.useState<string | null>(null);
  const questionsFF = useFeatureFlag('activity_questions');
  const actTypes = useActivityTypes();
  const finalized = stopwatch.run_state === 'finalized';
  const questionDefns = useQuestionDefns();

  const merged = React.useMemo(() => {
    if (currentAthlete && currentAthlete in specific) {
      return merge({}, generic, specific[currentAthlete]);
    }
    return generic;
  }, [currentAthlete, generic, specific]);

  const updateField = React.useCallback(
    (path: string, value: any) => {
      if (currentAthlete) {
        setSpecific((prev) => set({ ...prev }, `${currentAthlete}.${path}`, value));
      } else {
        setGeneric((prev) => set({ ...prev }, path, value));
      }
    },
    [currentAthlete],
  );

  const updateActivityType = React.useCallback(
    (id: string) => {
      const actType = actTypes[id];

      if (currentAthlete) {
        setSpecific((prev) => ({
          ...prev,
          [currentAthlete]: {
            ...prev[currentAthlete],
            activity_labels: {
              ...prev[currentAthlete].activity_labels,
              ...actType.defn.activity_labels,
              type_id: id,
            },
          },
        }));
        return;
      }

      setGeneric((prev) => ({
        ...prev,
        activity_labels: {
          ...prev.activity_labels,
          ...actType.defn.activity_labels,
          type_id: id,
        },
      }));
    },
    [currentAthlete, actTypes],
  );

  const unselectedLabelText = (() => {
    const { name } = athletes[keys(athletes)[0]];
    const numOfOtherAthletes = size(athletes) - 1;
    if (numOfOtherAthletes === 0) {
      return name;
    }
    if (numOfOtherAthletes === 1) {
      return `${name} and 1 other person`;
    }
    return `${name} and ${numOfOtherAthletes} other person`;
  })();

  const dropdownSubtext = (() => {
    const prefix = finalized ? 'View stopwatch' : 'Customize';
    const suffix = currentAthlete ? athletes[currentAthlete].name : unselectedLabelText;
    return `${prefix} for ${suffix}`;
  })();

  return (
    <>
      <FinalizeDropdown
        currentItem={currentAthlete}
        items={athletes}
        onChange={(id) => setCurrentAthlete(id)}
        disabled={size(athletes) === 1}
        unselectedLabelText={unselectedLabelText}
      />
      <Typography className={classes.selectDescription} gutterBottom>
        {dropdownSubtext}
      </Typography>
      <div>
        <AutocompleteActivityType
          activityTypeId={merged.activity_labels?.type_id ?? merged.activity_labels?.type ?? ''}
          onChange={updateActivityType}
          disabled={finalized}
          textFieldProps={{
            fullWidth: true,
          }}
        />
      </div>
      <TextField
        fullWidth
        label="Name"
        margin="normal"
        variant="outlined"
        value={merged.name}
        onChange={(e) => updateField('name', e.currentTarget.value)}
        disabled={finalized}
      />
      <TextField
        fullWidth
        multiline
        label="Notes"
        helperText="ex. How did you feel? Did you have any pain? How hard was the activity?"
        margin="normal"
        variant="outlined"
        value={merged.notes}
        onChange={(e) => updateField('notes', e.currentTarget.value)}
        disabled={finalized}
      />
      <Collapse in={questionsFF && (currentAthlete != null || stopwatch.athlete_ids.length === 1)}>
        {Object.keys(questionDefns).map((fieldId) => {
          const data = currentAthlete ? specific[currentAthlete] : generic;
          const strValue = data.activity_labels?.[stripServicePrefix(fieldId)];
          const value = strValue ? +strValue : null;
          return (
            <QuestionSlider
              key={fieldId}
              fieldId={fieldId}
              value={value}
              onChange={(v) => updateField(`activity_labels.${stripServicePrefix(fieldId)}`, v)}
            />
          );
        })}
      </Collapse>
      {/* spacer to ensure the page content is not blocked by the bottom gutter */}
      <div style={{ height: 120 }} />
      <PageGutterPortal gutter="bottom">
        <FinalizeActions
          disabled={false}
          activityTypeId={activityTypeId}
          swid={swid}
          stopwatch={stopwatch}
          athletes={athletes}
          finalized={finalized}
          generic={generic}
          specific={specific}
        />
      </PageGutterPortal>
    </>
  );
}
