import React from 'react';

import useActivityMetrics from 'plantiga-component/Metric/useActivityMetrics';
import useActivity from 'plantiga-firebase/Activity/useActivity';
import { useActivityType } from 'plantiga-firebase/ActivityTypes/useActivityTypes';

import { useQuestionDefns } from './useQuestionDefns';

export function useActivityQuestions(activityId: string) {
  const { activity } = useActivity(activityId);
  const actType = useActivityType(activity?.labels.type_id ?? activity?.labels.type);
  const questionDefns = useQuestionDefns();
  const fieldIds = React.useMemo(
    () => Object.values(questionDefns).map((v) => v.id),
    [questionDefns],
  );
  return useActivityMetrics(
    activity?.athlete_id ?? null,
    activityId,
    actType?.id ?? null,
    fieldIds,
  );
}
