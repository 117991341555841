import React from 'react';

import { makeStyles } from 'tss-react/mui';

import { Grid, Typography } from '@mui/material';

import stepsURL from 'plantiga-assets/steps.jpg';

const useStyles = makeStyles()((theme) => ({
  root: {
    background: `url('${stepsURL}')`,
    backgroundPositionX: 'center',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heroContainer: {
    display: 'inline-block',
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeftColor: theme.palette.primary.main,
  },

  hero: {
    display: 'block',
    color: 'white',
    textShadow: theme.shadows[24],
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export default function HeroGridItem() {
  const { classes } = useStyles();
  return (
    <Grid item xs={12} sm={6} className={classes.root}>
      <div className={classes.heroContainer}>
        <Typography variant="h4" className={classes.hero}>
          Human
        </Typography>
        <Typography variant="h4" className={classes.hero}>
          Movement
        </Typography>
        <Typography variant="h4" className={classes.hero}>
          Intelligence
        </Typography>
      </div>
    </Grid>
  );
}
