import React from 'react';

import { useToast } from 'plantiga-component/Toast/UseToast';
import { httpsCallablePost } from 'plantiga-firebase/util';

import type { PlantigaEvent } from './typedefs';

export default function useCreateEvent(): {
  create: (event: PlantigaEvent) => Promise<string | null | undefined>;
  error: undefined;
  loading: boolean;
} {
  const postToast = useToast();
  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>();

  const create = React.useCallback(
    async (event: PlantigaEvent): Promise<string | null | undefined> => {
      setLoading(true);
      const { team_id: teamId, date } = event;
      const flatTimeEvent = { ...event, date: date.toMillis() } as const;
      try {
        const createEventCF = httpsCallablePost('web-routes/event/createEvent');
        const { data } = await createEventCF({ teamId, event: flatTimeEvent });
        if (data.ok !== true) {
          throw new Error(JSON.stringify(data, null, 2));
        }
        if (unMounted.current) return null;
        setLoading(false);
        const { eventId } = data;
        return eventId;
      } catch (err: any) {
        console.error(err);
        if (unMounted.current) return null;
        postToast({ message: 'Could not create the event', variant: 'error' });
        setError(err);
      }
      if (unMounted.current) return null;
      setLoading(false);
      return null;
    },
    [postToast],
  );

  return { create, loading, error };
}
