import { query, where } from 'firebase/firestore';
import React from 'react';

import { collection } from '..';
import useFirestore from '../useFirestore';
import useSubscribe from '../useSubscribe';

export default function useSummaries(
  athleteId: string,
  activityIds?: Array<string> | null,
  summaryType?: string,
) {
  const db = useFirestore();

  const ref = React.useMemo(() => {
    if (activityIds == null) {
      return null;
    }
    if (activityIds.length > 10) {
      console.error('You are trying to query more than 10 activities at once');
    }
    const query1 = query(
      collection(db, 'athletes', athleteId, 'summaries'),
      where('activity_id', 'in', activityIds.slice(0, 10)),
    );
    if (summaryType == null) {
      return query1;
    }
    return query(query1, where('summary_type', '==', summaryType));
  }, [db, athleteId, activityIds, summaryType]);

  const [summaries, loading] = useSubscribe(ref);

  return { summaries, loading };
}
