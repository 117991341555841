import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ProfileLayout from './_root';
import urls from './_urls';
import events from './events';
import files from './files';
import info from './info';
import labels from './labels';

export default function ProfileRoutes() {
  return (
    <ProfileLayout>
      <Switch>
        <Redirect exact from={urls.athleteProfile} to={urls.athleteProfileInfo} />
        <Route path={urls.athleteProfileInfo} component={info} />
        <Route path={urls.athleteProfileLabels} component={labels} />
        <Route path={urls.athleteProfileEvents} component={events} />
        <Route path={urls.athleteProfileFiles} component={files} />
      </Switch>
    </ProfileLayout>
  );
}
