import React from 'react';

import makeDebug from 'debug';

import FeatureFlagsContext from './FeatureFlagsContext';
import type { FlagNames } from './typedefs';

const debug = makeDebug('plantiga:feature_flag');

export default function useFeatureFlag(flagName: FlagNames) {
  const { featureFlags } = React.useContext(FeatureFlagsContext);
  const enabled = featureFlags[flagName] ?? false;
  React.useEffect(() => {
    debug(`Using FeatureFlag:${flagName}=${enabled.toString()}`);
  }, [enabled, flagName]);
  return enabled;
}

export function useFeatureFlags() {
  const { featureFlags } = React.useContext(FeatureFlagsContext);
  return featureFlags;
}
