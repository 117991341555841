import org from './org/[teamId]/_urls';

export const root = '/(team|activity|athletes|org|new-team|new-org|current-org)';

export default {
  welcome: '/',
  team: '/org',
  teamProfileRedirect: '/:page(team|activity|athletes)/:rest*',
  currentTeam: '/current-org',
  newTeam: '/new-org',
  newTeamRedirect: '/new-team',
  ...org,
};
