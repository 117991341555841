import React from 'react';

import { isSameDay } from 'date-fns';

import { Typography } from '@mui/material';

import { formatDuration } from 'plantiga-util/time';

import { teamDashboardTemplate } from './constants';
import type { TeamDashDatum } from './useTeamDashboard';

type Props = {
  athleteId: string;
  date: Date;
  data: TeamDashDatum[] | undefined;
};

const TEMPLATE = teamDashboardTemplate.move_time;

export function MoveTime({ athleteId, date, data }: Props) {
  const datum = data?.find(
    (v) => v.fieldId === TEMPLATE.move && v.athleteId === athleteId && isSameDay(v.date, date),
  );

  if (datum == null) return null;
  return (
    <Typography noWrap>{formatDuration(new Date(0), new Date(datum.value * 1000))}</Typography>
  );
}
