import React from 'react';

import { AccountCircle as AccountIcon } from '@mui/icons-material';
import { Popover } from '@mui/material';

import UserCard from '../UserCard';

import DrawerItem from './DrawerItem';

export default function AccountDrawerItem() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <DrawerItem
        id="nav-account-button"
        icon={<AccountIcon />}
        text="Account"
        onClick={handleOpen}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <UserCard handleClose={handleClose} />
      </Popover>
    </>
  );
}
