import React from 'react';
import { Route, Switch } from 'react-router-dom';

import NotFound from 'plantiga-component/Error/NotFound';

import AthleteRoutes from './[athleteId]/_routes';
import { root as athleteRoot } from './[athleteId]/_urls';
import AthletesLayout from './_root';
import urls from './_urls';
import AthleteRedirectByName from './name_[name]';

import index from './index';
// TODO: this should be "name.[name]" to follow remix conventions, but that causes an issue
// with the unused-exports parser

export default function AthletesRoutes() {
  return (
    <AthletesLayout>
      <Switch>
        <Route path={urls.athletes} exact component={index} />
        <Route path={urls.athleteRedirectByName} exact component={AthleteRedirectByName} />
        <Route path={athleteRoot} component={AthleteRoutes} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </AthletesLayout>
  );
}
