function pad(num: number, size: number) {
  const s = `000000000${num}`;
  return s.substr(s.length - size);
}

export default function format(
  time: number,
  useHours?: boolean,
  useMillis: boolean = true,
): string {
  let t = time;
  const hour = Math.floor(t / (1000 * 60 * 60));
  t %= 1000 * 60 * 60;
  const minute = Math.floor(t / (1000 * 60));
  t %= 1000 * 60;
  const second = Math.floor(t / 1000);
  t %= 1000;
  const ms = Math.floor(t / 10);
  let base = pad(second, 2);
  if (useMillis) {
    base = `${base}:${pad(ms, 2)}`;
  }
  base = `${pad(minute, 2)}:${base}`;

  if (!useHours && !hour) {
    return base;
  }
  return `${pad(hour, 2)}:${base}`;
}
