import React from 'react';

import { mapValues, merge, pickBy } from 'lodash-es';
import useSWR from 'swr';

import type { MetricDefns } from '@plantiga/activity-summaries';

import Wait from 'plantiga-common/Wait';
import useTeam from 'plantiga-firebase/Team/useTeam';
import _fields from 'plantiga-util/fields';
import { useLocalCache } from 'plantiga-util/useLocalCache';

import useActivitySummariesClient from '../useActivitySummariesClient';

import Context from './FieldsContext';

type Props = {
  readonly children: React.ReactNode;
};

export function MetricDefnsProvider({ children }: Props) {
  const { teamId } = useTeam();
  const client = useActivitySummariesClient();
  const [localMetricDefns, setLocalMetricDefns] = useLocalCache<{
    teamId: string;
    data: MetricDefns | undefined;
  }>('metric-defns', true);

  const fetcher = async ([func, orgId]: [typeof client.getOrgMetricDefinitions, string]) => {
    const { metric_definitions: defns } = await func(orgId);
    return defns;
  };
  const swrRes = useSWR([client.getOrgMetricDefinitions, teamId], fetcher, {
    // definitions only need to be fetched once
    revalidateOnFocus: false,
    keepPreviousData: true,
    fallbackData: localMetricDefns?.teamId === teamId ? localMetricDefns.data : undefined,
  });

  React.useEffect(() => {
    setLocalMetricDefns({ teamId, data: swrRes.data });
  }, [swrRes.data, setLocalMetricDefns, teamId]);

  const value = React.useMemo(
    () =>
      ({
        loading: swrRes.isLoading,
        error: swrRes.error,
        // use fields.yml as the source of truth for metrics defined there.
        fields: mapValues(
          merge({}, _fields, pickBy(swrRes.data, (_, k) => k.includes(':')) ?? {}),
          (v, id) => ({ ...v, id }),
        ),
        mutate: swrRes.mutate,
      } as const),
    [swrRes],
  );

  return (
    <Wait waitingOn={swrRes.data == null} error={swrRes.error} info="Metric Definitions">
      <Context.Provider value={value}>{children}</Context.Provider>
    </Wait>
  );
}
