/* eslint-disable react/sort-comp */
import { updateDoc } from 'firebase/firestore';
import React, { useCallback } from 'react';

import makeDebug from 'debug';

import { doc } from '..';
import { getCurrentTimezone } from '../../../util/timezone';
import { useToast } from '../../Toast/UseToast';
import useTeam from '../Team/useTeam';
import useFirestore from '../useFirestore';
import { httpsCallablePost } from '../util';

import type { PartialAthlete } from './typedefs';

export type MetricKind = 'ground_events' | 'impact_force';

const debug = makeDebug('plantiga:firestore-component');

// TODO refactor as useUpdateTimezone
export function useAthleteActions(): {
  updateTimezone: (athleteId: string, currentTimezone?: any) => Promise<void>;
} {
  const { teamId } = useTeam();

  const updateTimezone = useCallback(
    async (athleteId: string, currentTimezone: any) => {
      const updateTimezoneCF = httpsCallablePost('web-routes/team/updateTimezone');
      const req = { teamId, athleteId, timezone: currentTimezone } as const;

      await updateTimezoneCF(req);
    },
    [teamId],
  );

  return {
    updateTimezone,
  };
}

export function useDeleteAthlete(): (athleteId: string) => Promise<void> {
  const { teamId } = useTeam();
  const postToast = useToast();

  const deleteAthlete = React.useCallback(
    async (athleteId: string) => {
      const deleteAthleteCF = httpsCallablePost('web-routes/team/deleteAthlete');
      const req = {
        athleteId,
        teamId,
      } as const;
      debug('deleteAthlete', req);
      try {
        await deleteAthleteCF(req);
      } catch (err: any) {
        console.error(err);
        postToast({ message: 'Could not delete person', variant: 'error' });
      }
    },
    [teamId, postToast],
  );

  return deleteAthlete;
}

export function useAddAthlete(): (
  name: string,
  gender: string,
  dateOfBirth: Date | null | undefined,
  height: number,
  weight: number,
) =>
  | Promise<any>
  | Promise<{
      athleteId: null;
      ok: string;
    }> {
  const { teamId } = useTeam();
  const postToast = useToast();

  const addAthlete = useCallback(
    async (
      name: string,
      gender: string,
      dateOfBirth: Date | null | undefined,
      height: number,
      weight: number,
    ) => {
      const createAthleteCF = httpsCallablePost('web-routes/team/createAthlete');
      const timezone = getCurrentTimezone();
      const req = {
        teamId,
        name,
        gender,
        date_of_birth: dateOfBirth ? dateOfBirth.toJSON() : null,
        height,
        weight,
        timezone,
      } as const;
      debug('addAthlete', req);
      try {
        const { data } = await createAthleteCF(req);
        return data;
      } catch (err: any) {
        console.error(err);
        postToast({ message: 'Could not create person', variant: 'error' });
      }
      return { athleteId: null, ok: 'false' };
    },
    [teamId, postToast],
  );

  return addAthlete;
}

export function useEditAthlete(): {
  editAthlete: (athleteId: string, athlete: PartialAthlete) => Promise<void>;
  error: boolean;
  loading: boolean;
} {
  const unMounted = React.useRef(false);
  React.useEffect(
    () => () => {
      unMounted.current = true;
    },
    [],
  );
  const { teamId } = useTeam();
  const db = useFirestore();
  const postToast = useToast();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const editAthlete = React.useCallback(
    async (athleteId: string, athlete: PartialAthlete) => {
      const ref = doc(db, 'teams', teamId, 'athletes', athleteId);
      setLoading(true);
      try {
        await updateDoc(ref, athlete);
      } catch (err: any) {
        console.error(err);
        if (unMounted.current) return;
        postToast({ message: 'Could not update person', variant: 'error' });
        setError(err);
      }
      if (unMounted.current) return;
      setLoading(false);
    },
    [db, teamId, postToast],
  );

  return { editAthlete, loading, error };
}
