import React from 'react';

import { merge, pickBy } from 'lodash-es';
import useSWR from 'swr';

import useTeam from 'plantiga-firebase/Team/useTeam';
import _timeseries from 'plantiga-util/timeSeries';

import useActivitySummariesClient from '../useActivitySummariesClient';

import Context from './TimeseriesContext';

type Props = {
  readonly children: React.ReactNode;
};

export function TimeseriesDefnsProvider({ children }: Props) {
  const { teamId } = useTeam();
  const client = useActivitySummariesClient();

  const fetcher = async ([func, orgId]: [typeof client.getOrgTimeseriesDefinitions, string]) => {
    const { timeseries_definitions: defns } = await func(orgId);
    return defns;
  };
  const { data, error, isLoading } = useSWR([client.getOrgTimeseriesDefinitions, teamId], fetcher, {
    // definitions only need to be fetched once on mount
    revalidateOnFocus: false,
  });

  const value = React.useMemo(
    () =>
      ({
        loading: isLoading,
        error,
        // use timeseries.yml as the source of truth for metrics defined there.
        timeseries: merge({}, _timeseries, pickBy(data, (_, k) => k.includes(':')) ?? {}),
      } as const),
    [data, error, isLoading],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
