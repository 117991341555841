import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { useTierViews } from 'plantiga-auth/useTierViews';
import TriggerRenderError from 'plantiga-component/Error/TriggerRenderError';
import Loading from 'plantiga-component/Loading';
import { useIsUserAdmin } from 'plantiga-firebase/Auth/useUser';

import TeamLayout from './_root';
import urls, { root } from './_urls';
import activity from './activity';
import { root as devicesRoot } from './devices/_urls';
import people from './people/_routes';
import { root as peopleRoot } from './people/_urls';
import { root as profileRoot } from './profile/_urls';
import { root as purchaseRoot } from './purchase/_urls';
import stopwatch from './stopwatch/_routes';
import {
  root as stopwatchRoot,
  rootDeprecated as stopwatchRootDeprecated,
} from './stopwatch/_urls';

import index from './index';

const SentryRoute = Sentry.withSentryRouting(Route);

const feed = React.lazy(() => import('./feed'));
const profile = React.lazy(() => import('./profile/_routes'));
const purchase = React.lazy(() => import('./purchase/_routes'));
const devices = React.lazy(() => import('./devices/_routes'));
const research = React.lazy(() => import('./research'));

export default function TeamRoutes() {
  const { exclude: redirectViews } = useTierViews();
  const isAdmin = useIsUserAdmin();
  return (
    <TeamLayout>
      <React.Suspense fallback={<Loading />}>
        <Switch>
          {/* Redirect views not included in a tier back to the root page */}
          {!isAdmin &&
            [...redirectViews].map((view) => (
              <Redirect key={view} from={view in urls ? urls[view] : undefined} to={root} />
            ))}
          {/* Report UI release - landing page is the athletes list */}
          <SentryRoute exact path={root} component={index} />
          <SentryRoute path={profileRoot} component={profile} />
          <SentryRoute path={stopwatchRootDeprecated} component={stopwatch} />
          <SentryRoute path={stopwatchRoot} component={stopwatch} />
          <SentryRoute path={peopleRoot} component={people} />
          <SentryRoute path={purchaseRoot} component={purchase} />
          <SentryRoute path={devicesRoot} component={devices} />
          <SentryRoute path={urls.activities} component={activity} />
          <SentryRoute path={urls.teamTestError} exact component={TriggerRenderError} />
          <SentryRoute path={urls.teamFeed} exact component={feed} />
          <Redirect from={urls.teamResearch} to={urls.teamExport} />
          <SentryRoute path={urls.teamExport} exact component={research} />
        </Switch>
      </React.Suspense>
    </TeamLayout>
  );
}
