import React from 'react';

import {
  DeveloperBoard as DeveloperBoardIcon,
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { Badge, IconButton, useTheme } from '@mui/material';

import { useParams } from 'plantiga-common/react-router-hooks';
import Wait from 'plantiga-common/Wait';
import Popover from 'plantiga-component/Common/Popover';
import useBreakpoint from 'plantiga-component/Common/useBreakpoint';
import { PairedPodChips } from 'plantiga-component/Devices/PairedPodChips';
import NotFound from 'plantiga-component/Error/NotFound';
import Loading from 'plantiga-component/Loading';
import TeamAppBarPortal from 'plantiga-component/Nav/AppBars/TeamAppBarPortal';
import AthletesProvider from 'plantiga-firebase/Athletes/AthletesProvider';
import {
  useAthlete,
  useAthletesLoading,
  useAthleteUser,
} from 'plantiga-firebase/Athletes/useAthletes';
import TeamProvider from 'plantiga-firebase/Team/TeamProvider';
import useTeam from 'plantiga-firebase/Team/useTeam';

function WaitForAthletes({ children }: { children: React.ReactNode }) {
  const { loading, error } = useAthletesLoading();
  return (
    <Wait waitingOn={loading} error={error} info="Primary team athletes">
      {children}
    </Wait>
  );
}

type PrimaryTeamProps = {
  athleteId: string;
  children: React.ReactNode;
};

function PrimaryTeamWrapper({ athleteId, children }: PrimaryTeamProps) {
  const athlete = useAthlete(athleteId);
  const { teamId } = useTeam();
  const [athleteUser, loading, error] = useAthleteUser(athleteId);

  if (!athlete) {
    return <NotFound title="Person not found" />;
  }

  return (
    <Wait waitingOn={loading} error={error} info="Athlete user">
      {athleteUser && athleteUser.primary_team_id && athleteUser.primary_team_id !== teamId ? (
        <TeamProvider teamId={athleteUser.primary_team_id}>
          <AthletesProvider>
            <WaitForAthletes>{children}</WaitForAthletes>
          </AthletesProvider>
        </TeamProvider>
      ) : (
        children
      )}
    </Wait>
  );
}

const countUploading = (statuses: { [deviceId: string]: string }) =>
  Object.values(statuses).reduce((sum, v) => sum + +(v === 'uploading'), 0);

function AthleteDevices({ athleteId }: { athleteId: string }) {
  const theme = useTheme();
  const isMobile = useBreakpoint();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [statuses, setStatuses] = React.useState({});

  const onStatusChange = React.useCallback((deviceId: string, status: string) => {
    setStatuses((p) => ({ ...p, [deviceId]: status }));
  }, []);

  if (!isMobile) return <PairedPodChips athleteId={athleteId} hidePairButton />;

  const badgeContent =
    countUploading(statuses) > 0 ? (
      <CloudUploadIcon fontSize="small" style={{ color: theme.palette.graphing.blue }} />
    ) : null;

  return (
    <>
      <IconButton
        size="small"
        edge="end"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
      >
        <Badge badgeContent={badgeContent}>
          <DeveloperBoardIcon />
        </Badge>
      </IconButton>
      <Popover
        keepMounted
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setAnchorEl(null)}
      >
        <div style={{ paddingLeft: 8, paddingRight: 8 }}>
          <PairedPodChips
            athleteId={athleteId}
            hidePairButton
            showChipsMobile
            onStatusChange={onStatusChange}
          />
        </div>
      </Popover>
    </>
  );
}

type Props = {
  children: React.ReactNode;
};

export default function AthleteLayout({ children }: Props) {
  const { athleteId } = useParams();

  return (
    <PrimaryTeamWrapper athleteId={athleteId}>
      <React.Suspense fallback={<Loading />}>{children}</React.Suspense>
      <TeamAppBarPortal>
        <AthleteDevices athleteId={athleteId} />
      </TeamAppBarPortal>
    </PrimaryTeamWrapper>
  );
}
