import React from 'react';

import Dialog from 'plantiga-common/Dialog';
import { useLogEvent } from 'plantiga-firebase/analytics';

import NewPersonStepper from './NewPersonStepper';

type Props = {
  readonly onClose: any;
  readonly open: boolean;
};

export default function AddPersonDialog({ onClose, open }: Props) {
  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (open) {
      logEvent('new_person_stepper__opened');
    }
  }, [open, logEvent]);

  const handleClose = React.useCallback(() => {
    logEvent('new_person_stepper__closed');
    onClose();
  }, [onClose, logEvent]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      title="New person"
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
    >
      <NewPersonStepper onClose={handleClose} />
    </Dialog>
  );
}
