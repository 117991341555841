import React from 'react';
import { Redirect } from 'react-router-dom';

import { findKey } from 'lodash-es';

import { useParams } from 'plantiga-common/react-router-hooks';
import useBuildURL from 'plantiga-common/useBuildURL';
import NotFound from 'plantiga-component/Error/NotFound';
import useAthletes from 'plantiga-firebase/Athletes/useAthletes';

export default function AthleteRedirectByName() {
  const athletes = useAthletes({ withArchived: true });
  const { name } = useParams();
  const buildURL = useBuildURL();

  const athleteId = findKey(athletes, (a) => a.name === name.replace('+', ' '));

  if (athleteId == null) {
    const title = `"${name.replace('+', ' ')}" not found`;
    return <NotFound title={title} />;
  }
  const to = buildURL('athlete', { athleteId });
  return <Redirect to={to} />;
}
